import {useDispatch, useSelector} from "react-redux";
import styled from "styled-components";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import {asyncSendTelegram, calcDistance, commaNum} from "../../toolbox";
import {cloneDeep, filter, get, isEmpty, reverse, sortBy} from "lodash";
import {
    calcTimeDiff,
    distanceFormat,
    getLoginLink,
    isPhoneFormat,
    isUrlFormat,
    toPhoneFormat
} from "../../toolbox/format";
import {useMutation, useQuery} from "@apollo/client";
import {
    UPDATE_NOTIFICATION
} from "../../query/userQuery";
import {Mannerbox} from "../vehicle/component/Mannerbox";
import {loadingAction} from "../../redux/loadingReducer";
import axios from "axios";
import {SERVER_ADDRESS} from "../../index";
import ReactMarkdown from "react-markdown";
import {authAction} from "../../redux/authReducer";
import moment from "moment-timezone";
import {DailyUpdateList} from "./DailyUpdatePage";
import queryString from "query-string";

const _NotificationRow = ({className, data, onChange}) => {
    const dispatch = useDispatch();
    const userInfo = useSelector(({user}) => user.user);
    const location = useLocation();

    const [updateNotification] = useMutation(UPDATE_NOTIFICATION);

    const updateNotificationState = (state) => {
        updateNotification({
            variables: {
                id: data?.id,
                data: {
                    state: state,
                    readedAt: moment.tz('Asia/Seoul'),
                },
            }
        }).then((res) => {
            if (onChange) {
                onChange();
            }
            dispatch(authAction.refresh());
        });
    }

    const updateNotificationRead = () => {
        if (data?.readedAt) {
            return;
        }
        updateNotification({
            variables: {
                id: data?.id,
                data: {
                    readedAt: moment.tz('Asia/Seoul'),
                },
            }
        }).then((res) => {
            if (onChange) {
                onChange();
            }
            dispatch(authAction.refresh());
        });
    }


    const getActions = (notiType, state) => {
        switch (notiType) {
            case 'TEXT':
                return null;
        }
    }

    const getLink = () => {
        if (location.pathname !== '/my/notification') {
            return '/my/notification';
        }
        return data?.link;
    }

    return <div className={className + (data?.readedAt? '': ' unread')} onClick={() => {
        updateNotificationRead();
    }}>
        <a href={getLink()}>
            {/*<a href={"/user/" + data?.sender?.id}>*/}
            <div className="guest">
                <div className="image_wrap">
                    <img src={data?.sender?.profile_image?.url ?? '/layout/char_doori.svg'}></img>
                </div>
                <div className="second_box">
                    <div className="name">
                        <ReactMarkdown linkTarget="_blank" unwrapDisallowed={true} disallowedElements={["ul", "li"]} children={data?.body?.replace(/\n/gi, "&nbsp;\n\n")}/>
                    </div>
                    <div className="time">{calcTimeDiff(data?.createdAt)}</div>
                </div>
            </div>
        </a>
    </div>
}

export const NotificationRow = styled(_NotificationRow)`
  //border-radius: 8px;
  padding-bottom: 6px;
  background-color: white;
  border-bottom: 1px solid #DDDDDD;
  position: relative;
  //margin-bottom: 20px;
  &:hover {
    background-color: #EEEEEE;
  }
  &.unread {
    background-color: #E7F3FF;
    &:hover {
      background-color: #D7E3EF;
    }
  }
  
  > .manner {
    position: absolute;
    top: 8px;
    right: 4px;
  }

  .guest {
    cursor: pointer;
    padding-top: 20px;
    padding-right: 20px;
    display: flex;
    > .image_wrap {
      margin-left: 20px;
      margin-right: 12px;
      padding-bottom: 10px;
      width: 48px;
      height: 48px;
      min-width: 48px;
      min-height: 48px;
      > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
    }
    > .second_box {
      width: 100%;
      margin-top: 4px;
      > .name {
        word-break: keep-all;
        padding-right: 62px;
        font-size: 16px;
        line-height: 18px;
        font-weight: 500;
      }

      > .title {
        display: flex;
        font-size: 14px;
        margin-top: 8px;
        margin-bottom: 8px;
        > span {
          padding: 4px 6px;
          background-color: gray;
          border-radius: 8px;
          white-space: pre-wrap;
          color: white;
          line-height: 18px;
        }
      }

      > .time {
        text-align: right;
        font-size: 12px;
      }
    }
  }

  > .select {
    display: flex;
    flex-grow: 1;
    //border-top: 1px solid #DDDDDD;
    align-items: center;
    min-height: 40px;
    font-size: 14px;
    font-weight: 600;
    padding-left: 12px;
    padding-right: 12px;

    > div {
      padding: 8px;
      flex-grow: 1;
      flex-basis: 80px;
      text-align: center;
      border-radius: 12px;
      color: white;
      margin: 4px;
    }
    
    > .text {
      color: black;
    }

    > .decline {
      cursor: pointer;
      background-color: gray;
    }

    > .accept {
      cursor: pointer;
      background-color: #007aff;
    }

    > .action {
      cursor: pointer;
    }
  }
`

export const _NotificationList = ({className, limit}) => {
    const [notifications, setNotifications] = useState(null);
    const userInfo = useSelector(({user}) => user.user);

    useEffect(() => {
        if (userInfo?.notifications) {
            let notifications = cloneDeep(userInfo.notifications);
            notifications = filter(notifications, (n) => n.notiType !== 'MANNER_ACTION');
            notifications = filter(notifications, (n) => n.state !== 'hide');
            if (limit && notifications.length > limit) {
                notifications.length = limit;
            }
            setNotifications(notifications);
        }
    }, [userInfo]);

    return <div className={className}>
        {notifications && notifications.map((n) =>
            <NotificationRow data={n} />
        )}
        {notifications && notifications.length === 0 && <div>
            알림이 없습니다.
        </div>}
    </div>
}

export const NotificationList = styled(_NotificationList)`
  //box-shadow: 0px -6px 20px -9px rgba(0, 0, 0, 0.18);
  //background-color: #F5F5F5;
`

const TopSelect = styled.div`
  display: flex;
  padding: 20px;
  //margin-bottom: 10px;
  
  
  > h2 {
    margin-right: 8px;
  }
  
  > .black {
    
  }
  
  > .gray {
    color: #9A9A9A;
    cursor: pointer;
  }
`

const NotificationPage = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);

    const parsed = location.search ? queryString.parse(location.search, {ignoreQueryPrefix: true}) : null;
    const [mode, setMode] = useState(parsed?.mode ?? 'notification')

    useEffect(() => {
        if (isEmpty(tokenInfo)) {
            const to = getLoginLink(location);
            navigate(to);
        }
    }, [tokenInfo]);

    return <_NotificationPage>
        <TopSelect>
            <h2 className={mode === 'notification' ? 'black': 'gray'} onClick={() => setMode('notification')}>알림</h2>
            {/*<h2 className={mode === 'daily_update' ? 'black': 'gray'} onClick={() => setMode('daily_update')}>내 주변 소식</h2>*/}
        </TopSelect>
        {/*<StyledFilter vehicles={filteredVehicles} form={form} onChange={onChange} reset={reset}></StyledFilter>*/}
        {mode === 'notification' && <NotificationList/>}
        {mode === 'daily_update' && <DailyUpdateList />}
        {/*<NotificationList/>*/}
        {/*<Requests>*/}
        {/*    {notifications && notifications.map((n) =>*/}
        {/*        <NotificationRow data={n} />*/}
        {/*    )}*/}

            {/*{notifications && notifications.map((r) =>*/}
            {/*    <MatchingRequest matchingRequest={r} />*/}
            {/*).reduce((acc, x, idx2) => acc === null ? [x] : [acc,*/}
            {/*    <MyDivider />,*/}
            {/*    x], null)}*/}
            {/*{notifications && notifications.map((n) =>*/}
            {/*    n.__typename === 'DmRequest' ?*/}
            {/*        <DmRequest dmRequest={n} onUpdate={() => {*/}
            {/*            setToast({severity: 'success', payload: "숨김 처리 되었습니다."});*/}
            {/*            setToastOpen(true);*/}
            {/*            refetchDm().then(() => {});*/}
            {/*        }} onAccept={() => {*/}
            {/*            setToast({severity: 'success', payload: "흠"});*/}
            {/*            setToastOpen(true);*/}
            {/*            refetchDm().then(() => {});*/}
            {/*        }}/>: n.__typename === 'MatchingRequest' ?*/}
            {/*        <MatchingRequest matchingRequest={n} onUpdate={() => {*/}
            {/*            setToast({severity: 'success', payload: "숨김 처리 되었습니다."});*/}
            {/*            setToastOpen(true);*/}
            {/*            refetchMatching().then(() => {});*/}
            {/*        }} onAccept={() => {*/}
            {/*            setToast({severity: 'success', payload: "매니저에게 전달되었습니다."});*/}
            {/*            setToastOpen(true);*/}
            {/*            refetchMatching().then(() => {});*/}
            {/*        }}/>:null*/}
            {/*)}*/}
        {/*    {notifications && notifications.length === 0 && <div>*/}
        {/*        알림이 없습니다.*/}
        {/*    </div>}*/}
        {/*</Requests>*/}
    </_NotificationPage>
}

const _NotificationPage = styled.div`
  font-family: 'Pretendard',sans-serif;
  //padding-left: 10px;
  //padding-right: 10px;

  > h2 {
    padding: 16px;
  }
`
const MyDivider = styled.div`
  width: 100%;
  border-width: 0;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.12);
  border-bottom-width: thin;
  margin-left: 22px;
  margin-right: 22px;
`

const Requests = styled.div`
  box-shadow: 0px -6px 20px -9px rgba(0, 0, 0, 0.18);
  background-color: #F5F5F5;
`

const _Request = styled.div`
  border-radius: 8px;
  background-color: white;
  border: 1px solid #DDDDDD;
  position: relative;
  margin-bottom: 20px;

  > .manner {
    position: absolute;
    top: 8px;
    right: 4px;
  }

  > .guest {
    padding-top: 20px;
    padding-right: 20px;
    display: flex;
    > .image_wrap {
      margin-left: 20px;
      margin-right: 12px;
      padding-bottom: 20px;
      width: 48px;
      height: 48px;
      min-width: 48px;
      min-height: 48px;
      > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
    }
    > .second_box {
      width: 100%;
      margin-bottom: 6px;
      > .name {
        word-break: keep-all;
        padding-right: 62px;
        font-size: 16px;
        font-weight: 500;
      }

      > .title {
        display: flex;
        font-size: 14px;
        margin-top: 8px;
        margin-bottom: 8px;
        > span {
          padding: 4px 6px;
          background-color: gray;
          border-radius: 8px;
          white-space: pre-wrap;
          color: white;
          line-height: 18px;
        }
      }

      > .time {
        text-align: right;
        font-size: 12px;
      }
    }
  }

  > .select {
    display: flex;
    flex-grow: 1;
    border-top: 1px solid #DDDDDD;
    align-items: center;
    min-height: 40px;
    font-size: 14px;
    font-weight: 600;
    
    > div {
      padding: 8px;
      flex-grow: 1;
      flex-basis: 80px;
      text-align: center;
    }
    
    > .decline {
      cursor: pointer;
      color: gray;
      border-right: 1px solid #DDDDDD;
    }

    > .accept {
      cursor: pointer;
      background-color: #007aff;
    }
    
    > .action {
      cursor: pointer;
    }
  }
`;

export default NotificationPage;
