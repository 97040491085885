import FullCalendar from "@fullcalendar/react";
import interactionPlugin from "@fullcalendar/interaction";
import dayGridPlugin from "@fullcalendar/daygrid";
import React from "react";
import styled from "styled-components";


const _MyCalendar = ({className, onClickDate, onSetMonthDates, reservations, onClickEvent}) => <div className={className}>
    <FullCalendar
        plugins={[interactionPlugin, dayGridPlugin]}
        initialView="dayGridMonth"
        buttonText={{
            today: "오늘"
        }}
        selectable={true}
        dateClick={onClickDate}
        eventOrder="createdAt"
        locale="ko"
        headerToolbar={{
            right: "prev,next",
            left: "title"
        }}
        displayEventTime={false}
        datesSet={onSetMonthDates}
        events={reservations}
        height="auto"
        eventClick={onClickEvent}
    />
</div>

export const MyCalendar = styled(_MyCalendar)`
  //.fc-event-title-container {
  //  display: flex;
  //  align-items: center;
  //}
  margin-left: 4px;
  margin-right: 4px;
  .fc-event-title-container {
    padding-top: 2px;
    padding-left: 2px;
    user-select: none;
    white-space: pre-wrap;
    word-break: break-all;
  }
  .fc-day-sat {
    color:#0000FF;
  }

  .fc-day-sun {
    color:#FF0000;
  }
  .loser {
    .fc-event-title {
      text-decoration-line: line-through !important;
    }
  }
  .fc .fc-button-primary{
    background-color: black !important;
    border-color: black !important;
    &:hover{
      opacity: 0.8;
    }
  }  
`
