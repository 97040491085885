import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import qs from 'query-string';
import styled from "styled-components";
import {useMutation} from "@apollo/client";
import {UPDATE_USER} from "../../query/userQuery";
import {loadingAction} from "../../redux/loadingReducer";
import {useLocation} from "react-router-dom";

const PageWrap = styled.div`
  padding: 20px;
  text-align: center;
`

export function EmailIgnorePage() {
    const dispatch = useDispatch();
    const location = useLocation();
    const parsed = location.search ? qs.parse(location.search, {ignoreQueryPrefix: true}) : null;
    const [text, setText] = useState('처리 중입니다.');
    const [updateUser] = useMutation(UPDATE_USER);

    useEffect(() => {
        dispatch(loadingAction.loading(true));
        const userId = parsed.key;
        const payload = {
            variables: {
                id: userId,
                data: {
                    subscription: false,
                }
            }
        };
        updateUser(payload).then(res => {
            setText('정상적으로 처리되었습니다.');
            dispatch(loadingAction.loading(false));
        });
    }, []);

    return (<PageWrap>
        <div>{text}</div>
    </PageWrap>);
};