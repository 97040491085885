import {useEffect, useLayoutEffect, useRef, useState} from "react";
import styled from "styled-components";
import {useWindowSize} from "react-use";
import {useSelector} from "react-redux";

const _VehicleThumbnail = ({className, image, children}) => {
    const size = useWindowSize();
    const imgWrap = useRef();
    const img = useRef();
    const [vehicleRatio, setVehicleRatio] = useState(null);
    const [imgClassName, setImgClassName] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);

    const handleImageLoad = () => {
        setIsLoaded(true);
        const _vehicleRatio = img?.current?.width / img?.current?.height;
        setVehicleRatio(_vehicleRatio);
    };

    useLayoutEffect(() => {
        if (!imgClassName && isLoaded) {
            const _vehicleRatio = img?.current?.width / img?.current?.height;
            setVehicleRatio(_vehicleRatio);
        }
    }, [img?.current?.width, isLoaded]);

    useLayoutEffect(() => {
        if (vehicleRatio) {
            const containerRatio = imgWrap?.current?.clientWidth / imgWrap?.current?.clientHeight;
            const name = vehicleRatio > containerRatio ? 'width-fix': 'height-fix';
            setImgClassName(name);
        }
    }, [vehicleRatio, size])

    return <div className={className} ref={imgWrap}>
        <img className={imgClassName} ref={img} src={image?.url} onLoad={handleImageLoad} />
        <>{children}</>
    </div>
};
export const VehicleThumbnail = styled(_VehicleThumbnail)`
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  height: 120px;
  width: 140px;
  > img {
    border-radius: 8px;
    position: absolute;
    transform: translateX(-50%) translateY(-50%);
    top: 50%;
    left: 50%;
  }

  > .width-fix {
    width: 100%;
    height: auto;
  }
  > .height-fix {
    height: 100%;
    width: auto;
  }
`


export const GuestThumbnail = styled(_VehicleThumbnail)`
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  height: 100px;
  width: 140px;

  ${props => props.small && `
    width: 100px;
  `}

  > img {
    border-radius: 8px;
    position: absolute;
    transform: translateX(-50%) translateY(-50%);
    top: 50%;
    left: 50%;
  }

  > .width-fix {
    width: 100%;
    height: auto;
  }
  > .height-fix {
    height: 100%;
    width: auto;
  }
`
