import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    loading: false,
};

export const loadingSlice = createSlice({
    name: 'loading',
    initialState,
    reducers: {
        loading: (state, action) => {
            state.loading = action.payload;
            return state;
        },
    }
})

export let loadingAction = loadingSlice.actions;
export let loadingReducer = loadingSlice.reducer;

