import styled from "styled-components";
import {useLocation, useNavigate} from "react-router-dom";
import qs from "query-string";
import {useEffect} from "react";
import axios from "axios";
import {SERVER_ADDRESS} from "../../index";
import {useSelector} from "react-redux";
import {paymentIdToOrderId} from "../../toolbox/format";

export const PaymentSuccessPage = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const parsed = location.search ? qs.parse(location.search, {ignoreQueryPrefix: true}) : null;
    const paymentKey = parsed.paymentKey;
    const orderId = parsed.orderId;
    const amount = parsed.amount;

    const userInfo = useSelector(({user}) => user.user);

    useEffect(() => {
        if (!paymentKey || !userInfo) {
            return;
        }
        axios.post(SERVER_ADDRESS + '/api/credit-cards/cardExecute', {
            paymentKey: paymentKey,
            amount: amount,
            orderId: paymentIdToOrderId(orderId),
        }).then((res) => {
            if (res.data === 'DONE') {
                navigate('/my/payment');
            } else {
                alert(JSON.stringify(res.data))
                navigate('/my/payment');
            }
        });
    }, [paymentKey, userInfo]);

    return <_PaymentSuccessPage>
        요청을 처리하는 중 입니다.
    </_PaymentSuccessPage>
}

const _PaymentSuccessPage = styled.div`
  text-align: center;
  padding: 20px;
`
