import {createSlice} from "@reduxjs/toolkit";
import {parseJwt} from "../toolbox";

const initialState = {
    token: undefined,
    tokenInfo: undefined,
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        update: (state, action) => {
            state.token = action.payload;
            state.tokenInfo = parseJwt(action.payload);
            if (state.tokenInfo.id) {
                state.tokenInfo.id = String(state.tokenInfo.id);
            }
            return state;
        },
        refresh: (state, action) => {
            // 의미없는 JWT 변경을 수행한다.
            if (state.token) {
                let _jwt;
                if (state.token.endsWith(" ")) {
                    _jwt = state.token.trimEnd();
                } else {
                    _jwt = state.token + " ";
                }
                state.token = _jwt;
            }
            return state;
        },
        logout: (state, action) => {
            return initialState;
        },
    }
})

export let authAction = authSlice.actions;
export let authReducer = authSlice.reducer;

