import {createSlice} from "@reduxjs/toolkit";
import {cloneDeep} from "lodash";

const initialState = {
    user: undefined,
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        update: (state, action) => {
            const payload = cloneDeep(action.payload);
            if (payload?.email2) {
                payload.email = payload.email2;
            }
            state.user = payload;
            return state;
        },
        logout: (state, action) => {
            state.user = undefined;
            return state;
        }
    }
})

export let userAction = userSlice.actions;
export let userReducer = userSlice.reducer;
