import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    address: undefined,
    longitude: undefined,
    latitude: undefined,
    isGps: undefined
};

export const pathSlice = createSlice({
    name: 'path',
    initialState,
    reducers: {
        update: (state, action) => {
            state.address = action.payload.address;
            state.longitude = action.payload.longitude;
            state.latitude = action.payload.latitude;
            state.isGps = action.payload.isGps;
            return state;
        },
    }
})

export let pathAction = pathSlice.actions;
export let pathReducer = pathSlice.reducer;

