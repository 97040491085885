import {useDispatch, useSelector} from "react-redux";
import styled from "styled-components";
import {useLocation, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {commaNum, openKakaoChat} from "../../toolbox";
import {get, isEmpty, reverse, sortBy} from "lodash";
import {getLoginLink, paymentIdToOrderId, toDateFormat} from "../../toolbox/format";
import {useQuery} from "@apollo/client";
import {GET_PAYMENTS_BY_USER} from "../../query/userQuery";
import {Alert, Snackbar} from "@mui/material";
import {loadTossPayments} from "@tosspayments/payment-sdk";
import {TOSS_CLIENT_KEY} from "../../index";
import queryString from "query-string";
import {flatEntity} from "../../toolbox/query";

const Payment = ({payment}) => {
    const userInfo = useSelector(({user}) => user.user);

    let amount = payment.amount;
    let discount = 0;
    if (payment?.walletUses?.length) {
        for (let w of payment.walletUses) {
            discount += w.amount;
        }
    }
    amount += discount;

    async function onClickToss() {
        const tossPayments = await loadTossPayments(TOSS_CLIENT_KEY);
        await tossPayments.requestPayment('카드', {
            amount: amount,
            orderId: paymentIdToOrderId(payment.id),
            orderName: payment.name,
            customerName: userInfo.realname,
            customerEmail: userInfo.email,
            successUrl: window.location.origin + '/payment_success',
            failUrl: window.location.origin + '/payment_fail',
        }).then((value) => {
            console.log(value);
        }).catch((reason => {
            alert(reason);
        }));
    }
    const statusToString = (status) => {
        if (status === 'READY')
            return '미결제';
        else if (status === 'DONE') {
            return '결제완료';
        }
        else if (status === 'WAITING_FOR_DEPOSIT') {
            return '입금대기';
        }
    }
    return <_Payment status={payment.status}>
        <div className="detail">
            <div className="wrap">
                <div className="date">
                    <span>{toDateFormat(payment.startAt || payment.createdAt)} 청구</span>
                    <span className={payment.status}>{statusToString(payment.status)}</span>
                </div>
                <div className="content">
                    <div className="title">{payment.name}</div>
                    <div className="description">{payment.description}</div>
                </div>
                {/*<div className="title">{payment.description}</div>*/}
                {/*<div className="title">{payment.description}</div>*/}
                {discount? <>
                    <div className="amount">청구 금액 : {commaNum(payment.amount)}원</div>
                    <div className="amount">월렛 차감 : {commaNum(discount)}원</div>
                    <div className="amount">결제 필요 금액 : {commaNum(amount)}원</div>
                </>: <>
                    <div className="amount">{commaNum(payment.amount)}원</div>
                </>}
            </div>
        </div>
        <div className="btn">
            {payment.status === 'DONE' ? <>
            {payment.toss_payment_histories.length > 0 && payment.toss_payment_histories[0].receiptUrl &&
                <div onClick={() => window.open(payment.toss_payment_histories[0].receiptUrl, '_blank')}>매출전표</div>}
                <div onClick={() => {
                    openKakaoChat('payment_EE_open_chat', payment.id);
                    // openChannelTalk('payment_EE_open_chat', payment.id, `${payment.name} (결제ID: ${payment.id}) 결제건에 대해 문의가 있어요!`)
                }}>문의하기</div>
            </>: payment.status === 'READY' ? <>
                <div className="pay" onClick={onClickToss}>결제하기</div>
            </>: <>
                <div onClick={() => {
                    openKakaoChat('payment_EE_open_chat', payment.id);
                    // openChannelTalk('payment_EE_open_chat', payment.id, `${payment.name} (결제ID: ${payment.id}) 결제건에 대해 문의가 있어요!`)
                }}>문의하기</div>
            </>}
        </div>
    </_Payment>
}

export const PaymentPage = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);
    const parsed = location.search ? queryString.parse(location.search, {ignoreQueryPrefix: true}) : null;

    useEffect(() => {
        if (!parsed?.id) {
            if (isEmpty(tokenInfo)) {
                const to = getLoginLink(location);
                navigate(to);
            } else {
                window.history.replaceState(null, null, '?id=' + tokenInfo.id);
            }
        }
    }, [tokenInfo, parsed]);

    useQuery(GET_PAYMENTS_BY_USER, {
        variables: {
            id: parsed?.id ?? tokenInfo?.id,
        },
        onCompleted: data => {
            const payments = get(data, "paymentRequests.data", []);
            setPayments(reverse(sortBy(payments.map(p => flatEntity(p)), (p) => {
                return p.status +  (p.startAt || p.createdAt);
            })));
        }
    });

    const [payments, setPayments] = useState(null);
    const [toast, setToast] = useState({
        severity: 'success',
        payload: '',
    });
    const [toastOpen, setToastOpen] = useState(false);

    return <_PaymentPage>
        <h2>결제 목록</h2>
        <Payments>
            {payments && payments.map((p) =>
                <Payment payment={p} />
            )}

            {payments && payments.length === 0 && <div>
                결제 항목이 없습니다.
            </div>}
        </Payments>
        <Snackbar anchorOrigin={{vertical: 'bottom', horizontal: 'center',}} autoHideDuration={3000} open={toastOpen} onClose={() => setToastOpen(() => false)} >
            <Alert severity={toast.severity}>{toast.payload}</Alert>
        </Snackbar>
    </_PaymentPage>
}

const _PaymentPage = styled.div`
  font-family: 'Pretendard',sans-serif;
  padding-left: 10px;
  padding-right: 10px;

  > h2 {
    padding: 16px;
  }
`
const MyDivider = styled.div`
  width: 100%;
  border-width: 0;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.12);
  border-bottom-width: thin;
  margin-left: 22px;
  margin-right: 22px;
`

const Payments = styled.div`
  box-shadow: 0px -6px 20px -9px rgba(0, 0, 0, 0.18);
  background-color: #F5F5F5;
`

const _Payment = styled.div`
  border-radius: 8px;
  background-color: white;
  position: relative;
  margin-bottom: 30px;
  border: 1px solid #DDDDDD;
  > .detail {
    padding: 12px;
    > .wrap {
      > .date {
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        font-weight: 500;
        padding-left: 2px;
        padding-right: 4px;
        > .DONE {
          color: #008C00;
        }
      }

      > .content {
        font-size: 14px;
        margin: 12px 0 10px;
        padding: 8px 10px;
        background-color: #EEEEEE;
        border-radius: 8px;
        white-space: pre-wrap;
        word-break: keep-all;
        font-weight: 500;
        line-height: 18px;
        > .description {
          font-size: 12px;
          margin-top: 8px;
          font-weight: 300;
        }
      }

      > .amount {
        text-align: right;
        font-size: 12px;
        line-height: 14px;
        font-weight: 700;
      }
    }
  }

  > .btn {
    display: flex;
    flex-grow: 1;
    line-height: 40px;
    font-size: 14px;
    font-weight: 600;
    border-top: inherit;
    > div {
      cursor: pointer;
      color: #202020;
      flex-grow: 1;
      flex-basis: 80px;
      text-align: center;
      border-left: 1px solid #DDDDDD;
      border-right: 1px solid #DDDDDD;
      :first-child {
        border-left: none;
        border-right: none;
      }
      :last-child {
        border-right: none;
      }
    }

    > .pay {
      font-size: 16px;
      font-weight: 600;
      color: #007aff;
    }
  }
`;
