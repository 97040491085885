import styled from "styled-components";
import {FormComponent, RadioComponent, RadioComponentSimple} from "../VehicleAdd2/FormComponent";

const ToHost = ({form, onChange}) => {
    return <>
        <Wrap>
            <h1>호스트에게 한마디</h1>
            <div className="forms">
                <div>
                    <RadioComponent id="추가운전자여부" title="게스트 본인 외 제3자 운전자 여부" form={form} onChange={onChange} description="게스트 본인 외 제3자는 운전이 불가능합니다. 제3자 운전 희망 시 인적사항과 면허증을 제출하여 반드시 호스트의 승낙을 얻어야합니다." values={[
                        {id: '없음', label: '없음'},
                        {id: '있음', label: '있음'},
                    ]}/>
                    {form.추가운전자여부 === '있음' && <FormComponent style={{marginTop: '6px'}} type="text" id="추가운전자" placeholder="게스트 본인과의 관계, 연령을 입력해주세요" onChange={onChange} form={form} />}
                </div>
                <FormComponent rows={3} type="textarea" id="호스트에게 한마디" title="호스트에게 하고싶은 한마디 (선택사항)" placeholder="운전경력/습관 등 나를 어필할 수 있는 내용이나 호스트에게 궁금한 점 등을 자유롭게 작성해주세요." onChange={onChange} form={form} />
            </div>
        </Wrap>
    </>
}

const Wrap = styled.div`
  padding: 0 20px 40px;
  > h1 {
    padding: 32px 0;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
  }
  
  > .forms {
    display: flex;
    flex-direction: column;
    gap: 32px;
    
    p {
      color: var(--gray-scale-gray-700, #939393);
      font-feature-settings: 'clig' off, 'liga' off;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      margin: 4px 0;
    }
  }
`

export default ToHost;