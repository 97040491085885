import styled from "styled-components";
import {KakaoFloat} from "../../layout/KakaoFloat";
import {useEffect, useRef, useState} from "react";
import qs from "query-string";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {commaNum} from "../../toolbox";
import axios from "axios";
import {isEmpty} from "lodash";
import {getLoginLink} from "../../toolbox/format";
import {SERVER_ADDRESS} from "../../index";
import {calcDooriTax, calcTax} from "../../toolbox/calculate";
import {openAddress} from "../../toolbox/logic";

const HostSimpleWritePage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);

    const parsed = location.search ? qs.parse(location.search, {ignoreQueryPrefix: true}) : null;

    const { storage } = useSelector(({sessionGuard}) => sessionGuard);
    const [payload, setPayload] = useState();

    useEffect(() => {
        if (parsed.key) {
            setPayload(storage[parsed.key]);
        }
    }, [parsed.key]);

    const onClickExit = () => {
        navigate('/vehicle/write');
    }

    const onClickSubmit = () => {
        if (isEmpty(tokenInfo)) {
            alert("로그인이 필요합니다.")
            const to = getLoginLink(location);
            navigate(to);
            return;
        }

        openAddress((address, longitude, latitude) => {
            if (window.confirm(address + ' 주소 기준으로 알림을 받으시는게 맞습니까?')) {
                window.fbq('track', 'SubmitApplication');
                axios.post(SERVER_ADDRESS + '/api/used-vehicles/writeVehicleSimple', {
                    payload: payload,
                    address,
                    longitude,
                    latitude,
                }, {}).then((res) => {
                    alert('등록되었습니다.')
                    // window.location.replace(`/vehicle/${res.data.id}?mode=doori`);
                    window.location.replace(`/#v${res.data.id}`);
                    // navigate(`/vehicle/${res.data.id}?mode=doori`);
                    // navigate(0);
                });
            }
        });
    }

    return <PageWrap>
        <Card1>
            <p>
                예상수익 간편조회가 완료되었어요!
            </p>
        </Card1>
        <Card2>
            {/*<h3>💸 한달에 얼마를 아낄 수 있을까요? 🧐</h3>*/}
            <div className="review_box">
                <p>
                    [조회차량]<br/>
                    - 차량번호: {payload?.numberPlate}<br/>
                    - 차종: {payload?.brand} {payload?.model}<br/>
                    - 중고차 시세(추정): {commaNum(payload?.minGrade?.중고차가격)}원<br/>
                    ※ 차종 별로 실제 시세와 차이가 많이 날 수 있습니다.<br/>
                    <br/>
                    [추천 월이용료]<br/>
                    {/*1️⃣ 월이용료<br/>*/}
                    - 게스트우선형(게스트전일사용): {commaNum(payload?.minGrade?.월고정비용 * 1.5)}원<br/>
                    - 요일지정형(요일별로나눠서사용): {commaNum(payload?.minGrade?.월고정비용)}원<br/>
                    - 호스트우선형(호스트주로사용): {commaNum(payload?.minGrade?.월고정비용 * 0.5)}원<br/>
                    <br/>
                    ※ 추천 월이용료는 차량의 시세와 연식에 따른 감가상각, 보험료, 자동차세, 정비비를 고려하여 산출됩니다.
                    {/*<span style={{fontSize: '12px', color: '#222222'}}>*/}
                    {/*{'  '}▷ 감가분담수익: {commaNum(Math.round(payload?.minGrade?.월간감가분담액 / 1000) * 1000)}원<br/>*/}
                    {/*    {'  '}▷ 정비비분담수익: {commaNum(Math.round(payload?.minGrade?.월간정비분담액 / 1000) * 1000)}원<br/>*/}
                    {/*    {'  '}▷︎ 자동차세분담수익: {commaNum(Math.round(payload?.minGrade?.월간세금분담액 / 1000) * 1000)}원<br/>*/}
                    {/*    {'  '}▷ 보험료분담수익: {commaNum(Math.round(payload?.minGrade?.월간보험분담액 / 1000) * 1000)}원<br/><br/>*/}
                    {/*</span>*/}
                    {/*2️⃣ 거리당주유비<br/>*/}
                    {/*: 게스트 1km 주행 당 {commaNum(payload?.minGrade?.거리당비용)}원<br/><br/>*/}
                    {/*💰 연간 추정수익: {commaNum(payload?.minGrade?.연간추정수익)}원 (요일지정형 기준)<br/>*/}
                    {/*<br/>*/}
                    {/*※ 위 금액은 추정치이며 등록 이후에도 원하는 매칭유형, 월이용료, 거리당주유비를 호스트가 자유롭게 결정/변경할 수 있습니다.<br/>*/}
                    <br/>


                    {/*[매칭유형 안내]<br/>*/}
                    {/*게스트우선형: 호스트가 운행요청을 하고 게스트가 승인 시 차를 이용하는 방식입니다.<br/>*/}
                    {/*(게스트가 주로 타고 호스트가 매월 0~5일 정도 타는 경우 추천드려요.)<br/>*/}
                    {/*<br/>*/}
                    {/*요일지정형: 호스트와 게스트가 동일한 조건에서 선착순으로 예약하면서 이용하는 방식입니다.<br/>*/}
                    {/*(호스트, 게스트가 매월 15일 정도씩 타는 경우 추천드려요.)<br/>*/}
                    {/*<br/>*/}
                    {/*호스트우선형: 게스트가 운행요청을 하고 호스트가 승인 시 차를 이용하는 방식입니다.<br/>*/}
                    {/*(호스트가 주로 타고 게스트가 매월 0~10일 정도 타는 경우 추천드려요.)<br/>*/}



                    <br/>
                    궁금하신 점이 있다면 톡상담 버튼을 눌러 문의를 남겨주세요. 🙂

                </p>
            </div>
            {/*<div className="review_box">*/}
            {/*    <p>*/}
            {/*        김**님이 기아 K5으로*/}
            {/*        월 360,000원을 절약했어요.*/}
            {/*    </p>*/}
            {/*</div>*/}
        </Card2>
        <Card3>
            <div>
                내 차에 관심있는 게스트 알림을 받아보시겠습니까?<br/>
                (주차장 주소 입력이 필요해요)
            </div>
            <div style={{display: 'flex', gap: '12px', padding: '0 0px'}}>
                {/*<a target="_blank" href="https://forms.gle/rXq3dZ56DynPA7fP9">*/}
                <div className="negative_button" onClick={onClickSubmit}>추천 월이용료로 받기</div>
                <div className="submit_button" onClick={onClickExit}>직접 입력하기</div>
                {/*<div className="negative_button" onClick={onClickExit}>아니오</div>*/}
                {/*<Link to="/vehicle/write">*/}
                {/*    <div className="submit_button">글 내용 직접 작성하고<br/>차량 호스팅 하기</div>*/}
                {/*</Link>*/}
                {/*</a>*/}
            </div>
        </Card3>
        {/*<KakaoFloat />*/}
    </PageWrap>
}

const PageWrap = styled.div`
  font-family: sans-serif;
`

const Card1 = styled.div`
  word-break: keep-all;
  margin-top: 40px;
  margin-bottom: 50px;
  padding-left: 30px;
  padding-right: 30px;
  font-size: 17px;
  line-height: 20px;
  
  > p {
    margin-bottom: 16px;
    line-height: 24px;
    font-size: 22px;
  }
  
  > div {
    display: flex;
    justify-content: center;
  }
`

const Card2 = styled.div`
  background-color: #F5F5F5;
  word-break: keep-all;
  margin-bottom: 8px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 32px;
  padding-bottom: 30px;
  font-size: 17px;
  line-height: 20px;
  
  > h3 {
    font-size: 18px;
    margin-bottom: 20px;
  }
  
  .review_box {
    display: flex;
    margin-bottom: 16px;
    > p {
      line-height: 130%;
      white-space: pre-wrap;
    }
    > div {
      margin-left: 4px;
      font-size: 14px;
      display: flex;
      background-color: #33CCC1;
      min-width: 80px;
      width: 80px;
      height: 36px;
      border-radius: 3px;
      color: white;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      &:hover{
        background-color : #44DDD2;
      }
    }
  }
  
  > :last-child {
    margin-bottom: 0;
  }
`

const Card3 = styled.div`
  //z-index: 0;
  //position: sticky;
  //bottom: 0;
  //background: #ffffff;
  //box-sizing: border-box;

  //padding: 15px 30px 40px;
  padding: 10px 0;
  //margin-bottom: 80px;
  font-size: 17px;
  line-height: 24px;
  > p {
    margin-bottom: 8px;
  }

  > div {
    display: flex;
    justify-content: center;
  }

  .submit_button {
    text-align: center;
    //flex-grow: 1;
    padding: 12px 20px;
    margin-top: 16px;
    min-width: 60px;
    display: flex;
    //background-color: #33CCC1;
    //width: 190px;
    //height: 40px;
    border-radius: 3px;
    //color: white;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    color: var(--gray-scale-white, #FFF);
    background: #33CCC1;
    &:hover {
      background-color: #44DDD2;
    }
  }
  .negative_button {
    text-align: center;
    //flex-grow: 1;
    padding: 12px 20px;
    margin-top: 16px;
    min-width: 60px;
    display: flex;
    //background-color: white;
    //width: 190px;
    //height: 40px;
    border-radius: 3px;
    //color: black;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    background: #D0FAF2;
    color: #0c6e73;
    &:hover {
      background-color: #E1FAF2;
    }
  }
`

export default HostSimpleWritePage;
