import styled from "styled-components";
import {useLocation, useNavigate} from "react-router-dom";
import qs from "query-string";
import {useMutation} from "@apollo/client";
import {DELETE_CARD} from "../../query/userQuery";
import {useEffect} from "react";
import axios from "axios";
import {SERVER_ADDRESS} from "../../index";
import {useSelector} from "react-redux";
import {get} from "lodash";

export const CardSuccessPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const parsed = location.search ? qs.parse(location.search, {ignoreQueryPrefix: true}) : null;

    const customerKey = parsed?.customerKey;
    const authKey = parsed?.authKey;

    const userInfo = useSelector(({user}) => user.user);

    const [deleteCard] = useMutation(DELETE_CARD);

    useEffect(() => {
        if (!authKey || !userInfo) {
            return;
        }
        axios.post(SERVER_ADDRESS + '/api/credit-cards/process', {
            customerKey: customerKey,
            authKey: authKey,
        }).then((res) => {
            console.log(res.data);
            if (res.data.status === 'DONE') {
                const cardInfo = get(userInfo, "credit_cards", []);
                let card = null;
                if (cardInfo.length > 0) {
                    card = cardInfo[0];
                }
                if (card) {
                    deleteCard({variables: { id: card.id }}).then(res => {
                        navigate('/my/info');
                    });
                } else {
                    navigate('/my/info')
                }
            } else {
                alert(res.data.e)
                navigate('/my/info');
            }
        });
    }, [authKey, userInfo]);

    return <_CardSuccessPage>
        요청을 처리하는 중 입니다.
    </_CardSuccessPage>
}

const _CardSuccessPage = styled.div`
  text-align: center;
  padding: 20px;
`
