import styled from "styled-components";
import {SwipeableDrawer} from "@mui/material";
import { Global } from '@emotion/react';

const BottomDrawer = ({open, onClose, children}) => {
    return <SwipeableDrawer
        anchor="bottom"
        open={open}
        onOpen={() => {}}
        onClose={onClose}>
        <Global styles={{
            '.MuiDrawer-root > .MuiPaper-root': {
                borderTopLeftRadius: '16px',
                borderTopRightRadius: '16px',
            },
        }}/>
        <Puller>
            <Bar/>
        </Puller>
        <DrawerWrap>
            <>{children}</>
        </DrawerWrap>
    </SwipeableDrawer>
}

const Bar = styled.div`
  width: 34px;
  height: 2px;
  border-radius: 300px;
  background: var(--gray-scale-gray-500, #C9C9C9);
`
const Puller = styled.div`
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  height: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
`

export const DrawerWrap = styled.div`
  padding: 20px;
  
  h1 {
    color: var(--gray-scale-black, #212121);
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px; /* 144.444% */
  }
  
  p {
    color: var(--gray-scale-black, #212121);
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 157.143% */
  }
  
  h2 {
    color: var(--gray-scale-black, #212121);
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px; /* 157.143% */
  }
  
  .--gray {
    color: var(--gray-scale-gray-800, #666);

  }
`

export default BottomDrawer;
