import styled from "styled-components";
import {FormComponent, RadioComponentSimple} from "../VehicleAdd2/FormComponent";

const UserInfo = ({form, onChange}) => {
    return <>
        <Wrap>
            <h1>내 정보 입력하기</h1>
            <div className="forms">
                <FormComponent id="nickname" title="닉네임" form={form} onChange={onChange} />
                <FormComponent type="tel" id="생년월일" placeholder="ex) 19900101" title="생년월일 (8자리 숫자 형식)" form={form} onChange={onChange} description={"예: 19900101 (1990년 1월 1일)"} />
                <RadioComponentSimple id="성별" title="성별" form={form} onChange={onChange} values={[
                    {id: '남', label: '남'},
                    {id: '여', label: '여'},
                ]}/>
                <RadioComponentSimple id="실내흡연여부" title="실내흡연여부" form={form} onChange={onChange} values={[
                  {id: '차량 내 비흡연', label: '차량 내 비흡연'},
                  {id: '차량 내 흡연 필요', label: '차량 내 흡연 필요'},
                ]}/>
                <RadioComponentSimple id="반려동물 탑승 방법" title="반려동물 탑승 방법" form={form} onChange={onChange} values={[
                    {id: '없음', label: '없음'},
                    {id: '케이지 탑승', label: '케이지 탑승'},
                    {id: '그냥 탑승', label: '그냥 탑승'},
                ]}/>
                <FormComponent rows={3} type="textarea" id="자기소개" title="자기소개 (선택)" placeholder="직업, 전공, 취미 등 나에 대한 이야기나 운전경력, 운전패턴/습관, 타고 싶은 차량 등 자동차에 관한 이야기를 자유롭게 적어주세요. 소개글을 성의있게 작성할수록 신뢰도가 올라가 매칭이 성사될 확률이 높아집니다." onChange={onChange} form={form} />
                <FormComponent type="tel" id="예상 운행거리" placeholder="ex) 500" title="예상 월주행거리 (km)" description="월 2,000km 초과 주행 시 km당 100원 내외의 초과주행료가 발생합니다." onChange={onChange} form={form} />
            </div>
        </Wrap>
    </>
}

const Wrap = styled.div`
  padding: 0 20px 40px;
  > h1 {
    padding: 32px 0;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
  }
  
  > .forms {
    display: flex;
    flex-direction: column;
    gap: 32px;
    
    p {
      color: var(--gray-scale-gray-700, #939393);
      font-feature-settings: 'clig' off, 'liga' off;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      margin: 4px 0;
    }
  }
`

export default UserInfo;