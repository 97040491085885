import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    read: false,
};

export const guestNotiSlice = createSlice({
    name: 'guestNoti',
    initialState,
    reducers: {
        read: (state, action) => {
            state.read = true;
            return state;
        },
    }
})

export let guestNotiAction = guestNotiSlice.actions;
export let guestNotiReducer = guestNotiSlice.reducer;
