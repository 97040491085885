const {TelegramClient} = require('messaging-api-telegram');
const client = new TelegramClient({
    accessToken: '1393058794:AAHRCsMtABS5vz-kJbml5zw3EKjbTGm6joc',
});

const CHAT_IDs = [
    '-1001828317335' // 채널
    // '333017874',  // 재창님
    // '2022881962', // 윤석님
    // '1753870954', // 성환님
    // '5248678958', // 예훈님
    // '5724203792', // 윤석님법인폰
    // '5924071351', // 인혁님
];

export const asyncSendTelegram = async (payload) => {
    for (let chatId of CHAT_IDs) {
        try {
            const res = await client.sendMessage(chatId, payload, {
                disableWebPagePreview: true,
                disableNotification: true,
            });
        } catch (e) {
            console.log(e)

            try {
                await client.sendMessage('1753870954', payload, {
                    disableWebPagePreview: true,
                    disableNotification: true,
                });
            } catch {

            }

            try {
                await client.sendMessage('1753870954', e.stack, {
                    disableWebPagePreview: true,
                    disableNotification: true,
                });
            } catch {

            }

            try {
                const shorted = payload;
                shorted.length = 200;
                await client.sendMessage('1753870954', shorted, {
                    disableWebPagePreview: true,
                    disableNotification: true,
                });
            } catch {

            }
        }
    }
    return true;
}
