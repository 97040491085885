import styled from "styled-components";


const BottomNavigator = ({page, onBefore, onNext, onSubmit, lastPage}) => {
    return <Wrap>
        {page !== 0 && <NegativeBtn onClick={onBefore}>이전</NegativeBtn>}
        {page !== lastPage && <PositiveBtn onClick={onNext}>다음</PositiveBtn>}
        {page === lastPage && <PositiveBtn onClick={onSubmit}>완료</PositiveBtn>}
    </Wrap>
}

const Wrap = styled.div`
  display: flex;
  padding: 16px 20px 34px;
  gap: 10px;
  align-self: stretch;
  background: #FFF;
  box-shadow: 0px -4px 10px 0px rgba(0, 0, 0, 0.04);
`

const Btn = styled.div`
  cursor: pointer;
  border-radius: 12px;
  padding: 15px 20px;
  flex: 1 0 0;
  text-align: center;

  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
`

export const PositiveBtn = styled(Btn)`
  color: var(--gray-scale-white, #FFF);
  background: #33CCC1;

`

export const NegativeBtn = styled(Btn)`
  background: #D0FAF2;
  color: #33CCC1;
`

export const DisabledBtn = styled(Btn)`
  background: #D0FAF2;
  color: #33CCC1;
  cursor: not-allowed;
`

export default BottomNavigator;
