import {commaNum} from "../../../toolbox";
import styled from "styled-components";

const VEHICLE_INFO_LIST = [
    {
        id: "year",
        label: "형식년도"
    // }, {
    //     id: ["displacement", "transmissionType"],
    //     label: "배기량 / 변속기",
    //     reform: (v, record) => `${commaNum(record?.displacement)}cc / ${record?.transmissionType}`
    }, {
        id: "fuelType",
        label: "연료",
        // reform: (v, record) => `${record?.fuelType} / ${record?.color}`
    },
    {
        id: "mileage",
        label: "누적주행거리",
        reform: (v, record) => `${commaNum(record?.mileage)}km`
    },
]
const _VehicleInfoSection = ({vehicle, className}) => {
    return <div className={className}>
        {/*<div className="--subject">기본 정보</div>*/}
        {/*{VEHICLE_INFO_LIST.map((info, idx) => {*/}
        {/*    return (*/}
        {/*        <div className="--item" key={idx}>*/}
        {/*            <div className="--title">*/}
        {/*                {info.label}*/}
        {/*            </div>*/}
        {/*            <div className="--value">*/}
        {/*                {!!info?.reform ? info.reform(vehicle[info.id], vehicle) : vehicle[info.id]}*/}
        {/*            </div>*/}
        {/*        </div>*/}
        {/*    )*/}
        {/*})}*/}
        {VEHICLE_INFO_LIST.filter((info) => vehicle[info.id] || vehicle[info.id] === 0).map((info, idx) => {
            return (
                <div key={idx} className="--value">
                    {!!info?.reform ? info.reform(vehicle[info.id], vehicle) : vehicle[info.id]}
                </div>
            )
            return null;
        }).reduce((prev, curr) => [prev, "·", curr])}
    </div>
}

const VehicleInfoSection = styled(_VehicleInfoSection)`
  display: flex;
  justify-content: center;
    .--value {
      padding-left: 4px;
      padding-right: 4px;
      color: gray;
    }
  //
  //padding-left: 16px;
  //padding-right: 16px;
  //.--subject {
  //  font-weight: bold;
  //  font-size: 18px;
  //  margin-bottom: 10px;
  //}
  //.--item {
  //  display: flex;
  //  align-items: center;
  //  font-size: 16px;
  //  font-weight: 700;
  //  margin-bottom: 10px;
  //
  //  .--title {
  //    min-width: 120px;
  //    //color: #818181;
  //    display: flex;
  //    align-items: center;
  //  }
  //
  //  .--value {
  //    margin-right: 4px;
  //  }
  //}
`;

export default VehicleInfoSection;
