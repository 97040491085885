import React, {useState} from "react";
import {useDispatch} from "react-redux";
import qs from 'query-string';
import {kakaoLogin} from "../../toolbox/kakao";
import {Link, useLocation, useNavigate} from "react-router-dom";
import styled, {css} from "styled-components";
import {parseJwt, useForm} from "../../toolbox";
import {authAction} from "../../redux/authReducer";
import {SIGN_UP_POLICY, SIGN_UP_POLICY_ID} from "./SignUpPolicy";
import {useMutation} from "@apollo/client";
import {UPDATE_USER} from "../../query/userQuery";
import moment from "moment-timezone";
import {userAction} from "../../redux/userReducer";
import {toNumberPlateFormat, toPhoneFormat} from "../../toolbox/format";
import {loadingAction} from "../../redux/loadingReducer";
import LoadingIndicator from "../../layout/LoadingIndicator";

const PageWrap = styled.div`
  padding: 20px;
  text-align: center;
`
const Login = styled.div`
    cursor: pointer;
`

const Agreement = styled.div`
  padding: 20px;
  > h3 {
    margin-bottom: 20px;
  }
  > textarea {
    width: 100%;
  }
  > .button-wrap {
    cursor: pointer;
    margin-top: 20px;
    padding: 8px;
    > .submit {
      border-radius: 8px;
      height: 30px;
      line-height: 30px;
      background-color: black;
      color: white;
    }
  }
`

export function Login2Page() {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const parsed = location.search ? qs.parse(location.search, {ignoreQueryPrefix: true}) : null;
    const prev = parsed?.prev ?? '/';

    return (<PageWrap>
        <Login onClick={() => {
            window.location.href = `https://kauth.kakao.com/oauth/authorize?${qs.stringify({
                response_type: 'code',
                client_id: 'cdb7e71eca23a794b59e98294245ad28',
                redirect_uri: `${window.location.origin}/login_callback`,
                state: prev,
            })}`;
        }}>
            <img src="/login/kakao_login_medium_narrow.png"/>
        </Login>
        <br/>
    </PageWrap>);
};
