import {Alert, CircularProgress, Dialog, Popover, Snackbar} from "@mui/material";
import styled from "styled-components";
import {useForm} from "../../../toolbox";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useRef, useState} from "react";
import {useMutation} from "@apollo/client";
import {GET_ME, UPDATE_USER} from "../../../query/userQuery";
import {authAction} from "../../../redux/authReducer";
import {SERVER_ADDRESS} from "../../../index";
import axios from "axios";
import {MyTag} from "../../vehicle/component/MyTagEdit";
import {VehicleThumbnail} from "../../vehicle/component/VehicleThumbnail";
import {useNavigate} from "react-router-dom";
import {toastAction} from "../../../redux/toastReducer";
import {FormComponent, RadioComponent, RadioComponentSimple} from "../../vehicle/VehicleAdd2/FormComponent";
import {loadingAction} from "../../../redux/loadingReducer";
import {VehicleWantedSet} from "../../vehicle/component/VehicleWanted";
import {GUEST_WANTED} from "../../../toolbox/guestTag";
import { PositiveBtn } from "../../vehicle/VehicleAdd2/BottomNavigator";
import { useCallback } from "react";

const MyTagEdit = styled(MyTag)`
  position: relative;
  display: block;
  top: unset;
  left: unset;
  transform: unset;
  width: unset;
`

const Line = styled.div`
  border-bottom: 1px solid #CCCCCC;
  margin-left: 24px;
  margin-right: 24px;
`

const MyThumbnail = styled(VehicleThumbnail)`
  width: 100%;
  min-width: 100%;
  box-sizing: border-box;
  margin-bottom: 2px;
`

const MyVehicleThumbnail = styled(VehicleThumbnail)`
  box-sizing: border-box;
  width: 100%;
  height: 280px;
  border-radius: 0;
  margin: 4px;
  cursor: pointer;
`

export const ProfileEditModal = ({open, onClose, onSuccess}) => {
    return <Dialog open={open} onClose={onClose} PaperProps={{style: {borderRadius: '12px'}}}>
        <ProfileEdit onClose={onClose} onSuccess={onSuccess}/>
    </Dialog>
}

export const ProfileEdit = ({onClose, onSuccess}) => {
    const navigate = useNavigate();
    const [updateUser, {loading}] = useMutation(UPDATE_USER);
    const userInfo = useSelector(({user}) => user.user);
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);
    const jwt = useSelector(({auth}) => auth.token);
    const { form, onChange, resetValue } = useForm({
        nickname: '',
        profile_image: null,
        mapMarker: true,
        '생년월일': '',
        '성별': '',
        '실내흡연여부': '',
        '반려동물 탑승 방법': '',
        '예상 운행거리': '',
        '자기소개': '',
        '원하는 차량': GUEST_WANTED.flatMap(w => w.tags.map(t => t.id)).join(','),
    });
    const dispatch = useDispatch();

    const fileRef = useRef();

    const [uploading, setUploading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    useEffect(() => {
        if (userInfo) {
            onChange([
                {id: 'profile_image', value: userInfo?.profile_image},
                {id: 'nickname', value: userInfo?.nickname},
                {id: 'mapMarker', value: userInfo?.mapMarker},
            ]);
            const keys = ["성별", "생년월일", "실내흡연여부", "예상 운행거리", "자기소개", "반려동물 탑승 방법", "원하는 차량"];
            if (userInfo.matching_profile) {
                for (let key in userInfo.matching_profile) {
                    if (!keys.includes(key)) {
                        continue;
                    }
                    if (form[key] !== undefined) {
                        onChange({id: key, value: userInfo.matching_profile[key]});
                    }
                }
            }
        }
    }, [userInfo]);

    const handleImage = useCallback((e) => {
        if (e.target.files.length === 0) {
            return;
        }
        const data = new FormData();
        data.append('files', e.target.files[0]);

        setUploading(true)
        axios.post(SERVER_ADDRESS + '/api/upload', data, {
            onUploadProgress: progressEvent => {

            }
        }).then((res) => {
            setUploading(false);
            setSnackbarOpen(true);
            onChange({id: 'profile_image', value: res.data[0]})
        });
    }, []);

    const save = useCallback(async () => {
        if (loading || uploading) {
            return;
        }
        dispatch(loadingAction.loading(true));

        const keys = ["성별", "생년월일", "실내흡연여부", "예상 운행거리", "자기소개", "반려동물 탑승 방법", "원하는 차량"];
        const _matching_profile = Object.keys(form).reduce((result, key) => {
            if (keys.includes(key)) {
                result[key] = form[key];
            }
            return result;
        }, {});

        const payload = {
            refetchQueries: [{query: GET_ME, variables: { id: tokenInfo.id }}],
            variables: {
                id: tokenInfo.id,
                data: {
                    nickname: form.nickname,
                    profile_image: form?.profile_image?.id ?? null,
                    mapMarker: form.mapMarker,
                    matching_profile: _matching_profile,
                }
            },
        };
        updateUser(payload).then(res => {
            dispatch(authAction.refresh());
            dispatch(toastAction.toast({severity: 'success', 'message': '프로필 정보가 저장되었습니다.'}));
            dispatch(loadingAction.loading(false));
            onClose();
            onSuccess();
        });
    }, [form, loading, uploading]);

    return <_ProfileEdit>
        <div className="content">
            <div className="img-box">
              <div className="image_wrap" onClick={() => fileRef.current.click()}>
                  <img src={form?.profile_image?.url ?? '/layout/char_doori.svg'}></img>
                  <input style={{display: 'none'}} type="file" accept="image/jpeg, image/png" onChange={handleImage} ref={fileRef}/>
                  <div className="edit">
                      <img src="/my/edit.svg" />
                  </div>
              </div>
              <div className="title" onClick={() => fileRef.current.click()}>
                프로필 사진 수정
              </div>
            </div>
            <FormComponent type="text" id="nickname" title="닉네임" onChange={onChange} form={form} />
            <FormComponent type="tel" id="생년월일" maxLength={8} placeholder="ex) 19900101" title="생년월일 (8자리 숫자 형식)" form={form} onChange={onChange} description={"예: 19900101 (1990년 1월 1일)"} />
            <RadioComponentSimple id="성별" title="성별" form={form} onChange={onChange} values={[
                {id: '남', label: '남'},
                {id: '여', label: '여'},
            ]}/>
            <RadioComponentSimple id="실내흡연여부" title="실내흡연여부" form={form} onChange={onChange} values={[
                  {id: '차량 내 비흡연', label: '차량 내 비흡연'},
                  {id: '차량 내 흡연 필요', label: '차량 내 흡연 필요'},
            ]}/>
            <RadioComponentSimple id="반려동물 탑승 방법" title="반려동물 탑승 방법" form={form} onChange={onChange} values={[
                {id: '없음', label: '없음'},
                {id: '케이지 탑승', label: '케이지 탑승'},
                {id: '그냥 탑승', label: '그냥 탑승'},
            ]}/>
            <FormComponent type="tel" id="예상 운행거리" placeholder="ex) 500" title="예상 월주행거리 (km)" onChange={onChange} form={form} />
            <FormComponent rows={3} type="textarea" id="자기소개" title="자기소개" placeholder="직업, 전공, 취미 등 나에 대한 이야기나 운전경력, 운전패턴/습관, 타고 싶은 차량 등 자동차에 관한 이야기를 자유롭게 적어주세요. 소개글을 성의있게 작성할수록 신뢰도가 올라가 매칭이 성사될 확률이 높아집니다." onChange={onChange} form={form} />

            <VehicleWantedSet title="원하는 차량 설정" tags={form["원하는 차량"]} setTags={(v) => { onChange({id: '원하는 차량', value: v})}} />
            <RadioComponent id="mapMarker" title="프로필 활성화 여부" form={form} onChange={onChange} values={[
                {id: true, label: '활성화', description: '호스트에게 매칭 제안을 받을 수 있습니다.'},
                {id: false, label: '비활성화', description: '호스트에게 매칭 제안을 받을 수 없습니다.'},
            ]}/>
            <div className="btn-box">
              <PositiveBtn onClick={save}>프로필 저장</PositiveBtn>
            </div>
            <Line/>
            <div>
            </div>
        </div>
        <Snackbar anchorOrigin={{vertical: 'bottom', horizontal: 'center',}} open={uploading}>
            <Alert icon={false} severity="info" >
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <CircularProgress sx={{marginRight: '8px'}} size={14}/>
                    <span>업로드 중 입니다.</span>
                </div>
            </Alert>
        </Snackbar>
        <Snackbar anchorOrigin={{vertical: 'bottom', horizontal: 'center',}} autoHideDuration={4000} open={snackbarOpen} onClose={() => setSnackbarOpen(() => false)} >
            <Alert severity="success">업로드가 완료되었습니다.</Alert>
        </Snackbar>
    </_ProfileEdit>
}

const _ProfileEdit = styled.div`
  > .wrap {
    outline: none;
    vertical-align: middle;
    display: table-cell;
  }

  > .content {
    display: flex;
    flex-direction: column;
    gap: 32px;
    
    box-sizing: border-box;
    outline: none;
    background: #fff;
    //border-radius: 12px;
    position: relative;
    //overflow: hidden;
    //overflow-y: scroll;

    margin: 0 auto;
    padding: 16px 12px 58px;
    //width: 300px;
    //height: 390px;

    //top: 25%;
    //height: 50%;
    > .title {
      display: block;
      font-size: 22px;
      //border-bottom: 1px solid #303030;
      word-break: break-all;
      line-height: 28px;
      margin-bottom: 10px;
      //padding: 30px 0 14px;
      padding-bottom: 14px;
      padding-top: 10px;
    }


    > .input-box {
      position: relative;
      border-radius: 6px;
      border: 1px solid rgba(212, 216, 229, 0.3);
      background-color: rgba(212, 216, 229, 0.12);
      display: flex;
      align-items: center;
      margin-top: 4px;
      margin-bottom: 4px;
      width: 100%;
      > .input {
        background-color: rgba(0, 0, 0, 0);
        padding: 9px 11px 9px 11px;
        display: block;
        width: 100%;
        height: 36px;
        font-size: 14px;
        line-height: 16px;
        box-sizing: border-box;
        border: none;
      }
    }


    > .img-box {
      flex-direction: column;
      display: flex;
      justify-content: center;
      align-items: center;
      > .title {
        cursor: pointer;
        font-size: 14px;
        font-weight: 700;
        line-height: 16px;
        color: #6d6dff;
      }
      > .image_wrap {
        position: relative;
        cursor: pointer;
        width: 120px;
        height: 120px;
        padding: 14px;

        > img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
        }

        > .edit {
          background-color: #33CCC1;
          border-radius: 50%;
          border: 2px solid white;
          position: absolute;
          right: 11px;
          bottom: 11px;
          width: 22px;
          height: 22px;
          padding: 6px;
          > img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }


    > .btn-box {
      margin-top: 12px;
      margin-bottom: 12px;
      //position: absolute;
      //bottom: 0;
      //left: 0;
      //right: 0;
      display: flex;
      gap: 8px;
      text-align: center;
      justify-content: center;
      > .btn {
        padding: 19px 0 18px;
        //border-bottom-left-radius: 12px;
        width: 180px;
        color: white;
        font-size: 18px;
        font-weight: 700;
        //background-color: #959EA0;
        background-color: #33CCC1;
        cursor: pointer;
        border-radius: 4px;
      }
    }
  }
`

const _FormComponent = styled.div`
  padding: 6px 12px;
  > .title {
    padding-left: 4px;
    padding-top: 12px;
    padding-bottom: 4px;
    font-size: 16px;
  }

  > .content {

    > .image {
      max-width: 100%;
      max-height: 200px;
      img {
        max-width: 100%;
        max-height: 200px;
        object-fit: contain;
      }
    }
    > .text-input {
      padding: 4px;
      border: 1px solid #ededed;
      input {
        width: 100%;
        border: none;
        outline: none;
        font-size: 18px;
        ::placeholder {
          color: #BBBBBB
        }
      }
      textarea {
        width: 100%;
        border: none;
        outline: none;
        resize: none;
        font-size: 16px;
        ::placeholder {
          color: #BBBBBB
        }
      }
    }
    
    .vehicles {
      display: flex;
      flex-wrap: wrap;
      padding: 12px 0;

      > .vehicle {
        box-sizing: border-box;
        max-width: calc(50% - 8px);
        flex-basis: 140px;
        flex-grow: 1;
        height: 120px;
        margin: 4px 4px 36px 4px;
        text-align: center;
        cursor: pointer;
        position: relative;
      }
    }
    
    > .upload-btn {
      cursor: pointer;
      margin-top: 8px;
      margin-bottom: 8px;
      border-radius: 19px;
      //width: 114px;
      height: 37px;
      line-height: 37px;
      border: 1px solid #ededed;
      text-align: center;
      color: #EEEEEE;
      background-color: #666666;
      font-weight: 500;
    }
  }
`;
