import styled from "styled-components";
import {GrDocumentText} from "react-icons/gr";
import {commaNum} from "../../../toolbox";
import {calcDooriTax} from "../../../toolbox/calculate";

const ContractInfoSection = ({vehicle}) => {
    return <_ContractInfoSection>
        <p>
            {vehicle.useType === 'TEMP' && <>
                <p>예비호스트 등록시 자동입력된 값으로, 매칭 조율 과정에서 변동될 수 있습니다.</p><br/>
            </>}
            게스트가 주행한 거리만큼 호스트에게 지급하는 금액입니다. 대신, 호스트는 본인의 계산으로 연료를 항상 충분한 상태로 유지하여 게스트의 운행에 차질이 없도록 해야합니다.<br/>
            단, <span style={{textDecoration: 'underline'}}>‘게스트우선형’의 경우 반대로 호스트가 게스트에게 지급</span>하며, 게스트는 본인의 계산으로 연료를 항상 충분한 상태로 유지하여야합니다.

            {/*{!vehicle.useTypes?.length &&*/}
            {/*<>*/}
            {/*    월고정비용: {commaNum(vehicle.priceDuo)}원 / 월<br/>*/}
            {/*    거리당비용: {commaNum(vehicle.kmCharge)}원 / km<br/>*/}
            {/*</>}*/}

            {/*{calcDooriTax(vehicle) && <>*/}
            {/*    자동차세분담비용: {commaNum(calcDooriTax(vehicle))}원 / 월<br/>*/}
            {/*</>}*/}

            {/*보험료분담비용: {vehicle.priceInsurance ? commaNum(vehicle.priceInsurance)+'원': '매칭 시 확정'} / 월<br/>*/}
            {/*보증금: 월고정비용의 3배*/}

            {/*{commaNum(vehicle.priceDuo * 3)}원<br/>*/}
            {/*약정기간: 1개월 (미해지시 1개월씩 자동연장)*/}
        </p>

        {/*<p className="light">*/}
        {/*    1) 월고정비용: 게스트가 공유의 대가로 매월 고정적으로 납부하는 금액으로, 차량의 시세와 감가상각을 고려하여 계산됩니다.<br/><br/>*/}

        {/*    2) 거리당비용: 게스트가 주행한 거리만큼 발생하는 금액으로, 주유, 오일교환 등 소모품교체, 정비, 세차 등 다양한 유지비가 모두 포함되어 있습니다.<br/>*/}
        {/*    ※ 게스트는 호스트에게 거리당비용을 납부하는 대신, 주유, 소모품교체, 정비, 세차 등 비용을 따로 부담하지 않습니다. (차량마다 제공되는 ‘두리케어카드’로 무상결제 가능)<br/><br/>*/}

        {/*    3) 자동차세분담비용: 게스트와 호스트는 자동차세를 절반씩 분담합니다. (연간 총 자동차세를 12개월로 나누어 환산한 금액)<br/><br/>*/}

        {/*    4) 보험료분담비용: 게스트와 호스트는 보험료를 절반씩 분담합니다. (연간 총 보험료를 12개월로 나누어 환산한 금액) 게스트의 연령에 따라 보험료가 달라지므로, 매월 분담할 보험료 액수는 호스트에게 매칭 요청 시 확인할 수 있습니다.<br/><br/>*/}
        {/*    5) 보증금: 차량과 차키의 반납이 완료되고 1% 소유권지분등기가 호스트에게 원상복구된 후 게스트에게 반환됩니다.*/}
        {/*</p>*/}

        {/*<a href={""} target="_blank">*/}
        {/*    <GrDocumentText size={16} /> 자세한 계약 정보 확인하러가기*/}
        {/*</a>*/}
    </_ContractInfoSection>
}

export const TaxInfoSection = ({}) => {
    return <_ContractInfoSection>
        <p>
            게스트는 호스트가 가입한 자동차보험의 보장을 받으며, 보험료 분담액이 월이용료에 포함되어있습니다.<br/>
            게스트의 연령이 30세 미만인 경우, 보험료 할증에 따라 추가금액이 청구될 수 있습니다.<br/>
            게스트는 사고 시 호스트에게 보험료 할증, 차량 감가, 수리기간 동안 차를 이용하지 못하는 손해 등에 대한 포괄적인 보상으로 ‘위로금’을 지급합니다. (세부 산정기준은 자주묻는질문 참조)
        </p>
    </_ContractInfoSection>
}


export const UseDateInfoSection = ({}) => {
    return <_ContractInfoSection>
        <p>
        단기계약(1~5개월 중 선택가능)과 장기계약(6~12개월 중 선택가능) 중에 자유롭게 선택 가능합니다.<br/>
        ※ 최소이용기간은 1개월(시작일이 1일이 아닌 경우 다음달의 말일까지)입니다.<br/>
        ※ 시작일과 무관하게 종료일은 매월 말일로 고정됩니다.
        </p>
    </_ContractInfoSection>
}

const _ContractInfoSection = styled.div`
  padding: 4px 8px;
  > p {
    padding-left: 18px;
    font-family: 'Pretendard',sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    margin-bottom: 12px;
  }
  .light {
    font-family: 'Pretendard',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px
  }
  > a {
    padding-left: 18px;
    display: flex;
    align-items: center;
    font-size: 16px;
    text-decoration: underline;
    text-underline-offset: 4px;

    svg {
      margin-right: 4px;
    }
  }
`;

export default ContractInfoSection;
