import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";
import { useMutation, useQuery } from "@apollo/client";
import axios from "axios";
import moment from "moment-timezone";
import queryString from "query-string";
import * as Hangul from 'hangul-js';
import FilterListIcon from '@mui/icons-material/FilterList';

import { calcDistance, useForm } from "../../toolbox";
import { filter, find, get, isEmpty, sortBy, sum } from "lodash";

import { pathAction } from "../../redux/pathReducer";
import { convertDooricar, convertMapVehicle, fillUseTypes, getUseTypeId } from "../../toolbox/format";
import { applyFilters, isAdmin, openAddress, pathSelector } from "../../toolbox/logic";
import { SERVER_ADDRESS } from "../../index";
import { MY_CONTRACTS, UPDATE_USER } from "../../query/userQuery";
import { GUEST_WANTED } from "../../toolbox/guestTag";

import { VehicleMap } from "./component/VehicleMap";
import { EventSwipe } from "../event/component/EventSwipe";
import LoadingIndicator from "../../layout/LoadingIndicator";
import { ListVehicle2, RowVehicle } from "./component/ListVehicle2";
import { FormComponent } from "./VehicleAdd2/FormComponent";
import { VehicleWanted } from "./component/VehicleWanted";
import { ListGuest, RowGuest } from "./component/ListGuest";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/swiper-bundle.css';
import { MyVehicleSelectDialog } from "../user/UserPage";
import { isAvailableContract } from "../../toolbox/calculate";
import { flatEntity } from "../../toolbox/query";

const MyDivider = styled.div`
  width: 100%;
  border-width: 0;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.12);
  border-bottom-width: thin;
//   margin-left: 22px;
//   margin-right: 22px;
`
const _AddressRequest = ({className, children, onClick}) => {
    return <div className={className} onClick={onClick}>
        <>{children}</>
    </div>
}

const AddressRequest = styled(_AddressRequest)`
  cursor: pointer;
  margin: 8px 22px;
  padding: 6px;
  display: flex;
  align-items: center;
  //box-sizing: border-box;
  //width: 100%;
  border-radius: 4px;
  border: 1px solid rgb(180,180,180);
  color: rgb(80,80,80);
  word-break: keep-all;
  
  > img {
    width: 20px;
    margin-right: 8px;
  }
`

const VehiclesRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding: 0 10px;
`

const Vehicles = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
  padding: 10px 22px;
`

export const SearchBtn = styled.div`
  padding: 4px;
  cursor: pointer;
  margin-left: auto;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  gap: 4px;
  border-radius: 8px;
  > img {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
  > span {
  }  
  user-select: none;
  
  &:hover {
    background-color: rgb(232,234,237);
  }
  
  ${props => props.selected && css`
    border-color: rgb(232,240,254);
    background-color: rgb(232,240,254);
    color: #1558d6;
  `}
`
export const FilterBtn = styled.div`
  border: 1px solid rgb(218,220,224);
  background-color: white;
  border-radius: 80px;
  color: rgb(32,33,36);
  display: flex;
  gap: 4px;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 0 10px;
  line-height: 40px;
  font-size: 14px;
  cursor: pointer;
  user-select: none;
  
  &:hover {
    background-color: rgb(232,234,237);
  }
  
  ${props => props.selected && css`
    border-color: rgb(232,240,254);
    background-color: rgb(232,240,254);
    color: #1558d6;
  `}
`

const GuestListPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const parsed = useMemo(() => location.search ? queryString.parse(location.search, {ignoreQueryPrefix: true}) : null, [location.search]);

  const { path: pathInfo, user: { user: userInfo }, auth: { tokenInfo } } = useSelector(state => state);

  const [filterOpen, setFilterOpen] = useState(false);
  const { form: filterForm, onChange: onFilterChange } = useForm({
    filters: GUEST_WANTED.flatMap(w => w.tags.map(t => t.id)).join(','),
    sorter: '관심높은순',
    searchText: '',
  });

  const [vehicles, setVehicles] = useState(null);
  const [showGuests, setShowGuests] = useState(null);
  const [userTags, setUserTags] = useState(null);
  const [filteredVehicles, setFilteredVehicles] = useState([]);
  const [mapBound, setMapBound] = useState();
  const [mapOpen, setMapOpen] = useState(null);
  const [myVehicles, setMyVehicles] = useState([]);
  const [requestOpen, setRequestOpen] = useState(false);
  const [showGuest, setShowGuest] = useState(false);
  const filters = useMemo(() => parsed?.filters, [parsed]);

  const [updateUser] = useMutation(UPDATE_USER);

  const path = useMemo(() => pathSelector(userInfo, pathInfo), [userInfo, pathInfo]);
  const needPath = useMemo(() => !path || !path.latitude || !path.longitude, [path]);
  
  const {data: myContracts} = useQuery(MY_CONTRACTS, {
    variables: {
        id: tokenInfo?.id,
    },
    skip: !tokenInfo,
    fetchPolicy: "cache-first",
});

  useEffect(() => {
    fetchVehicles();
  }, []);

  useEffect(() => {
    if (!showGuest) {
      if (filters) {
        onFilterChange({id: 'filters', value: filters});
      }
    }
  }, [filters, showGuest]);
  
  useEffect(() => {
    if (myContracts) {
        let contracts = get(myContracts, "usersPermissionsUser.data.attributes.contracts.data", null);
        contracts = contracts.map(c => flatEntity(c));
        contracts = contracts.filter((c) => isAvailableContract(c) && c.vehicle.doorive && c.contractType === 'DOORICAR_WAITING');
        let vehicles = contracts.map(c => convertDooricar(c.vehicle, false, true));
        setMyVehicles(vehicles);
    }
  }, [myContracts]);

  useEffect(() => {
    if (userTags) {
      const newShowGuests = filterAndSortGuests(userTags);
      setShowGuests(newShowGuests);
      setShowGuest(true);
    }
  }, [userTags, filterForm, path]);

  const fetchVehicles = useCallback(async () => {
    try {
      const { data } = await axios.get(`${SERVER_ADDRESS}/api/vehicles/mapVehicles2`);
      processVehiclesData(data);
    } catch (error) {
      console.error("Error fetching vehicles:", error);
    }
  }, []);

  const processVehiclesData = useCallback((data) => {
    const processedVehicles = data.vehicles.map(convertMapVehicle);
    setVehicles(processedVehicles);
    setUserTags(processUserTags(data.userTags));
    setFilteredVehicles(data.filteredVehicles.map(v => {
      let processed = convertMapVehicle(v);
      processed.hostContract.dooriveTitle = '이미 매칭된 차량입니다.';
      processed.hostContract.useTypes = sortBy(v.hostContract.useTypes, (u) => u.price);
      return {...processed, type: 'matched'};
    }));
  }, []);

  const processUserTags = useCallback((userTags) => {
    return userTags.map(u => ({
      ...u,
      mapId: `u${u.id}`,
      type: 'user',
      latitude: u.lat,
      longitude: u.lng
    }));
  }, []);

  const filterAndSortGuests = useCallback((guests) => {
    let filteredGuest = sortBy(guests?.filter(u => u.address && u.longitude && u.latitude
      && u.matching_profile
      && u.matching_profile["원하는 차량"]
    ), (u) => calcDistance(u.latitude, u.longitude, path.latitude, path.longitude));
    filteredGuest = applyFilter(filteredGuest, filterForm.filters.split(','));
    filteredGuest = applySearch(filteredGuest);
    filteredGuest = filteredGuest.filter(u => String(u.id) !== String(tokenInfo?.id));
    return filteredGuest;
  }, [filterForm.filters, path, tokenInfo, filterForm.searchText]);

  const applyFilter = useCallback((guests, filters) => {
    let newGuests = [...guests];
    for (let category of GUEST_WANTED) {
      const applied = category.tags.filter(t => filters.includes(t.id));
      if (applied.length) {
        newGuests = newGuests.filter(u => {
          for (let apply of applied) {
            if (u.matching_profile && u.matching_profile?.["원하는 차량"]?.includes(apply.id)) {
              return true;
            }
          }
          return false;
        });
      }
    }
    return newGuests;
  }, [filterForm.filters]);
  
  const applySearch = useCallback((users) => {
    let searcher = new Hangul.Searcher(filterForm.searchText.replace(' ', '').toLowerCase());
    return users.filter((u) => searcher.search(`${u.nickname.replace(' ', '').toLowerCase()}`) >= 0);
  }, [filterForm.searchText]);

  const getCurrentPosition = useCallback(() => {
    openAddress(callbackAddress);
  }, []);

  const callbackAddress = useCallback((address, longitude, latitude) => {
    dispatch(pathAction.update({
      address: address,
      longitude: longitude,
      latitude : latitude,
      isGps : false,
    }));
    onFilterChange({id: 'sorter', value: '가까운거리순'});

    if (!isEmpty(tokenInfo)) {
      updateUser({
        variables: {
          id: tokenInfo.id,
          data: {
            lng: longitude,
            lat: latitude,
            address: `${address}\t`,
          }
        }
      }).then(res => {
      });
    };
  }, []);
  
  const guestTagCount = useMemo(() => sum(GUEST_WANTED.map(w => w.tags.length)), []);
  const _filters = useMemo(() => filterForm?.filters ? filterForm.filters.split(',') : [], [filterForm.filters]);
  const isFiltered = useMemo(() => _filters.length && _filters.length < guestTagCount , [filterForm.filters, guestTagCount]);

  return (
    <StyledVehicleListPage>
      <AddressRequest onClick={getCurrentPosition}>
        <img src="/vehicle/my_position.svg"/>
        {needPath ? '이곳을 눌러 주소를 설정하면 가까운 게스트를 먼저 볼 수 있습니다.' : `${path.address} 에서 가까운 게스트 순으로 표시됩니다.`}
      </AddressRequest>

      <EventBanner>
        <EventSwipe images={[{link: '/guide', url: '/event/guest_banner2.png'}]} />
      </EventBanner>

      <div style={{display: 'flex', flexWrap: 'wrap', padding: '10px 22px', gap: '8px'}}>
        <SearchBtn selected={isFiltered} onClick={() => setFilterOpen(true)}>
          <FilterListIcon fontSize={'14px'} />
          <span>조건 맞춤설정</span>
        </SearchBtn>
      </div>
      <div>
        <div style={{padding: '6px 22px', position: 'relative'}}>
          <FormComponent id="searchText" placeholder="찾으시는 게스트 닉네임을 입력해보세요" form={filterForm} onChange={onFilterChange}/>
          <img src="/vehicle/search.svg" style={{position: 'absolute', right: '34px', top: '50%', transform: 'translateY(-50%)', userSelect: 'none', pointerEvents: 'none'}}/>
        </div>
      </div>
      
      <div onClick={() => setRequestOpen(true)} style={{cursor: 'pointer', margin: '4px 22px 10px', lineHeight: '120%', backgroundColor: '#2eb0e5', color: 'white', padding: '6px', borderRadius: '6px', wordBreak: 'keep-all', textAlign: 'center'}}>
        주변 게스트들에게 매칭제안 보내기
      </div>
      <VehicleWanted noSave={true} tags={filterForm.filters} setTags={(v) => onFilterChange({id: 'filters', value: v})} open={filterOpen} onClose={() => setFilterOpen(false)}/>
      <div style={{borderBottom: '1px solid #DDDDDD', margin: '10px 22px'}} />
      <Vehicles>
        {showGuest && showGuests && showGuests.map((u, idx) =>
          (<ListGuest key={u.id} user={u} imageHide={true} />)
        ).reduce((acc, x, idx2) => acc === null ? [x] : [acc,
          <MyDivider />,
          x], null)}
      </Vehicles>

      {/* 지도에서 보기 버튼 */}
      {window?.kakao?.maps && vehicles && userTags && (!tokenInfo || userInfo) && !mapOpen && (
        <div className="map-btn" onClick={() => setMapOpen(true)}>
          지도에서 보기
        </div>
      )}

      {/* VehicleMap 컴포넌트 */}
      {window?.kakao?.maps && vehicles && userTags && (!tokenInfo || userInfo) && (
        <VehicleMap 
          vehicles={vehicles} 
          filteredVehicles={filteredVehicles}
          filters={filterForm.filters}
          onFilterChange={onFilterChange}
          guestVehicles={[]} 
          guestTags={userTags} 
          setMapBound={setMapBound}
          guestList={true}
          open={mapOpen} 
          onClose={() => {
            setMapOpen(false);
            setShowGuest(true);
            if (window.history.replaceState) {
              const newUrl = window.location.href.split('#')[0];
              window.history.replaceState(null, null, newUrl);
            }
          }}
        />
      )}
      <MyVehicleSelectDialog open={requestOpen} onClose={async (selected) => {
            setRequestOpen(false);
            if (selected) {
              navigate(`/vehicle/${selected.id}?mode=doori&action=bulk`);
            }
        }} myVehicles={myVehicles} />
    </StyledVehicleListPage>
  );
};

const DescriptionBox = styled.div`
  word-break: keep-all;
  font-size: 16px;
  line-height: 140%;
  margin: 4px 22px 8px;
  padding: 8px;
  color: #444444;
  font-weight: 500;
  background-color: #DDDDDD;
  text-align: center;
  border-radius: 4px;
`

const StyledVehicleListPage = styled.div`
  font-family: 'Pretendard', sans-serif;
  
  .map-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #5ECDC0;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
    border-radius: 999px;
    color: white;
    cursor: pointer;
    padding: 11px 16px;
    position: fixed;
    bottom: 10px;
    z-index: 50;
    left: 50%;
    transform: translateX(-50%);
  }
`

export default GuestListPage;

const EventBanner = styled.div`
    margin: 0 22px;
`

const GuestList = ({userTags, filters}) => {
}

const VehicleList = ({title, vehicles, filters}) => {
  const filteredVehicles = useMemo(() => applyFilters(vehicles, filters), [vehicles, filters]);
  const getUseTypes = useCallback(() => {
    let tags = filters.split(',').filter(t => ["호스트우선형","요일지정형","게스트우선형"].includes(t));
    return tags.map(t => getUseTypeId(t));
  }, [filters]);

  return <RowList>
    <div className="header">
      <h3 className="title">{title}</h3>
      <div className="actions">더보기</div>
    </div>
    <div className="list">
      <StyledListSwiper>
        {filteredVehicles.map((v) => <SwiperSlide><RowVehicle key={v.id} vehicle={v} imageHide={true} useTypes={getUseTypes()}/></SwiperSlide>)}
      </StyledListSwiper>
    </div>
  </RowList>
}

const ListSwiper = ({className, children}) => {
  return <div className={className}>
    <Swiper spaceBetween={10} slidesPerView={"auto"}>
      {children}
    </Swiper>
  </div>
}

const StyledListSwiper = styled(ListSwiper)`
  overflow: hidden;
  .swiper-slide {
    width: 150px;
  }
`

const RowList = styled.div`
  padding: 0 22px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .title {
    font-weight: 500;
    font-size: 18px;
    line-height: 160%;
  }

  .list {
    flex: 1;
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
`
