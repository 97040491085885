import {combineReducers, configureStore} from '@reduxjs/toolkit'
import {vehiclesReducer} from "./vehiclesReducer";
import {createMigrate, persistReducer, persistStore} from "redux-persist";
import {pathReducer} from "./pathReducer";
import storage from 'redux-persist/lib/storage';
import {authReducer} from "./authReducer";
import {loadingReducer} from "./loadingReducer";
import {guestNotiReducer} from "./guestNotiReducer";
import {userReducer} from "./userReducer";
import {toastReducer} from "./toastReducer";
import {sessionGuardReducer} from "./sessionGuardReducer";

const rootReducer = combineReducers({
    vehicles: vehiclesReducer,
    loading: loadingReducer,
    toast: toastReducer,
    path: pathReducer,
    auth: authReducer,
    guestNoti: guestNotiReducer,
    user: userReducer,
    sessionGuard: sessionGuardReducer,
})

const persistConfig = {
    key: 'root',
    version: 2,
    storage,
    whitelist: ["path", "auth", "guestNoti", "sessionGuard"],
    migrate: createMigrate({
        2: (state) => {
            // strapi v4로 넘어가면서 id의 형식이 바뀌었으므로 auth 리셋
            return {
                path: state.path,
                guestNoti: state.guestNoti,
            }
        }
    })
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware({
        serializableCheck: false,
    }),
})

export const persistor = persistStore(store);
