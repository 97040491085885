import {useDispatch, useSelector} from "react-redux";
import styled from "styled-components";
import {useLocation, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {commaNum, openKakaoChat} from "../../toolbox";
import {filter, get, find, reverse, sortBy, sumBy} from "lodash";
import {filterAvailableWallets, getLoginLink, paymentIdToOrderId, toDateFormat} from "../../toolbox/format";
import {useQuery} from "@apollo/client";
import {GET_PAYMENTS_BY_CONTRACT, GET_PAYMENTS_BY_USER, GET_WALLETS_BY_CONTRACT} from "../../query/userQuery";
import {Alert, Dialog, Snackbar} from "@mui/material";
import {loadTossPayments} from "@tosspayments/payment-sdk";
import {TOSS_CLIENT_KEY} from "../../index";
import queryString from "query-string";
import {flatEntity} from "../../toolbox/query";
import {GET_ALL_CONTRACTS_CALENDAR} from "../../query/calendarQuery";
import moment from "moment-timezone";
import {isAdmin} from "../../toolbox/logic";
import {isShowContractWallet} from "../../toolbox/calendar";
import {ContractSelect} from "../my/WalletPage";

const isShowWallet = (wallet) => {
    if (wallet.paymentCharge) {
        if (wallet.contract.id === wallet.paymentCharge.contract.id) {
            return false;
        }
    }
    return true;
}

const Payment = ({payment}) => {
    const statusToString = (status) => {
        if (status === 'READY')
            return '미결제';
        else if (status === 'DONE') {
            return '결제완료';
        }
        else if (status === 'WAITING_FOR_DEPOSIT') {
            return '입금대기';
        }
    }

    const totalAmount = payment.amount;
    const discountAmount = sumBy(payment.walletUses, (w) => w.amount);
    const paymentAmount = totalAmount + discountAmount;

    const selfCharges = filter(payment.walletCharges, (w) => w.contract.id === payment.contract.id);
    const selfChargeAmount = sumBy(selfCharges, (w) => w.amount);
    const opponents = filter(payment.walletCharges, (w) => w.contract.id !== payment.contract.id);
    const opponentChargeAmount = sumBy(opponents, (w) => w.amount);
    const chargeAmount = sumBy(payment.walletCharges, (w) => w.amount);

    const err = !(payment.name.includes('보증') || payment.name.includes('하이패스')) && payment.walletCharges.length === 0 && payment.amount !== 0;

    return <_Payment>
        <div className="detail">
            <div className="wrap">
                <div className="date">
                    <span>{toDateFormat(payment.startAt || payment.createdAt)} 청구</span>
                    <span className={payment.status}>{statusToString(payment.status)}</span>
                </div>
                <div className="content">
                    <div className="title">{payment.name}</div>
                    <div className="description">{payment.description}</div>
                </div>
                {err? <div className="error">이상결제건</div>: null}
                <div className="amount">결제 ID : {payment.id}</div>
                <div className="amount">청구 금액 : {commaNum(payment.amount)}원</div>
                <div className="amount">할인 금액 : {commaNum(discountAmount)}원</div>
                <div className="amount">실결제 금액 : {commaNum(paymentAmount)}원</div>
                {selfCharges.length ? <div className="amount">자기 충전 : {commaNum(selfChargeAmount)}원 ({selfCharges.map(w => w.user.id).join(',')})</div> : null}
                {opponents.length ? <div className="amount">상대 충전 : {commaNum(opponentChargeAmount)}원 ({opponents.map(w => w.user.id).join(',')})</div> : null}
                <div className="amount">두리카 매출 : {commaNum(totalAmount - chargeAmount)}원</div>
            </div>
        </div>
        <div>
            {/*<div>*/}
            {/*    연관 월렛 내역*/}
            {/*    {payment.walletCharges.map(w => <div>*/}
            {/*        <WalletSimple wallet={w}/>*/}
            {/*    </div>)}*/}
            {/*    {payment.walletUses.map(w => <div>*/}
            {/*        <WalletSimple wallet={w}/>*/}
            {/*    </div>)}*/}
            {/*</div>*/}
        </div>
        <div className="btn">
            {payment.toss_payment_histories.length > 0 && payment.toss_payment_histories[0].receiptUrl &&
                <div onClick={() => window.open(payment.toss_payment_histories[0].receiptUrl, '_blank')}>매출전표</div>}
        </div>
    </_Payment>
}

const WalletSimple = ({wallet}) => {
    const walletStatus = () => {
        return wallet.amount > 0? 'DONE': 'READY';
    }
    return <div>
        <span className={walletStatus()}>{wallet.user.realname}님 {wallet.amount > 0? '월렛': '월렛'} {wallet.amount > 0 && '+'}{commaNum(wallet.amount)}</span>
    </div>
}

const Wallet = ({wallet}) => {
    const walletStatus = () => {
        return wallet.amount > 0? 'DONE': 'READY';
    }

    const err = !(wallet.paymentCharge) && wallet.amount > 0;

    return <_Payment>
        <div className="detail">
            <div className="wrap">
                <div className="date">
                    <span>{toDateFormat(wallet.recordedAt || wallet.createdAt)} 내역</span>
                    <span className={walletStatus()}>{wallet.amount > 0? '월렛충전': '월렛차감'}{toDateFormat(wallet.recordedAt || wallet.createdAt) > toDateFormat()? ' 예정': ''}</span>
                </div>
                <div className="content">
                    <div className="title">{wallet.title}</div>
                    <div className="description">{wallet.description}</div>
                </div>
                {err? <div className="error">이상충전건</div>: null}
                <div className="amount">{commaNum(wallet.amount)}원</div>
            </div>
        </div>
    </_Payment>
}

export const PaymentManagePage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);

    const [contracts, setContracts] = useState(null);
    const [selectedContractId, setSelectedContractId] = useState(null);

    const [payments, setPayments] = useState(null);
    const [wallets, setWallets] = useState(null);

    const [openWithdraw, setOpenWithdraw] = useState(false);
    const [openCreate, setOpenCreate] = useState(false);

    const end = moment.tz('Asia/Seoul').subtract(2, 'month').format('YYYY-MM-DD');

    const {data: adminContracts, refetch: refetchAdmin} = useQuery(GET_ALL_CONTRACTS_CALENDAR, {
        variables: {
            endDate: moment.tz('Asia/Seoul').subtract(2, 'month').format('YYYY-MM-DD'),
        },
        fetchPolicy: "cache-first",
        nextFetchPolicy: "cache-only"
    });

    const {data: paymentRequests} = useQuery(GET_PAYMENTS_BY_CONTRACT, {
        variables: {
            id: selectedContractId,
        },
        skip: !selectedContractId,
    });

    const {data: walletData} = useQuery(GET_WALLETS_BY_CONTRACT, {
        variables: {
            id: selectedContractId,
        },
        skip: !selectedContractId,
    });

    useEffect(() => {
        if (!isAdmin(tokenInfo?.id)) {
            navigate('/');
        }
    }, [])

    useEffect(() => {
        if (adminContracts) {
            let _contracts = get(adminContracts, 'contracts.data', []);
            _contracts = _contracts.map(c => flatEntity(c));
            _contracts = filter(_contracts, c => isShowContractWallet(c));
            _contracts = sortBy(_contracts, c => c.vehicle.numberPlate);
            setContracts(_contracts);
        }
    }, [adminContracts]);

    useEffect(() => {
        if (paymentRequests && walletData) {
            let _paymentRequests = get(paymentRequests, 'paymentRequests.data', []);
            _paymentRequests = _paymentRequests.map(c => flatEntity(c));

            let _wallets = get(walletData, 'wallets.data', []);
            _wallets = _wallets.map(c => flatEntity(c));

            let result = _paymentRequests.concat(_wallets);

            result = sortBy(result, (v) => v.recordedAt ?? v.startAt ?? v.createdAt);
            setPayments(result.reverse());
        }
    }, [paymentRequests, walletData]);

    return <_PaymentPage>
        <ContractSelect visible={contracts && contracts.length > 1}>
            {contracts && contracts.map(c => <div key={c.id} className={selectedContractId === c.id? 'selected': 'unselected'}
                                                  onClick={() => {
                                                      if (selectedContractId === c.id) {
                                                          setSelectedContractId(null);
                                                      } else {
                                                          setSelectedContractId(c.id);
                                                      }
                                                  }}>
                {c.vehicle?.model ?? '오류'} ({c.vehicle?.numberPlate ?? '오류'}) {`(${c?.users_permissions_user?.realname ?? '이름없음'} ${c.contractType === 'DOORICAR'? '게스트': c.contractType === 'SOLOCAR'? '렌터카': '호스트'})`}
            </div>)}
        </ContractSelect>
        <div>
            <div onClick={() => setOpenWithdraw(true)}>수익금 출금</div>
            <div onClick={() => setOpenCreate(true)}>금액 청구</div>
        </div>
        <h2>거래 목록</h2>
        <Payments>
            {payments && payments.map((p) => {
                if (p.__typename === 'PaymentRequest') {
                    return <Payment payment={p} />;
                } else if (p.__typename === 'Wallet' && isShowWallet(p)) {
                    return  <Wallet wallet={p} />;
                }
            })}

            {payments && payments.length === 0 && <div>
                거래 항목이 없습니다.
            </div>}
        </Payments>
        <WithdrawDialog open={openWithdraw} onClose={() => setOpenWithdraw(false)} contracts={contracts} contractId={selectedContractId} payments={payments}></WithdrawDialog>
    </_PaymentPage>
}

const WithdrawDialog = ({open, onClose, contracts, contractId, payments}) => {
    let contract = find(contracts, (c) => c.id === contractId);
    const [withdrawPoint, setWithdrawPoint] = useState(0);
    console.log(contract);
    console.log(payments);
    let wallets = filter(payments, p => p.__typename === 'Wallet');
    wallets = filterAvailableWallets(wallets);
    let sum = 0;
    for (let w of wallets) {
        sum += w.amount;
    }

    const withdrawAvailable = Math.max(sum - contract?.minWallet, 0)

    console.log(withdrawPoint)
    return <Dialog open={open} onClose={onClose}>
        <div>
            <div>
                {contract?.users_permissions_user?.realname} {contract?.contractType === 'DOORICAR'? '게스트': '호스트'}
            </div>
            <div>월렛 잔액 : {commaNum(sum)}</div>
            <div>출금 불가 금액 : {commaNum(contract?.minWallet)}</div>
            <div>출금 가능 금액 : {commaNum(withdrawAvailable)}</div>
            <input value={withdrawPoint} onChange={(e) => {
                const v = Number(e.target.value);
                setWithdrawPoint(Math.min(v? v: 0, withdrawAvailable))
            }}/>;
        </div>
        <div>출금하기</div>
    </Dialog>
}

const _PaymentPage = styled.div`
  font-family: 'Pretendard',sans-serif;
  padding-left: 10px;
  padding-right: 10px;

  > h2 {
    padding: 16px;
  }
`
const MyDivider = styled.div`
  width: 100%;
  border-width: 0;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.12);
  border-bottom-width: thin;
  margin-left: 22px;
  margin-right: 22px;
`

const Payments = styled.div`
  box-shadow: 0px -6px 20px -9px rgba(0, 0, 0, 0.18);
  background-color: #F5F5F5;
`

const _Payment = styled.div`
  border-radius: 8px;
  background-color: white;
  position: relative;
  margin-bottom: 30px;
  border: 1px solid #DDDDDD;
  > .detail {
    padding: 12px;
    > .wrap {
      > .date {
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        font-weight: 500;
        padding-left: 2px;
        padding-right: 4px;
        > .DONE {
          color: #008C00;
        }
      }

      > .content {
        font-size: 14px;
        margin: 12px 0 10px;
        padding: 8px 10px;
        background-color: #EEEEEE;
        border-radius: 8px;
        white-space: pre-wrap;
        word-break: keep-all;
        font-weight: 500;
        line-height: 18px;
        > .description {
          font-size: 12px;
          margin-top: 8px;
          font-weight: 300;
        }
      }

      > .amount {
        text-align: right;
        font-size: 12px;
        font-weight: 700;
        line-height: 14px;
      }
      > .error {
        color: red;
        text-align: right;
        font-size: 12px;
        font-weight: 700;
        line-height: 14px;
      }
    }
  }

  > .btn {
    display: flex;
    flex-grow: 1;
    line-height: 40px;
    font-size: 14px;
    font-weight: 600;
    border-top: inherit;
    > div {
      cursor: pointer;
      color: #202020;
      flex-grow: 1;
      flex-basis: 80px;
      text-align: center;
      border-left: 1px solid #DDDDDD;
      border-right: 1px solid #DDDDDD;
      :first-child {
        border-left: none;
        border-right: none;
      }
      :last-child {
        border-right: none;
      }
    }

    > .pay {
      font-size: 16px;
      font-weight: 600;
      color: #007aff;
    }
  }
`;
