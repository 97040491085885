import {useDispatch, useSelector} from "react-redux";
import {useEffect, useMemo, useRef, useState} from "react";
import {ThumbSwipe2} from "./ThumbSwipe2";
import {commaNum, distanceFormat, getLoginLink, getResponseTimeTxt, getUseTypeTxt} from "../../../toolbox/format";
import {calcDistance} from "../../../toolbox";
import {Link, useLocation, useNavigate} from "react-router-dom";
import styled, {css} from "styled-components";
import {Mannerbox} from "./Mannerbox";
import {pathSelector} from "../../../toolbox/logic";
import {useMutation, useQuery} from "@apollo/client";
import {CREATE_VEHICLE_LIKE, DELETE_VEHICLE_LIKE} from "../../../query/userQuery";
import {GET_DOORI_VEHICLE} from "../../../query/vehicleQuery";
import {find, get, isEmpty} from "lodash";
import {toastAction} from "../../../redux/toastReducer";
import {authAction} from "../../../redux/authReducer";
import {VehicleThumbnail} from "./VehicleThumbnail";
import { useCallback } from "react";

const _Mannerbox = styled(Mannerbox)`
  position: absolute;
  right: 0;
  top:-4px;
  background-color: white;
  padding: 2px;
`

const _ListVehicle = ({className, vehicle, key, isSolo, imageHide, useTypes, hideManner, showBulk}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const pathInfo = useSelector(({path}) => path);
    const userInfo = useSelector(({user}) => user.user);
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);
    const [_imageHide, setImageHide] = useState(imageHide ? true: false);
    const path = useMemo(() => pathSelector(userInfo, pathInfo), [userInfo, pathInfo]);
    const bottomObserver = useRef();
    const isMyVehicle = useMemo(() => vehicle.hostUser?.id === tokenInfo?.id, [vehicle, tokenInfo]);

    useEffect(() => {
        let observer;
        if (bottomObserver && _imageHide) {
            observer = new IntersectionObserver(onIntersect, {
                threshold: 0.1,
                rootMargin: '450px',
            });
            observer.observe(bottomObserver.current);
        }
        return () => observer && observer.disconnect();
    }, [bottomObserver, _imageHide]);

    const onIntersect = useCallback(async ([entry], observer) => {
        if (entry.isIntersecting) {
            setImageHide(false);
            observer.unobserve(entry.target);
        }
    }, [_imageHide]);

    const formatAddress = useCallback((address) => {
        const str = address.split('\t')[0];
        const regex = /\s\d+/;
        const match = str.match(regex);
        if (match) {
            const index = match.index;
            return str.substring(0, index);
        } else {
            return str;
        }
    }, [vehicle.hostContract.address]);

    const getPrice = useCallback(() => {
        if (useTypes && useTypes.length) {
            let minPrice = null;
            for (let useType of useTypes) {
                const u = find(vehicle.hostContract.useTypes, (u) => u.id === useType);
                if (u) {
                    if (minPrice === null || minPrice > u.price) {
                        minPrice = u.price;
                    }
                }
            }
            return minPrice;
        }
        return Math.min(...vehicle.hostContract.useTypes.map(u => u.price));
    }, [useTypes]);

    const getYear = useCallback(() => {
      const year = String(vehicle.year);
      if (year.length === 4) {
        return year.substring(2);
      }
      return year;
    }, [vehicle.year]);

    return (
      <a href={`/vehicle/${vehicle.id}?mode=doori`} target="_blank" className={className}>
        <div className="container">
          <div className="header-box" ref={bottomObserver}>
            {_imageHide ?
              <VehicleThumbnail image={({url: null})}/>
              :
              <VehicleThumbnail image={vehicle.thumbnail} />
            }
            <LikeButton vehicle={vehicle} />
          </div>
          <div className="content">
            {!isSolo && !hideManner && <_Mannerbox liter={36} />}
            <p className="car">
              {vehicle.brand} {vehicle.model}
            </p>
            <p className="description">
              <span className="year">{getYear()}년식 · </span><span className="address">{formatAddress(vehicle.hostContract.address)}</span> <span className="distance-bracket">(</span><span className="distance">거리 {distanceFormat(calcDistance(vehicle.hostContract.latitude, vehicle.hostContract.longitude, path.latitude, path.longitude))}</span><span className="distance-bracket">)</span>
            </p>
            <p className="price">
              {useTypes && (
                vehicle.eventPrice === false ?
                  <>
                    <span>월 {commaNum(getPrice())}원</span><br/>
                  </>
                  :
                  <>
                    <span>월 {commaNum(getPrice() * 0.9)}원</span> <span className="line-through">{commaNum(getPrice())}원</span><br/>
                    <span className="first-month-price" style={{fontSize: '14px', color: '#2eb0e5'}}>첫 달 {commaNum(getPrice() * 0.9 - 100000)}원</span>
                  </>
              )}
              {!useTypes && (
                vehicle.eventPrice === false ?
                  <>
                    <span>월 {commaNum(getPrice())}원</span>
                  </>
                  :
                  <>
                    <span>월 {commaNum(getPrice() * 0.9)}원</span> <span className="before-price">{commaNum(getPrice())}원</span>
                    <br/>
                    <span className="first-month-price" style={{fontSize: '14px', color: '#2eb0e5'}}>첫 달 {commaNum(getPrice() * 0.9 - 100000)}원</span>
                  </>
              )}
            </p>
            <p className="tags">
              {isMyVehicle && !vehicle.visible && <span className="user-tag">미노출</span>}
              <span className="response-time-tag">{getResponseTimeTxt(vehicle?.hostUser?.hostStat)? `평균응답${getResponseTimeTxt(vehicle?.hostUser?.hostStat)}`: '응답시간보통'}</span>
              {vehicle.hostContract.useTypes.map((u) => <span className="user-tag">{getUseTypeTxt(u.id)}</span>)}
            </p>
            {showBulk && isMyVehicle && 
              <div>
              <a href={`/vehicle/${vehicle.id}?mode=doori&action=bulk`} target="_blank">
                <div style={{marginTop: '4px', lineHeight: '120%', backgroundColor: '#2eb0e5', color: 'white', padding: '4px 6px', borderRadius: '4px', wordBreak: 'keep-all', textAlign: 'center'}}>주변 게스트들에게 매칭제안 보내기</div>
              </a>
              </div>
            }
          </div>
        </div>
      </a>
    )
}

export const ListVehicle2 = styled(_ListVehicle)`
width: 100%;
display: flex;
flex-direction: column;

.line-through {
  text-decoration: line-through;
}
  > .container {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    font-size: 14px;
    cursor: pointer;
    padding: 0;
    display: flex;
    flex-grow: 1;
    gap: 8px;
    > .header-box {
    position: relative;
    display: flex;
    flex-grow: 0;
    width: 140px;
  }
  > .content {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    gap: 2px;
    > a {
      display: none;
    }
    > .car {
      // word-break: break-all;

      ${props => !props.hideManner && css`
        margin-right: 44px;
      `}
      line-height: 20px;
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 2px;
      display: flex;
      align-items: center;
      color: black;
    }
    
    > .description {
      color: #555555;
      line-height: 18px;
      margin-bottom: 4px;
      word-break: keep-all;
    }
    .distance {
      white-space: nowrap;
    }
    > .address {
      font-size: 14px;
      line-height: 16px;
      color: #555555;
    }
    > .tags {
      display: flex;
      gap: 4px;
      flex-wrap: wrap;
      padding: 4px 0;
    }
    > .price {
      white-space: nowrap;
      font-size: 16px;
      line-height: 130%;
      font-weight: 600;

      > .before-price {
        text-decoration: line-through;
      }
    }
    > .title {
      word-break: break-all;
      font-size: 16px;
      line-height: 120%;
      margin: 10px 0 6px;
      padding: 4px;
      color: #444444;
      font-weight: 600;
      background-color: #DDDDDD;
      text-align: center;
      border-radius: 4px;
    }
  }

  .user-tag {
    font-weight: 500;
    font-size: 14px;
    line-height: 130%;
    padding: 3px 5px;
    border-radius: 4px;
    white-space: nowrap;
    color: #F16767;
    background: #FFEAEA;
  }

  .response-time-tag {
    font-weight: 500;
    font-size: 14px;
    line-height: 130%;
    padding: 3px 5px;
    border-radius: 4px;
    white-space: nowrap;
    color: #4dc361;
    background: #E0FFE0;
  }
  }
`;

export const RowVehicle = styled(ListVehicle2)`
width: 140px;
  .car {
    margin: 0;
  }
  > .container {
    padding: 0;
    flex-direction: column;


    
  .user-tag {
    font-size: 12px;
    padding: 2px 4px;
  }

  .response-time-tag {
    font-size: 12px;
    padding: 2px 4px;
  }
  }

  > .header-box {
        flex-grow: 0;
  }

  > .content {
    > .response-time-tag {
      display: none;
    }
  }

  .response-time-tag {
      display: none;
    }
      
.line-through {
  display: none;
}
  .address {
    display: none;
  }
    .distance-bracket {
      display: none;
    }

    .first-month-price {
      display: none;
    }
`


export const TinyVehicle = styled(ListVehicle2)`
  .tags {
    display: none !important;
  }
  .before-price {
    display: none !important;
  }
  .first-month-price {
    display: none !important;
  }
  .content {
    justify-content: center;
  }
`

const _LikeButton = ({className, vehicle}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);

    const [vehicleLike, {loading: loading1}] = useMutation(CREATE_VEHICLE_LIKE);
    const [deleteLike, {loading: loading2}] = useMutation(DELETE_VEHICLE_LIKE);
    const [myLikeId, setMyLikeId] = useState(null);

    useEffect(() => {
        if (vehicle) {
            const myLike = find(vehicle?.likes, (l) => l.user.id == tokenInfo?.id);
            if (myLike) {
                setMyLikeId(myLike.id);
            }
        }
    }, [vehicle]);

    const isLike = () => {
        if (loading1) {
            return true;
        } else if (loading2) {
            return false;
        }
        return myLikeId;
    }

    const handleLike = async (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (isEmpty(tokenInfo)) {
            alert("로그인이 필요합니다.")
            const to = getLoginLink(location);
            navigate(to);
            return;
        }
        if (loading1 || loading2) {
            return;
        }
        if (isLike() && myLikeId) {
            await deleteLike({
                variables: {
                    id: myLikeId,
                },
                onCompleted: data => {
                    setMyLikeId(null);
                    vehicle.likes = vehicle.likes.filter((v) => v.user.id != tokenInfo.id);
                }
            });
        } else if (!isLike() && !myLikeId){
            dispatch(toastAction.toast({severity: 'success', 'message': '관심 차량 목록에 추가되었습니다.'}));
            await vehicleLike({
                variables: {
                    data: {
                        user: tokenInfo.id,
                        vehicle: vehicle.id,
                    },
                },
                onCompleted: data => {
                    let likeId = get(data, "createVehicleLike.data.id");
                    setMyLikeId(likeId);
                    vehicle.likes.push({
                        id: likeId,
                        user: {id: tokenInfo.id}
                    })
                }
            });
        }
    }

    return <img className={className} src={(!isEmpty(tokenInfo) && isLike())? "/vehicle/liked.png": "/vehicle/like.png"} onClick={handleLike} />
}

export const LikeButton = styled(_LikeButton)`
  cursor: pointer;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 4px;
  right: 4px;
`
