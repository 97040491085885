import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    needFetch: true,
    payload: [],
};

export const vehiclesSlice = createSlice({
    name: 'vehicles',
    initialState,
    reducers: {
        update: (state, action) => {
            state.payload = action.payload;
            state.needFetch = false;
            return state;
        },
        refetch: (state) => {
            state.needFetch = true;
            return state;
        }
    }
})

export let vehiclesAction = vehiclesSlice.actions;
export let vehiclesReducer = vehiclesSlice.reducer;

