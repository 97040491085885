import styled from "styled-components";
import Header from "./Header";
import {Drawer} from "@mui/material";

export const FullScreen = ({children}) => {
    return <Drawer open={true} PaperProps={{
        style: {
            maxHeight: '100%',
            height: '100%',
        },
    }}>
        <PageWrap>
            <Header />
            <div className="content">
                {children}
            </div>
        </PageWrap>
    </Drawer>
}

const PageWrap = styled.div`
  box-sizing: border-box;
  font-family: "Elice Digital Baeum", sans-serif;
  height: calc(100% - 50px);
  width: 100vw;
  .content {
    height: 100%;
    max-height: 100%;
    box-shadow: 0 2px 36px 0 rgba(0,0,0,.05);
    max-width: 450px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
  };
  
`