import React, { useEffect, useRef, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { calcDistance } from "../../../toolbox";
import { calcAge, distanceFormat, manNum } from "../../../toolbox/format";
import { pathSelector } from "../../../toolbox/logic";
import { GUEST_WANTED } from "../../../toolbox/guestTag";
import { GuestThumbnail, VehicleThumbnail } from "./VehicleThumbnail";
import { Mannerbox } from "./Mannerbox";

const _Mannerbox = styled(Mannerbox)`
  position: absolute;
  right: 0;
  top: -4px;
  background-color: white;
  padding: 2px;
`;

const formatAddress = (address) => {
  if (!address) return '';
  const str = address.split('\t')[0];
  const regex = /\s\d+/;
  const match = str.match(regex);
  return match ? str.substring(0, match.index) : str;
};

const _ListGuest = ({ className, user, imageHide, hideManner, small }) => {
  const pathInfo = useSelector(({ path }) => path);
  const userInfo = useSelector(({ user }) => user.user);
  const [_imageHide, setImageHide] = useState(imageHide);
  const path = useMemo(() => pathSelector(userInfo, pathInfo), [userInfo, pathInfo]);
  const bottomObserver = useRef();

  useEffect(() => {
    let observer;
    if (bottomObserver && _imageHide) {
      observer = new IntersectionObserver(onIntersect, {
        threshold: 0.1,
        rootMargin: '450px',
      });
      observer.observe(bottomObserver.current);
    }
    return () => observer && observer.disconnect();
  }, [bottomObserver, _imageHide]);

  const onIntersect = async ([entry], observer) => {
    if (entry.isIntersecting) {
      setImageHide(false);
      observer.unobserve(entry.target);
    }
  };

  const tags = useMemo(() => user?.matching_profile?.["원하는 차량"]?.split(',') || null, [user]);
  const introduce = useMemo(() => user?.matching_profile?.["자기소개"] || null, [user]);

  const price = useMemo(() => {
    if (!tags) return '가격대 상관 없음';

    const allPriceTags = GUEST_WANTED.find((wanted) => wanted.title === "월이용료").tags;
    const priceTags = allPriceTags.filter((tag) => tags.includes(tag.id));

    if (priceTags.length === 0) return '가격대 상관 없음';

    const groups = priceTags.reduce((acc, tag, index) => {
      const currentIndex = allPriceTags.findIndex(t => t.id === tag.id);
      
      if (index === 0 || currentIndex !== allPriceTags.findIndex(t => t.id === priceTags[index - 1].id) + 1) {
        acc.push([tag]);
      } else {
        acc[acc.length - 1].push(tag);
      }
      return acc;
    }, []);

    const texts = groups.map((group) => {
      if (group.length === 1) return group[0].text;
      
      const min = group.some(tag => tag.min === undefined) ? null : Math.min(...group.map(tag => tag.min));
      const max = group.some(tag => tag.max === undefined) ? null : Math.max(...group.map(tag => tag.max));

      if (min && max) return `${manNum(min)}만원 ~ ${manNum(max)}만원`;
      if (min) return `${manNum(min)}만원 이상`;
      if (max) return `${manNum(max)}만원 이하`;
      return '가격대 상관 없음';
    });

    return texts.join('\n또는 ');
  }, [tags]);

  const vehicleBrandTags = useMemo(() => {
    if (!tags) return null;
    const allBrandTags = GUEST_WANTED.find((wanted) => wanted.title === "브랜드").tags;
    const filteredBrandTags = allBrandTags.filter((tag) => tags.includes(tag.id));
    if (filteredBrandTags.length === 0) return allBrandTags;
    return allBrandTags.filter((tag) => tags.includes(tag.id));
  }, [tags]);

  const vehicleMatchingTypeTags = useMemo(() => {
    if (!tags) return null;
    const allMatchingTypeTags = GUEST_WANTED.find((wanted) => wanted.title === "매칭유형").tags;

    const filteredMatchingTypeTags = allMatchingTypeTags.filter((tag) => tags.includes(tag.id));
    if (filteredMatchingTypeTags.length === 3 || filteredMatchingTypeTags.length === 0) return [
      {id: "매칭유형 상관없음", tag: "매칭유형 상관없음"},
    ];
    return allMatchingTypeTags.filter((tag) => tags.includes(tag.id));
  }, [tags]);


  const showTags = (vehicleBrandTags && vehicleBrandTags.length > 0) || (vehicleMatchingTypeTags && vehicleMatchingTypeTags.length > 0);

  const truncatedIntroduce = useMemo(() => {
    if (!introduce) return null;
    return introduce.length > 60 ? `${introduce.slice(0, 60)}...` : introduce;
  }, [introduce]);

  const age = useMemo(() => {
    if (!user.matching_profile?.["생년월일"]) return null;
    const birthString = user.matching_profile?.["생년월일"];
    if (birthString?.length === 8) {
      return calcAge(`${birthString.slice(0, 4)}-${birthString.slice(4, 6)}-${birthString.slice(6, 8)}`);
    }
    return null;
  }, [user.matching_profile]);

  const gender = useMemo(() => {
    if (!user.matching_profile?.["성별"]) return null;
    return `${user.matching_profile?.["성별"]}성`;
  }, [user.matching_profile]);

  return (
    <a href={`/user/${user.id}`} target="_blank" className={className}>
      <div className="container">
        <div className="header-box" ref={bottomObserver}>
          <GuestThumbnail small={small} image={_imageHide ? {url: null} : (user?.profile_image?.url ? user.profile_image : {url: '/layout/char_doori.svg'})} />
        </div>
        <div className="content">
          {!hideManner && <_Mannerbox liter={36} />}
          <p className="car">{user.nickname}</p>
          <p className="description">
            <span className="address">{formatAddress(user.address)}</span> <span className="distance">(거리 {distanceFormat(calcDistance(user.latitude, user.longitude, path.latitude, path.longitude))})</span>
            <p style={{marginTop: '4px'}}>{age && <span className="age">{age}세</span>} {gender && <span className="gender">{gender}</span>}</p>
          </p>
          <p className="price">
            <span>{price === '가격대 상관 없음' ? '가격대 상관 없음' : `월 ${price}`}</span>
          </p>
          {showTags && <p className="tags">
            {vehicleBrandTags?.map((tag) => <span key={tag.id} className="brand-tag">{tag.tag}</span>)}
            {vehicleMatchingTypeTags?.map((tag) => <span key={tag.id} className="matching-type-tag">{tag.tag}</span>)}
          </p>}
          {/* <p className="distance">거리 {distanceFormat(calcDistance(user.latitude, user.longitude, path.latitude, path.longitude))}</p> */}
        </div>
      </div>
      {introduce && <p className="introduce">
        {truncatedIntroduce}
      </p>}
    </a>
  );
};

export const ListGuest = styled(_ListGuest)`
  width: 100%;
  display: flex;
  flex-direction: column;

  > .container {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    flex-grow: 1;
    gap: 8px;

    > .header-box {
      position: relative;
    }

    > .content {
      display: flex;
      flex-direction: column;
      position: relative;
      flex-grow: 1;
      gap: 2px;

      > a {
        display: none;
      }

      > .car {
        white-space: nowrap;
        overflow: hidden;
        word-break: break-all;
        margin-right: 44px;
        line-height: 20px;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 2px;
        display: flex;
        align-items: center;
        color: black;
      }
      
      > .description {
        color: #555555;
        line-height: 18px;
        margin-bottom: 4px;
        word-break: keep-all;

        > .address {
          white-space: nowrap;
        }

        > .distance {
          white-space: nowrap;
        }
      }

      > .address {
        font-size: 14px;
        line-height: 16px;
        color: #555555;
      }

      > .tags {
        display: flex;
        gap: 4px;
        flex-wrap: wrap;
        padding: 4px 0;
      }

      > .price {
        white-space: pre-wrap;
        font-size: 15px;
        line-height: 130%;
        font-weight: 600;
      }
      
      > .distance {
        font-size: 14px;
        line-height: 130%;
        color: #555555;
      }

      > .title {
        word-break: break-all;
        font-size: 16px;
        line-height: 0%;
        margin: 10px 0 6px;
        padding: 4px;
        color: #444444;
        font-weight: 600;
        background-color: #DDDDDD;
        text-align: center;
        border-radius: 4px;
      }
    }

    .matching-type-tag {
      font-weight: 500;
      font-size: 14px;
      line-height: 130%;
      padding: 3px 5px;
      border-radius: 4px;
      white-space: nowrap;
      color: #F16767;
      background: #FFEAEA;
    }

    .brand-tag {
      font-weight: 500;
      font-size: 14px;
      line-height: 130%;
      padding: 3px 5px;
      border-radius: 4px;
      white-space: nowrap;
      color: #4dc361;
      background: #E0FFE0;
    }
  }

  
      .introduce {
        font-size: 14px;
        line-height: 130%;
        color: #555555;
        background-color: #f0f0f0;
        border-radius: 8px;
        padding: 3px 10px;
        margin-top: 12px;
        margin-bottom: 4px;
        position: relative;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        max-height: 36px;
        word-break: break-word;
        white-space: normal;
        box-sizing: content-box;

        > * {
          overflow: hidden;
        }

        &::before {
          content: '';
          position: absolute;
          top: -6px;
          left: 20px;
          width: 0;
          height: 0;
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          border-bottom: 6px solid #f0f0f0;
          z-index: 1;
        }
      }
`;

export const RowGuest = styled(ListGuest)`
  width: 140px;

  > .container {
    padding: 0;
    flex-direction: column;

    > .content {
      flex-grow: 0;
}
    
  .brand-tag {
    font-size: 12px;
    padding: 2px 4px;
  }

  .matching-type-tag {
    font-size: 12px;
    padding: 2px 4px;
  }
  }
`;
