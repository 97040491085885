import {openKakaoChat} from "../toolbox";
import styled, {css} from "styled-components";
import {useLocation} from "react-router-dom";
import {find} from "lodash";

const HIDDEN_PATH = ["/my/chat/", "/my/chat_start"]
const _KakaoFloat = ({className}) => {
    const location = useLocation();
    return <div className={className} path={location.pathname} hidden={find(HIDDEN_PATH, (p) => location.pathname.startsWith(p))} onClick={() => openKakaoChat("consult-btn_open_chat", null)}>
        <img src="/layout/kakaotalk-consult.png" alt="톡상담"/>
    </div>
}

export const KakaoFloat = styled(_KakaoFloat)`
  width: 104px;
  transition: ease-in-out 0.2s;

  position: fixed;
  z-index: 1300;
  right: 15px;
  bottom: 100px;


  ${(props) => {
    if (props.path === '/') {
      return css`
        right: 14px;
        bottom: 16px;
        //bottom: 266px;
        //display: none;
      `
    }
    if (props.path.startsWith('/vehicle/')) {
      return css`
            right: 14px;
            bottom: 146px;
            //bottom: 266px;
            //display: none;
          `
    }
    if (props.path === '/vehicle') {
      return css`
        right: 14px;
        bottom: 16px;
        //bottom: 266px;
        //display: none;
      `
    }
    if (props.path === '/user') {
      return css`
        right: 14px;
        bottom: 16px;
        //bottom: 266px;
        //display: none;
      `
    }
  }}
  
  &:hover {
    cursor: pointer;
    transform: translateY(-4px);
  }

  > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`
