import {gql} from "@apollo/client";

const CONTRACT = `
  id
  attributes {
    startDate
    endDate
    useType
    monthlyCharge
    monthlyChargeDuo
    kmCharge
    contractType
    address
    parkingInfo
    tokenAmount
    minWallet
    manner_actions {
      data {
        attributes {
          actionType
          likes {
            data {
              id
              attributes {
                actionDate
              }
            }
        }
        }
      }
    }
    users_permissions_user {
      data {
        id
        attributes {
          realname
          nickname
        }
      }
    }
    vehicle {
      data {
        id
        attributes {
          brand
          model
          numberPlate
          doorive
          draftVehicle
          contracts {
            data {
              id
              attributes {
                startDate
                endDate
                contractType
                useType
                useTypeDetail
                users_permissions_user {
                  data {
                    id
                    attributes {
                      realname
                      nickname
                    }
                  }
                }
              }
            }
          }
          thumbnail {
            data {
              id
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`

/*

query getUser($id:ID!){
  usersPermissionsUser(id: $id) {
 */
export const GET_ALL_CONTRACTS_CALENDAR = gql`
query getContracts($endDate: Date!) {
  contracts(filters: {or: [{endDate: {null: true}}, {endDate: {gte: $endDate}}]}) {
    data {
      ${CONTRACT}
    }
  }
}
`;

// export const GET_MY_CONTRACTS = gql`
// query getContracts {
//     me {
//       user {
//         contracts {
//           data {
//             ${CONTRACT}
//           }
//         }
//       }
//     }
//   }
// `;

export const GET_MY_CONTRACTS_CALENDAR = gql`
query getUser($id:ID!){
  usersPermissionsUser(id: $id) {
    data {
      id
      attributes {
        contracts {
          data {
            ${CONTRACT}
          }
        }
      }
    }
  }
}`;


export const GET_TRIPS = gql`
query getTrips ($vehicleId: ID!, $startDate: Date!, $endDate: Date!) {
  trips(filters: {vehicle: {id: {eq: $vehicleId}}, ignore: {eq: false}, and: [{date: {gte: $startDate}}, {date: {lte: $endDate}}]}) {
    data {
      id
      attributes {
        date
        description
        meta
        km
        hipass
        contract {
          data {
            id
            attributes {
              contractType
              startDate
              endDate
              users_permissions_user {
                data {
                  id
                  attributes {
                    realname
                    nickname
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

export const GET_RESERVATION_REQUESTS = gql`
query getReservationRequests ($vehicleId: ID!, $startDate: Date!, $endDate: Date!) {
  reservationRequests(filters: {vehicle: {id: {eq: $vehicleId}}, accepted: {null: true}, and: [{useDate: {gte: $startDate}}, {useDate: {lte: $endDate}}]}) {
    data {
      id
      attributes {
        useDate
        expireAt
        autoAccept
        contract {
          data {
            id
            attributes {
              users_permissions_user {
                data {
                  id
                  attributes {
                    realname
                    nickname
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

export const UPDATE_TRIP = gql`
mutation updateTrip ($id: ID!, $data: TripInput!) {
  updateTrip(id: $id, data: $data) {
    data {
      id
    }
  }
}
`;

export const GET_RESERVATIONS = gql`
query getReservations ($vehicleId: ID!, $startDate: Date!, $endDate: Date!) {
  reservations(filters: {vehicle: {id: {eq: $vehicleId}}, and: [{useDate: {lte: $endDate}}, {useDate: {gte: $startDate}}]}) {
    data {
      id
      attributes {
        useDate
        description
        createdAt
        priority
        contract {
          data {
            id
            attributes {
              contractType
              startDate
              endDate
              daySelect
              users_permissions_user {
                data {
                  id
                  attributes {
                    realname
                    nickname
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

// 예약 API는 당일 예약 시 알림톡을 보내는 스펙으로 인해서 별도 API를 사용함
// export const ADD_RESERVATION = gql`
// mutation createReservation($data: ReservationInput) {
//   createReservation(input: {data: $data}) {
//     reservation {
//       id
//       useDate
//       description
//       createdAt
//       contract {
//         id
//         contractType
//         startDate
//         endDate
//         users_permissions_user {
//           id
//           realname
//           nickname
//         }
//       }
//     }
//   }
// }
// `;


export const DELETE_RESERVATION = gql`
mutation deleteReservation ($id: ID!) {
  deleteReservation(id: $id) {
    data {
      id
    }
  }
}
`;
