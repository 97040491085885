import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    toast: false,
};

export const toastSlice = createSlice({
    name: 'toast',
    initialState,
    reducers: {
        toast: (state, action) => {
            state.toast = action.payload;
            return state;
        },
    }
})

export let toastAction = toastSlice.actions;
export let toastReducer = toastSlice.reducer;

