import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import styled, { css } from "styled-components";
import { useMutation } from "@apollo/client";
import axios from "axios";
import moment from "moment-timezone";
import queryString from "query-string";
import * as Hangul from 'hangul-js';
import FilterListIcon from '@mui/icons-material/FilterList';

import { calcDistance, useForm } from "../../toolbox";
import { filter, find, isEmpty, sortBy, sum } from "lodash";
import { pathAction } from "../../redux/pathReducer";
import { convertMapVehicle, fillUseTypes, getUseTypeId } from "../../toolbox/format";
import { applyFilters, isAdmin, openAddress, pathSelector } from "../../toolbox/logic";
import { SERVER_ADDRESS } from "../../index";
import { UPDATE_USER } from "../../query/userQuery";
import { GUEST_WANTED } from "../../toolbox/guestTag";

import { VehicleMap } from "./component/VehicleMap";
import { EventSwipe } from "../event/component/EventSwipe";
import LoadingIndicator from "../../layout/LoadingIndicator";
import { ListVehicle2, RowVehicle } from "./component/ListVehicle2";
import { FormComponent } from "./VehicleAdd2/FormComponent";
import { VehicleWanted } from "./component/VehicleWanted";
import { ListGuest, RowGuest } from "./component/ListGuest";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/swiper-bundle.css';

const MyDivider = styled.div`
  width: 100%;
  border-width: 0;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.12);
  border-bottom-width: thin;
//   margin-left: 22px;
//   margin-right: 22px;
`
const _AddressRequest = ({className, children, onClick}) => {
    return <div className={className} onClick={onClick}>
        <>{children}</>
    </div>
}

const AddressRequest = styled(_AddressRequest)`
  cursor: pointer;
  margin: 8px 22px;
  padding: 6px;
  display: flex;
  align-items: center;
  //box-sizing: border-box;
  //width: 100%;
  border-radius: 4px;
  border: 1px solid rgb(180,180,180);
  color: rgb(80,80,80);
  word-break: keep-all;
  
  > img {
    width: 20px;
    margin-right: 8px;
  }
`

const VehiclesRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding: 0 10px;
`

const Vehicles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px 10px;
`

export const SearchBtn = styled.div`
  padding: 4px;
  cursor: pointer;
  margin-left: auto;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  gap: 4px;
  border-radius: 8px;
  > img {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
  > span {
  }  
  user-select: none;
  
  &:hover {
    background-color: rgb(232,234,237);
  }
  
  ${props => props.selected && css`
    border-color: rgb(232,240,254);
    background-color: rgb(232,240,254);
    color: #1558d6;
  `}
`

export const FilterBtn = styled.div`
  border: 1px solid rgb(218,220,224);
  background-color: white;
  border-radius: 80px;
  color: rgb(32,33,36);
  display: flex;
  gap: 4px;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 0 10px;
  line-height: 40px;
  font-size: 14px;
  cursor: pointer;
  user-select: none;
  
  &:hover {
    background-color: rgb(232,234,237);
  }
  
  ${props => props.selected && css`
    border-color: rgb(232,240,254);
    background-color: rgb(232,240,254);
    color: #1558d6;
  `}
`

const VehicleListPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const parsed = useMemo(() => location.search ? queryString.parse(location.search, {ignoreQueryPrefix: true}) : null, [location.search]);

  const { path: pathInfo, user: { user: userInfo }, auth: { tokenInfo } } = useSelector(state => state);
  const [filterOpen, setFilterOpen] = useState(false);

  const [vehicles, setVehicles] = useState(null);
  const [showVehicles, setShowVehicles] = useState(null);
  const [userTags, setUserTags] = useState(null);
  const [filteredVehicles, setFilteredVehicles] = useState([]);
  const [mapBound, setMapBound] = useState();
  const [mapOpen, setMapOpen] = useState(null);
  const [showVehicle, setShowVehicle] = useState(false);
  const filters = useMemo(() => parsed?.filters, [parsed]);

  const [updateUser] = useMutation(UPDATE_USER);

  const path = useMemo(() => pathSelector(userInfo, pathInfo), [userInfo, pathInfo]);
  const needPath = useMemo(() => !path || !path.latitude || !path.longitude, [path]);

  const { form: filterForm, onChange: onFilterChange } = useForm({
    filters: GUEST_WANTED.flatMap(w => w.tags.map(t => t.id)).join(','),
    sorter: needPath ? '관심높은순' : '가까운거리순',
    searchText: '',
  });

  useEffect(() => {
    fetchVehicles();
  }, []);

  useEffect(() => {
    if (!showVehicle) {
      if (filters) {
        onFilterChange({id: 'filters', value: filters});
      } else if (userInfo?.matching_profile?.["원하는 차량"]) {
        onFilterChange({ id: 'filters', value: userInfo.matching_profile["원하는 차량"] });
      }
    }
  }, [userInfo, filters, showVehicle]);

  useEffect(() => {
    if (vehicles) {
      const newShowVehicles = filterAndSortVehicles(vehicles);
      setShowVehicles(newShowVehicles);
      setShowVehicle(true);
    }
  }, [vehicles, filterForm, path]);

  const fetchVehicles = useCallback(async () => {
    try {
      const { data } = await axios.get(`${SERVER_ADDRESS}/api/vehicles/mapVehicles2`);
      processVehiclesData(data);
    } catch (error) {
      console.error("Error fetching vehicles:", error);
    }
  }, []);
  
  const processVehiclesData = useCallback((data) => {
    const processedVehicles = data.vehicles.map(convertMapVehicle);
    setVehicles(processedVehicles);
    setUserTags(processUserTags(data.userTags));
    setFilteredVehicles(data.filteredVehicles.map(v => {
      let processed = convertMapVehicle(v);
      processed.hostContract.dooriveTitle = '이미 매칭된 차량입니다.';
      processed.hostContract.useTypes = sortBy(v.hostContract.useTypes, (u) => u.price);
      return {...processed, type: 'matched'};
    }));
  }, []);

  const processUserTags = useCallback((userTags) => {
    return userTags.map(u => ({
      ...u,
      mapId: `u${u.id}`,
      type: 'user',
      latitude: u.lat,
      longitude: u.lng
    }));
  }, []);

  const filterAndSortVehicles = useCallback((vehicles) => {
    let newVehicles = [...vehicles];
    newVehicles = applyFilters(newVehicles, filterForm.filters.split(','));
    newVehicles = applySearch(newVehicles);
    newVehicles = applySorting(newVehicles);
    return newVehicles;
  }, [filterForm, path]);

  const applySearch = useCallback((vehicles) => {
    let searcher = new Hangul.Searcher(filterForm.searchText.replace(' ', '').toLowerCase());
    return vehicles.filter((v) => searcher.search(`${v.brand.replace(' ', '').toLowerCase()}${v.model.replace(' ', '').toLowerCase()}`) >= 0);
  }, [filterForm.searchText]);

  const applySorting = useCallback((vehicles) => {
    const sorters = [
      {id: "가까운거리순", predicate: (vs) => sortBy(vs, (v) => calcDistance(v.latitude, v.longitude, path.latitude, path.longitude))},
      {id: "가격낮은순", predicate: (vs) => sortBy(vs, (v) => getPrice(v))},
      {id: "가격높은순", predicate: (vs) => sortBy(vs, (v) => getPrice(v)).reverse()},
      {id: "관심높은순", predicate: (vs) => sortBy(vs, (v) => v.likes.length).reverse()},
      {id: "내가찜한차량", predicate: (vs) => sortBy(vs, [
          v => Number(v.likes.some(like => String(like.user.id) === String(tokenInfo?.id))),
          v => v.likes.length
        ]).reverse()
      },
    ];
    const sorterObj = find(sorters, s => s.id === filterForm.sorter);
    return sorterObj.predicate(vehicles);
  }, [filterForm.sorter]);

  const getCurrentPosition = useCallback(() => {
    openAddress(callbackAddress);
  }, []);

  const callbackAddress = useCallback((address, longitude, latitude) => {
    dispatch(pathAction.update({
      address: address,
      longitude: longitude,
      latitude : latitude,
      isGps : false,
    }));
    onFilterChange({id: 'sorter', value: '가까운거리순'});

    if (!isEmpty(tokenInfo)) {
      updateUser({
        variables: {
          id: tokenInfo.id,
          data: {
            lng: longitude,
            lat: latitude,
            address: `${address}\t`,
          }
        }
      }).then(res => {
      });
    };
  }, []);

  const getUseTypes = useCallback(() => {
    let tags = filterForm.filters.split(',').filter(t => ["호스트우선형","요일지정형","게스트우선형"].includes(t));
    return tags.map(t => getUseTypeId(t));
  }, [filterForm.filters]);

  const getPrice = useCallback((v) => {
    let useTypes = getUseTypes();
    if (useTypes && useTypes.length) {
      let minPrice = null;
      for (let useType of useTypes) {
        const u = find(v.hostContract.useTypes, (u) => u.id === useType);
        if (u) {
          if (minPrice === null || minPrice > u.price) {
            minPrice = u.price;
          }
        }
      }
      return minPrice;
    }
    return Math.min(...v.hostContract.useTypes.map(u => u.price));
  }, [filterForm.filters]);

  const guestTagCount = useMemo(() => sum(GUEST_WANTED.map(w => w.tags.length)), []);
  const _filters = useMemo(() => filterForm?.filters ? filterForm.filters.split(',') : [], [filterForm.filters]);
  const isFiltered = useMemo(() => _filters.length && _filters.length < guestTagCount , [filterForm.filters, guestTagCount]);

  return (
    <StyledVehicleListPage>
      <AddressRequest onClick={getCurrentPosition}>
        <img src="/vehicle/my_position.svg"/>
        {needPath ? '이곳을 눌러 주소를 설정하면 가까운 차량을 먼저 볼 수 있습니다.' : `${path.address} 에서 가까운 차량 순으로 표시됩니다.`}
      </AddressRequest>

      <EventBanner>
        <EventSwipe images={[{link: '/guide', url: '/event/guest_banner2.png'}]} />
      </EventBanner>

      <div style={{display: 'flex', flexWrap: 'wrap', padding: '10px 22px', gap: '8px'}}>
        {["가까운거리순", "관심높은순", "가격낮은순", "가격높은순", "내가찜한차량"].map(label => <FilterBtn onClick={() => {
          if (label === "가까운거리순") {
            if (needPath) {
              alert('주소 설정이 필요합니다.');
              getCurrentPosition();
            } else {
              onFilterChange({id: 'sorter', value: label});
            }
          } else {
            onFilterChange({id: 'sorter', value: label});
          }
        }} selected={filterForm.sorter === label}>
          {label}
        </FilterBtn>)}
        <SearchBtn selected={isFiltered} onClick={() => setFilterOpen(true)}>
          <FilterListIcon fontSize={'14px'} />
          <span>조건 맞춤설정</span>
        </SearchBtn>
      </div>
      <div>
        <div style={{padding: '6px 22px', position: 'relative'}}>
          <FormComponent id="searchText" placeholder="찾으시는 차량 이름을 입력해보세요" form={filterForm} onChange={onFilterChange}/>
          <img src="/vehicle/search.svg" style={{position: 'absolute', right: '34px', top: '50%', transform: 'translateY(-50%)', userSelect: 'none', pointerEvents: 'none'}}/>
        </div>
      </div>
      <VehicleWanted tags={filterForm.filters} setTags={(v) => onFilterChange({id: 'filters', value: v})} open={filterOpen} onClose={() => setFilterOpen(false)}/>
      <Vehicles>
        {showVehicle && showVehicles && showVehicles.map((v, idx) =>
          (<ListVehicle2 key={v.id} vehicle={v} imageHide={true} useTypes={getUseTypes()} showBulk={true}/>)
        ).reduce((acc, x, idx2) => acc === null ? [x] : [acc,
          <MyDivider />,
          x], null)}
      </Vehicles>

      {/* 지도에서 보기 버튼 */}
      {window?.kakao?.maps && vehicles && userTags && (!tokenInfo || userInfo) && !mapOpen && (
        <div className="map-btn" onClick={() => setMapOpen(true)}>
          지도에서 보기
        </div>
      )}

      {/* VehicleMap 컴포넌트 */}
      {window?.kakao?.maps && vehicles && userTags && (!tokenInfo || userInfo) && (
        <VehicleMap 
          vehicles={vehicles} 
          filteredVehicles={filteredVehicles}
          filters={filterForm.filters}
          onFilterChange={onFilterChange}
          guestVehicles={[]} 
          guestTags={userTags} 
          setMapBound={setMapBound} 
          open={mapOpen} 
          onClose={() => {
            setMapOpen(false);
            setShowVehicle(true);
            if (window.history.replaceState) {
              const newUrl = window.location.href.split('#')[0];
              window.history.replaceState(null, null, newUrl);
            }
          }}
        />
      )}
    </StyledVehicleListPage>
  );
};

const DescriptionBox = styled.div`
  word-break: keep-all;
  font-size: 16px;
  line-height: 140%;
  margin: 4px 22px 8px;
  padding: 8px;
  color: #444444;
  font-weight: 500;
  background-color: #DDDDDD;
  text-align: center;
  border-radius: 4px;
`

const StyledVehicleListPage = styled.div`
  font-family: 'Pretendard', sans-serif;
  
  .map-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #5ECDC0;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
    border-radius: 999px;
    color: white;
    cursor: pointer;
    padding: 11px 16px;
    position: fixed;
    bottom: 10px;
    z-index: 50;
    left: 50%;
    transform: translateX(-50%);
  }
`

export default VehicleListPage;

const EventBanner = styled.div`
    margin: 0 22px;
`

const GuestList = ({userTags, filters}) => {
}

const VehicleList = ({title, vehicles, filters}) => {
  const filteredVehicles = useMemo(() => applyFilters(vehicles, filters), [vehicles, filters]);
  const getUseTypes = useCallback(() => {
    let tags = filters.split(',').filter(t => ["호스트우선형","요일지정형","게스트우선형"].includes(t));
    return tags.map(t => getUseTypeId(t));
  }, [filters]);

  return <RowList>
    <div className="header">
      <h3 className="title">{title}</h3>
      <div className="actions">더보기</div>
    </div>
    <div className="list">
      <StyledListSwiper>
        {filteredVehicles.map((v) => <SwiperSlide><RowVehicle key={v.id} vehicle={v} imageHide={true} useTypes={getUseTypes()}/></SwiperSlide>)}
      </StyledListSwiper>
    </div>
  </RowList>
}

const ListSwiper = ({className, children}) => {
  return <div className={className}>
    <Swiper spaceBetween={10} slidesPerView={"auto"}>
      {children}
    </Swiper>
  </div>
}

const StyledListSwiper = styled(ListSwiper)`
  overflow: hidden;
  .swiper-slide {
    width: 150px;
  }
`

const RowList = styled.div`
  padding: 0 22px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .title {
    font-weight: 500;
    font-size: 18px;
    line-height: 160%;
  }

  .list {
    flex: 1;
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
`
