import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {find, get, isEmpty} from "lodash";
import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import VehicleLocationSection from "./component/VehicleLocationSection";
import styled from "styled-components";
import {MB16, MB32, MB8} from "../../toolbox/UtilComponent";
import VehicleInfoSection from "./component/VehicleInfoSection";
import VehicleOptionSection from "./component/VehicleOptionSection";
import VehicleDescriptionSection from "./component/VehicleDescriptionSection";
import {calcDistance, commaNum, openKakaoChat, useForm} from "../../toolbox";
import HostIntroduce from "./component/HostIntroduce";
import {VehicleSwipe} from "./component/VehicleSwipe";
import ContractInfoSection, {TaxInfoSection, UseDateInfoSection} from "./component/ContractInfoSection";
import queryString from 'query-string';
import {
    convertDooricar,
    distanceFormat,
    fillUseTypes,
    getLoginLink,
    getUseTypeId,
    getUseTypeTxt2,
    manFormat
} from "../../toolbox/format";
import {useQuery} from "@apollo/client";
import {GET_DOORI_VEHICLE} from "../../query/vehicleQuery";
import {ThumbSwipe2} from "./component/ThumbSwipe2";
import {pathSelector} from "../../toolbox/logic";
import {LikeButton} from "./component/ListVehicle2";
import {ContractInfoSectionSolocar} from "./component/ContractInfoSectionSolocar";
import {VehicleUseInfoSection, VehicleUseInfoSection3} from "./component/VehicleUseInfoSection";
import moment from "moment-timezone";
import {calcDooriTax, calcTax, fillUsedCarInfo} from "../../toolbox/calculate";
import VehicleHeader from "./component/VehicleHeader";
import {flatEntity} from "../../toolbox/query";
import axios from "axios";
import {SERVER_ADDRESS} from "../../index";
import LoadingIndicator from "../../layout/LoadingIndicator";
import HelpIcon from "@mui/icons-material/Help";
import {Dialog, Popover} from "@mui/material";
import {loadingAction} from "../../redux/loadingReducer";
import ReactSlider from 'react-slider';
import { ListGuest } from "./component/ListGuest";
import { GUEST_WANTED } from "../../toolbox/guestTag";
import { toastAction } from "../../redux/toastReducer";

const TagList = styled.div`
  margin-bottom: 18px;

  & > span {
    margin-right: 10px;
    padding: 6px 10px;
    border-radius: 10px;
    color: #fff;
    font-size: 15px;
    font-weight: 700;
    display: inline-block;
  }
`

const HeaderWrap = styled.div`
  position: relative;
  margin-bottom: 8px;
`

const Header = styled.div`
  //padding: 28px;
`

const HeaderBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`
const HeaderTitle = styled.div`
  > .--title {
    font-weight: 700;
    font-size: 27px;
    line-height: 27px;
    margin-bottom: 4px;
  }

  > .--description {
    color: rgba(0, 0, 0, 0.49);
    font-weight: 700;
    font-size: 15px;
  }
`

const Title = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
`

const _CollapseBtn = ({className, children, open, onCollapse}) =>
    <img className={className} onClick={onCollapse} src={open? "/vehicle/chevron_down.svg": "/vehicle/chevron_right.svg"}></img>

export const CollapseBtn = styled(_CollapseBtn)`
  width: 28px;
  cursor: pointer;
  padding-bottom: 2px;
`

const _CollapseDescription = ({className, title, content, children, open, onCollapse}) =>
    <div className={className} onClick={onCollapse}>
        {/*<img src="/vehicle/parking-icon.svg" />*/}
        <span className="--title">{title}</span>
        <span className="--content"><>{content}</></span>
        {onCollapse && <CollapseBtn open={open} onCollapse={() => {}}/>}
        {/*<img className="--collapse" onClick={onCollapse} src={open? "/vehicle/chevron_down.svg": "/vehicle/chevron_up.svg"}></img>*/}
    </div>

const CollapseDescription = styled(_CollapseDescription)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  //min-width: 150px;
  padding-left: 16px;
  padding-right: 16px;
  cursor: pointer;
  margin-bottom: 8px;

  > .--title {
    color: #818181;
    font-size: 16px;
    font-weight: bold;
    line-height: 30px;
    //min-width: 106px;
    margin-right: 16px;
    word-break: keep-all;
    white-space: nowrap;
  }

  > .--content {
    font-size: 16px;
    font-weight: bold;
    white-space: pre-wrap;
    color: black;
    line-height: 120%;
  }
`

const PageWrap = styled.div`
  box-shadow: 0px -6px 20px -9px rgba(0, 0, 0, 0.18);
`

const _RequestBtn = ({className, onClick, content}) => {
    return <div className={className} onClick={onClick}>
        <div>
            {content}
        </div>
    </div>
}

const Actions = styled.div`
  display: flex;
  padding: 15px 20px 40px;
  z-index: 0;
  position: sticky;
  bottom: 0;
  width: 100%;
  max-width: 450px;
  background: #ffffff;
  box-sizing: border-box;
  justify-content: space-between;
  gap: 8px;
`
const Actions2 = styled.div`
  display: flex;
  padding: 6px 20px;
  z-index: 0;
  position: sticky;
  bottom: 0;
  width: 100%;
  background: #ffffff;
  box-sizing: border-box;
  justify-content: space-between;
  gap: 8px;
`

const ActionsMultiline = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 20px 40px;
  z-index: 0;
  position: sticky;
  bottom: 0;
  width: 100%;
  max-width: 450px;
  background: #ffffff;
  box-sizing: border-box;
  justify-content: space-between;
  gap: 8px;
`

const _KakaoBtn = styled.div`
  padding: 14px 14px;
  img {
    height: 14px;
  }
  font-size: 16px;
  font-weight: 500;
  color: #191919;
  background-color: #FEE500;
  border-radius: 12px;
  gap: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
`;

const KakaoBtn = ({chatLink, vehicleId}) => {
    return <_KakaoBtn onClick={() => {
        axios.post(SERVER_ADDRESS + '/api/cars/chat', {
            vehicleId: vehicleId,
        }, {}).then(() => {}).catch(() => {});
        window.open(chatLink, '_blank');
    }}>
        <img src="/kakao/shape.png"/> 카카오 오픈채팅
    </_KakaoBtn>
}

const RequestBtn = styled(_RequestBtn)`
  font-size: 16px;
  font-weight: 500;
  line-height: 130%;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px 8px;
  background-color: #5ECDC0;
  cursor: pointer;
  color: white;
  flex-grow: 1;
  white-space: nowrap;
`

const VehiclePage = () => {
    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const vehicleId = params.id;
    const parsed = location.search ? queryString.parse(location.search, {ignoreQueryPrefix: true}) : null;
    const mode = parsed?.mode ?? 'doori';
    const guestId = parsed?.guestId;
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);
    const userInfo = useSelector(({user}) => user.user);
    const pathInfo = useSelector(({path}) => path);
    const path = useMemo(() => pathSelector(userInfo, pathInfo), [userInfo, pathInfo]);

    const {data, refetch, loading} = useQuery(GET_DOORI_VEHICLE, {variables: {id: vehicleId}});

    const hasLoggedIn = useSelector(({auth}) => !isEmpty(auth.tokenInfo));

    const [mapOpen, setMapOpen] = useState(true);
    const [vehicle, setVehicle] = useState(null);
    const [vehicles, setVehicles] = useState(null);
    const [vehicleOpen, setVehicleOpen] = useState(false);
    const [contractOpen, setContractOpen] = useState(false);
    const [contract2Open, setContract2Open] = useState(false);
    const [useOpen, setUseOpen] = useState(true);
    const [taxOpen, setTaxOpen] = useState(false);
    const [availableDateOpen, setAvailableDateOpen] = useState(false);
    const [oneWeekDialogOpen, setOneWeekDialogOpen] = useState(false);
    const [popupOpen, setPopupOpen] = useState(false);
    const [guestBulkProposalDialogOpen, setGuestBulkProposalDialogOpen] = useState(false);
    const [userTags, setUserTags] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const {form, onChange} = useForm({});
    
    const isMyCar = useMemo(() => {
        if (vehicle?.hostUser?.id && userInfo?.id) {
            return vehicle.hostUser.id == userInfo.id;
        }
        return false;
    }, [vehicle?.hostUser?.id, userInfo?.id]);

    useEffect(() => {
        if (data) {
            const payload = flatEntity(get(data, "vehicle.data", null));
            if (payload) {
                let v = convertDooricar(payload, false, true);
                if (v) {
                    if (v.visible || v.hostUser?.id === tokenInfo?.id) {
                        setVehicle(v);
                    } else {
                        alert('이미 매칭이 되었거나 미노출 처리된 차량입니다.');
                        navigate(-1);
                    }
                } else {
                    alert('이미 매칭이 되었거나 미노출 처리된 차량입니다.');
                    navigate(-1);
                }
            } else {
                alert('이미 매칭이 되었거나 미노출 처리된 차량입니다.');
                navigate(-1);
            }
        }
    }, [data]);

    useEffect(() => {
        window.fbq('track', 'ViewContent');
    }, [])

    useEffect(() => {
        if (parsed?.action === 'bulk' && vehicle && isMyCar && !isLoaded) {
            setIsLoaded(true);
            if (vehicle.visible) {
                setGuestBulkProposalDialogOpen(true);
            } else {
                if (window.confirm('미노출 상태에서는 매칭제안을 보낼 수 없습니다. 노출상태로 변경하시겠습니까?')) {
                    onClickVisible();
                    setGuestBulkProposalDialogOpen(true);
                }
            }
        }
    }, [vehicle, isMyCar, isLoaded]);

    useEffect(() => {
        axios.get(SERVER_ADDRESS + '/api/vehicles/mapVehicles2', {}).then((res) => {
            const {data} = res;
            setUserTags(processUserTags(data.userTags).filter(u => u.id !== tokenInfo?.id));
            // setUserTags(processUserTags(data.userTags).filter(u => u.id !== tokenInfo?.id && u?.matching_profile?.["원하는 차량"]));
            setVehicles(data.vehicles.map(v => {
                if (!v.thumbnail && v.bakchaAdditionalInfo) {
                    const bakchaInfo = v.bakchaAdditionalInfo;
                    if (bakchaInfo.Photo) {
                        v.thumbnail = {url: bakchaInfo.Photo[0]};
                        v.pictures = bakchaInfo.Photo.map((p) => ({url: p}));
                    }
                }
                if (!v.thumbnail || !v.pictures) {
                    v.thumbnail = {url: "/vehicle/dummy.png"};
                    v.pictures = [{url: "/vehicle/dummy.png"}];
                }

                let updatedAt = moment.tz(v.updatedAt, 'Asia/Seoul');
                if (updatedAt.format('YYYY-MM-DD') <= '2024-01-06') {
                    fillUseTypes(v.hostContract);
                } else {
                }

                return ({...v, mapId: `v${v.id}`, type: 'vehicle',
                    latitude: v.hostContract.latitude, longitude: v.hostContract.longitude,
                    price: Math.min(...v.hostContract.useTypes.map(u => u.price)),
                })}));
        });
    }, []);
    
  const processUserTags = useCallback((userTags) => {
    return userTags.map(u => ({
      ...u,
      latitude: u.lat,
      longitude: u.lng
    }));
  }, []);

  
  const onClickVisible = useCallback(async () => {
    dispatch(loadingAction.loading(true));
    const res = await axios.post(SERVER_ADDRESS + '/api/vehicles/setVisible', {
        contractId: vehicle.hostContract.id,
        visible: true,
    });
    await refetch();
    dispatch(loadingAction.loading(false));
    // dispatch(toastAction.toast({severity: 'success', 'message': '게시글이 노출상태로 변경되었습니다.'}));
  }, [vehicle]);
    // const getVehicles = () => {
    //     let result = [];
    //     if (vehicles) {
    //         result = result.concat(vehicles)
    //     }
    //     return filter(result, (v) => v.id !== vehicleId);
    // }


    const getPrice = (v) => {
        if (v.eventPrice === false) {
            return Math.min(...v.useTypes.map(u => u.price));
        }
        return Math.min(...v.useTypes.map(u => u.price * 0.9));
    }

    const getImgList = useCallback((v) => {
        return get(v, "pictures", [])
    }, [])

    if (!vehicle) {
        return <div>
            <LoadingIndicator isVisible={!data} />
        </div>
    }

    const calcEstimate = () => {
        const result = fillUsedCarInfo(vehicle.currentValue, vehicle.year);
        const tax = calcTax(vehicle.year, vehicle.displacement) ?? 0;
        const 월이용료 = result.월간정비분담액 + result.월간보험분담액 + (tax / 12 / 2);
        return commaNum(Math.round(월이용료 * 2 / 1000) * 1000);
    }

    const getAvailable = (v) => {
        if (v.useType === 'TEMP') {
            return '상담 후 이용 가능';
        }
        if (v.availableDate && v.availableDate > moment.tz('Asia/Seoul').format('YYYY-MM-DD')) {
            return vehicle.availableDate + ' 부터 이용 가능';
        }
        return '최소 1개월';
    }

    const onCloseGuestBulkProposalDialog = (selectedGuests) => {
        setGuestBulkProposalDialogOpen(false);
        if (selectedGuests && selectedGuests.length > 0 && window.confirm(`${selectedGuests.length}명에게 매칭제안을 보내시겠습니까?`)) {
            const message = window.prompt('매칭 제안 메시지를 입력해주세요.', `안녕하세요, ${vehicle.brand} ${vehicle.model} 차주 ${vehicle.hostUser.nickname}입니다. 제 차량 확인 후 맘에 드신다면 매칭요청 부탁드릴게요! 궁금하신 점은 편하게 문의해주세요.`);
            if (message !== null) {
                axios.post(`${SERVER_ADDRESS}/api/chat-rooms/sendMatchingMessageBulk`, {
                    targetUserIds: selectedGuests.map(g => g.id),
                    vehicleId: vehicle.id,
                    contractId: vehicle.hostContract.id,
                    message: message,
                }).then(res => {
                    alert('매칭제안이 전송되었습니다.\n미노출 상태에서는 게스트가 차량 정보를 확인할 수 없으니, 가능하면 노출 상태를 유지해주세요.');
                });
            }
        }
    }

    return <PageWrap>
        <HeaderWrap>
            <ThumbSwipe2 className="swiper-container" images={getImgList(vehicle)}/>
            <LikeButton vehicle={vehicle} />
        </HeaderWrap>
        {/*<PlanSelectSection planOptions={planList} curPlan={curPlan}/>*/}
        {/*<DiffExplainSection/>*/}
        {/*{guestId && !guestLoaded && "  차량 정보를 불러오는 중"}*/}
        {(mode === 'doori') && <>
            {tokenInfo?.id == vehicle?.user?.id && <VehicleHeader vehicle={vehicle} />}
            <HostIntroduce vehicle={vehicle} tag={'호스트'} refetch={refetch} />
            <VehicleDescriptionSection vehicle={vehicle} />

            <MB32/>

            <CollapseDescription open={vehicleOpen} onCollapse={() => {setVehicleOpen(!vehicleOpen)}}
                                 content={`${vehicle.brand} ${vehicle.model}${vehicle.fuelType === '고급유' ? ' (고급유)' : ''}`} title="차량정보" />
            {vehicleOpen && <>
                <VehicleInfoSection vehicle={vehicle} />
                <MB8 />
                <div style={{padding: '0 8px'}}>
                    <VehicleOptionSection vehicle={vehicle} />
                </div>
                <MB32 />
            </>}
            <MB16 />

            {vehicle.useTypes ? <>
                {/*<div>월이용료</div>*/}
                {/*<VehicleUseInfoSection2 vehicle={vehicle} />*/}
                <CollapseDescription open={true}
                                     content={`₩ ${commaNum(getPrice(vehicle))}부터 (보험, 정비 포함)`} title="월이용료" />
                {vehicle.currentValue && <>
                    <div style={{wordBreak: 'keep-all', color:'#ff9000', padding: '4px 12px 0', fontWeight: '500'}}>동급차량 운용 시 발생하는 유지비  {calcEstimate()}원<EstimateButton />까지 포함된 금액이에요!</div>
                </>}
                {true && <>
                    <VehicleUseInfoSection3 vehicle={vehicle} id="useType" form={form} onChange={onChange} />
                    {/*<VehicleUseInfoSection2 vehicle={vehicle} />*/}
                </>}
                <MB16/>

                <CollapseDescription open={contractOpen} onCollapse={() => {setContractOpen(!contractOpen)}}
                                     content={`${vehicle.kmCharge}원 / km`} title="거리당주유비" />
                {contractOpen && <>
                    <ContractInfoSection vehicle={vehicle} />
                </>}
                <MB16 />


            </>: vehicle.useType && <>
                <CollapseDescription open={useOpen} onCollapse={() => {setUseOpen(!useOpen)}}
                                     content={getUseTypeTxt2(vehicle)} title="매칭유형" />
                {useOpen && <>
                    <VehicleUseInfoSection vehicle={vehicle} />


                    <CollapseDescription open={contractOpen} onCollapse={() => {setContractOpen(!contractOpen)}}
                                         content={`월 ${manFormat(vehicle.priceDuo + calcDooriTax(vehicle))}원 + ${vehicle.kmCharge}원/km + 보증금 ${manFormat(vehicle.priceDuo * 3)}원`} title="공유비용" />
                    {contractOpen && <>
                        <ContractInfoSection vehicle={vehicle} />
                    </>}
                    <MB16 />


                </>}
                <MB16 />
            </>}

            <CollapseDescription open={true}
                                 content={`월이용료 3개월분`} title="보증금" />
            <MB16 />

            <CollapseDescription open={taxOpen} onCollapse={() => {setTaxOpen(!taxOpen)}}
                                 content="가입완료" title="자동차보험" />
            {taxOpen && <>
                <TaxInfoSection />
            </>}
            <MB16 />

            <CollapseDescription open={availableDateOpen} onCollapse={() => {setAvailableDateOpen(!availableDateOpen)}}
                                 content={getAvailable(vehicle)} title="이용기간" />
            {availableDateOpen && <>
                <UseDateInfoSection vehicle={vehicle} />
            </>}
            <MB16 />

            <CollapseDescription open={mapOpen} onCollapse={() => {setMapOpen(!mapOpen)}}
                                 content={`${vehicle.address} (거리 : ${distanceFormat(calcDistance(vehicle.latitude, vehicle.longitude, path.latitude, path.longitude))})`} title={"공유주차지"} />
            {mapOpen && <>
                <VehicleLocationSection vehicle={({...vehicle, type: 'vehicle'})} />
                <MB32 />
            </>}

        </>}
        {isMyCar ?
            <ActionsMultiline>
                <div>
                    <RequestBtn content={"내 주변 게스트들에게 매칭제안 보내기"} onClick={() => {
                        if (vehicle.visible) {
                            setGuestBulkProposalDialogOpen(true);
                        } else {
                            if (window.confirm('미노출 상태에서는 매칭제안을 보낼 수 없습니다. 노출상태로 변경하시겠습니까?')) {
                                onClickVisible();
                                setGuestBulkProposalDialogOpen(true);
                            }
                        }
                    }}/>
                </div>
                <div style={{display: 'flex', gap: '8px'}}>
                    <RequestBtn content={"내 차 당근마켓에 광고 요청하기"} onClick={() => {
                        axios.post(SERVER_ADDRESS + '/api/cars/hostReq', {
                            vehicleId: vehicle?.id,
                        }, {}).then(() => {
                            alert('광고 요청이 완료되었습니다. 🎉\n' +
                                '담당 매칭매니저가 영업시간(평일 10:00 ~ 19:00) 내에 개별적으로 연락을 드릴 예정이오니 조금만 기다려주세요');
                        }).catch(() => {});
                    }}/>
                    <RequestBtn content={"정보 수정하기"} onClick={() => {
                        navigate(`/vehicle_modify?id=${vehicle.contract}`);
                    }}/>
                </div>
            </ActionsMultiline>:
            <Actions>
                {vehicle?.hostContract?.openChatLink? <KakaoBtn chatLink={vehicle.hostContract.openChatLink} vehicleId={vehicle.id}>
                </KakaoBtn>:<RequestBtn content={"채팅하기"} onClick={() => {
                    if (isEmpty(tokenInfo)) {
                        alert("로그인이 필요합니다.")
                        const to = getLoginLink(location);
                        navigate(to);
                        return;
                    }
                    navigate('/my/chat_start?vehicleId=' + vehicle.id);
                }}/>}
                <RequestBtn content={"매칭 요청"} onClick={() => {
                    if (isEmpty(tokenInfo)) {
                        alert("로그인이 필요합니다.")
                        const to = getLoginLink(location);
                        navigate(to);
                    } else {
                        let to = `/vehicle/${vehicle?.id}/request`;
                        if (guestId) {
                            to += `?guestId=${guestId}`;
                        }
                        navigate(to)
                    }
                }}/>

                {/* <RequestBtn content={"일주일 타보기"} onClick={() => {
                    if (isEmpty(tokenInfo)) {
                        alert("로그인이 필요합니다.")
                        const to = getLoginLink(location);
                        navigate(to);
                    } else {
                        setOneWeekDialogOpen(true);
                    }
                }}/> */}
            </Actions>
        }
        <GuestBulkProposalDialog open={guestBulkProposalDialogOpen} onClose={onCloseGuestBulkProposalDialog} guests={userTags} vehicle={vehicle} />
        <OneWeekDialog open={oneWeekDialogOpen} onClose={() => setOneWeekDialogOpen(false)} vehicle={vehicle}/>
        <VehicleSwipe vehicles={vehicles} exceptId={vehicleId}/>
        <LoadingIndicator isVisible={loading} />
    </PageWrap>
}

const GuestItem = styled.div`
    display: flex;
    align-items: center;
    margin: 10px 0;
    padding: 8px;
    border-radius: 8px;
    transition: background-color 0.2s;
    background-color: ${props => props.checked ? '#E6F7F5' : 'transparent'};
    
    &:hover {
        background-color: ${props => props.checked ? '#E6F7F5' : '#f5f5f5'};
    }

    .checkbox-area {
        flex-grow: 1;
        display: flex;
        align-items: center;
        align-self: stretch;
        cursor: pointer;

        img {
            margin-right: 10px;
            width: 24px;  // 이미지 크기는 필요에 따라 조정
            height: 24px;
        }
    }
`;

const _GuestBulkProposalDialog = ({className, open, onClose, guests, vehicle}) => {
    const navigate = useNavigate();
    const [selectedGuests, setSelectedGuests] = useState([]);
    const [maxArea, setMaxArea] = useState(5); // 기본값 5km

    const userInfo = useSelector(({user}) => user.user);
    const pathInfo = useSelector(({path}) => path);
    const path = useMemo(() => pathSelector(userInfo, pathInfo), [userInfo, pathInfo]);

    const filteredGuests = useMemo(() => {
        if (!guests || !vehicle) {
            return null;
        }
        let filtered = guests.filter(g => calcDistance(path.latitude, path.longitude, g.latitude, g.longitude) <= maxArea);
        
        filtered = filtered.filter(u => {
            if (!u.matching_profile || !u.matching_profile?.["원하는 차량"]) {
                return true;
            }
            const filters = u.matching_profile["원하는 차량"].split(',');
            let useTypeIds = ['호스트우선형', '요일지정형', '게스트우선형'];
            if (filters.includes('호스트우선형') || filters.includes('요일지정형') || filters.includes('게스트우선형')) {
                useTypeIds = useTypeIds.filter(id => filters.includes(id));
            }
            useTypeIds = useTypeIds.map(id => getUseTypeId(id));

            for (let category of GUEST_WANTED) {
                const applied = category.tags.filter(t => filters.includes(t.id));
                if (applied.length) {
                    let hasMatch = false;
                    for (let apply of applied) {
                        if (apply.predicate(vehicle, useTypeIds)) {
                            hasMatch = true;
                            break;
                        }
                    }
                    if (!hasMatch) {
                        return false;
                    }
                }
            }
            return true;
        });

        return filtered;
    }, [guests, maxArea, path, vehicle]);

    useEffect(() => {
        setSelectedGuests(prevSelectedGuests => 
            prevSelectedGuests.filter(g => filteredGuests.includes(g))
        );
    }, [filteredGuests]);

    const handleCheckboxChange = useCallback((guest) => {
        setSelectedGuests(prevSelectedGuests => 
            prevSelectedGuests.includes(guest) 
                ? prevSelectedGuests.filter(g => g !== guest) 
                : [...prevSelectedGuests, guest]
        );
    }, []);

    const allSelected = useMemo(() => {
        return filteredGuests && filteredGuests.length > 0 && selectedGuests.length === filteredGuests.length;
    }, [filteredGuests, selectedGuests]);

    const handleSelectAllToggle = useCallback(() => {
        setSelectedGuests(prevSelectedGuests => 
            allSelected ? [] : filteredGuests
        );
    }, [allSelected, filteredGuests]);

    return <Dialog open={open} onClose={() => onClose(null)} fullWidth={true}>
        <div className={className}>
            <h3 style={{textAlign: 'center'}}>주변 게스트들에게 매칭제안 보내기</h3>
            <div className="range-selector">
                <div className="range-label">거리 범위: {maxArea}km 이내</div>
                <ReactSlider
                    className="slider"
                    thumbClassName="thumb"
                    trackClassName="track"
                    value={maxArea}
                    onChange={value => setMaxArea(value)}
                    min={1}
                    max={20}
                    step={1}
                />
            </div>

            <div className="select-all-buttons" onClick={handleSelectAllToggle}>
                <img 
                    src={allSelected ? '/vehicle/check_true.svg' : '/vehicle/check_false.svg'} 
                    alt="체크박스"
                />
                <span>전체선택 ({filteredGuests?.length})</span>
            </div>
            
            <div className="guests">
                {!filteredGuests && <h3 style={{textAlign: 'center', padding: '32px'}}>게스트 목록을 불러오는 중...</h3>}
                {filteredGuests?.map(g => (
                    <GuestItem 
                        key={g.id} 
                        checked={selectedGuests.includes(g)}
                    >
                        <div 
                            className="checkbox-area"
                            onClick={() => handleCheckboxChange(g)}
                        >
                            <img 
                                src={selectedGuests.includes(g) ? '/vehicle/check_true.svg' : '/vehicle/check_false.svg'} 
                                alt="체크박스"
                            />
                        </div>
                        <ListGuest 
                            user={g} 
                            imageHide={false} 
                            hideManner={true} 
                            small={true} 
                        />
                    </GuestItem>
                )).reduce((acc, cur) => {
                    acc.push(cur);
                    acc.push(<div style={{height: '1px', borderBottom: '1px solid #DDDDDD', margin: '10px 0'}}/>);
                    return acc;
                }, [])}
                {(filteredGuests && filteredGuests.length === 0) && 
                    <div className="no-guests">{maxArea}km 이내에 게스트가 없습니다.</div>
                }
            </div>
            {/* <div className="btn-box">
                <RequestBtn className="btn" onClick={() => onClose(null)} content="취소" />
                <RequestBtn className="btn" onClick={() => onClose(selectedGuests)} content={`제안하기 (${selectedGuests.length})`} />
            </div> */}
            <Actions2>
                <RequestBtn className="btn" onClick={() => onClose(null)} content="취소" />
                <RequestBtn className="btn" onClick={() => onClose(selectedGuests)} content={`제안하기 (${selectedGuests.length})`} />
            </Actions2>
        </div>
    </Dialog>
}

const GuestBulkProposalDialog = styled(_GuestBulkProposalDialog)`
    padding: 12px;
    
    .range-selector {
        padding: 20px;
        
        .range-label {
            text-align: center;
            margin-bottom: 10px;
            color: #666;
        }
        .slider {
            height: 36px;
        }
        
        .thumb {
            background-color: #33CCC1;
            border-radius: 50%;
            cursor: pointer;
            width: 20px;
            height: 20px;
            top: 8px;
        }
        
        .track {
            background: #ddd;
            height: 4px;
            top: 16px;
            
            &.track-0 {
                background: #33CCC1;
            }
        }
    }

    .btn-box {
        display: flex;
        gap: 12px;
    }

    .select-all-buttons {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 8px 20px;
        margin-bottom: 10px;
        cursor: pointer;

        img {
            width: 24px;
            height: 24px;
        }

        span {
            font-size: 14px;
            color: #666;
        }
    }
`

const EstimateButton = () => {
    const guideIcon = useRef();
    const [guide, setGuide] = useState(false);
    return <>
        <HelpIcon ref={guideIcon} fontSize="16px" onMouseEnter={() => setGuide(true)} onMouseLeave={() => setGuide(false)} />
        <Popover open={guide} anchorEl={guideIcon.current} style={{pointerEvents: "none"}}>
            <div style={{whiteSpace: "pre-wrap", width: "200px", overflow: "hidden", padding: "8px", lineHeight: "22px"}}>
                차량의 시세와 연식에 따른 보험료, 자동차세, 정비비, 소모품교체비, 세차비 등을 종합적으로 고려하여 산출된 금액입니다.
            </div>
        </Popover>
    </>
}

const OneWeekDialog = ({open, onClose, vehicle}) => {
    const dispatch = useDispatch();
    const onSubmit = () => {
        if (window.confirm('호스트와 일정 등 조율 절차가 진행됩니다.')) {
            dispatch(loadingAction.loading(true));
            axios.post(SERVER_ADDRESS + '/api/vehicles/oneWeekRide', {
                vehicleId: vehicle.id,
            }).then((res) => {
                dispatch(loadingAction.loading(false));
                alert('일주일 타보기 요청이 완료되었습니다. 담당 매니저가 개별적으로 이후 진행을 도와드리겠습니다.')
            });
        }
    }

    const getPrice = () => {
        if (!vehicle?.useTypes) {
            return null;
        }
        let price = null;
        const prHost = find(vehicle.useTypes, (u) => u.id === 'PRIORITY_HOST');
        if (prHost) {
            price = prHost.price;
        }

        if (!price) {
            const prGuest = find(vehicle.useTypes, (u) => u.id === 'PRIORITY_GUEST');
            if (prGuest) {
                price = Math.round(prGuest.price / 3 / 1000) * 1000;
            }
        }

        if (!price) {
            const daySelect = find(vehicle.useTypes, (u) => u.id === 'DAY_SELECT');
            if (daySelect) {
                price = Math.round(daySelect.price / 2 / 1000) * 1000;
            }
        }
        return price;
    }

    return <Dialog open={open} onClose={onClose}>
        <_OneWeekDialog>
            <div>
                <h3>✅ 일주일타보기란?</h3>
                <p>
                    게스트가 관심 있는 호스트의 차를 일주일 간 이용해보고, 정식 매칭을 신청할지 여부를 결정할 수 있는 체험 프로그램입니다.
                </p>
            </div>
            <div>
                <h3>✅ 이용금액은?</h3>
                <p>
                    {commaNum(getPrice())}원<br/>
                    호스트우선형 1개월 이용료와 동일한 금액입니다.<br/>
                    (호스트우선형 미선택 차량인 경우, 요일지정형의 1/2 또는 게스트우선형의 1/3 금액 비율로 계산)
                </p>
            </div>
            <div>
                <p>
                    더 자세한 내용은 일주일타보기 안내사항을 참고해주세요.
                </p>
            </div>
            <div className="actions">
                <a target="_blank" href="https://copper-abrosaurus-467.notion.site/d85306a6a36f46f59bf7f0e0a089fb3d?pvs=4">
                    <div>일주일타보기 안내사항</div>
                </a>
                <div onClick={onSubmit}>신청하기</div>
            </div>
        </_OneWeekDialog>
    </Dialog>
}

const _OneWeekDialog = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  line-height: 130%;

  h3 {
    font-weight: 500;
    font-size: 18px;
    line-height: 160%;
  }

  > .actions {
    display: flex;
    justify-content: space-evenly;
    gap: 12px;

    > a, > div {
      cursor: pointer;
      border-radius: 8px;
      background-color: #33CCC1;
      padding: 8px;
      color: white;
      flex-grow: 1;
      text-align: center;
    }
  }
`

export default VehiclePage;
