import styled from "styled-components";
import {GrDocumentText} from "react-icons/gr";
import {commaNum} from "../../../toolbox";

const _ContractInfoSection = ({vehicle, className}) => {
    return <div className={className}>
        <p>
            <span className="light">※ 위 금액은 호스트가 회사에 납부하는 비용입니다. 언제든지 호스팅이 가능하며 이때는 게스트로부터 받을 수익을 자유롭게 지정할 수 있습니다. (게스트와 매칭없이 혼자 이용하는 것도 가능합니다)</span><br/><br/>
            월이용료: {commaNum(vehicle.priceSolo)}원 (만 26세 미만: {commaNum(vehicle.priceSolo + vehicle.youngCharge)}원)<br/><br/>
            보증금: 1개월치 월이용료와 동일<br/><br/>
            보험: 가입완료<br/>
            <img src="/vehicle/insurance.svg"></img><br/>
            <span className="light">실제 손해액이 50만원 미만 일경우 해당 금액으로 감액합니다.</span><br/><br/>
            정비: 포함<br/>
            <span className="light">※ 엔진오일교환, 타이어교체 등 소모품교체가 무료로 지원됩니다.</span><br/>
            <span className="light">※ 사고, 타이어 펑크 등 운전자의 귀책사유에 따른 수리는 정비항목에 포함되지 않습니다.</span><br/><br/>
            약정기간: 이용시작일부터 1개월<br/>
            <span className="light">※ 미해지시 1개월씩 자동연장됩니다.</span><br/>
            <span className="light">※ 매칭시 게스트의 이용시작일로부터 3개월의 약정기간이 적용됩니다.</span><br/><br/>
            약정주행거리: 3,000km / 월<br/>
            <span className="light">※ 초과시 km당 {vehicle.overCharge}원이 부과됩니다.</span><br/>
        </p>
        {/*<p className="light">*/}
        {/*    1) 월고정비용분담액: 게스트가 매월 고정적으로 납부하는 금액으로, 감가상각분담액과 보험세금분담액을 포함합니다.<br/>*/}
        {/*    2) 거리당비용: 게스트가 주행한 거리만큼 납부하는 금액으로, 주유비 외에 오일교환, 타이어교환, 세차, 부품관리 등 다양한 비용을 모두 포함합니다. 대신, 차 안에 있는 두리케어카드를 통해서 주유비를 결제해주세요. (이 카드로 결제된 금액은 따로 청구되지 않습니다.)<br/>*/}
        {/*    ※ GPS로 측정된 결과에 +10%(평균오차율)를 보정한 값을 기준으로 주행거리가 산정됩니다.<br/>*/}
        {/*    3) 보증금: 이용기간이 종료되고 1% 소유권지분등기가 호스트에게 원상복구된 후 게스트에게 반환됩니다.<br/>*/}
        {/*    4) 약정기간: 게스트가 차를 실제로 이용한 날부터 3개월입니다. 종료일 기준 30일 이전에 해지를 통지하지 않을 경우 매월 1개월씩 자동연장됩니다.*/}
        {/*</p>*/}
        {/*<a href={""} target="_blank">*/}
        {/*    <GrDocumentText size={16} /> 자세한 계약 정보 확인하러가기*/}
        {/*</a>*/}
    </div>
}

const _ContractInfoSectionSolocarGuest = ({vehicle, className}) => {
    return <div className={className}>
        <p>
            <span className="light">※ 위 금액은 게스트가 호스트에게 납부하는 비용(추정)이며, 정확한 금액은 호스트와 매칭시 결정됩니다.</span><br/><br/>
            월이용료: {commaNum(vehicle.priceDuo)}원 (만 26세 미만: {commaNum(vehicle.priceDuo + vehicle.youngCharge)}원)<br/><br/>
            거리당비용: {commaNum(vehicle.kmCharge)}원 / km<br/>
            <span className="light">※ 주유비와 오일교환, 타이어교환, 세차, 부품관리 등 다양한 비용을 모두 포함합니다.</span><br/>
            <span className="light">※ 차 안에 있는 '두리케어카드'를 통해서 주유비를 결제하셔야 합니다.</span><br/><br/>
            {/*<span className="light">※ GPS로 측정된 결과에 +10%(평균오차율)를 보정한 값을 기준으로 주행거리가 산정됩니다.</span><br/><br/>*/}
            보증금: 3개월치 월이용료와 동일<br/><br/>
            보험: 가입완료<br/>
            <img src="/vehicle/insurance.svg"></img><br/>
            <span className="light">실제 손해액이 50만원 미만 일경우 해당 금액으로 감액합니다.</span><br/><br/>
            정비: 포함<br/>
            <span className="light">※ 엔진오일교환, 타이어교체 등 소모품교체가 무료로 지원됩니다.</span><br/>
            <span className="light">※ 사고, 타이어 펑크 등 운전자의 귀책사유에 따른 수리는 정비항목에 포함되지 않습니다.</span><br/><br/>
            약정기간: 이용시작일부터 3개월<br/>
            <span className="light">※ 미해지시 1개월씩 자동연장됩니다.</span><br/><br/>
        </p>
        <p className="light">
            월이용료: 게스트가 매월 고정적으로 납부하는 금액으로, 보험료 및 자동차세가 포함되어있습니다. 만 26세를 기준으로 보험료가 변동됩니다.<br/><br/>
            보증금: 최초 1회만 결제되며, 아래 규칙을 지켜주시는 경우 해지시 전액 반환됩니다.<br/>
            1) 매월 말일 중 해지하고 싶은 날을 기준으로 최소 한달 이전에 통지하기<br/>
            예를 들어 8월 31일에 해지하고 싶으시면, 7월 31일 24시 전까지 말씀해주세요.<br/>
            2) 차량을 반납하기 전에 세차, 실내청소, 주유 등을 완료하기<br/><br/>
            ※ GPS로 측정된 결과에 +10%(평균오차율)를 보정한 값을 기준으로 주행거리가 산정됩니다.
        </p>
        {/*<a href={""} target="_blank">*/}
        {/*    <GrDocumentText size={16} /> 자세한 계약 정보 확인하러가기*/}
        {/*</a>*/}
    </div>
}

export const ContractInfoSectionSolocar = styled(_ContractInfoSection)`
  padding: 4px 8px;
  > p {
    padding-left: 18px;
    font-family: 'Pretendard',sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    margin-bottom: 12px;
  }
  .light {
    font-family: 'Pretendard',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 80%;
  }
  > a {
    padding-left: 18px;
    display: flex;
    align-items: center;
    font-size: 16px;
    text-decoration: underline;
    text-underline-offset: 4px;

    svg {
      margin-right: 4px;
    }
  }
`;
export const ContractInfoSectionSolocarGuest = styled(_ContractInfoSectionSolocarGuest)`
  padding: 4px 8px;
  > p {
    padding-left: 18px;
    font-family: 'Pretendard',sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    margin-bottom: 12px;
  }
  .light {
    font-family: 'Pretendard',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px
  }
  > a {
    padding-left: 18px;
    display: flex;
    align-items: center;
    font-size: 16px;
    text-decoration: underline;
    text-underline-offset: 4px;
    
    svg {
      margin-right: 4px;
    }
  }
`;
