import React, {useEffect, useState} from "react";
import {useMutation} from "@apollo/client";
import {useDispatch, useSelector} from "react-redux";
import {filter, find} from "lodash";
import {UPDATE_TRIP} from "../../query/calendarQuery";
import {calcNeedToken, toReservationDate} from "../../toolbox/calendar";
import {calcTimeRemain, getDayContract, kmFormat} from "../../toolbox/format";
import {SERVER_ADDRESS} from "../../index";
import axios from "axios";
import styled from "styled-components";
import {loadingAction} from "../../redux/loadingReducer";
import {isAdmin} from "../../toolbox/logic";
import {Button, Dialog, FormControlLabel, Radio, RadioGroup} from "@mui/material";

const _DetailDialog = styled.div`
  user-select: none;
  > .content {
    padding: 16px 16px 0;
    > .tag-select {
      margin-bottom: 16px;
      :last-child {
        margin-bottom: 0;
      }
      > .item {
        display: flex;
        align-items: center;
        > .title {
          width: 50px;
          min-width: 50px;
          font-weight: 500;
          font-size: 15px;
          line-height: 130%;
          color: #878787;
        }
        
        > .name {
          margin-right: 12px;
          word-break: keep-all;
        }

        > .tags {
          width: 156px;
          margin-left: 14px;
          display: flex;
          flex-wrap: wrap;

          > .selected {
            border-radius: 999px;
            padding: 6px 10px;
            margin: 4px 3px;
            cursor: pointer;
            background: #5ECDC0;
            color: white;
          }
          
          > .not-selected {
            border-radius: 999px;
            padding: 6px 10px;
            margin: 4px 3px;
            cursor: pointer;
            color: #878787;
            background: #F7F7F7;
            //border: 1px solid #FFFFFF;
          }
        }
      }
    }
    > .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 12px;
      > .title {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 16px;
        line-height: 130%;
        > img {
          width: 16px;
          height: 16px;
          margin-right: 8px;
        }
        > span {

        }
      }

      > .toggle {
        display: flex;
        align-items: center;
        cursor: pointer;
        border: 1px solid #F7F7F7;
        border-radius: 999px;
        padding-right: 8px;
      }
      
      > .right-btn {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 6px 10px;
        color: #878787;
        background: #F7F7F7;
        border: 1px solid #FFFFFF;
        border-radius: 999px;
      }
    }
  }
  
  > .action-box {
    //width: 260px;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    > .submit {
      cursor: pointer;
      background: #5ECDC0;
      border: 1px solid #5ECDC0;
      border-radius: 4px;
      font-weight: 600;
      font-size: 16px;
      line-height: 130%;
      text-align: center;
      color: #FFFFFF;
      padding: 12px;
      flex-grow: 1;
      margin-right: 8px;
    }
    
    > .cancel {
      cursor: pointer;
      border-radius: 4px;
      font-weight: 600;
      font-size: 16px;
      line-height: 130%;
      text-align: center;
      padding: 12px;
      flex-grow: 1;
      color: #FFFFFF;
      background: #5ECDC0;
      border: 1px solid #5ECDC0;
      //color: #878787;
      //background: #F7F7F7;
      //border: 1px solid #FFFFFF;
    }
  }
`;

export const DetailPopup = ({open, onClose, dateStr, vehicleId, reservations, trips, contracts, refetch}) => {
    const [changeOpen, setChangeOpen] = useState(false);
    const [winnerChangeOpen, setWinnerChangeOpen] = useState(false);
    const [changeTrip, setChangeTrip] = useState(null);

    let _trips = filter(trips, (t) => toReservationDate(t) === dateStr);
    let _before = filter(_trips, (t) => t.date === dateStr);
    let _after = filter(_trips, (t) => t.date !== dateStr);
    let _reservation = find(reservations, (r) => r.useDate === dateStr);

    return <Dialog open={open} onClose={onClose}>
        <_DetailDialog>
            <div className="content">
                <div className="header">
                    <div className="title">
                        <img src="/vehicle/tag.svg"/>
                        <span>자세히 보기 ({dateStr})</span>
                    </div>
                </div>
                <div className="tag-select">
                    <div className="item">
                        {/*<div className="title">예약자</div>*/}
                        <div className="tags">
                            사용 예약권 {calcNeedToken(dateStr)}개
                        </div>
                        <div className="name">
                            {_reservation?.contract?.users_permissions_user?.nickname ?? '미지정'}
                        </div>
                        <Button style={{marginLeft: 'auto'}} variant="outlined" onClick={() => {
                            setWinnerChangeOpen(true);
                        }}>예약자 변경</Button>
                    </div>
                </div>
                {_before.map((t) =>
                    <div key={t.id} className="tag-select">
                        <div className="item">
                            <div className="title">
                                {t.hipass? `${kmFormat(t.hipass)}원`: `${kmFormat(t.km)}km`}
                            </div>
                            <div className="tags">
                                {t.description} {(t.km && !t.meta.주행거리) && ' (공회전)'}
                            </div>
                            <div className="name">
                                {t?.contract?.users_permissions_user? t?.contract?.users_permissions_user?.nickname: '미지정'}
                            </div>
                            <Button style={{marginLeft: 'auto'}} variant="outlined" onClick={() => {
                                setChangeTrip(t);
                                setChangeOpen(true);
                            }}>운전자 변경</Button>
                        </div>
                    </div>)}
                {_after.length > 0 && <>
                    <div className="tag-select">
                        <div className="item">
                            <div className="title" style={{color: 'black', width: 'unset', minWidth: 'unset'}}>
                                다음날 ({_after[0].date})
                            </div>
                        </div>
                    </div>
                    {_after.map((t) =>
                        <div key={t.id} className="tag-select">
                            <div className="item">
                                <div className="title">
                                    {t.hipass? `${kmFormat(t.hipass)}원`: `${kmFormat(t.km)}km`}
                                </div>
                                <div className="tags">
                                    {t.description}
                                </div>
                                <div className="name">
                                    {t?.contract?.users_permissions_user? t?.contract?.users_permissions_user?.nickname: '알 수 없음'}
                                </div>
                                <Button style={{marginLeft: 'auto'}} variant="outlined" onClick={() => {
                                    setChangeTrip(t);
                                    setChangeOpen(true);
                                }}>운전자 변경</Button>
                            </div>
                        </div>)}
                </>}
            </div>
            <div className="action-box">
                <div className="cancel" onClick={() => onClose()}>닫기</div>
            </div>
        </_DetailDialog>
        <WinnerChangeDialog open={winnerChangeOpen} onClose={() => {setWinnerChangeOpen(false)}} vehicleId={vehicleId} useDate={dateStr} reservation={_reservation} contracts={contracts} refetch={refetch} />
        <DriverChangeDialog open={changeOpen} onClose={() => {setChangeOpen(false)}} trip={changeTrip} contracts={contracts} refetch={refetch} />
    </Dialog>
}

export const WinnerChangeDialog = ({open, onClose, vehicleId, useDate, reservation, contracts, refetch}) => {
    const dispatch = useDispatch();
    const [selected, setSelected] = useState();
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);

    useEffect(() => {
        if (reservation?.contract) {
            setSelected(reservation.contract.id);
        } else {
            setSelected(null);
        }
    }, [reservation]);

    const filterContract = () => {
        return filter(contracts, (c) => {
            if (c.startDate && c.startDate > useDate) {
                return false;
            } else if (c.endDate && c.endDate < useDate) {
                return false;
            }
            return true;
        })
    }

    const getName = (c) => {
        if (isAdmin(tokenInfo?.id)) {
            return c?.users_permissions_user?.nickname;
        } else {
            if (c?.users_permissions_user?.id === tokenInfo?.id) {
                return '나';
            }
            return c?.users_permissions_user?.nickname;
        }
    }

    const handleChange = (e) => {
        let contractId = e.target.value;
        if (!contractId) {
            contractId = null;
        }

        setSelected(e.target.value);
        dispatch(loadingAction.loading(true));

        axios.post(SERVER_ADDRESS + '/api/reservations/changeWinner', {
            vehicleId: vehicleId,
            contractId: contractId,
            useDate: useDate,
        }).then(() => {
            dispatch(loadingAction.loading(false));
            refetch();
            onClose();
        });
    }
    return <Dialog open={open} onClose={onClose}>
        <div style={{padding: '16px'}}>
            <RadioGroup value={selected} onChange={handleChange}>
                {filterContract().map((c) =>
                    <FormControlLabel value={c.id} control={<Radio />} label={getName(c)}/>)}
                <FormControlLabel value={null} control={<Radio />} label={"미지정"}/>
            </RadioGroup>
        </div>
    </Dialog>
}

export const DriverChangeDialog = ({open, onClose, trip, contracts, refetch}) => {
    const dispatch = useDispatch();
    const [selected, setSelected] = useState();
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);

    const [updateTrip] = useMutation(UPDATE_TRIP);

    useEffect(() => {
        if (trip?.contract) {
            setSelected(trip.contract.id);
        } else {
            setSelected(null);
        }
    }, [trip]);

    const filterContract = () => {
        return filter(contracts, (c) => {
            if (c.startDate && c.startDate > trip?.date) {
                return false;
            } else if (c.endDate && c.endDate < trip?.date) {
                return false;
            }
            return true;
        })
    }

    const getName = (c) => {
        if (isAdmin(tokenInfo?.id)) {
            return c?.users_permissions_user?.nickname;
        } else {
            if (c?.users_permissions_user?.id === tokenInfo?.id) {
                return '나';
            }
            return c?.users_permissions_user?.nickname;
        }
    }

    const handleChange = (e) => {
        let contractId = e.target.value;
        const tripId = trip.id;

        if (!contractId) {
            contractId = null;
        }

        setSelected(e.target.value);
        dispatch(loadingAction.loading(true));

        const payload = {
            variables: {
                id: tripId,
                data: {
                    contract: contractId,
                }
            }
        }
        updateTrip(payload).then((res) => {
            dispatch(loadingAction.loading(false));
            refetch();
            onClose();
        });
    }
    return <Dialog open={open} onClose={onClose}>
        <div style={{padding: '16px'}}>
            <RadioGroup value={selected} onChange={handleChange}>
                {filterContract().map((c) =>
                    <FormControlLabel value={c.id} control={<Radio />} label={getName(c)}/>)}
                {isAdmin(tokenInfo?.id) && <FormControlLabel value={null} control={<Radio />} label={"제거 (관리자 전용)"}/>}
            </RadioGroup>
        </div>
    </Dialog>
}


export const ReservationRequestDetailPopup = ({open, onClose, dateStr, reservationRequests, contract, refetch}) => {
    const dispatch = useDispatch();
    const req = find(reservationRequests, r => r.useDate === dateStr);
    const contracts = contract?.vehicle?.contracts;
    const dayContracts = getDayContract(contracts, dateStr);

    const onAccept = () => {
        dispatch(loadingAction.loading(true));
        const res = axios.post(SERVER_ADDRESS + '/api/reservation-requests/accept', {
            reservationRequestId: req.id,
        }).then(() => {
            dispatch(loadingAction.loading(false));
            refetch();
            onClose();
        });
    }

    const onDecline = () => {
        dispatch(loadingAction.loading(true));
        const res = axios.post(SERVER_ADDRESS + '/api/reservation-requests/decline', {
            reservationRequestId: req.id,
        }).then(() => {
            dispatch(loadingAction.loading(false));
            refetch();
            onClose();
        });
    }

    const getUserName = () => {
        if (req?.contract?.id === contract?.id) {
            return '나'
        }
        return `${req?.contract?.users_permissions_user?.nickname}님`;
    }

    return <Dialog open={open} onClose={onClose}>
        <_DetailDialog>
            <div className="content">
                <div className="header">
                    <div className="title">
                        <img src="/vehicle/tag.svg"/>
                        <span>자세히 보기</span>
                    </div>
                </div>
                <div>
                    <p style={{lineHeight: '120%'}}>
                        {getUserName()}의 운행 요청<br/>
                        자동 {req?.autoAccept? '수락': '거절'}까지 남은 시간 : {calcTimeRemain(req?.expireAt)}
                    </p>
                </div>
                <div>
                </div>
                {/*{_before.map((t) =>*/}
                {/*    <div key={t.id} className="tag-select">*/}
                {/*        <div className="item">*/}
                {/*            <div className="title">*/}
                {/*                {t.hipass? `${kmFormat(t.hipass)}원`: `${kmFormat(t.km)}km`}*/}
                {/*            </div>*/}
                {/*            <div className="tags">*/}
                {/*                {t.description}*/}
                {/*            </div>*/}
                {/*            <div className="name">*/}
                {/*                {t?.contract?.users_permissions_user? t?.contract?.users_permissions_user?.nickname: '알 수 없음'}*/}
                {/*            </div>*/}
                {/*            <Button style={{marginLeft: 'auto'}} variant="outlined" onClick={() => {*/}
                {/*                setChangeTrip(t);*/}
                {/*                setChangeOpen(true);*/}
                {/*            }}>운전자 변경</Button>*/}
                {/*        </div>*/}
                {/*    </div>)}*/}
                {/*{_after.length > 0 && <>*/}
                {/*    <div className="tag-select">*/}
                {/*        <div className="item">*/}
                {/*            <div className="title" style={{color: 'black'}}>*/}
                {/*                다음날*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    {_after.map((t) =>*/}
                {/*        <div key={t.id} className="tag-select">*/}
                {/*            <div className="item">*/}
                {/*                <div className="title">*/}
                {/*                    {t.hipass? `${kmFormat(t.hipass)}원`: `${kmFormat(t.km)}km`}*/}
                {/*                </div>*/}
                {/*                <div className="tags">*/}
                {/*                    {t.description}*/}
                {/*                </div>*/}
                {/*                <div className="name">*/}
                {/*                    {t?.contract?.users_permissions_user? t?.contract?.users_permissions_user?.nickname: '알 수 없음'}*/}
                {/*                </div>*/}
                {/*                <Button style={{marginLeft: 'auto'}} variant="outlined" onClick={() => {*/}
                {/*                    setChangeTrip(t);*/}
                {/*                    setChangeOpen(true);*/}
                {/*                }}>운전자 변경</Button>*/}
                {/*            </div>*/}
                {/*        </div>)}*/}
                {/*</>}*/}
            </div>
            <div className="action-box">
                {(req?.contract?.id !== contract?.id && find(dayContracts, c => c.id === contract?.id)) &&
                    <>
                        <div className="submit" onClick={() => onAccept()}>수락</div>
                        <div className="cancel" onClick={() => onDecline()}>거절</div>
                    </>
                }
                {req?.contract?.id === contract?.id &&
                    <>
                        <div className="submit" onClick={() => onDecline()}>요청 취소하기</div>
                        <div className="cancel" onClick={() => onClose()}>닫기</div>
                    </>
                }
            </div>
        </_DetailDialog>
    </Dialog>
}
