import ReactGA from "react-ga4";
import {SERVER_ADDRESS} from "../index";
import axios from "axios";
import {store} from "../redux/store";
import {authAction} from "../redux/authReducer";
import {loadingAction} from "../redux/loadingReducer";
import {userAction} from "../redux/userReducer";

export const openKakaoChat = (action, label) => {
    if (label) {
        ReactGA.event({
            category: "DooriveOpenChat",
            action: action,
            label: label, // optional
        });
    } else {
        ReactGA.event({
            category: "DooriveOpenChat",
            action: action,
        });
    }
    window.Kakao.Channel.chat({channelPublicId: '_nnmxeK'});
    window.fbq('track', 'Contact');
}

export const kakaoRestLogin = (code, callback) => {
    store.dispatch(loadingAction.loading(true));
    const url = SERVER_ADDRESS + '/api/auth/kakao/callback?code=' + code;
    axios.get(url)
        .then(res => {
            const {jwt, user} = res.data;
            store.dispatch(loadingAction.loading(false));
            callback(jwt, user);
            // requestUserInfo(callback);
        })
        .catch((reason => {
            store.dispatch(loadingAction.loading(false));
            alert(reason);
            console.log(reason);
        }));
}

export const kakaoLogin = (callback=null) => {
    const throughTalk = true;
    const persistAccessToken = true;
    const method = 'login';
    (window.Kakao?.Auth)[method]({
        throughTalk,
        persistAccessToken,
        success: (response) => {
            store.dispatch(loadingAction.loading(true));
            const url = SERVER_ADDRESS + '/auth/kakao/callback?access_token=' + response.access_token;
            axios.get(url)
                .then(res => {
                    const {jwt, user} = res.data;
                    store.dispatch(loadingAction.loading(false));
                    callback(jwt, user);
                    // requestUserInfo(callback);
                })
                .catch((reason => {
                    store.dispatch(loadingAction.loading(false));
                    alert(reason);
                    console.log(reason);
                }));
        },
        fail: (err) => {
            store.dispatch(loadingAction.loading(false));
            console.log(err);
        },
    });
}
