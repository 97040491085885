import styled from "styled-components";
import {get, isEmpty} from "lodash";
import {useSelector} from "react-redux";
import {MY_CONTRACTS} from "../../query/userQuery";
import {useQuery} from "@apollo/client";
import {isAvailableContract, isMatchedContract} from "../../toolbox/calculate";
import {asyncSendTelegram, commaNum} from "../../toolbox";
import {loadTossPayments} from "@tosspayments/payment-sdk";
import {TOSS_SUB_CLIENT_KEY} from "../../index";
import {useLocation, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {getLoginLink} from "../../toolbox/format";
import {UserEditModal} from "./component/UserEdit";
import {Alert, Snackbar} from "@mui/material";
import {ProfileEdit, ProfileEditModal} from "./component/ProfileEdit";

export const ProfileEditPage = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const userInfo = useSelector(({user}) => user.user);
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);


    useEffect(() => {
        if (isEmpty(tokenInfo)) {
            const to = getLoginLink(location);
            navigate(to);
        }
    }, [tokenInfo]);

    return <_ProfileEditPage>
        <ProfileEdit onClose={() => navigate('/my/info')}/>
    </_ProfileEditPage>;
}



const _ProfileEditPage = styled.div`
  .section {
    > .title {
      display: flex;
      padding-top: 24px;
      padding-left: 32px;
      padding-right: 32px;
      font-weight: 700;
      font-size: 14px;
      align-items: center;
      justify-content: space-between;
      > .right_button {
        cursor: pointer;
        font-size: 12px;
        color: #6993FF;
        text-decoration-line: underline;
        text-underline-offset: 2px;
        text-decoration-color: #6993FF;
        text-decoration-thickness: auto;
        text-decoration-style: solid;
      }
    }
  }

  .items {
    margin-top: 18px;
    margin-bottom: 18px;
  }
`
