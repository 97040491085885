import moment from "moment-timezone";
import {cloneDeep, filter, find, reverse, sortBy} from "lodash";
import {getNextMonthLastDay} from "./day";
import * as Hangul from 'hangul-js';
import {calcFirstMonthlyCharge} from "./calculate";

export const hangulSearch = (query, value) => {
    return Hangul.search(value.toLowerCase(), query.toLowerCase());
}

export const commaNum = (number) => {
    if (number) {
        return Math.round(number).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
        return "0"
    }
}

export const calcAge = (birth) => {
    const now = moment.tz('Asia/Seoul');
    return now.diff(birth, 'years');
}

export const paymentIdToOrderId = (paymentId) => {
    let str = paymentId.toString();
    while (str.length < 6) {
        str = '0' + str;
    }
    return str;
}

export const manNum = (number) => {
    if (number) {
        return Math.round(number / 1000) / 10;
    } else {
        return 0;
    }
}

export const getUseTypeTxt2 = (vehicle) => {
    if (vehicle.useTypes?.length) {
        return vehicle.useTypes.map((u) => getUseTypeTxt(u.id, vehicle.useTypeDetail)).join(', ');
    }
    return getUseTypeTxt(vehicle.useType, vehicle.useTypeDetail);
}

export const getUseTypeTxt = (useType, useTypeDetail) => {
    switch (useType) {
        case "PRIORITY":
            return `${useTypeDetail.priority} 우선예약형`;
        case "DAY_SELECT":
            return "요일지정형";
        case "HALF":
            return "반반예약형";
        case "FULL_SHARE":
            return "전체공유형";
        case "FREE":
            return "협의후결정";
        case "TEMP":
            return "예비호스트";
        case "PRIORITY_HOST":
            return "호스트우선형";
        case "PRIORITY_GUEST":
            return "게스트우선형";
    }
}

export const getUseTypeId = (useTypeTxt) => {
    switch (useTypeTxt) {
        case "요일지정형":
            return "DAY_SELECT";
        case "호스트우선형":
            return "PRIORITY_HOST";
        case "게스트우선형":
            return "PRIORITY_GUEST";
    }
}
export const toDateFormat = (date) => {
    const m = moment.tz(date, 'Asia/Seoul');
    return m.format('YYYY. MM. DD');
}

export const kmFormat = (km) => {
    const v = Number(km);
    return Math.round(km * 10) / 10;
}

export const isNumberPlateFormat = (maybeNumberPlate) => {
    return maybeNumberPlate.match(/\d{2,3}[가-힣]{1}\d{4}/gm);
}

export const isPhoneFormat = (maybePhone) => {
    let result = maybePhone.replace(/[^0-9]/g, "");
    return result.length >= 10;
}

export const isUrlFormat = (maybeUrl) => {
    const exp = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
    const result = maybeUrl.match(exp);
    if (result) {
        return result[0];
    }
    return null;
}

export const toNumberPlateFormat = (value) => {
    let result = value.replace(' ', '');
    return result;
    // let result = value.replace(/[^0-9|가-힣|ㄱ-ㅎ]/g, "");
    // let numbers = result.split(/[가-힣]{1}/g);
    // if (numbers[1] && numbers[1].length > 4) {
    //     result = result.slice(0, 4 - numbers[1].length);
    // }
    // return result.slice(0, 8);
}
export const toPhoneFormat = (value) => {
    let result = value.replace(/[^0-9]/g, "");
    result = result.slice(0, 11);
    if (result.length === 10) {
        result = `${result.slice(0,3)}-${result.slice(3,6)}-${result.slice(6,10)}`;
    } else if (result.length > 7) {
        result = `${result.slice(0,3)}-${result.slice(3,7)}-${result.slice(7,11)}`;
    } else if (result.length > 3) {
        result = `${result.slice(0,3)}-${result.slice(3,7)}`;
    }
    return result;
}

export const toNumberFormat = (value) => {
    let result = value.replace(/[^0-9]/g, "");
    return result;
}

export const toTagFormat = (value) => {
    let result = value.replace(' ', '');
    return result;
}

export const manFormat = (maybe_number) => {
    if (typeof(maybe_number) === 'number') {
        if (maybe_number === 0) {
            return '0';
        }
        return `${Math.round(maybe_number / 1000) * 1000 / 10000}만`
        // return `${maybe_number / 10000}만`;
    }
    return maybe_number;
}

export const distanceFormat = (number) => {
    if (number) {
        if (number < 1) {
            return Math.round(number * 1000) + 'm'
        } else {
            return Math.round(number * 10) / 10 + 'km';
        }
    } else {
        return "0m"
    }
}

export const reformGMT = (gmt) => {
    return moment(gmt).format('YYYY-MM-DD');
};

export const getDayContract = (contracts, date) => {
    return filter(contracts, (c) => {
        if (c.startDate && c.startDate > date) {
            return false;
        } else if (c.endDate && c.endDate < date) {
            return false;
        }
        return true;
    });
}

/**
 * 다음달 마지막날 이전까지 끝나지 않는 계약을 찾는다.
 * (다음달 마지막날 이후에 끝나는 계약들은 적어도 2달후에 티오가 나오는 차량이라 노출하지 않기 위함)
 * @param contracts
 * @returns {{
 * result: *["다음달 마지막날 이전까지는 지속되는 계약"],
 * filtered: *["다음달 마지막날 이후에 끝나는 계약. 이용가능일을 표시하기위해 리턴"]}}
 */
const filterContract = (contracts) => {
    const cut = getNextMonthLastDay();
    if (!contracts) {
        contracts = [];
    }
    let _contracts = cloneDeep(contracts);
    let now = moment.tz('Asia/Seoul');
    _contracts = _contracts.filter((contract) => !contract.endDate || moment.tz(contract.endDate, 'Asia/Seoul') > now);
    let filtered = [];
    let result = [];
    _contracts.forEach((contract) => {
        if (!contract.endDate) {
            result.push(contract);
        } else {
            const endDate = moment.tz(contract.endDate, 'Asia/Seoul');
            if (endDate >= cut) {
                result.push(contract);
            } else {
                filtered.push(contract);
            }
        }
    })
    return {
        filtered: filtered,
        result: result,
    };
}

export const calcTimeDiff = (date) => {
    let m = moment.tz(date, 'Asia/Seoul');
    let n = moment.tz('Asia/Seoul');
    let diff = n.diff(m, 'weeks');
    if (diff) {
        return `${diff}주 전`;
    }
    diff = n.diff(m, 'days');
    if (diff) {
        return `${diff}일 전`;
    }
    diff = n.diff(m, 'hours');
    if (diff) {
        return `${diff}시간 전`;
    }
    diff = n.diff(m, 'minutes');
    if (diff) {
        return `${diff}분 전`;
    }
    return '방금 전';
}

export const getResponseTimeTxt = (hostStat) => {
    const responseTime = hostStat?.매칭요청평균응답초;
    if (!responseTime) {
        return null;
    } else if (responseTime === 172800) {
        return `${Math.ceil(responseTime / 3600)}시간초과`
    }
    return `${Math.ceil(responseTime / 3600)}시간이내`
    // if (!responseTime) {
    //     return '보통';
    // }
    // if (responseTime < 3600 * 24) {
    //     return '빠름';
    // } else if (responseTime < 3600 * 48) {
    //     return '보통';
    // }
    // return '느림';
}

export const calcTimeRemain = (date) => {
    let m = moment.tz(date, 'Asia/Seoul');
    let n = moment.tz('Asia/Seoul');
    let diff = m.diff(n);
    let duration = moment.duration(diff);
    return `${duration.hours()}시간 ${duration.minutes()}분`;
}

const isDevelopment = () => {
    const DEV = ['localhost', 'develop'];
    if (window?.location?.hostname) {
        if (find(DEV, (hostname) => window.location.hostname.includes(hostname))) {
            return true;
        }
    }
    return false;
}

const isDooricar = (result) => {
    const contract = find(result, (c) => c.contractType === 'DOORICAR_WAITING');
    if (contract.latitude && contract.longitude && contract.address) {
        return contract;
    }
    return false;
    // if (result.length === 1) {
    //     const contract = result[0];
    //     if (contract.contractType !== 'SOLOCAR' && contract.latitude && contract.longitude && contract.address) {
    //         return contract;
    //     }
    // } else if (result.length && force) {
    //     const contract = find(result, (c) => c.contractType === 'DOORICAR_WAITING');
    //     if (contract.latitude && contract.longitude && contract.address) {
    //         return contract;
    //     }
    // }
    // return false;
}

const isSolocar = (result) => {
    if (result.length === 0) {
        return true;
    }
    return false;
}

export const isLike = (vehicle, userId) => {
    return find(vehicle.likes, (l) => l.user.id === userId)
}

export const getLikeSolocar = (vehicles, userId) => {
    const vs = filterVehicles(vehicles, 'solo').filter(v => v.thumbnail);
    if (vs) {
        return reverse(sortBy(filter(vs, (v) => isLike(v, userId)), (v) => isLike(v, userId).createdAt));
    }
    return [];
}

export const filterVehicles = (vehicles, vehicleType) => {
    if (!vehicles) {
        return null;
    }
    return filter(vehicles, (v) => v?.vehicleType === vehicleType);
}

export const findGuestVehicle = (vehicles, vehicleId, userId) => {
    const guestVehicles = getGuestVehicles(vehicles);
    const vehicle = find(guestVehicles, (v) => String(v.id) === String(vehicleId) && String(v.user.id) === String(userId));
    return vehicle;
}

export const getGuestVehicles = (vehicles, exceptUserId) => {
    return [];
    if (!vehicles) {
        return [];
    }
    const result = [];
    for (const vehicle of vehicles) {
        for (let like of vehicle.likes) {
            const user = like.user;
            if (vehicle.vehicleType !== 'solo') {
                continue;
            }
            if (user.id === exceptUserId) {
                continue;
            }
            if (user.lat && user.lng && user.address) {
                // pass
            } else {
                continue;
            }
            if (!user.mapMarker) {
                continue;
            }
            const v = cloneDeep(vehicle);
            v.title = user?.introduce? user?.introduce?.split(',').map(tag => '#' + tag).reduce((prev, curr) => prev + " " + curr): '';
            v.description = (vehicle.owned? '즉시출고⚡\n\n': '') + (v.title? (v.title + '\n\n'): ("")) + `${user?.nickname}님이 찜한 두리렌터카입니다.
1:1 대화 후 매칭을 요청해보세요!\n
두리렌터카는 두리카에서 제공하는 공식 렌터카입니다.
월단위로 위약금 걱정없이, 합리적인 가격으로 이용할 수 있어요.\n
🏠 호스트란?
게스트와 같이 사용할 주차장을 제공하고
차량 정비 등 전반적인 관리를 책임지며,
게스트로부터 매월 ‘월이용료'와 ‘주행거리당수익'을 얻을 수 있습니다.\n
※ ${user?.nickname}님과 협의 후 호스트가 될 분을 결정해주세요.`;
            v.latitude = user.lat;
            v.longitude = user.lng;
            v.address = user?.address?.split('\t')[0];
            v.guestId = user.id;
            v.user = user;
            result.push(v);
        }
    }
    return result;
}

export const fillUseTypes = (vehicle) => {
    if (vehicle.useTypes && vehicle.useTypes.length > 0) {
        let temp = find(vehicle.useTypes, (u) => u.id === 'TEMP');
        let daySelect = find(vehicle.useTypes, (u) => u.id === 'DAY_SELECT');
        let host = find(vehicle.useTypes, (u) => u.id === 'PRIORITY_HOST');
        let guest = find(vehicle.useTypes, (u) => u.id === 'PRIORITY_GUEST');
        let price;

        if (daySelect) {
            price = daySelect.price;
        } else if (guest) {
            price = guest.price / 1.5;
        } else if (host) {
            price = host.price * 2;
        } else if (temp) {
            price = temp.price;
        }

        if (!daySelect) {
            vehicle.useTypes.push(({
                id: 'DAY_SELECT',
                price: Math.round(price / 1000) * 1000,
            }));
        }
        if (!host) {
            vehicle.useTypes.push(({
                id: 'PRIORITY_HOST',
                price: Math.round(price * 0.5 / 1000) * 1000,
            }));
        }
        if (!guest) {
            vehicle.useTypes.push(({
                id: 'PRIORITY_GUEST',
                price: Math.round(price * 1.5 / 1000) * 1000,
            }));
        }
        if (temp) {
            const index = vehicle.useTypes.findIndex(item => item.id === "TEMP");
            if (index !== -1) {
                vehicle.useTypes.splice(index, 1);
            }
        }
    }
    return vehicle;
}



export const convertMapVehicle = (v) => {
    if (!v.thumbnail && v.bakchaAdditionalInfo) {
      const bakchaInfo = v.bakchaAdditionalInfo;
      if (bakchaInfo.Photo) {
        v.thumbnail = {url: bakchaInfo.Photo[0]};
        v.pictures = bakchaInfo.Photo.map((p) => ({url: p}));
      }
    }
    if (!v.thumbnail || !v.pictures) {
      v.thumbnail = {url: "/vehicle/dummy.png"};
      v.pictures = [{url: "/vehicle/dummy.png"}];
    }

    let updatedAt = moment.tz(v.updatedAt, 'Asia/Seoul');
    if (updatedAt.format('YYYY-MM-DD') <= '2024-01-06') {
      fillUseTypes(v.hostContract);
    } else {
    }

    let model = v.model;
    if (v.model.startsWith(v.brand)) {
      model = v.model.substring(v.brand.length).trim();
    }

    v.hostContract.useTypes = sortBy(v.hostContract.useTypes, (u) => u.price);
    return ({...v, mapId: `v${v.id}`, type: 'vehicle',
      latitude: v.hostContract.latitude, longitude: v.hostContract.longitude, model: model,
      price: Math.min(...v.hostContract.useTypes.map(u => u.price)),
    });
  };

export const convertDooricar = (vehicle, ignoreDraft, ignoreVisible) => {
    if (!vehicle) {
        return null;
    }

    if (ignoreDraft) {

    } else {
        if (vehicle.draftVehicle) {
            return null;
        }
    }

    if (ignoreVisible) {

    } else {
        if (!vehicle.visible) {
            return null;
        }
    }
    // if (!vehicle.thumbnail && !vehicle.bakchaAdditionalInfo) {
    //     return null;
    // }

    const {result, filtered} = filterContract(vehicle?.contracts);
    const payload = {
        id: vehicle.id,
        owned: vehicle.owned,
        brand: vehicle.brand,
        model: vehicle.model,
        trim: vehicle.trim,
        year: vehicle.year,
        numberPlate: vehicle.numberPlate,
        mileage: vehicle.mileage,
        options: vehicle.options,
        fuelType: vehicle.fuelType,
        color: vehicle.color,
        transmissionType: vehicle.transmissionType,
        displacement: vehicle.displacement,
        priceSolo: vehicle.priceSolo,
        priceDuo: vehicle.priceDuo,
        kmCharge: vehicle.kmCharge,
        youngCharge: vehicle.youngCharge,
        thumbnail: vehicle.thumbnail,
        pictures: vehicle.pictures,
        likes: vehicle.likes,
        availableDate: vehicle.availableDate,
        currentValue: vehicle.currentValue,
        eventPrice: vehicle.eventPrice,
        visible: vehicle.visible,
        draftVehicle: vehicle.draftVehicle,
    }

    if (!vehicle.thumbnail && vehicle.bakchaAdditionalInfo) {
        const bakchaInfo = vehicle.bakchaAdditionalInfo;
        if (bakchaInfo.Photo) {
            payload.thumbnail = {url: bakchaInfo.Photo[0]};
            payload.pictures = bakchaInfo.Photo.map((p) => ({url: p}));
        }
    }

    if (!payload.thumbnail || !payload.pictures) {
        payload.thumbnail = {url: "/vehicle/dummy.png"};
        payload.pictures = [{url: "/vehicle/dummy.png"}];
    }

    if (isDooricar(result)) {
        let c = isDooricar(result);
        let updatedAt = null;
        for (let history of c.updateHistory ?? []) {
            if (history.updatedAt) {
                if (updatedAt === null || updatedAt < history.updatedAt) {
                    updatedAt = history.updatedAt;
                }
            }
        }

        if (updatedAt) {
            updatedAt = moment.tz(updatedAt, 'Asia/Seoul');
            if (updatedAt.format('YYYY-MM-DD') <= '2024-01-06') {
                fillUseTypes(c);
            } else {
            }
        }

        const contract = {
            id: c.id,
            longitude: c.longitude,
            latitude: c.latitude,
            address: c.address.split('\t')[0],
            user: {
                id: c.users_permissions_user.id,
                nickname: c.users_permissions_user.nickname,
                address: c.users_permissions_user.address,
                profile_image: c.users_permissions_user.profile_image,
                phone: c.users_permissions_user.phone,
            },
            dooriveTitle: c.dooriveTitle,
            dooriveDescription: c.dooriveDescription,
            doorivePriceMonth: c.doorivePriceMonth,
            doorivePriceKm: c.doorivePriceKm,
            doorivePriceInsurance: c.doorivePriceInsurance,
            doorivePriceTax: c.doorivePriceTax,
            doorivePriceOil: c.doorivePriceOil,
            matchingDetail: c.matchingDetail,
            startDate: c.startDate,
            useType: c.useType,
            useTypeDetail: c.useTypeDetail,
            useTypes: sortBy(c.useTypes, (u) => u.price),
            daySelect: c.daySelect,
        }

        let addMessage = '';
        if (contract.matchingDetail) {
            const matchingDetail = [];
            for (let key in contract.matchingDetail) {
                let v = `${key}: ${contract.matchingDetail[key]}`;
                if (key === '최소연령제한') {
                    if (contract.matchingDetail[key] !== '무관') {
                        v = `${key}: 만 ${contract.matchingDetail[key]}세`;
                    }
                }
                matchingDetail.push(v);
            }
            addMessage += '\n\n';
            addMessage += matchingDetail.join('\n');
        }

        let minPrice = contract.doorivePriceMonth ? contract.doorivePriceMonth: vehicle.priceDuo;
        let minTypes = contract.useType;
        if (contract.useTypes) {
            for (let u of contract.useTypes) {
                if (u.price < minPrice) {
                    minPrice = u.price;
                    minTypes = u.id;
                }
            }
        }

        return {
            ...payload,
            vehicleType: 'doori',
            priceDuo: minPrice,
            priceTax: contract.doorivePriceTax ? contract.doorivePriceTax: null,
            priceInsurance: contract.doorivePriceInsurance ? contract.doorivePriceInsurance: null,
            kmCharge: contract.doorivePriceKm !== null ? contract.doorivePriceKm: vehicle.kmCharge,
            priceOil: contract.doorivePriceOil,
            priceManage: contract.doorivePriceKm !== null ? contract.doorivePriceKm: vehicle.kmCharge,
            youngCharge: '(문의필요)',
            title: contract.dooriveTitle ?? `${vehicle.model} 같이 타실분 구해요`,
            description: (contract.dooriveDescription ??
                `- 차 종 : ${vehicle.brand} ${vehicle.model}
- 연 식 : ${vehicle.year}
- 색 상 : ${vehicle.color}
- 내,외관 : 실내, 실외 모두 깨끗합니다.
- 관리상태 : 정기적으로 모든 점검과 정비를 받고 있습니다.

- 호스트 한마디 : 매너 있는분만 신청해주세요. 제가 비흡연자라서 흡연자는 죄송합니다.`) + addMessage,
            contract: contract.id,
            longitude: contract.longitude,
            latitude: contract.latitude,
            address: contract.address,
            user: contract.user,
            useType: contract.useType,
            useTypes: contract.useTypes,
            useTypeDetail: contract.useTypeDetail,
            daySelect: contract.daySelect,
            hostUser: c.users_permissions_user,
            hostContract: c,
        }
    } else if (isSolocar(result)) {
        if (vehicle.doorive) {
            return null;
        }
        return {
            ...payload,
            overCharge: vehicle.overCharge,
            vehicleType: 'solo',
            description: (vehicle.owned? '즉시출고⚡\n\n': '') + `두리카에서 제공하는 렌트카입니다.
월단위로 위약금 걱정없이,
합리적인 가격으로 렌트카를 이용해보세요!\n
혹시 차를 많이 많이 타지 않으신다면?
언제든 두리카 호스트로 등록하여 월수익을 올릴 수 있습니다.\n
🏠 호스트란?
게스트와 같이 사용할 주차장을 제공하고 차량 정비 등 전반적인 관리를 책임지며,
게스트로부터 매월 ‘월이용료'와 ‘주행거리당수익'을 얻을 수 있습니다.\n
만약 공유 가능한 주차장이 없어서 게스트로 매칭을 요청하려면,
차량사진 우측상단의 하트표시를 클릭해주세요 ❤️`,
            user: {
                nickname: '두리렌트카',
                profile_image: {
                    url: '/logo.png'
                }
            },
            address: '서울특별시 강남구 강남대로 382 메리츠타워',
        }
    }
    return null;
}

export const getPricePlan = ({isSocarEvent, originMonthlyCharge, startDate, endDate}) => {
    let price = Number(originMonthlyCharge);
    if (isSocarEvent) {
        price = Number(originMonthlyCharge) * 0.4;
    }
    let startMoment = moment.tz(startDate, 'Asia/Seoul');
    let endMoment = moment.tz(endDate, 'Asia/Seoul');

    let startEvent;
    if (startMoment.date() === 1) {
        startEvent = startMoment.clone();
    } else {
        startEvent = startMoment.clone().add(1, 'month').startOf('month');
    }
    let endEvent = startEvent.clone().add(4, 'month').endOf('month');

    let endDuration = startMoment.clone().add(3, 'month').endOf('month');
    if (endMoment.isValid()) {
        endDuration = endMoment.clone();
    }

    let i = startMoment.clone();
    const result = [];
    while (i < endDuration) {
        let end = i.clone().endOf('month');
        if (end > endDuration) {
            end = endDuration;
        }

        let paymentDate = i.clone().subtract(1, 'month').date(25);
        if (paymentDate < startMoment) {
            paymentDate = startMoment.clone();
        }

        let charge;
        if (isSocarEvent && (startEvent.format('YYYY-MM-DD') > i.format('YYYY-MM-DD') || endEvent.format('YYYY-MM-DD') < i.format('YYYY-MM-DD'))) {
            charge = calcFirstMonthlyCharge(Number(originMonthlyCharge), i.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'));
            result.push({
                isEvent: false,
                startDate: i.format('YYYY-MM-DD'),
                endDate: end.format('YYYY-MM-DD'),
                paymentDate: paymentDate.format('YYYY-MM-DD'),
                charge: charge,
            });
        } else {
            charge = calcFirstMonthlyCharge(price, i.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'));
            result.push({
                isEvent: isSocarEvent,
                startDate: i.format('YYYY-MM-DD'),
                endDate: end.format('YYYY-MM-DD'),
                paymentDate: paymentDate.format('YYYY-MM-DD'),
                charge: charge,
            });
        }
        i = i.add(1, 'month').startOf('month');
    }
    return result;
}

export const getLoginLink = (location) => {
    if (location.pathname.startsWith("/login")) {
        return location.pathname + location.search + location.hash;
    }
    return "/login?prev=" + location.pathname + "?" + encodeURIComponent(location.search.substr(1)) + encodeURIComponent(location.hash);
}

export const filterAvailableWallets = (wallets) => {
    let now = moment.tz('Asia/Seoul');
    return filter(wallets, (w) => {
        const recordedAt = moment.tz(w.recordedAt ? w.recordedAt: w.createdAt, 'Asia/Seoul');
        return now > recordedAt;
    });
}