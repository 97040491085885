import Header from "./Header";
import Footer from "./Footer";
import {useLocation} from "react-router-dom";
import {find} from "lodash";

const HIDDEN_PATH = ["/my/chat/", "/my/chat_start"]

const Layout = ({children}) => {
    const location = useLocation();
    return (
        <>
            <Header />
            <>{children}</>
            {!find(HIDDEN_PATH, (p) => location.pathname.startsWith(p))  && <Footer />}
        </>
    )
}

export default Layout;
