import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";
import { useMutation } from "@apollo/client";
import axios from "axios";
import moment from "moment-timezone";
import queryString from "query-string";
import * as Hangul from 'hangul-js';
import FilterListIcon from '@mui/icons-material/FilterList';

import { calcDistance, useForm } from "../../toolbox";
import { filter, find, isEmpty, sortBy } from "lodash";
import { pathAction } from "../../redux/pathReducer";
import { convertDooricar, convertMapVehicle, fillUseTypes, getUseTypeId } from "../../toolbox/format";
import { applyFilters, isAdmin, openAddress, pathSelector } from "../../toolbox/logic";
import { SERVER_ADDRESS } from "../../index";
import { UPDATE_USER } from "../../query/userQuery";
import { GUEST_WANTED } from "../../toolbox/guestTag";

import { VehicleMap } from "./component/VehicleMap";
import { EventSwipe } from "../event/component/EventSwipe";
import LoadingIndicator from "../../layout/LoadingIndicator";
import { ListVehicle2, RowVehicle, TinyVehicle } from "./component/ListVehicle2";
import { FormComponent } from "./VehicleAdd2/FormComponent";
import { VehicleWanted } from "./component/VehicleWanted";
import { ListGuest, RowGuest } from "./component/ListGuest";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/swiper-bundle.css';
import { HelpBtn } from "../../toolbox/UtilComponent";

const MyDivider = styled.div`
  width: 100%;
  border-width: 0;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.12);
  border-bottom-width: thin;
//   margin-left: 22px;
//   margin-right: 22px;
`
const _AddressRequest = ({className, children, onClick}) => {
    return <div className={className} onClick={onClick}>
        <>{children}</>
    </div>
}

const AddressRequest = styled(_AddressRequest)`
  cursor: pointer;
  margin: 8px 22px;
  padding: 6px;
  display: flex;
  align-items: center;
  //box-sizing: border-box;
  //width: 100%;
  border-radius: 4px;
  border: 1px solid rgb(180,180,180);
  color: rgb(80,80,80);
  word-break: keep-all;
  
  > img {
    width: 20px;
    margin-right: 8px;
  }
`

const VehiclesRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding: 0 10px;
`

const Vehicles = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
  padding: 10px 10px;
`

const VehicleMainPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { path: pathInfo, user: { user: userInfo }, auth: { tokenInfo } } = useSelector(state => state);

  const [filterOpen, setFilterOpen] = useState(false);
  const { form: filterForm, onChange: onFilterChange } = useForm({
    filters: GUEST_WANTED.flatMap(w => w.tags.map(t => t.id)).join(','),
    sorter: '가까운거리순',
    searchText: '',
  });

  const [vehicles, setVehicles] = useState(null);
  const [showVehicles, setShowVehicles] = useState(null);
  const [userTags, setUserTags] = useState(null);
  const [filteredVehicles, setFilteredVehicles] = useState([]);
  const [mapBound, setMapBound] = useState();
  const [mapOpen, setMapOpen] = useState(null);
  const [showVehicle, setShowVehicle] = useState(false);
  const [myUserTagCollapse, setMyUserTagCollapse] = useState(false);

  const [updateUser] = useMutation(UPDATE_USER);

  const path = useMemo(() => pathSelector(userInfo, pathInfo), [userInfo, pathInfo]);
  const needPath = useMemo(() => !path || !path.latitude || !path.longitude, [path]);

  useEffect(() => {
    fetchVehicles();
  }, []);

  
  useEffect(() => {
    if (needPath) {
      onFilterChange({ id: 'sorter', value: '관심높은순'});
    } else {
      onFilterChange({ id: 'sorter', value: '가까운거리순'});
    }
  }, [needPath]);

  useEffect(() => {
    if (userInfo?.matching_profile?.["원하는 차량"]) {
      onFilterChange({ id: 'filters', value: userInfo.matching_profile["원하는 차량"] });
    }
  }, [userInfo]);

  useEffect(() => {
    if (vehicles) {
      const newShowVehicles = filterAndSortVehicles(vehicles);
      setShowVehicles(newShowVehicles);
      setShowVehicle(true);
    }
  }, [vehicles, filterForm, path]);

  const fetchVehicles = useCallback(async () => {
    try {
      const { data } = await axios.get(`${SERVER_ADDRESS}/api/vehicles/mapVehicles2`);
      processVehiclesData(data);
    } catch (error) {
      console.error("Error fetching vehicles:", error);
    }
  }, []);

  
  const processVehiclesData = useCallback((data) => {
    const processedVehicles = data.vehicles.map(convertMapVehicle);
    setVehicles(processedVehicles);
    setUserTags(processUserTags(data.userTags));
    setFilteredVehicles(data.filteredVehicles.map(v => {
      let processed = convertMapVehicle(v);
      processed.hostContract.dooriveTitle = '이미 매칭된 차량입니다.';
      processed.hostContract.useTypes = sortBy(v.hostContract.useTypes, (u) => u.price);
      return {...processed, type: 'matched'};
    }));
  }, []);

  const processUserTags = useCallback((userTags) => {
    return userTags.map(u => ({
      ...u,
      mapId: `u${u.id}`,
      type: 'user',
      latitude: u.lat,
      longitude: u.lng
    }));
  }, []);

  const filterAndSortVehicles = useCallback((vehicles) => {
    let newVehicles = [...vehicles];
    newVehicles = applyFilters(newVehicles, filterForm.filters.split(','));
    newVehicles = applySearch(newVehicles);
    newVehicles = applySorting(newVehicles);
    return newVehicles;
  }, [filterForm, path]);

  const applySearch = useCallback((vehicles) => {
    let searcher = new Hangul.Searcher(filterForm.searchText.replace(' ', '').toLowerCase());
    return vehicles.filter((v) => searcher.search(`${v.brand.replace(' ', '').toLowerCase()}${v.model.replace(' ', '').toLowerCase()}`) >= 0);
  }, [filterForm.searchText]);

  const applySorting = useCallback((vehicles) => {
    const sorters = [
      {id: "가까운거리순", predicate: (vs) => sortBy(vs, (v) => calcDistance(v.latitude, v.longitude, path.latitude, path.longitude))},
      {id: "가격낮은순", predicate: (vs) => sortBy(vs, (v) => getPrice(v))},
      {id: "가격높은순", predicate: (vs) => sortBy(vs, (v) => getPrice(v)).reverse()},
      {id: "관심높은순", predicate: (vs) => sortBy(vs, (v) => v.likes.length).reverse()},
      {id: "최신연식순", predicate: (vs) => sortBy(vs, (v) => v.year).reverse()},
    ];
    const sorterObj = find(sorters, s => s.id === filterForm.sorter);
    return sorterObj.predicate(vehicles);
  }, [path, filterForm.sorter]);

  const applySortingUserTags = useCallback((userTags) => {
    const sorterObj = {id: "가까운거리순", predicate: (us) => sortBy(us, (u) => calcDistance(u.latitude, u.longitude, path.latitude, path.longitude))};
    return sorterObj.predicate(userTags.filter(u => String(u.id) !== String(tokenInfo?.id)));
  }, [path, tokenInfo]);

  const getCurrentPosition = useCallback(() => {
    openAddress(callbackAddress);
  }, []);

  const callbackAddress = useCallback((address, longitude, latitude) => {
    dispatch(pathAction.update({
      address: address,
      longitude: longitude,
      latitude : latitude,
      isGps : false,
    }));
    onFilterChange({id: 'sorter', value: '가까운거리순'});

    if (!isEmpty(tokenInfo)) {
      updateUser({
        variables: {
          id: tokenInfo.id,
          data: {
            lng: longitude,
            lat: latitude,
            address: `${address}\t`,
          }
        }
      }).then(res => {
      });
    };
  }, [tokenInfo]);

  const getUseTypes = useCallback(() => {
    let tags = filterForm.filters.split(',').filter(t => ["호스트우선형","요일지정형","게스트우선형"].includes(t));
    return tags.map(t => getUseTypeId(t));
  }, [filterForm.filters]);

  const getPrice = useCallback((v) => {
    let useTypes = getUseTypes();
    if (useTypes && useTypes.length) {
      let minPrice = null;
      for (let useType of useTypes) {
        const u = find(v.hostContract.useTypes, (u) => u.id === useType);
        if (u) {
          if (minPrice === null || minPrice > u.price) {
            minPrice = u.price;
          }
        }
      }
      return minPrice;
    }
    return Math.min(...v.hostContract.useTypes.map(u => u.price));
  }, [filterForm.filters]);

  const myVehicles = useMemo(() => userInfo?.contracts
    .filter(c => c.endDate === null || moment.tz('Asia/Seoul').format('YYYY-MM-DD') <= c.endDate)
    .filter(c => !c.vehicle.draftVehicle)
    .filter(c => c.contractType === 'DOORICAR_WAITING')
    .map(c => convertDooricar(c.vehicle, false, true))
    .filter(v => v.hostUser?.id === tokenInfo?.id), [userInfo, tokenInfo]);

  const myUserTag = useMemo(() => {
    if (!userTags || !tokenInfo) return null;

    const myTag = userTags.find(u => 
      String(u.id) === String(tokenInfo.id)
      && u.matching_profile
      && u.matching_profile["원하는 차량"]
    );

    return myTag || null;
  }, [userTags, tokenInfo]);

  const visibleMyVehicles = useMemo(() => myVehicles && myVehicles.length > 0, [myVehicles]);

  return (
    <StyledVehicleListPage>
      <AddressRequest onClick={getCurrentPosition}>
        <img src="/vehicle/my_position.svg"/>
        {needPath ? '이곳을 눌러 주소를 설정하면 가까운 차량을 먼저 볼 수 있습니다.' : `${path.address} 에서 가까운 차량 순으로 표시됩니다.`}
      </AddressRequest>

      <EventBanner>
        <EventSwipe images={[{link: '/guide', url: '/event/guest_banner2.png'}]} />
      </EventBanner>

      <VehicleWanted tags={filterForm.filters} setTags={(v) => onFilterChange({id: 'filters', value: v})} open={filterOpen} onClose={() => setFilterOpen(false)}/>

      <div style={{display: 'flex', flexDirection: 'column', gap: '20px', padding: '10px 0 22px'}}>
        {myUserTag && <div style={{padding: '0 20px'}}>
          <h4 onClick={() => setMyUserTagCollapse(!myUserTagCollapse)} style={{display: 'flex', gap: '4px', alignItems: 'center', cursor: 'pointer', fontSize: '18px', fontWeight: '700', lineHeight: '140%'}}>내 매칭 프로필 보기
          <img style={{width: '25px', height: '25px'}} src={myUserTagCollapse? "/vehicle/chevron_down.svg": "/vehicle/chevron_right.svg"} />
          </h4>
          {myUserTagCollapse && <div style={{marginTop: '10px'}}><ListGuest user={myUserTag} /></div>}
        </div>}
        {visibleMyVehicles && myVehicles.length > 1 && <VehicleList title="내 차량" vehicles={myVehicles} filters={""} noLink={true} />}
        {visibleMyVehicles && myVehicles.length === 1 && <div style={{padding: '10px 24px'}}>
          <h3>내 차량</h3>
          <div style={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            {myVehicles.map(v => <TinyVehicle key={v.id} vehicle={v} hideManner={true} imageHide={false} showBulk={true} />)}
          </div>
        </div>}
        {!myUserTag && !visibleMyVehicles && <div style={{padding: '0 24px'}} onClick={() => navigate('/my/profile')}>
            <h4 onClick={() => setMyUserTagCollapse(!myUserTagCollapse)} style={{display: 'flex', gap: '4px', alignItems: 'center', cursor: 'pointer', fontSize: '18px', fontWeight: '700', lineHeight: '140%'}}>내 매칭 프로필</h4>
            <div style={{
              marginTop: '10px',
              padding: '16px 20px',
              textAlign: 'center', 
              fontSize: '16px',
              fontWeight: '700', 
              lineHeight: '140%',
              backgroundColor: '#F8F8F8',
              borderRadius: '8px',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
              color: '#333333',
              cursor: 'pointer',
              transition: 'transform 0.2s ease',
              ':hover': {
                transform: 'translateY(-2px)'
            }
        }}>
            내 프로필을 등록하고<br/>
            호스트의 매칭제안을 받아보세요.
            </div>
        </div>}
        {visibleMyVehicles && showVehicle && userTags && <GuestList title="내 근처의 게스트" userTags={applySortingUserTags(userTags)}  />}
        {showVehicle && vehicles && <VehicleList title="게스트 우선형 차량" description="게스트가 공유기간 동안 전일 이용하는 방식입니다." vehicles={applySorting(vehicles)} filters={["게스트우선형"].join(',')} />}
        {showVehicle && vehicles && <VehicleList title="100만원 이하 외제차" vehicles={applySorting(vehicles)} filters={["외제차", "30만원 이하", "30만원 ~ 60만원", "60만원 ~ 100만원"].join(',')} />}
        {!visibleMyVehicles && showVehicle && userTags && <GuestList title="내 근처의 게스트" userTags={applySortingUserTags(userTags)}  />}
      </div>

      {/* 지도에서 보기 버튼 */}
      {window?.kakao?.maps && vehicles && userTags && (!tokenInfo || userInfo) && !mapOpen && (
        <div className="map-btn" onClick={() => setMapOpen(true)}>
          지도에서 보기
        </div>
      )}

      {/* VehicleMap 컴포넌트 */}
      {window?.kakao?.maps && vehicles && userTags && (!tokenInfo || userInfo) && (
        <VehicleMap 
          vehicles={vehicles} 
          filteredVehicles={filteredVehicles}
          filters={filterForm.filters}
          onFilterChange={onFilterChange}
          guestVehicles={[]} 
          guestTags={userTags} 
          setMapBound={setMapBound} 
          open={mapOpen} 
          onClose={() => {
            setMapOpen(false);
            setShowVehicle(true);
            if (window.history.replaceState) {
              const newUrl = window.location.href.split('#')[0];
              window.history.replaceState(null, null, newUrl);
            }
          }}
        />
      )}
    </StyledVehicleListPage>
  );
};

const DescriptionBox = styled.div`
  word-break: keep-all;
  font-size: 16px;
  line-height: 140%;
  margin: 4px 22px 8px;
  padding: 8px;
  color: #444444;
  font-weight: 500;
  background-color: #DDDDDD;
  text-align: center;
  border-radius: 4px;
`

const StyledVehicleListPage = styled.div`
  font-family: 'Pretendard', sans-serif;
  
  .map-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #5ECDC0;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
    border-radius: 999px;
    color: white;
    cursor: pointer;
    padding: 11px 16px;
    position: fixed;
    bottom: 10px;
    z-index: 50;
    left: 50%;
    transform: translateX(-50%);
  }
`

export default VehicleMainPage;

const EventBanner = styled.div`
    margin: 0 22px;
`

const GuestList = ({title, description, userTags, filters}) => {
  const filteredGuest = useMemo(() => userTags.filter(u => u.address && u.longitude && u.latitude
    && u.matching_profile
    && u.matching_profile["원하는 차량"]
  ).slice(0, 5), [userTags]);

  return <RowList>
    <div className="header">
      <div className="title">
        <Link to={`/user${filters? `?filters=${filters}`: ''}`}><h4>{title}</h4></Link>
        {description && <HelpBtn contents={description} />}
      </div>
      <Link to={`/user${filters? `?filters=${filters}`: ''}`}><div className="actions">전체보기</div></Link>
    </div>
    <div className="list">
      <StyledListSwiper>
        {filteredGuest.map((u) => <SwiperSlide><RowGuest key={u.id} user={u} imageHide={true} /></SwiperSlide>)}
      </StyledListSwiper>
    </div>
  </RowList>
}

const VehicleList = ({title, description, vehicles, filters, noLink}) => {
  const filteredVehicles = useMemo(() => applyFilters(vehicles, filters).slice(0, 5), [vehicles, filters]);
  const getUseTypes = useCallback(() => {
    let tags = filters.split(',').filter(t => ["호스트우선형","요일지정형","게스트우선형"].includes(t));
    return tags.map(t => getUseTypeId(t));
  }, [filters]);

  return <RowList>
    <div className="header">
      <div className="title">
        {noLink ? <h4>{title}</h4> : <Link to={`/vehicle${filters? `?filters=${filters}`: ''}`}><h4>{title}</h4></Link>}
        {description && <HelpBtn contents={description} />}
      </div>
      {!noLink && <Link to={`/vehicle${filters? `?filters=${filters}`: ''}`}><div className="actions">전체보기</div></Link>}
    </div>
    <div className="list">
      <StyledListSwiper>
        {filteredVehicles.map((v) => <SwiperSlide><RowVehicle key={v.id} vehicle={v} hideManner={true} imageHide={true} useTypes={getUseTypes()} showBulk={true}/></SwiperSlide>)}
      </StyledListSwiper>
    </div>
  </RowList>
}

const ListSwiper = ({className, children}) => {
  return <div className={className}>
    <Swiper spaceBetween={10} slidesPerView={"auto"}>
      {children}
    </Swiper>
  </div>
}

const StyledListSwiper = styled(ListSwiper)`
  overflow: hidden;
  .swiper-slide {
    width: 150px;
  }
`

const RowList = styled.div`
  padding: 0 22px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .title {
    font-weight: 500;
    font-size: 18px;
    gap: 8px;
    display: flex;
    align-items: center;
    line-height: 140%;
  }

  .list {
    flex: 1;
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
`
