import {min} from "lodash";
import moment from "moment-timezone";

export const calcDistance = (lat1, lon1, lat2, lon2) => {
    if ((lat1 == lat2) && (lon1 == lon2)) {
        return 0;
    }
    else {
        let radlat1 = Math.PI * lat1/180;
        let radlat2 = Math.PI * lat2/180;
        let theta = lon1-lon2;
        let radtheta = Math.PI * theta/180;
        let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        if (dist > 1) {
            dist = 1;
        }
        dist = Math.acos(dist);
        dist = dist * 180/Math.PI;
        dist = dist * 60 * 1.1515;
        dist = dist * 1.609344
        return dist;
    }
}

export const calcOil = (price) => {
    const basePrice = 1700 / 10;  // 기본 거리당 비용 (170원/km)
    const additionalPrice = Math.floor(price / 500000);  // 50만원당 1원 추가
    return basePrice + additionalPrice;
}

export const calcTax = (year, displacement) => {
    if (displacement && year) {
        let 세액;
        const 배기량 = Number(displacement);
        let 기준세액;
        if (배기량 === 0) {
            기준세액 = 130000;
        } else if (배기량 <= 1000) {
            기준세액 = 배기량 * 104;
        } else if (배기량 <= 1600) {
            기준세액 = 배기량 * 182;
        } else if (배기량 <= 2000) {
            기준세액 = 배기량 * 260;
        } else if (배기량 <= 2500) {
            기준세액 = 배기량 * 260;
        } else {
            기준세액 = 배기량 * 260;
        }

        const 연차 = moment.tz('Asia/Seoul').year() - Number(year) + 1;
        if (배기량 === 0) {
            세액 = 기준세액;
        } else {
            if (연차 <= 2) {
                세액 = 기준세액;
            } else {
                const 경감 = Math.min((연차 - 2) * 0.05, 0.5);
                세액 = 기준세액 * (1 - 경감);
            }
        }
        세액 = Math.round(세액 / 10) * 10;
        return 세액;
    }
    return null;
}

export const calcDooriTax = (vehicle) => {
    if (!vehicle.priceTax) {
        return null;
    }
    const price = (vehicle.priceTax) / 2 / 12;
    return Math.round(price / 1000) * 1000;
}

export const isCollision = (bounds, v) => {
    const longitude = v.longitude ?? v.lng;
    const latitude = v.latitude ?? v.lat;
    return bounds.ha <= longitude && bounds.oa >= longitude && bounds.qa <= latitude && bounds.pa >= latitude;
}

export const isAvailableContract = (contract) => {
    if (contract?.vehicle?.draftVehicle) {
        return false;
    }
    if (!contract.endDate) {
        return true;
    }
    const endDate = moment.tz(contract.endDate, 'Asia/Seoul');
    const now = moment.tz('Asia/Seoul');
    now.hour(0);
    now.minute(0);
    now.second(0);
    now.millisecond(0);
    if (now <= endDate) {
        return true;
    }
    return false;
}

export const isMatchedContract = (contract) => {
    if (contract?.contractType === 'SOLOCAR') {
        return false;
    }
    let targetDate = moment.tz('Asia/Seoul');
    const startDate = moment.tz(contract?.startDate, 'Asia/Seoul');
    if (targetDate < startDate) {
        targetDate = startDate;
    }
    const results = [];
    for (let c of contract?.vehicle?.contracts) {
        if (!c.startDate || moment.tz(c.startDate, 'Asia/Seoul') <= moment.tz(targetDate, 'Asia/Seoul')) {
            if (!c.endDate || moment.tz(c.endDate, 'Asia/Seoul') >= moment.tz(targetDate, 'Asia/Seoul')) {
                results.push(c);
            }
        }
    }
    return results.length > 1;
}

export const fillUsedCarInfo = (price, year) => {
    const result = {};

    let 연식 = Number(year);
    const 가격 = Math.max(Number(price), 3000000);
    if (!연식) {
        연식 = moment.tz('Asia/Seoul').year();
    }

    const 보유년수 = moment.tz('Asia/Seoul').year() - 연식;
    const 신차가격 = 가격 / Math.pow(1 - 0.13, 보유년수)

    const 감가 = 가격 * 0.15;
    const 월간감가 = 감가 / 12;

    const 정비율 = 0.01 + (0.002 * 보유년수);
    const 연간정비비 = 신차가격 * 정비율;
    const 월간정비비 = 연간정비비 / 12;

    const 연간보험료 = 800000 + (price * 0.02);
    const 월간보험료 = 연간보험료 / 12;

    result.월간감가분담액 = 월간감가 / 2;
    result.월간정비분담액 = 월간정비비 / 2;
    result.월간보험분담액 = 월간보험료 / 2;

    return result;
}

export const calcFirstMonthlyCharge = (charge, startDate, endDate) => {
    let paymentMoment = moment.tz(startDate, 'Asia/Seoul');
    let startMoment = moment.tz(startDate, 'Asia/Seoul');
    let endMoment = moment.tz(endDate, 'Asia/Seoul');

    if (!startDate || !paymentMoment.isValid()) {
        return charge;
    }

    const totalCount = paymentMoment.daysInMonth();
    let useCount = totalCount;
    useCount -= startMoment.date() - 1;

    if (endDate && endMoment.isValid() && endMoment.year() === paymentMoment.year() && endMoment.month() === paymentMoment.month()) {
        useCount -= totalCount - endMoment.date();
    }

    if (useCount !== totalCount) {
        const dayRate = useCount / totalCount;
        return Math.floor(charge * dayRate / 100) * 100; // 백원 이하 절사
    }
    return charge;
}