import styled, {css} from "styled-components";
import {FormComponent, PhotoComponent} from "./FormComponent";
import {useForm} from "../../../toolbox";
import React, {useState} from "react";
import {Dialog, Drawer} from "@mui/material";
import {DrawerWrap} from "./BottomDrawer";
import {PositiveBtn} from "./BottomNavigator";

const Safety = ({form, onChange}) => {
    const { form: openForm, onChange: onChangeOpen} = useForm({
        a: false,
        b: false,
        c: false,
        d: false,
        e: false,
        f: false,
        g: false,
        h: false,
        i: false,
    });
    const [open, setOpen] = useState(false)

    return <>
        <Wrap>
            <div className="forms">
                <div>
                    <h1>두리카 안전장치 확인</h1>
                    <h3>마지막 단계에요!</h3>
                </div>
                <div>
                    <h2>두리카만의 안전장치</h2>
                    <TextBox onClick={() => onChangeOpen({id: 'a', value: !openForm.a})}>
                        <div className="title">
                            <span className="title">1억 한도 100% 선보상</span>
                            <img src={`/vehicle/chevron_${openForm.a? 'up': 'down'}_18px.svg`}/>
                        </div>
                        {openForm.a &&
                            <div style={{marginTop:'16px'}}>
                                <p>
                                    게스트가 차량을 도난, 횡령, 손괴하는 등 자동차보험으로 보장되지 않는 범죄 유형의 손해가 발생할 경우, 두리카에서 최대 1억원까지 선보상해드립니다.<br/>
                                    <br/>
                                    아울러, 두리카의 협력사인 ‘법무법인 웨이브’에서 필요한 모든 민형사상 조치를 대리하며 관련 비용을 두리카에서 전부 부담합니다.
                                </p>
                            </div>
                        }
                    </TextBox>

                    <TextBox onClick={() => onChangeOpen({id: 'b', value: !openForm.b})}>
                        <div className="title">
                            <span className="title">사고 시 위로금 보장</span>
                            <img src={`/vehicle/chevron_${openForm.b? 'up': 'down'}_18px.svg`}/>
                        </div>
                        {openForm.b &&
                            <div style={{marginTop:'16px'}}>
                                <p>
                                    게스트가 사고를 내면 보험료가 할증되고 차량이 감가되어 호스트에게 손해가 발생할 수 있습니다. 따라서 게스트는 사고 발생 시 보험료 할증, 감가상각, 수리기간 동안 차를 이용하지 못하는 손해 등에 대한 포괄적인 손해보상으로 충분한 액수의 ‘위로금’을 지급해야합니다.<br/>
                                    (세부금액 기준은 공동사용규칙 참조)
                                </p>
                            </div>
                        }
                    </TextBox>

                    <TextBox onClick={() => onChangeOpen({id: 'c', value: !openForm.c})}>
                        <div className="title">
                            <span className="title">미납 시 결제 보증</span>
                            <img src={`/vehicle/chevron_${openForm.c? 'up': 'down'}_18px.svg`}/>
                        </div>
                        {openForm.c &&
                            <div style={{marginTop:'16px'}}>
                                <p>게스트가 월이용료, 위로금 등 결제금액 납부를 지연하거나 거부할 경우, 두리카에서 전액을 호스트에게 대신 지급하여 미납금이 발생하지 않도록 100% 보장해드립니다.</p>
                            </div>
                        }
                    </TextBox>

                    <TextBox onClick={() => onChangeOpen({id: 'd', value: !openForm.d})}>
                        <div className="title">
                            <span className="title">잔기스 무상수리</span>
                            <img src={`/vehicle/chevron_${openForm.d? 'up': 'down'}_18px.svg`}/>
                        </div>
                        {openForm.d &&
                            <div style={{marginTop:'16px'}}>
                                <p>공유기간 중에 잔기스(클리어 층 손상으로 파우더 처리 가능한 손상)가 발생할 경우, 두리카에서 협력 정비업체를 통해 무상으로 제거해드립니다. (년 1회 제공)</p>
                            </div>
                        }
                    </TextBox>

                    <TextBox onClick={() => onChangeOpen({id: 'e', value: !openForm.e})}>
                        <div className="title">
                            <span className="title">안전운행 모니터링 시스템</span>
                            <img src={`/vehicle/chevron_${openForm.e? 'up': 'down'}_18px.svg`}/>
                        </div>
                        {openForm.e &&
                            <div style={{marginTop:'16px'}}>
                                <p>
                                    차량마다 지급되는 ‘두리플러그’로 주행거리를 측정하는 것은 물론, 급출발, 급가속, 급정거, 과속 등 무리한 운전 습관을 적발할 수 있습니다.<br/>
                                    <br/>
                                    게스트의 불량한 운전 습관이 발견되면 호스트에게 통지되며, 호스트는 1차 경고 후 동일 패턴 반복시 호스트는 1개월 치 월이용료를 몰수하고 공유를 즉시 중단할 수 있습니다.
                                </p>
                            </div>
                        }
                    </TextBox>

                    <TextBox onClick={() => onChangeOpen({id: 'f', value: !openForm.f})}>
                        <div className="title">
                            <span className="title">1:1 밀착 관리</span>
                            <img src={`/vehicle/chevron_${openForm.f? 'up': 'down'}_18px.svg`}/>
                        </div>
                        {openForm.f &&
                            <div style={{marginTop:'16px'}}>
                                <p>차량마다 담당 책임매니저가 단톡방에 상주하면서 모든 운영을 처음부터 끝까지 책임집니다.</p>
                            </div>
                        }
                    </TextBox>

                    <TextBox onClick={() => onChangeOpen({id: 'g', value: !openForm.g})}>
                        <div className="title">
                            <span className="title">긴급차량 지원금</span>
                            <img src={`/vehicle/chevron_${openForm.g? 'up': 'down'}_18px.svg`}/>
                        </div>
                        {openForm.g &&
                            <div style={{marginTop:'16px'}}>
                                <p>일정충돌로 인하여 차를 사용하지 못할 경우, 쏘카 대여금액의 30%를 지원해드립니다.<br/>(분기당 1회, 연간 4일)</p>
                            </div>
                        }
                    </TextBox>

                    <TextBox onClick={() => onChangeOpen({id: 'h', value: !openForm.h})}>
                        <div className="title">
                            <span className="title">보증금과 벌칙금 제도</span>
                            <img src={`/vehicle/chevron_${openForm.h? 'up': 'down'}_18px.svg`}/>
                        </div>
                        {openForm.h &&
                            <div style={{marginTop:'16px'}}>
                                <p>
                                    게스트가 납부하는 보증금과 공동사용규칙 위반시 부과되는 벌칙금은 상대방으로 하여금 불미스러운 행동을 하지 않도록 사전에 억제하는 예방적 기능을 합니다.
                                </p>
                            </div>
                        }
                    </TextBox>
                    
                    <TextBox onClick={() => onChangeOpen({id: 'i', value: !openForm.i})}>
                        <div className="title">
                            <span className="title">1% 지분 등기이전</span>
                            <img src={`/vehicle/chevron_${openForm.i? 'up': 'down'}_18px.svg`}/>
                        </div>
                        {openForm.i &&
                            <div style={{marginTop:'16px'}}>
                                <p>
                                    매칭이 이뤄지면 호스트는 소유권 지분 중 일부(1%)를 이전하여 게스트를 공유자로 등록해야합니다. 이는 두리카 서비스가 여객자동차운수사업법에서 금지하고 있는 유상 운송대여에 해당하지 않는다는 점을 명확히 하기 위한 조치입니다.<br/><br/>
                                    단, 공유시작일로부터 6개월 경과 시점에 이전등기가 필요하며 그 전까지는 등기를 유예할 수 있습니다.<br/><br/>
                                    지분이전에 필요한 서류는 두리카에서 직접 작성하여 발송해드리며, 인근 구청(서울 외 지역은 차량등록사업소)에 혼자 방문하여 간단하게 처리 가능합니다. (이전 비용 약 3만원 내외)<br/><br/>
                                    ※ 호스트의 차량이 렌터카, 리스차인 경우 지분이전이 불필요합니다.
                                </p>
                            </div>
                        }
                    </TextBox>
                </div>


                {/* <div>
                    <h2>1% 지분 등기이전</h2>
                    <TextBox>
                        <p>
                            매칭이 이뤄지면 6개월 내에 소유권 지분 중 일부(1%)를 이전하여 게스트를 공유자로 등록해야합니다. 이는 두리카 서비스가 여객자동차운수사업법에서 금지하고 있는 유상 운송대여에 해당하지 않는다는 점을 명확히 하기 위한 불가피한 조치입니다.<br/>
                            <br/>
                            지분이전에 필요한 서류는 두리카에서 직접 작성하여 발송해드리며, 인근 구청(서울 외 지역은 차량등록사업소)에 혼자 방문하여 처리 가능합니다. (이전 비용 약 3만원 내외)<br/>
                            <br/>
                            게스트에게 1% 지분이전을 하지 않는 경우, 수익을 현금으로 인출할 수 없고 ‘두리케어카드’를 통하여 주유, 정비 등 차량 유지비 결제 용도로 제약 없이 사용할 수 있습니다.<br/>
                            <br/>
                            (렌터카, 리스차는 지분이전이 불필요합니다.)
                        </p>
                    </TextBox>
                </div> */}
            </div>
        </Wrap>
        <SafetyDialog open={open} onClose={() =>setOpen(false)} />
    </>
}

const Wrap = styled.div`
  padding: 32px 20px 100px;
  h1 {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
  }

  h2 {
    color: var(--gray-scale-black, #212121);
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
  }
  
  h3 {
    margin-top: 4px;
    color: #33CCC1;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
  }
  
  > .forms {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
`

const TextBox = styled.div`
  margin-top: 16px;
  padding: 16px 12px;
  border-radius: 8px;
  background: var(--gray-scale-gray-200, #F5F5F5);

  color: var(--gray-scale-gray-700, #939393);
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */

  ${(props) => props.onClick && css`
    cursor: pointer;
  `}
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--gray-scale-black, #212121);
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 157.143% */
  }
  .thin {
    font-weight: 400;
  }
  .link {
    font-weight: 600;
    text-decoration-line: underline;
    cursor: pointer;
  }
`

const SafetyDialog = ({open, onClose}) => {
    return <Dialog open={open} onClose={onClose} fullWidth={true}>
        <DrawerWrap>
            <h1>1% 지분 등기이전</h1>
            <h2 style={{marginTop: '16px'}}>[게스트에게 1% 이전등기 전]</h2>
            <p style={{marginTop: '8px'}}>・ 게스트 결제금액이 호스트 월렛에 d포인트로 적립 (1p = 1원)</p>
            <p style={{marginTop: '8px'}}>・ 호스트 월렛과 연동된 두리케어카드로 주유비, 정비비 등 결제 가능</p>
            <p style={{marginTop: '8px'}}>・ 공유기간 종료 후에도 두리케어카드 사용 가능</p>
            <h2 style={{marginTop: '20px'}}>[게스트에게 1% 이전등기 후]</h2>
            <p style={{marginTop: '8px'}}>・ 게스트 결제금액이 호스트 월렛에 원화로 적립 (그동안 누적된 d포인트는 원화로 자동전환)</p>
            <p style={{marginTop: '8px'}}>・ 호스트 월렛과 연동된 두리케어카드로 주유비, 정비비 등 결제 가능</p>
            <p style={{marginTop: '8px'}}>・ 원화 출금 상시 가능</p>
            <div style={{marginTop: '20px', marginBottom: '20px', borderBottom: '1px solid #D9D9D9'}}></div>
            <h2>[등기이전 방법]</h2>
            <p style={{marginTop: '8px'}}>호스트님께서 혼자 구청 자동차등록민원실에 방문하여 간단하게 진행할 수 있습니다. 자세한 절차는 담당 매니저가 안내드리며 아래 준비물 3가지만 준비해주세요!</p>
            <p style={{marginTop: '8px'}}>자동차공유약정서 / 자동차등록증 원본 / 호스트의 신분증</p>
            <p style={{marginTop: '8px'}}>・ 자동차공유약정서는 온라인 계약 방식(모두싸인)으로 제공해드립니다.</p>
            <p style={{marginTop: '8px'}}>・ 주소지 관할 구청이 아니어도 괜찮습니다. 가까운 곳으로 방문해주세요!</p>
            <PositiveBtn style={{marginTop: '16px'}} onClick={onClose}>확인</PositiveBtn>
        </DrawerWrap>
    </Dialog>
}


export default Safety;
