import React, {useCallback, useEffect, useRef, useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import styled, {css} from "styled-components";
import {down} from "styled-breakpoints";
import {HamburgerMenu} from "./HamburgerMenu";
import {Badge} from "@mui/material";
import {NotiPopper} from "./NotiPopper";
import {useDispatch, useSelector} from "react-redux";
import {guestNotiAction} from "../redux/guestNotiReducer";
import {countBy, filter, isEmpty} from "lodash";
import {filterNotifications, getNotificationBadgeCount} from "../toolbox/logic";
import {useMutation} from "@apollo/client";
import {UPDATE_USER} from "../query/userQuery";
import {authAction} from "../redux/authReducer";
import moment from "moment-timezone";
import {openKakaoChat, parseJwt} from "../toolbox";
import {openChannelTalk} from "../toolbox/channelTalk";
import {TagDialog} from "../page/my/component/TagDialog";
import {getLoginLink} from "../toolbox/format";
import axios from "axios";
import {SERVER_ADDRESS} from "../index";

const HeaderSpacing = styled.div`
    padding: 25px;
`

const HeaderWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  z-index: 4;
  box-shadow: 0 6px 6px -3px rgba(0, 0, 0, 0.07);
  padding: 0px 20px;
  transition: ease-in-out 0.3s;
  width: 100%;
  max-width: 450px;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  ${down('pc')} {
    box-shadow: none;
  }

  ${(props) => props.fixed && css`
    position: fixed;
    max-width: 450px;
    background: #ffffff;
    z-index: 4;
    ${down('pc')} {
      box-shadow: 0 6px 6px -3px rgba(0, 0, 0, 0.07);
    }`
  }
`;

const LogoWrap = styled.div`
  cursor: pointer;
  max-width: 110px;
  ${down('pc')} {
    max-width: 90px;
  }
`;

const Logo = styled.img.attrs(props => ({
    alt: 'Logo',
}))`
  width: 100%;
  height: 100%;
  object-fit: contain;
`

const Icons = styled.div`
  display: flex;
`
const IconWrap = styled.div`
  width: 24px;
  height: 24px;
  cursor: pointer;
  color: #242628;
  font-size: 14px;
  margin-left: 8px;
  margin-right: 8px;
`
const TextWrap = styled.div`
  display: flex;
  align-items: center;
  //width: 24px;
  height: 24px;
  cursor: pointer;
  //color: #33CCC1;
  font-size: 14px;
  margin-left: 8px;
  margin-right: 8px;
  white-space: nowrap;
`

const Icon = styled.img.attrs(props => ({
    alt: `Menu`,
}))`
  width: 100%;
  height: 100%;
  object-fit: contain;
`

const Header = ({onLogo}) => {
    const hasLoggedIn = useSelector(({auth}) => !isEmpty(auth.tokenInfo));
    const guestNotiRead = useSelector(({guestNoti}) => guestNoti.read);
    const userInfo = useSelector(({user}) => user.user);
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);

    const [isFixed, setIsFixed] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const [tagOpen, setTagOpen] = useState(false);
    const [notiOpen, setNotiOpen] = useState(false);
    const [notiRef, setNotiRef] = useState();
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [chatRooms, setChatRooms] = useState(null);
    const pollInterval = useRef(null);

    const [updateUser] = useMutation(UPDATE_USER);

    const onScroll = useCallback(() => {
        if (window.scrollY > 60) {
            setIsFixed(true)
        } else {
            setIsFixed(false)
        }
    }, []);

    const refetch = () => {
        if (!isEmpty(tokenInfo)) {
            axios.post(SERVER_ADDRESS + '/api/chat-rooms/findMyRooms', {}).then((res) => {
                setChatRooms(res.data);
            });
        }
    }

    useEffect(() => {
        refetch();
    }, [tokenInfo]);


    const startPolling = useCallback((interval) => {
        clearInterval(pollInterval.current);
        pollInterval.current = setInterval(() => {
            refetch();
        }, interval);
    }, [refetch, tokenInfo]);

    const handleVisibilityChange = () => {
        if (document.hidden) {
            startPolling(60000);
        } else {
            document.title = `두리카`;
            startPolling(2000);
        }
    };

    useEffect(() => {
        startPolling(2000);
        document.addEventListener('visibilitychange', handleVisibilityChange);
        return () => {
            clearInterval(pollInterval.current);
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [startPolling]);


    const getUnread = (room) => {
        const lastMessage = room?.lastMessage?.sentAt;
        if (lastMessage) {
            const read = room.readAt?.[tokenInfo.id];
            if (read && lastMessage <= read) {
                return false;
            }
            return true;
        }
        return false;
    }

    const getBadgeCount = () => {
        if (hasLoggedIn && chatRooms) {
            return filter(chatRooms, r => getUnread(r)).length;
            // return getNotificationBadgeCount(userInfo);
            // const noti = filterNotifications(userInfo?.notifications);
            // const res = countBy(noti, (n) => n.createdAt > userInfo.notification_readed_at);
            // return res.true;
        } else {
            return guestNotiRead? 0: 1;
        }
    }

    const updateNotiReadedAt = () => {
        const noti = filterNotifications(userInfo?.notifications);
        if (noti && noti.length > 0) {
            const payload = {
                variables: {
                    id: userInfo.id,
                    data: {
                        notification_readed_at: noti[0].createdAt,
                    }
                }
            };
            updateUser(payload).then(res => {
                dispatch(authAction.refresh());
            });
        }
    }

    useEffect(() => {
        window.addEventListener("scroll", onScroll)
        return () => {
            window.removeEventListener("scroll", onScroll)
        }
    }, []);

    return (
        <>
            <HeaderWrap fixed={isFixed}>
                {onLogo ?
                    <LogoWrap onClick={onLogo}>
                        <Logo src="/layout/logo_dooricar.png"/>
                    </LogoWrap>:
                    <Link to="/">
                        <LogoWrap>
                            <Logo src="/layout/logo_dooricar.png"/>
                        </LogoWrap>
                    </Link>}
                <Icons>
                    {/*<Link to={(location.pathname === '/host' || hasLoggedIn)? "/vehicle/write":"/host"}>*/}
                    {/*    <TextWrap>내차등록하기</TextWrap>*/}
                    {/*</Link>*/}
                    <a href={(location.pathname === '/host' || hasLoggedIn)? "/vehicle/write":"/host"}>
                        <TextWrap>내차등록하기</TextWrap>
                    </a>
                    {/*<TextWrap onClick={() => {*/}
                    {/*    openKakaoChat("header_chat", null);*/}
                    {/*    // openChannelTalk("header_chat", null, `${window.location.href} 페이지에서 문의가 있어요!`)*/}
                    {/*}}>차량상담</TextWrap>*/}
                    <TextWrap onClick={() => {
                        // openKakaoChat("header_chat", null);
                        if (!hasLoggedIn) {
                            alert('로그인이 필요합니다.');
                            const to = getLoginLink(location);
                            navigate(to);
                            return;
                        }
                        setTagOpen(true);
                        window.fbq('trackCustom', 'LeeSubin');
                        // openChannelTalk("header_chat", null, `${window.location.href} 페이지에서 문의가 있어요!`)
                    }}>차량추천받기</TextWrap>
                    <IconWrap onClick={(e) => {
                        setNotiRef(e.target);
                        setNotiOpen((prev) => !prev);
                        if (hasLoggedIn) {
                            updateNotiReadedAt();
                        } else {
                            dispatch(guestNotiAction.read());
                        }
                    }}>
                        <Badge className="nav-item_icon" badgeContent={getBadgeCount()} max={9} color={"primary"}>
                            <Icon src="/layout/noti.svg" />
                        </Badge>
                    </IconWrap>
                    <IconWrap onClick={() => setMenuOpen(() => true)}>
                        <Icon src="/layout/menu.svg" />
                    </IconWrap>
                </Icons>
            </HeaderWrap>
            <TagDialog open={tagOpen} onClose={() => setTagOpen(false)} />
            <HamburgerMenu open={menuOpen} onClose={() => setMenuOpen(() => false)}/>
            <NotiPopper open={notiOpen} onClose={() => setNotiOpen(() => false)} anchorEl={notiRef} chatRooms={chatRooms}/>
            {isFixed && <HeaderSpacing />}
        </>
    )
}

export default Header;
