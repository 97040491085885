import moment from "moment";

export const API_DATE_FORM = "YYYY-MM-DD";

export const getNow = () => {
    return moment().format(API_DATE_FORM);
};

export const reformGMT = (gmt, outputForm = API_DATE_FORM) => {
    return moment(gmt).format(outputForm);
};

export const reformDate = (date, inputForm, outputForm = API_DATE_FORM) => {
    return moment(date, inputForm).format(outputForm);
};

export const isLater = (a, b, inputDateForm = API_DATE_FORM) => {
    a = reformDate(a, inputDateForm);
    b = reformDate(b, inputDateForm);
    return moment(a).isAfter(b);
};
