import {useNavigate} from "react-router-dom";
import {useEffect} from "react";

const NotFoundPage = () => {
    const navigate = useNavigate();

    useEffect(() => {
        setTimeout(() => {
            navigate('/');
        }, 1500)
    }, []);

    return <div style={{textAlign: 'center', padding: '20px'}}>
        존재하지 않는 페이지입니다. 잠시 후 메인으로 이동합니다.
    </div>
}

export default NotFoundPage;
