import styled, {css} from "styled-components";
import {useNavigate} from "react-router-dom";

const _VehicleHeader = ({vehicle, className}) => {
    const navigate = useNavigate();
    const getContent = () => {
        if (!vehicle.useType) {
            return '매칭 유형을 수정해주세요.';
        }
        if (vehicle.useType === 'TEMP') {
            return <>자동으로 입력된 값입니다.<br/>원하는대로 수정이 가능합니다.</>;
        }
        return null;
    }

    if (!getContent()) {
        return <div style={{display: 'none'}} />
    }

    return <div className={className}>
        <p>{getContent()}</p>
        <div className="btn" onClick={() => {
            navigate(`/vehicle_modify?id=${vehicle.contract}`);
        }}>지금 수정하기</div>
    </div>
}

const VehicleHeader = styled(_VehicleHeader)`
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  padding: 0 12px;
  font-size: 14px;
  line-height: 1.2;
  min-height: 44px;
  color: white;
  background: rgb(235, 87, 87);
  user-select: none;
  
  > .btn {
    user-select: none;
    transition: background 20ms ease-in 0s;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    border-radius: 4px;
    height: 28px;
    padding: 0 8px;
    line-height: 1.2;
    font-size: 14px;
    color: white;
    border: 1px solid white;
    margin-left: 12px;
    
    
    &:hover {
      background-color: rgba(55,53,47,0.08);
    }
    
    &:active {
      background-color: rgba(55,53,47,0.16);
    }
  }
`;

export default VehicleHeader;
