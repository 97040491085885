import {toNumberFormat, toNumberPlateFormat, toPhoneFormat} from "../../../toolbox/format";
import styled, {css} from "styled-components";
import CloseIcon from "@mui/icons-material/Close";
import {VehicleThumbnail} from "../component/VehicleThumbnail";
import React, {useRef, useState} from "react";
import {loadingAction} from "../../../redux/loadingReducer";
import axios from "axios";
import {SERVER_ADDRESS} from "../../../index";
import {Dialog, MenuItem, Radio, Select} from "@mui/material";
import {cloneDeep, filter, find, sortBy} from "lodash";
import VehicleOptionSection from "../component/VehicleOptionSection";
import {useDispatch} from "react-redux";
import {toastAction} from "../../../redux/toastReducer";
import {openAddress} from "../../../toolbox/logic";
import {pathAction} from "../../../redux/pathReducer";
import {DrawerWrap} from "./BottomDrawer";
import {PositiveBtn} from "./BottomNavigator";
import {useForm} from "../../../toolbox";
import HelpIcon from "@mui/icons-material/Help";

const Thumbnail = styled(VehicleThumbnail)`
  padding: 0;
  margin: 0;
  min-width: unset;
  width: 64px;
  height: 64px;
  border: none;
`

export const PhotoComponent = ({id, isSingle, title, form, onChange, ...props}) => {
    const imageRef = useRef();
    const multiImageRef = useRef();
    const dispatch = useDispatch();

    const handleImage = (e) => {
        const data = new FormData();
        for (let file of e.target.files) {
            data.append('files', file);
        }
        e.target.value = null;
        dispatch(loadingAction.loading(true));
        axios.post(SERVER_ADDRESS + '/api/upload', data, {
            onUploadProgress: progressEvent => {
            },
        }).then(async (res) => {
            if (isSingle) {
                onChange({id, value: res.data[0] ?? null});
            } else {
                const result = form[id].concat(res.data);
                onChange({id, value: result});
                if (id === 'pictures') {
                    onChange({id: 'thumbnail', value: result[0]});
                }
            }
            dispatch(loadingAction.loading(false));
        }).catch((e) => {
            alert(e);
        }).finally(() => {
        });
    }

    const deletePicture = (picture) => {
        console.log('del')
        let pictures = cloneDeep(form[id]);
        pictures = filter(pictures, (p) => p.id !== picture.id);
        if (id === 'pictures') {
            if (form?.thumbnail?.id === picture.id) {
                onChange({id: 'thumbnail', value: pictures[0] ?? null});
            }
        }
        onChange({id: 'pictures', value: pictures});
    }

    const isPictureExist = () => {
        if (isSingle && form[id]) {
            return true;
        } else if (!isSingle && form[id] && form[id].length > 0) {
            return true;
        }
        return false;
    }

    return <_PhotoComponent {...props}>
        {title && <div className="title">{title}</div>}
        <div className="content" onClick={() => {
            if (isSingle) {
                imageRef.current.click();
            } else {
                multiImageRef.current.click();
            }
        }}>
            <div className="photo-input">
                + 사진을 첨부해주세요
            </div>
        </div>
        {isPictureExist() &&
            <div className="attached">
                {isSingle && form[id] && <div className="image">
                    <Thumbnail image={form[id]}/>
                </div>}
                {!isSingle && form[id].map((p) =>
                    <div key={p.id} className="image --multi" onClick={() => {
                        if (id === 'pictures') {
                            onChange({id: 'thumbnail', value: p});
                        }
                    }}>
                        <div className="close" onClick={(e) => {
                            deletePicture(p);
                            e.stopPropagation();
                        }}>
                            <img src="/vehicle/close.svg"/>
                        </div>
                        {id === 'pictures' && form?.thumbnail?.id === p.id && <div className="selected">
                            대표
                        </div>}
                        <Thumbnail image={p}/>
                    </div>)}
            </div>
        }
        <input style={{display: 'none'}} type="file" accept="image/jpeg, image/png" onChange={handleImage} ref={imageRef}/>
        <input style={{display: 'none'}} type="file" accept="image/jpeg, image/png" onChange={handleImage} ref={multiImageRef} multiple={true}/>
    </_PhotoComponent>
}

export const FormComponent = ({id, title, smallTitle, form, onChange, onClick, style, description, helpBtn, ...props}) => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);

    const InputComponent = () => {
        if (id === 'phone') {
            return <input {...props} id={id} value={form[id]} onChange={(e) => onChange({id: e.target.id, value: toPhoneFormat(e.target.value)})}/>;
        } else if (id === 'numberPlate') {
            return <input {...props} id={id} value={form[id]} onChange={(e) => onChange({id: e.target.id, value: toNumberPlateFormat(e.target.value)})}/>;
        } else if (id === 'year') {
            return <input {...props} id={id} value={form[id]} onChange={(e) => onChange({id: e.target.id, value: toNumberFormat(e.target.value)})}/>;
        } else if (id === 'address') {
            return <>
                <span className="search" {...props} id={id} onClick={(e) => openAddress((address, longitude, latitude) => {
                    onChange([
                        {id: 'address', value: address},
                        {id: 'longitude', value: longitude},
                        {id: 'latitude', value: latitude},
                    ]);
                    dispatch(pathAction.update({
                        address: address,
                        longitude: longitude,
                        latitude : latitude,
                        isGps : false,
                    }));
                })}><img src="/vehicle/search.svg"/>{form[id]}</span>
            </>;
        } else if (id === 'userAddress') {
            return <>
                <span className="search" {...props} id={id} onClick={(e) => openAddress((address, longitude, latitude) => {
                    onChange([
                        {id: 'userAddress', value: address},
                    ]);
                    if (!form.address) {
                        onChange([
                            {id: 'address', value: address},
                            {id: 'longitude', value: longitude},
                            {id: 'latitude', value: latitude},
                        ])
                        dispatch(toastAction.toast({severity: 'success', 'message': '차량 위치가 해당 주소로 자동 입력되었습니다.'}));
                    }
                })}><img src="/vehicle/search.svg"/>{form[id]}</span>
            </>;
        }
        switch (props.type) {
            case "textarea":
                return <textarea {...props} id={id} value={form[id]} onChange={(e) => onChange({id: e.target.id, value: e.target.value})}/>
        }
        return <input id={id} value={form[id]} {...props} onChange={(e) => onChange({id: e.target.id, value: e.target.value})}/>;
    }

    return <_FormComponent style={style} {...props}>
        {title && <div className={smallTitle? 'small-title': 'title'}>{title}
            {(description && helpBtn) &&
                <HelpIcon fontSize="16px" onClick={() => setOpen(true)} />
            }
        </div>}
        {/*<div className="title">{title}</div>*/}
        {(description && !helpBtn)  && <div className="description">{description}</div>}
        <div className="content" onClick={onClick}>
            <div className="text-input">
                {InputComponent()}
                {props.unit && <span className="unit-label">{props.unit}</span>}
                {/*<input id={id} type="text" value={form[id]} onChange={(e) => onChange({id: e.target.id, value: e.target.value})}/>*/}
            </div>
        </div>
        {(description && helpBtn) && <Dialog open={open} onClose={() => setOpen(false)} fullWidth={true}>
            <div style={{padding: '32px', whiteSpace: 'pre-wrap', wordBreak: 'keep-all', lineHeight: '160%'}}>
                {description}
            </div>
        </Dialog>}
    </_FormComponent>
}

const _FormComponent = styled.div`
  > .small-title {
    color: var(--gray-scale-gray-700, #939393);
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 157.143% */
    margin: 4px 0;
  }
  > .title {
    color: var(--gray-scale-black, #212121);
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
    margin-bottom: 4px;
  }
  
  > .description {
    font-size: 14px;
    color: #666666;
    margin-bottom: 8px;
  }

  > .content {
    padding: 14px;
    border-radius: 8px;
    //border: 1px solid var(--gray-200, #F5F5F5);
    border: 1px solid #E0E0E0;
    //background: var(--gray-100, #FAFAFA);
    background: white;
    > .text-input {
      position: relative;
      input, textarea, .search {
        caret-color: #33CCC1;
        background-color: rgba(0, 0, 0, 0);
        display: block;
        width: 100%;
        box-sizing: border-box;
        border: none;
        
        color: var(--gray-scale-black, #212121);
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        resize: none;
        
        ::placeholder {
          color: var(--gray-500, #C9C9C9);
        }
        
        :focus {
          outline: none;
        }
      }
      
      > .unit-label {
        color: var(--gray-scale-black, #212121);
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        
        position: absolute;
        top: 50%;
        right: 0; /* 오른쪽 패딩 값 */
        transform: translateY(-50%);  /* 세로 중앙 정렬 */
        pointer-events: none;  /* 마우스 이벤트 무시 */
      }
      
      > .search {
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 8px;
      }
      
      textarea {
        
      }
    }


    ${(props) => props.disabled && css`
      //background: var(--gray-100, #FAFAFA);
      background: #F5F5F5;
      cursor: not-allowed;
      > .text-input {
        cursor: not-allowed;
        input, textarea, .search {
          cursor: not-allowed;
          caret-color: #33CCC1;
          background-color: rgba(0, 0, 0, 0);
          color: var(--gray-scale-black, #212121);
          //color: white;
          ::placeholder {
            color: var(--gray-500, #C9C9C9);
            //color: white;
          }
        }

        > .unit-label {
          color: var(--gray-scale-black, #212121);
        }
      }
    `}
  }
`;

const _PhotoComponent = styled(_FormComponent)`
  > .content {
    user-select: none;
    cursor: pointer;
    text-align: center;
    color: var(--gray-500, #C9C9C9);
  }

  > .attached {
    margin-top: 20px;
    display: flex;
    gap: 16px;
    flex-wrap: wrap;

    > .--multi {
      cursor: pointer;
    }
    > .image {
      position: relative;
      border-radius: 8px;
      border: 1px solid var(--gray-scale-gray-200, #F5F5F5);
      background: var(--gray-scale-gray-100, #FAFAFA);
      
      > .close {
        cursor: pointer;
        z-index: 1;
        position: absolute;
        top: -12px;
        right: -12px;
      }
      
      > .selected {
        z-index: 1;
        box-sizing: border-box;
        position: absolute;
        padding: 3px;
        left: 0;
        top: 0;
        font-size: 12px;
        border: 1px solid black;
        color: black;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        pointer-events: none;
      }
    }
  }
`

export const OptionComponent = ({id, title, form, onChange, onClick, ...props}) => {
    return <_FormComponent {...props}>
        {title && <div className="title">{title}</div>}
        <VehicleOptionSection vehicle={({options: form.options})} onToggle={(label) => {
            let options = [];
            if (form.options) {
                options = form.options.split(',');
            };
            if (options.includes(label)) {
                options = filter(options, (o) => o !== label);
            } else {
                options.push(label);
            }
            onChange({id: 'options', value: options.join(',')});
        }} />
    </_FormComponent>
}

const _RadioComponent = styled(_FormComponent)`
  > .radio-input {
    margin-top: 4px;
    display: flex;
    flex-direction: column;
    gap: 6px;

    .select-box {
      cursor: pointer;
      user-select: none;
      padding: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 8px;
      border: 1px solid var(--gray-scale-gray-300, #EFEFEF);

      color: var(--gray-scale-gray-800, #666);
      font-feature-settings: 'clig' off, 'liga' off;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 26px;


      > .body {
        display: flex;
        flex-direction: column;
        gap: 2px;
        > .title {
          color: var(--gray-scale-gray-800, #666);
          font-feature-settings: 'clig' off, 'liga' off;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 26px; /* 144.444% */
        }

        > .description {
          color: var(--gray-scale-gray-700, #939393);
          font-feature-settings: 'clig' off, 'liga' off;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px; /* 157.143% */
          white-space: pre-wrap;
          word-break: keep-all;
        }

        > .additional {
          color: var(--i-os-orange, #FF4F17);
          font-feature-settings: 'clig' off, 'liga' off;
          font-size: 10px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px; /* 160% */
        }
      }
    }

    > .--checked {
      color: var(--gray-scale-black, #212121);
      font-weight: 600;
      border: 1px solid var(--mint, #33CCC1);
      background: rgba(51, 204, 193, 0.10);


      > .body {
        > .title {
          color: var(--gray-scale-black, #212121);
        }
      }
    }
  }
`

const _RadioComponentSimple = styled(_FormComponent)`
  
  > .title {
    color: var(--gray-scale-black, #212121);
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
  }
  
  > .radio-input {
    margin-top: 4px;
    display: flex;
    gap: 20px;

    > .select-box {
      cursor: pointer;
      user-select: none;
      display: flex;
      justify-content: space-between;
      align-items: center;

      color: var(--gray-scale-gray-800, #666);
      font-feature-settings: 'clig' off, 'liga' off;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 26px;
      
      
      > .body {
        > .title {
          margin-left: 8px;
          color: var(--gray-scale-gray-800, #666);
          font-feature-settings: 'clig' off, 'liga' off;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 26px; /* 144.444% */
        }
      }
    }

    > .--checked {
      color: var(--gray-scale-black, #212121);
      font-weight: 600;
      > .body {
        > .title {
          color: var(--gray-scale-black, #212121);
        }
      }
    }
  }


  > .is-column {
    flex-direction: column;
    gap: 16px;
    > .select-box {
      justify-content: flex-start;
  }
`


export const RadioComponentSimple = ({id, title, isColumn, form, onChange, values, ...props}) => {
    const checked = (v) => {
        if (form[id] !== null && form[id] !== undefined) {
            return form[id] === v.id;
        }
        return false;
    }
    return <_RadioComponentSimple {...props}>
        {title && <div className="title">{title}</div>}
        <div className={`radio-input ${isColumn? 'is-column': ''}`}>
            {values.map((v) => <>
                <div key={id + v.id} className={`select-box --${checked(v)? 'checked': 'unchecked'}`} onClick={() => {
                    onChange({id, value: v.id})
                }}>
                    <img src={`/vehicle/check_${checked(v)}.svg`} />
                    <div className="body">
                        <div className="title">{v.label}</div>
                    </div>
                </div>
            </>)}
        </div>
    </_RadioComponentSimple>
}

export const UseTypeComponent = ({id, title, form, onChange, values, ...props}) => {
    return <_FormComponent {...props}>
        {title && <div className="title">{title}</div>}
        <div style={{display: 'flex', gap: '6px', flexDirection: 'column'}}>
            {values.map(v => <UseType id={id} form={form} onChange={onChange} v={v}/>)}
        </div>
        {/*<div className="radio-input">*/}
            {/*{values.map((v) => <>*/}
            {/*    <div key={id + v.id} className={`select-box --${checked(v)? 'checked': 'unchecked'}`} onClick={() => {*/}
            {/*        onChange({id, value: v.id})*/}
            {/*    }}>*/}
            {/*        <div className="body">*/}
            {/*            <div className="title">{v.label}</div>*/}
            {/*            {v.description && <div className="description">{v.description}</div>}*/}
            {/*            <div className="show-detail">*/}
            {/*                <span onClick={(e) => {*/}
            {/*                    e.stopPropagation();*/}
            {/*                    setUseType(v.id);*/}
            {/*                    setDetailOpen(true);}}>자세히 보기*/}
            {/*                </span>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <img style={{marginLeft: '4px'}} src={`/vehicle/check_${checked(v)}.svg`} />*/}
            {/*    </div>*/}

            {/*</>)}*/}
        {/*</div>*/}
    </_FormComponent>
}

const _UseType = styled(_FormComponent)`
  user-select: none;
  .wrap {
    border-radius: 8px;
    padding: 16px;
  }
  .--checked {
    color: var(--gray-scale-black, #212121);
    border: 1px solid var(--mint, #33CCC1);
    background: rgba(51, 204, 193, 0.10);
    
    .title {
      font-weight: 600 !important;
    }
  }
  .--unchecked {
    color: var(--gray-scale-black, #212121);
    border: 1px solid var(--gray-scale-gray-300, #EFEFEF);
  }
  
  .select-box {
    cursor: pointer;
    user-select: none;
    display: flex;
    justify-content: space-between;
    align-items: center;

    color: var(--gray-scale-gray-800, #666);
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;


    > .body {
      display: flex;
      flex-direction: column;
      gap: 2px;
      > .title {
        color: var(--gray-scale-gray-800, #666);
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 26px; /* 144.444% */
      }
      > .description {
        color: var(--gray-scale-gray-700, #939393);
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px; /* 157.143% */
        white-space: pre-wrap;
        word-break: keep-all;
      }

      > .additional {
        color: var(--i-os-orange, #FF4F17);
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px; /* 160% */
      }

      > .show-detail {
        margin-top: 4px;
        text-decoration: underline;
        color: var(--i-os-orange,#FF4F17);
        font-feature-settings: "clig" 0, "liga" 0;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
      }
    }
  }
`

const UseType = ({id, form, onChange, v}) => {
    const [detailOpen, setDetailOpen] = useState(false);

    const checked = () => {
        if (form[id]) {
            const vs = form[id].split(',');
            return vs.includes(v.id);
        }
        return false;
    }

    const toggle = () => {
        let options = [];
        if (form[id]) {
            options = form[id].split(',');
        }
        if (options.includes(v.id)) {
            options = filter(options, (o) => o !== v.id);
        } else {
            options.push(v.id);
        }
        options = sortBy(options, (v) => {
            if (v === 'DAY_SELECT') {
                return 0;
            } else if (v === 'PRIORITY_HOST') {
                return 1;
            } else if (v === 'PRIORITY_GUEST') {
                return 2;
            }
        });
        onChange({id, value: options.join(',')});
    }

    return <>
        <_UseType>
            <div className={`wrap --${checked(v)? 'checked': 'unchecked'}`}>
                <div className="select-box" onClick={toggle}>
                    <div className="body">
                        <div className="title">{v.label}</div>
                        {v.description && <div className="description">{v.description}</div>}
                        <div className="show-detail">
                <span onClick={(e) => {
                    e.stopPropagation();
                    setDetailOpen(true);}}>자세히 보기
                </span>
                        </div>
                    </div>
                    <img style={{marginLeft: '4px'}} src={`/vehicle/check_${checked(v)}.svg`} />
                </div>

                {/*{checked() && <>*/}
                {/*    <div style={{marginTop: '20px', marginBottom: '20px', borderBottom: '1px solid #D9D9D9'}}/>*/}
                {/*    <div style={{display: 'flex', flexDirection: 'column', gap: '8px'}}>*/}
                {/*        <div>*/}
                {/*            <h2 style={{minWidth: '115px', margin: '6px 0'}}>월고정수익</h2>*/}
                {/*            <div style={{flexGrow: '1'}}>*/}
                {/*                <FormComponent id="priceMonth" placeholder="매월 받을 고정금액" unit="원 / 월" form={form2} onChange={onChange2} />*/}
                {/*            </div>*/}
                {/*            <h3>게스트가 매월 고정적으로 지급하는 금액으로, 차량의 시세와 감가상각를 고려하여 자유롭게 입력해주세요.</h3>*/}
                {/*        </div>*/}
                {/*        <div>*/}
                {/*            <h2 style={{minWidth: '115px'}}>거리당수익</h2>*/}
                {/*            <div style={{flexGrow: '1', margin: '6px 0'}}>*/}
                {/*                <FormComponent id="priceKm" placeholder="1km 주행당 받을 금액" unit="원 / km" form={form2} onChange={onChange2} />*/}
                {/*            </div>*/}
                {/*            {v.id !== 'PRIORITY_GUEST' &&*/}
                {/*                <h3>게스트가 매월 주행한 거리만큼 지급하는 금액으로, 주유, 소모품교체, 정비, 세차 등 다양한 유지비가 모두 포함되어 있습니다.<br/>*/}
                {/*                    특히, 게스트가 결제하는 주유비는 호스트의 수익금 계좌에서 차감된다는 점을 고려하여 금액을 여유롭게 입력해주세요.</h3>}*/}
                {/*            {v.id === 'PRIORITY_GUEST' &&*/}
                {/*                <>*/}
                {/*                    <h3>게스트가 매월 주행한 거리만큼 지급하는 금액으로, 소모품교체, 정비 등 주유비를 제외한 다양한 유지비가 모두 포함되어 있습니다. (주유비는 게스트 본인 부담)</h3>*/}
                {/*                </>*/}
                {/*            }*/}
                {/*        </div>*/}
                {/*        {v.id === 'PRIORITY_GUEST' && <>*/}
                {/*            <div>*/}
                {/*                <h2 style={{minWidth: '115px'}}>거리당주유비</h2>*/}
                {/*                <div style={{flexGrow: '1', margin: '6px 0'}}>*/}
                {/*                    <FormComponent id="priceOil" placeholder="1km 주행당 부담할 금액" unit="원 / km" form={form2} onChange={onChange2} />*/}
                {/*                </div>*/}
                {/*                <h3>호스트는 운행 시 게스트에게 '거리당주유비'를 납부하는 대신 주유비를 따로 부담하지 않습니다. 게스트는 호스트가 운행을 할 때 문제가 없도록 항상 연료를 충분한 상태로 유지해야합니다.</h3>*/}
                {/*            </div>*/}
                {/*        </>}*/}
                {/*    </div>*/}
                {/*</>}*/}

            </div>
        </_UseType>
        <SafetyDialog useType={v.id} open={detailOpen} onClose={() => setDetailOpen(false)} />
    </>
}

export const getUseTypeDetailContent = (useType) => {
    if (useType === 'DAY_SELECT') {
        return <>
            <h1>요일지정형</h1>
            <p style={{marginTop: '8px'}}>호스트, 게스트 모두 우선권 없이 선착순으로 예약하여 이용합니다.</p>
            <p>호스트와 게스트는 각자 15개의 무료예약권을 보유합니다.</p>

            <h2 style={{marginTop: '20px'}}>* 예약권이란?</h2>
            <p style={{marginTop: '8px'}}>차를 사용할 날을 미리(또는 당일에) 예약할 때 사용됩니다.</p>
            <p>평일예약 시 1개, 주말 및 공휴일 예약 시 2개가 소모됩니다.</p>
            <p>※ 공유계약체결일 다음날 오전 10시부터 캘린더 예약이 가능해요</p>

            <h2 style={{marginTop: '20px'}}>[요일지정 기능]</h2>
            <p style={{marginTop: '8px'}}>호스트와 게스트가 협의하여 각자 고정적으로 탈 요일을 지정할 수 있습니다.
                선택한 요일은 자동으로 사전예약이 이뤄집니다.
                이 때, 무료예약권 15개를 초과하여 사전예약이 가능합니다.
                사전예약 시 사용된 예약권은 아래 유료예약권 계산에 산입되지 않습니다.</p>

            <h2 style={{marginTop: '20px'}}>[15개 초과 시 유료예약권 사용]</h2>
            <p style={{marginTop: '8px'}}>호스트/게스트가 15개의 예약권을 초과하여 사용 시에는 유료예약권이 소모됩니다.</p>
            <p>유료예약권 사용에 따른 비용은 상대방에게 지급됩니다.</p>
            <p>(📌 유료예약권 1개 당 비용 = 월이용료 / 15)</p>

            <h2 style={{marginTop: '20px'}}>[상대방에게 운행요청]</h2>
            <p style={{marginTop: '8px'}}>캘린더에 예약 및 취소 시 상대방에게 알림톡이 날아갑니다.</p>
            <p>호스트, 게스트 모두 상대방이 예약한 요일에 ‘운행요청’을 할 수 있습니다.</p>
            <p>운행요청 시 알림톡이 발송되며 수락 여부를 결정할 수 있습니다.</p>
            <p style={{marginTop: '8px'}}>상대방의 운행요청 수락 시 본인의 예약권은 복원됩니다.</p>
            <p>(상대방의 운행요청이 없는 상태에서 예약을 취소할 경우에는 예약권이 복원되지 않습니다)</p>
        </>
    } else if (useType === 'PRIORITY_HOST') {
        return <>
            <h1>호스트 우선형</h1>
            <p style={{marginTop: '8px'}}>호스트는 캘린더에 따로 예약할 필요가 없으며, 차를 꼭 사용해야하는 날은 미리 예약을 하여 게스트로부터 운행요청을 받지 않겠다는 의미로 활용이 가능합니다.</p>
            <p style={{marginTop: '8px'}}>게스트는 10개의 무료예약권을 보유합니다.</p>

            <h2 style={{marginTop: '20px'}}>* 예약권이란?</h2>
            <p style={{marginTop: '8px'}}>차를 사용할 날을 미리(또는 당일에) 예약할 때 사용됩니다.</p>
            <p>평일예약 시 1개, 주말 및 공휴일 예약 시 2개가 소모됩니다.</p>
            <p style={{marginTop: '8px'}}>게스트는 운행을 희망하는 날짜를 클릭하여 ‘운행요청’을 할 수 있습니다.</p>
            <p>호스트는 수락여부를 결정할 수 있고, 무응답 시 24시간 후 이를 수락한 것으로 보아 게스트로 예약이 이뤄집니다. (호스트가 이미 예약한 날에 대하여 운행요청을 하는 경우에는 무응답 시 24시간 후 거절된 것으로 봄)</p>
            <p style={{marginTop: '8px'}}>호스트는 게스트가 보유한 무료예약권 10개를 전부 사용하도록 보장해야합니다.</p>

            {/*<h2 style={{marginTop: '20px'}}>[요일지정 기능]</h2>*/}
            <h2 style={{marginTop: '20px'}}>[10개 초과 시 유료예약권 사용]</h2>
            <p style={{marginTop: '8px'}}>게스트가 10개의 무료예약권을 초과하여 사용 시에는 유료예약권이 소모됩니다.</p>
            <p style={{marginTop: '8px'}}>유료예약권 사용에 따른 비용은 호스트에게 지급됩니다.</p>
            <p>(📌 유료예약권 1개 당 비용 = 월이용료 / 10)</p>
        </>
    } else if (useType === 'PRIORITY_GUEST') {
        return <>
            <h1>게스트 우선형</h1>
            {/*<p style={{marginTop: '8px'}}>게스트는 따로 예약을 할 필요가 없이 모든 날짜에 우선권을 가지고 있습니다.</p>*/}
            <p style={{marginTop: '8px'}}>게스트는 캘린더에 따로 예약할 필요가 없으며, 차를 꼭 사용해야하는 날은 미리 예약을 하여 호스트로부터 운행요청을 받지 않겠다는 의미로 활용이 가능합니다.</p>
            <p style={{marginTop: '8px'}}>호스트는 캘린더에 희망날짜를 클릭하여 ‘운행요청’을 할 수 있으며, 게스트가 무응답 시 24시간 후 수락한 것으로 보아 예약이 이뤄집니다. (게스트가 이미 예약을 해놓은 날에 대하여 운행요청을 하는 경우에는 무응답 시 24시간 후 거절된 것으로 봄)</p>

            <h2 style={{marginTop: '20px'}}>* 예약권이란?</h2>
            <p style={{marginTop: '8px'}}>차를 사용할 날을 미리(또는 당일에) 예약할 때 사용됩니다.</p>
            <p>평일예약 시 1개, 주말 및 공휴일 예약 시 2개가 소모됩니다.</p>
            <p style={{marginTop: '8px'}}>호스트의 예약권 사용에 따른 비용은 게스트에게 지급됩니다.</p>
            <p>(📌 예약권 1개 당 비용 = 월이용료 / 10)</p>

            {/*<h2 style={{marginTop: '20px'}}>[요일지정 기능]</h2>*/}
            {/*<h2 style={{marginTop: '20px'}}>[5개 초과 시 유료예약권 사용]</h2>*/}
            {/*<p style={{marginTop: '8px'}}>호스트가 5개의 예약권을 초과하여 사용 시에는 유료예약권이 소모됩니다.</p>*/}
            {/*<p style={{marginTop: '8px'}}>유료예약권 사용에 따른 비용은 게스트에게 지급됩니다.</p>*/}
            {/*<p style={{marginTop: '8px'}}>(📌 유료예약권 1개 당 비용 = 월이용료 / 5)</p>*/}
        </>
    }
    return null;
}

export const SafetyDialog = ({open, onClose, useType}) => {
    return <Dialog open={open} onClose={onClose} fullWidth={true}>
        <DrawerWrap>
            {getUseTypeDetailContent(useType)}
            <PositiveBtn style={{marginTop: '16px'}} onClick={onClose}>확인</PositiveBtn>
        </DrawerWrap>
    </Dialog>
}


export const RadioComponent = ({id, title, form, onChange, values, description, ...props}) => {
    const checked = (v) => {
        if (form[id] !== null && form[id] !== undefined) {
            return form[id] === v.id;
        }
        return false;
    }
    return <_RadioComponent {...props}>
        {title && <div className="title">{title}</div>}
        {description && <div className="description">{description}</div>}
        <div className="radio-input">
            {values.map((v) => <>
                <div key={id + v.id} className={`select-box --${checked(v)? 'checked': 'unchecked'}`} onClick={() => {
                    onChange({id, value: v.id})
                }}>
                    <div className="body">
                        <div className="title">{v.label}</div>
                        {v.description && <div className="description">{v.description}</div>}
                        {v.additional && <div className="additional">{v.additional}</div>}
                    </div>
                    <img style={{marginLeft: '4px'}} src={`/vehicle/check_${checked(v)}.svg`} />
                </div>
            </>)}
        </div>
    </_RadioComponent>
}

export const SelectComponent = ({id, title, form, onChange, values, ...props}) => {
    return <_RadioComponent {...props}>
        {title && <div className="title">{title}</div>}
        <div className="radio-input">
            <Select value={form[id]} onChange={(e) => { onChange({id, value: e.target.value})}}>
                {values.map((v) =>
                    <MenuItem value={v.id}>{v.label}</MenuItem>
                )}
            </Select>
        </div>
    </_RadioComponent>
}
