import {useCallback, useState} from "react";
import {forEach, isArray} from "lodash";

export const useForm = (defaultForm = {}) => {
    const [form, setForm] = useState({...defaultForm});

    const onChange = useCallback((input, ignoreNullish) => {
        if (isArray(input)) {
            setForm(old => {
                let newValues = {...old}
                forEach(input, ({id, value}) => {
                    if (ignoreNullish) {
                        if (value !== null && value !== undefined) {
                            newValues[id] = value
                        }
                    } else {
                        newValues[id] = value
                    }
                })
                return newValues
            })
        }//
        else {
            const id = input.id;
            const value = input.value;
            if (ignoreNullish) {
                if (value !== null && value !== undefined) {
                    setForm(old => ({...old, [id]: value}));
                }
            } else {
                setForm(old => ({...old, [id]: value}));
            }
        }
    }, []);

    const resetValue = useCallback((id) => {
        setForm(old => ({...old, [id]: undefined}));
    }, []);

    const reset = useCallback(() => {
        setForm({});
    }, []);

    return {form, onChange, resetValue, reset};
};
