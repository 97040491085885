import styled from "styled-components";
import {useNavigate} from "react-router-dom";

export const GuidePage = () => {
    const navigate = useNavigate();
    return <_GuidePage>
        <img src="/guide/guide3.png" />


        <div className="faq" onClick={() => navigate('/')}>
            홈으로 이동
        </div>
        <div className="faq" onClick={() => window.open("https://copper-abrosaurus-467.notion.site/1a2c696f5199403a938b3b6abf3c1b64", '_blank')}>
            자주 묻는 질문
        </div>
    </_GuidePage>
}

const _GuidePage = styled.div`
  text-align: center;
  padding: 30px 20px;
  
  > img {
    width: 100%;
  }
  
  > .faq {
    box-sizing: border-box;
    margin: 28px 40px;
    padding: 20px 10px;
    cursor: pointer;
    border-radius: 26px;
    background-color: #33CCC1;
    color: white;
    font-size: 18px;
    font-weight: 700;
  }
`
