import styled from "styled-components";
import {Divider, Drawer} from "@mui/material";
import {Link, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";
import {store} from "../redux/store";
import {authAction} from "../redux/authReducer";
import {userAction} from "../redux/userReducer";
import {getLoginLink} from "../toolbox/format";

const _Menu = ({className, children, onClick}) => {
    return <div className={className} onClick={onClick}>
        <>{children}</>
    </div>
}

export const Menu = styled(_Menu)`
  padding-top: 8px;
  box-sizing: border-box;
  width: 55vw;
  min-width: 300px;
  div {
    padding: 12px;
  }
`

export const Logout = styled.div`
  cursor: pointer;
`

const _Guide = styled.div`
  user-select: none;
  font-size: 14px;
  color: #666666;
  padding: 8px 8px 2px !important;
`

const Guide = ({children}) => {
    return <_Guide onClick={(e) => e.stopPropagation() }>
        <>
            {children}
        </>
    </_Guide>
}

export const HamburgerMenu = ({open, onClose}) => {
    const hasLoggedIn = useSelector(({auth}) => !isEmpty(auth.tokenInfo));
    const dispatch = useDispatch();
    const location = useLocation();

    const handleLogout = () => {
        dispatch(authAction.logout());
        dispatch(userAction.logout());
    }

    return <Drawer open={open} onClose={onClose} anchor="right">
        <Menu onClick={onClose}>
            <Guide>
                두리카란?
            </Guide>
            <Link to="/guide">
                <div>두리카 소개</div>
            </Link>
            <a target="_blank" href="https://copper-abrosaurus-467.notion.site/1a2c696f5199403a938b3b6abf3c1b64">
                <div>자주 묻는 질문</div>
            </a>
            <Divider />
            <Guide>
                게스트가 되려면?
            </Guide>

            {location.pathname === '/'?
                <a href="/vehicle">
                    <div>차량 찾기</div>
                </a>:
                <Link to="/vehicle">
                    <div>차량 찾기</div>
                </Link>
            }
            <Link to="/my/profile">
                <div>내 프로필 등록하고 매칭제안 받기</div>
            </Link>

            <Divider />
            <Guide>
                호스트가 되려면?
            </Guide>
            {/*<Link to="/vehicle/write">*/}
            {/*    <div>내 차 등록하기</div>*/}
            {/*</Link>*/}
            <Link to="/user">
                <div>게스트 찾기</div>
            </Link>
            <a href="/vehicle/write">
                <div>내 차 등록하기</div>
            </a>
            <Link to="/host">
                <div>내 차 수익 알아보기</div>
            </Link>
            {/*<Link to="/rent">*/}
            {/*    <div>월단위 렌트카로 시작하기</div>*/}
            {/*</Link>*/}

            <Divider />
            {hasLoggedIn && <>
                <Guide>
                    마이 두리카
                </Guide>
                {/*<div>마이 두리카</div>*/}
                {/*<Link to="/my/profile">*/}
                {/*    <div>내 프로필</div>*/}
                {/*</Link>*/}
                <Link to="/my/info">
                    <div>내 정보</div>
                </Link>
                <Link to="/my/calendar">
                    <div>내 캘린더</div>
                </Link>
                <Link to="/my/payment">
                    <div>내 결제 목록</div>
                </Link>
                <Link to="/my/chat">
                    <div>내 채팅방</div>
                </Link>
                <Link to="/my/notification">
                    <div>알림</div>
                </Link>
                <Divider />
                <div style={{cursor: 'pointer'}} onClick={handleLogout}>로그아웃</div>
            </>}

            {!hasLoggedIn && <>
                <Guide>
                    마이 두리카
                </Guide>
                <Link to={getLoginLink(location)}>
                    <div>로그인</div>
                </Link>
            </>}
        </Menu>
    </Drawer>
}
