import styled, {css} from "styled-components";
import {filter, sortBy} from "lodash";
import {Checkbox, Dialog, Radio} from "@mui/material";
import ReactMarkdown from "react-markdown";
import {commaNum, useForm} from "../../../toolbox";
import InfoIcon from '@mui/icons-material/Info';
import {getUseTypeTxt, manFormat, manNum} from "../../../toolbox/format";
import React, {useState} from "react";
import moment from "moment-timezone";

const getLabel = (useType) => {
    switch (useType) {
        case "PRIORITY":
            return "우선예약형";
        case "DAY_SELECT":
            return "요일지정형";
        case "HALF":
            return "반반예약형";
        case "FULL_SHARE":
            return "전체공유형";
        case "FREE":
            return "협의후결정";
        case "PRIORITY_HOST":
            return "호스트우선형";
        case "PRIORITY_GUEST":
            return "게스트우선형";
        case "TEMP":
            return "예비호스트";
    }
}

export const getDescriptionSingleLine = (useType) => {
    switch (useType) {
        case "DAY_SELECT":
            return '호스트/게스트가 각자 고정적으로 이용할 날을 미리 지정하고, 나머지 날짜는 선착순으로 예약 후 이용하는 방식입니다.';
        case "PRIORITY_HOST":
            return "호스트가 주로 이용하고 게스트는 매월 5~10일 정도 이용하는 방식입니다.";
        case "PRIORITY_GUEST":
            return "게스트가 공유기간 동안 전일 이용하는 방식입니다.";
        case "TEMP":
            return '임시 등록된 차량입니다. 문의 시 매칭매니저가 매칭유형을 확인해드립니다.';
    }
}

export const getDescriptionSimple = (useType) => {
    switch (useType) {
        case "HALF":
            return '호스트와 게스트가 각자 15개의 예약권으로 캘린더에 예약하면서 사용하기 (평일 1개, 주말/공휴일 2개 차감)\n\n' +
                '매월 말일에 다음달 캘린더가 확정됩니다.\n' +
                '예약충돌시 홀짝일로 결정됩니다.\n' +
                '다음달이 도래하면 선착순으로 예약 가능합니다.';
        case "PRIORITY":
            return '우선예약권자가 매월 말일 전에 다음달에 탈 날을 먼저 예약하면서 사용하기\n\n' +
                '우선예약권자는 8개, 상대방은 15개의 예약권을 보유합니다.\n' +
                '나머지는 반반예약형과 동일합니다.';
        case "DAY_SELECT":
            return "호스트/게스트가 각자 고정적으로 이용할 날을 미리 지정하고 나머지 날짜는 선착순으로 예약 후 이용하는 방식입니다.\n" +
                // "이용패턴이 겹치지 않는 상대방과 안정적으로 공유할 수 있어요.\n" +
                "* 차고지: 호스트의 주차장";
            // return '호스트와 게스트가 선착순으로 예약하여 이용하는 방식입니다.\n' +
            //     '매주 고정적으로 이용할 요일을 미리 지정하는 경우, 해당 날짜는 자동으로 예약되어 선착순 대상에서 제외됩니다.\n' +
            //     '(호스트의 주차장 사용이 원칙이에요)';
        case "FULL_SHARE":
            return '공유기간 동안 게스트가 전부 사용하기';
        case "FREE":
            return '호스트와 협의 후 결정됩니다.';
        case "PRIORITY_HOST":
            return "호스트가 주로 이용하고 게스트는 매월 5~10일 정도 이용하는 방식입니다.\n" +
                "게스트가 차를 이용하려면 사전에 공유캘린더에 예약(운행요청)하여 호스트의 승인을 받아야합니다.\n" +
                "* 차고지: 호스트의 주차장"
            // return "호스트가 주로 이용하고 게스트는 매월 0~10일 정도 이용하는 방식입니다.\n" +
            //     "게스트는 호스트의 승인을 받아 예약확정 후 차를 이용할 수 있습니다.\n" +
            //     "(호스트의 주차장 사용이 원칙이에요)";
        case "PRIORITY_GUEST":
            return "게스트가 공유기간 동안 전일 이용하는 방식입니다.\n" +
                "호스트가 차를 이용하려면 공유캘린더에 예약(운행요청)하여 게스트의 승인을 받아야합니다.\n" +
                "게스트는 호스트가 차를 이용한 만큼 월이용료를 할인받을 수 있습니다.\n" +
                "* 차고지: 게스트의 주차장"
            // return "게스트가 주로 이용하고 호스트는 매월 0~5일 정도 이용하는 방식입니다.\n" +
            //     "호스트는 게스트의 승인을 받아 예약확정 후 차를 이용할 수 있습니다.\n" +
            //     "(게스트의 주차장 사용이 원칙이에요)";
        case "TEMP":
            return '임시 등록된 차량입니다. 문의 시 매칭매니저가 매칭유형을 확인해드립니다.';
    }
}

export const getDescription = (useType) => {
    switch (useType) {
        case "HALF":
            return '호스트와 게스트가 각자 15개의 예약권으로 캘린더에 예약하면서 사용하기 (평일 1개, 주말/공휴일 2개 차감)\n\n' +
                '매월 말일에 다음달 캘린더가 확정됩니다.\n' +
                '예약충돌시 홀짝일로 결정됩니다.\n' +
                '다음달이 도래하면 선착순으로 예약 가능합니다.';
        case "PRIORITY":
            return '우선예약권자가 매월 말일 전에 다음달에 탈 날을 먼저 예약하면서 사용하기\n\n' +
                '우선예약권자는 8개, 상대방은 15개의 예약권을 보유합니다.\n' +
                '나머지는 반반예약형과 동일합니다.';
        case "DAY_SELECT":
            return getDescriptionSimple(useType) + '\n' +
                '\n' +
                '* 예약권: 차를 사용할 날을 미리(또는 당일에) 예약할 때 사용됩니다. (평일예약 시 1개, 주말 및 공휴일 예약 시 2개가 소모됨.)\n\n' +
                '호스트와 게스트는 각자 15개의 무료예약권을 보유합니다.\n' +
                '각자 미리 선택한 요일은 캘린더에 자동으로 사전예약이 이뤄집니다. (무료예약권 15개를 초과하여 사전예약 가능)\n' +
                '사전예약 시 사용된 예약권은 아래 유료예약권 계산에 산입되지 않습니다.\n' +
                '15개의 무료예약권을 초과하여 사용 시에는 유료예약권이 소모됩니다. 유료예약권 사용에 따른 비용은 상대방에게 지급됩니다. (유료예약권 1개 당 비용 = 월이용료 / 15)';
        case "FULL_SHARE":
            return '공유기간 동안 게스트가 전부 사용하기';
        case "FREE":
            return '호스트와 협의 후 결정됩니다.';
        case "PRIORITY_HOST":
            return getDescriptionSimple(useType) + "\n" +
                "\n" +
                "* 예약권: 차를 사용할 날을 미리(또는 당일에) 예약할 때 사용됩니다. (평일예약 시 1개, 주말 및 공휴일 예약 시 2개가 소모됨.)\n\n" +
                "게스트는 10개의 무료예약권을 보유합니다.\n" +
                "호스트는 게스트가 보유한 무료예약권 10개를 전부 사용하도록 보장해야합니다.\n" +
                "게스트가 10개의 무료예약권을 초과하여 사용 시에는 유료예약권이 소모됩니다.\n" +
                "유료예약권 사용에 따른 비용은 호스트에게 지급됩니다. (유료예약권 1개 당 비용 = 월이용료 / 10)";
        case "PRIORITY_GUEST":
            return getDescriptionSimple(useType) + "\n" +
                "\n" +
                "* 예약권: 차를 사용할 날을 미리(또는 당일에) 예약할 때 사용됩니다. (평일예약 시 1개, 주말 및 공휴일 예약 시 2개가 소모됨.)\n\n" +
                "호스트는 0개의 무료예약권을 보유합니다. (유료예약권만 사용 가능)\n" +
                "호스트의 유료예약권 사용에 따른 비용은 게스트에게 지급됩니다. (유료예약권 1개 당 비용 = 월이용료 / 10)\n";
        case "TEMP":
            return '임시 등록된 차량입니다. 문의 시 매칭매니저가 매칭유형을 확인해드립니다.';
    }
}


const _VehicleUseInfoSection = ({vehicle, className}) => {
    // return <div className={className}>
    return <_FormComponent>
        <div className="content">
            <div className="radio-input">
                <div className="select-box">
                    {getDescription(vehicle.useType) && <div className="description">{getDescription(vehicle.useType)}</div>}
                    {vehicle.useType === 'DAY_SELECT' && <CheckComponentRow id="요일선택" form={({
                        요일선택: vehicle.daySelect,
                    })} onChange={() => {}} values={[
                        {id: '평일형', label: '평일전부'},
                        {id: '휴일형', label: '휴일전부'},
                        {id: '월', label: '월'},
                        {id: '화', label: '화'},
                        {id: '수', label: '수'},
                        {id: '목', label: '목'},
                        {id: '금', label: '금'},
                        {id: '토', label: '토'},
                        {id: '일', label: '일'},
                    ]} />}
                    {vehicle.useType === 'PRIORITY' && <RadioComponentRow id="우선예약권자" form={({
                        우선예약권자: vehicle.useTypeDetail.priority,
                    })} onChange={() => {}} values={[
                        {id: '호스트', label: '호스트 우선'},
                        {id: '게스트', label: '게스트 우선'},
                    ]} />}
                </div>
            </div>
        </div>
    </_FormComponent>
}

const UseTypeDetailPopup = ({useType, open, onClose}) => {
    return <Dialog open={open} onClose={onClose} fullWidth={true}>
        <div style={{padding: '20px', whiteSpace: 'pre-wrap'}}>
            <p style={{
                color: "#212121",
                fontStyle: "14px",
                fontWeight: "500",
                lineHeight: "22px",
            }}>
                {getDescription(useType)}
            </p>
        </div>
    </Dialog>
}

const EventDetailPopup = ({open, onClose, vehicle}) => {
    return <Dialog open={open} onClose={onClose} fullWidth={true}>
        <div style={{padding: '20px', whiteSpace: 'pre-wrap'}}>
            <p style={{
                color: "#212121",
                fontStyle: "14px",
                fontWeight: "500",
                lineHeight: "22px",
            }}>
                {"장기 계약 할인 안내\n" +
                    "\n" +
                    "정상가격과 특별할인가격 중 한가지를 선택하여 계약을 진행할 수 있습니다.\n" +
                    "※ 6개월 이상 계약 시 10% 할인이 적용되고, 첫달 요금이 10만원 할인됩니다.\n\n" +
                    "■ 정상가격\n" +
                    "계약기간: 최소 1개월\n" +
                    vehicle?.useTypes.map((u) => `${getUseTypeTxt(u.id)} : 월 ${commaNum(u.price)}원 (보증금 ${manNum(u.price * 3)}만원)`).join("\n") +
                    "\n\n" +
                    "■ 할인가격\n" +
                    "계약기간: 최소 6개월\n" +
                    vehicle?.useTypes.map((u) => `${getUseTypeTxt(u.id)} : 월 ${commaNum(u.price * 0.9)}원 (보증금 ${manNum(u.price * 2.7)}만원)\n                     ${u.id !== 'DAY_SELECT' ? '   ' : '' }첫달 ${commaNum(u.price * 0.9 - 100000)}원 (10만원추가할인)`).join("\n")}
            </p>
        </div>
    </Dialog>
}

const UseTypeComponent = ({vehicle, id, form, onChange, ...props}) => {
    const [open, setOpen] = useState(false);
    const [event, setEvent] = useState(false);
    const [useType, setUseType] = useState(null);

    const checked = (v) => {
        return form[id] === v;
    }

    return <_RadioComponent {...props}>
        <div className="radio-input">
            {vehicle?.useTypes?.map((u) => <>
                <div key={u.id} className={`select-box --${checked(u.id)? 'checked': 'unchecked'}`} onClick={() => {
                    onChange({id, value: u.id});
                }}>
                    <div className="body">
                        <div className="title">{getLabel(u.id)}<InfoIcon onClick={(e) => {
                            e.stopPropagation();
                            setUseType(u.id);
                            setOpen(true);
                        }} style={{marginBottom: '2px', marginLeft: '4px', fontSize: '18px'}}/></div>
                        {/*<div className="description">{getDescriptionSingleLine(u.id)}</div>*/}
                        {/*{v.additional && <div className="additional">{v.additional}</div>}*/}
                        {vehicle?.eventPrice === false ?
                            <div>
                                월 {commaNum(u.price)}원<br/>
                                <span style={{fontSize: '14px'}}>보증금 {manNum(u.price * 3)}만원</span>
                            </div>
                            :<div>
                                월 <span style={{textDecoration: 'line-through'}}>{commaNum(u.price)}원</span> → {commaNum(u.price * 0.9)}원<br/>(6개월 이상 계약 시)<br/>
                                <span style={{color: '#2eb0e5', fontSize: '14px'}}>첫 달 {commaNum(u.price * 0.9 - 100000)}원 (10만원추가할인) + 보증금 {manNum(u.price * 2.7)}만원</span>
                            </div>}
                        {u.id === 'DAY_SELECT' && <div className="additional">호스트 고정이용일 : {vehicle.daySelect ? vehicle.daySelect.replaceAll("형", "전부"): '없음'}</div>}
                    </div>
                    <img style={{marginLeft: '4px'}} src={`/vehicle/check_${checked(u.id)}.svg`} />
                </div>
            </>)}
            {/*{vehicle?.eventPrice !== false && <div style={{marginTop: '4px', border: '1px solid #222222', color: '#222222', borderRadius: '4px', fontWeight: '500', padding: '10px', textAlign: 'center', cursor: 'pointer'}} onClick={() => setEvent(true)}>*/}
            {/*    🎉 {moment.tz('Asia/Seoul').format('M')}월 기간한정 특별할인 자세히 보기*/}
            {/*</div>}*/}
        </div>
        <UseTypeDetailPopup useType={useType} open={open} onClose={() => setOpen(false)}/>
        <EventDetailPopup vehicle={vehicle} open={event} onClose={() => setEvent(false)}/>
    </_RadioComponent>
}

export const VehicleUseInfoSection = _VehicleUseInfoSection;
export const VehicleUseInfoSection3 = UseTypeComponent;

const CheckComponentRow = ({id, title, form, onChange, values, ...props}) => {
    const checked = (v) => {
        if (form[id]) {
            const vs = form[id].split(',');
            return vs.includes(v);
        }
        return false;
    }
    return <_FormComponent {...props}>
        {title && <div className="title">{title}</div>}
        {/*<div className="title">{title}</div>*/}
        <div className="content">
            <div className="row-radio-input">
                {values.map((v) => <>
                    <div className="select-box">
                        <div className="top">
                            <Checkbox disabled checked={checked(v.id)} style={{padding: '0'}} />
                            <div>
                                {v.label}
                            </div>
                        </div>
                    </div>
                </>)}
            </div>
        </div>
    </_FormComponent>
}

const RadioComponentRow = ({id, title, form, onChange, values, ...props}) => {
    const checked = (v) => {
        if (form[id]) {
            return form[id] === v;
        }
        return false;
    }
    return <_FormComponent {...props}>
        {title && <div className="title">{title}</div>}
        <div className="content">
            <div className="row-radio-input">
                {values.map((v) => <>
                    <div className="select-box">
                        <div className="top">
                            <Radio disabled checked={checked(v.id)} style={{padding: '0'}} />
                            <div>
                                {v.label}
                            </div>
                        </div>
                    </div>
                </>)}
            </div>
        </div>
    </_FormComponent>
}

const _FormComponent = styled.div`
  padding: 6px 12px;
  > .title {
    font-weight: 700;
    line-height: 120%;
    padding-left: 4px;
    padding-top: 12px;
    padding-bottom: 4px;
    font-size: 16px;
  }

  > .content {
    > .row-radio-input {
      margin-top: 8px;
      display: flex;
      width: 100%;
      > .select-box {
        flex-grow: 1;
        padding-top: 4px;
        padding-bottom: 4px;
        border: 1px solid #E8E8E8;
        margin-bottom: 8px;
        > .top {
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        > .description {
          white-space: pre-wrap;
          line-height: 120%;
        }
      }
    }

    > .radio-input {
      margin-top: 8px;
      > .select-box {
        border: 1px solid #E8E8E8;
        margin-bottom: 8px;
        > .top {
          //padding: 10px;
          display: flex;
          align-items: center;
          > .price {
            margin-left: auto;
          }
        }

        > .description {
          padding: 10px;
          white-space: pre-wrap;
          line-height: 130%;
        }
      }
    }
  }
`;

const _RadioComponent = styled(_FormComponent)`
  > .radio-input {
    margin-top: 4px;
    display: flex;
    flex-direction: column;
    gap: 6px;

    .select-box {
      cursor: pointer;
      user-select: none;
      padding: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 8px;
      border: 1px solid var(--gray-scale-gray-300, #EFEFEF);

      color: var(--gray-scale-gray-800, #666);
      font-feature-settings: 'clig' off, 'liga' off;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 26px;


      > .body {
        display: flex;
        flex-direction: column;
        gap: 2px;
        > .title {
          display: flex;
          align-items: center;
          color: var(--gray-scale-gray-800, #666);
          font-feature-settings: 'clig' off, 'liga' off;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 26px; /* 144.444% */
        }

        > .description {
          color: var(--gray-scale-gray-700, #939393);
          font-feature-settings: 'clig' off, 'liga' off;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px; /* 157.143% */
          white-space: pre-wrap;
          word-break: keep-all;
        }

        > .additional {
          color: var(--i-os-orange, #FF4F17);
          font-feature-settings: 'clig' off, 'liga' off;
          font-size: 10px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px; /* 160% */
        }
      }
    }

    > .--checked {
      color: var(--gray-scale-black, #212121);
      font-weight: 600;
      border: 1px solid var(--mint, #33CCC1);
      background: rgba(51, 204, 193, 0.10);


      > .body {
        > .title {
          color: var(--gray-scale-black, #212121);
        }
      }
    }
  }
`
