import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import qs from 'query-string';
import {useLocation, useNavigate} from "react-router-dom";
import styled from "styled-components";
import {loadingAction} from "../../redux/loadingReducer";
import {SERVER_ADDRESS} from "../../index";
import axios from "axios";

const PageWrap = styled.div`
  margin-top: 50px;
  padding: 20px;
  text-align: center;
`

export function HostChatReplyPage() {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const parsed = location.search ? qs.parse(location.search, {ignoreQueryPrefix: true}) : null;

    const [finished, setFinished] = useState(false);
    useEffect(() => {
        dispatch(loadingAction.loading(true));
        axios.post(SERVER_ADDRESS + '/api/cars/hostChatCreate', {
            vehicleId: parsed.id,
            guestId: parsed.guest ?? null,
        }).then((res) => {
            if (res.data) {
                alert('채팅방 개설요청이 완료되었습니다.\n' +
                    '담당 매칭매니저가 영업시간(평일 10:00 ~ 19:00) 내에 순차적으로 연락드려 채팅방 링크를 전달해드릴 예정이오니 조금만 기다려주세요.\n' +
                    '문의사항이 있으시거나 매칭매니저의 도움이 필요하시면 채팅방에 "상담"이라고 남겨주세요!\n' +
                    '감사합니다.');
            } else {
                alert('알 수 없는 오류가 발생했습니다.');
            }
            dispatch(loadingAction.loading(false));
            setFinished(true);
            navigate('/');
        });
    }, []);

    return (<PageWrap>
        <div>
            채팅방 개설 요청이 {finished? '완료되었습니다.': '처리중입니다.'}
        </div>
    </PageWrap>);
};
