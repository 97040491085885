import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import qs from 'query-string';
import {useLocation, useNavigate} from "react-router-dom";
import styled from "styled-components";
import {loadingAction} from "../../redux/loadingReducer";
import {SERVER_ADDRESS} from "../../index";
import axios from "axios";

const PageWrap = styled.div`
  margin-top: 50px;
  padding: 20px;
  text-align: center;
`

export function MatchingReplyPage() {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const parsed = location.search ? qs.parse(location.search, {ignoreQueryPrefix: true}) : null;
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);

    const [finished, setFinished] = useState(false);
    useEffect(() => {
        dispatch(loadingAction.loading(true));
        let msg;
        if (parsed.payload === 'accept') {
            msg = '매칭 수락';
        } else if (parsed.payload === 'decline') {
            msg = '매칭 거절';
        } else if (parsed.payload === 'holding') {
            msg = '매칭 보류';
        }

        axios.post(SERVER_ADDRESS + '/api/vehicles/matchingRequestReply', {
            thread: parsed.thread,
            payload: msg,
        }).then((res) => {
            if (res.data) {
                if (parsed.payload === 'accept') {
                    alert('매칭 요청이 수락되었어요. 🎉\n' +
                        '담당 매칭매니저가 세부사항 조율을 위해 영업시간(평일 10:00 ~ 19:00) 내에 개별적으로 연락을 드릴 예정이오니 조금만 기다려주세요.\n' +
                        '매칭이 먼저 성사된 차량부터 순차적으로 연락드리고 있으며, 이에 따라 시간이 다소 소요될 수 있는 점 양해부탁드립니다.\n' +
                        '감사합니다.');
                } else if (parsed.payload === 'decline') {
                    alert('매칭요청 거절이 완료되었습니다.\n' +
                        '거절 사유를 채팅방에 남겨주시면 매칭매니저가 확인 후 계약 조건 변경 등 조율을 도와드리겠습니다.\n' +
                        '감사합니다.');
                } else if (parsed.payload === 'holding') {
                    alert('대화해보고 결정하기 선택이 완료되었습니다.');
                    navigate(`/my/chat_start?userId=${res.data.guestId}&vehicleId=${res.data.vehicleId}`);
                    dispatch(loadingAction.loading(false));
                    setFinished(true);
                    return;
                }
            } else {
                alert('이미 처리되었거나 제거된 요청입니다.');
            }
            dispatch(loadingAction.loading(false));
            setFinished(true);
            navigate('/');
        });
    }, []);

    const getMsg = () => {
        if (parsed.payload === 'accept') {
            return '매칭 수락이';
        } else if (parsed.payload === 'decline') {
            return '매칭 거절이';
        } else if (parsed.payload === 'holding') {
            return '대화해보고 결정하기 선택이';
        }
    }

    return (<PageWrap>
        <div>
            {getMsg()} {finished? '완료되었습니다.': '처리중입니다.'}
        </div>
    </PageWrap>);
};
