import {commaNum} from "../../../toolbox";
import styled, {css} from "styled-components";

const VEHICLE_OPTION_LIST = [
    {
        id: "year",
        label: "선루프"
    // }, {
    //     id: ["displacement", "transmissionType"],
    //     label: "헤드램프",
    //     reform: (v, record) => `${commaNum(record?.displacement)}cc / ${record?.transmissionType}`
    }, {
        id: ["fuelType", "color"],
        label: "주차감지센서",
        reform: (v, record) => `${record?.fuelType} / ${record?.color}`
    }, {
        id: "mileage",
        label: "후방카메라",
        reform: (v, record) => `${record?.mileage}km`
    }, {
        id: "options",
        label: "자동에어컨",
        reform: (v, record) => `${record?.options || "옵션정보 없음"}`
    // },{
    //     id: "year",
    //     label: "스마트키"
    // },{
    //     id: "year",
    //     label: "내비게이션"
    },{
        id: "year",
        label: "열선시트"
    },{
        id: "year",
        label: "통풍시트"
    // },{
    //     id: "year",
    //     label: "가죽시트"
    },
]

const VehicleOption = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 84px;
  padding: 8px 0;
  text-align: center;
  float: left;
  white-space: pre-line;
  min-width: 96px;
  width: 30%;
  margin-right: 8px;
  margin-bottom: 8px;

  color: var(--gray-scale-gray-800, #666);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
  
  border-radius: 8px;
  border: 1px solid var(--gray-scale-gray-300, #EFEFEF);
  ${(props) => props.on && css`
    border-radius: 8px;
    border: 1px solid var(--mint, #33CCC1);
    background: rgba(51, 204, 193, 0.10);
    color: var(--gray-scale-black, #212121);
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 157.143% */
  `}
  
  ${(props) => props.onClick && css`
    user-select: none;
    cursor: pointer;`
  }
  
  > .img {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2px;
    > img {
    }
  }
  
  //.--title {
  //  color: #818181;
  //}
`
const _VehicleOptionSection = ({vehicle, className, onToggle}) => {
    const optionOn = (v, option) => {
        if (v?.options) {
            return v.options.includes(option);
        }
        return false;
    }

    return <ul className={className}>
        {VEHICLE_OPTION_LIST.map((info, idx) => {
            const on = optionOn(vehicle, info.label);
            return (
                <VehicleOption key={idx} on={on} onClick={onToggle? () => {
                    onToggle(info.label);
                }: null}>
                    <div className="img">
                        <img src={`/vehicle/options/${info.label}_${on}.svg`} />
                    </div>
                    <div className="--title">{info.label}</div>
                </VehicleOption>
            )
        })}
    </ul>
}

const VehicleOptionSection = styled(_VehicleOptionSection)`
  //padding-left: 16px;
  //padding-right: 16px;

  ::after {
    content: '';
    clear: both;
    display: block;
  }
  
  .--subject {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 10px;
  }
  .--item {
    text-align: center;
    float: left;
    white-space: pre-line;
    width: 20%;
    color: #333333;
    
    opacity: 0.2;
    ${(props) => props.on && css`
      opacity: 1;`
    }
    .--title {
      color: #818181;
    }
  }
`;

export default VehicleOptionSection;
