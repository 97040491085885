import React, {memo} from "react";
import {CircularProgress, Modal} from "@mui/material";
import styled from "styled-components";

const Loading = styled.div`
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;
`

const LoadingIndicator = ({ isVisible, ...props }) => {
    return (
        <Modal open={isVisible}>
            <Loading>
                <CircularProgress color="primary" />
            </Loading>
        </Modal>
    );
};
export default memo(LoadingIndicator);
