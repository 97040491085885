import styled from "styled-components";
import {useRef, useState} from "react";
import ReactSlider from 'react-slider'
import {openKakaoChat, useForm} from "../../toolbox";
import {cloneDeep} from "lodash";
import {DooriveModal} from "../../toolbox/UtilComponent";
import {Alert, Snackbar, TextField} from "@mui/material";
import {useDispatch} from "react-redux";
import {loadingAction} from "../../redux/loadingReducer";
import {v4 as uuidv4} from 'uuid';
import {isNumberPlateFormat, isPhoneFormat, toNumberPlateFormat, toPhoneFormat} from "../../toolbox/format";
import {Link, useNavigate} from "react-router-dom";
import axios from "axios";
import {SERVER_ADDRESS} from "../../index";
import ProfitCalculator from "../vehicle/component/ProfitCalculator";
import {sessionGuardAction} from "../../redux/sessionGuardReducer";
import {toastAction} from "../../redux/toastReducer";
import {EventSwipe} from "./component/EventSwipe";

const PageWrap = styled.div`
`

const StyledSlider = styled(ReactSlider)`
  width: 100%;
  height: 20px;
  margin-top: 14px;
  margin-bottom: 10px;
  overflow: hidden;
  
  .--circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: black;
    z-index: unset !important;
  }
  
  .--track {
    top: 8px;
    width: 100%;
    height: 3px;
    background-color: #666666;
    border-radius: 3px;
  }
  
  .--track-1 {
    top: 8px;
    width: 100%;
    height: 3px;
    background-color: #D9D9D9;
    border-radius: 3px;
  }
`;

const Card1 = styled.div`
  padding: 20px 0;
  > h3 {
    padding: 0 20px;
    font-family: 'Pretendard', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 30px;
    color: #00D0C1;
  }
`
const Card2 = styled.div`
  padding: 40px 20px;
  background: #FCFCFC;
  border-width: 1px 0;
  border-style: solid;
  border-color: #F2F2F2;
  > p {
    font-family: 'Pretendard', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    margin-bottom: 24px;
  }
  
  .--img {
    > img {
      width: 100%;
    }
  }
`

const Card3 = styled.div`
  padding: 80px 20px;

  .--img {
    > img {
      width: 100%;
    }
    margin-bottom: 20px;
  }
  
  > a {
    > p {
      text-align: center;
      font-family: 'Pretendard',sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      text-decoration-line: underline;
      color: #838383;
    }
  }
  > :last-child {
    margin-bottom: 0;
  }
`

const Card4 = styled.div`
  padding: 40px 20px;
  background: #FCFCFC;
  border-width: 1px 0;
  border-style: solid;
  border-color: #F2F2F2;
  
  > p {
    font-family: 'Pretendard',sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    color: #00D0C1;
    margin-bottom: 20px;
    word-break: keep-all;
  }

  .--img {
    > img {
      width: 100%;
    }
    margin-bottom: 20px;
  }
`

const Card5 = styled.div`
  padding: 80px 20px;

  .--img {
    > img {
      width: 100%;
    }
    margin-bottom: 40px;
  }
  > h2 {
    text-align: center;
    font-family: 'Pretendard',sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 150%;
    color: #00D0C1;
    margin-bottom: 12px;
  }
  
  > p {
    font-family: 'Pretendard',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
  }
  
  .--content {
    margin-top: 20px;
    padding: 15px;
    border: 1px solid #F2F2F2;
    border-radius: 5px;
    
    > div {
      > h3 {
        font-family: 'Pretendard',sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 150%;
        color: #00D0C1;
        margin-bottom: 12px;
      }
      
      > p {
        word-break: keep-all;
        font-family: 'Pretendard',sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        
        margin-bottom: 12px;
        :last-child {
          margin-bottom: 0;
        }
      }
      margin-bottom: 40px;
      :last-child {
        margin-bottom: 0;
      }
    }
  }
`

const Card6 = styled.div`
  padding: 0 20px 80px;

  > h2 {
    font-family: 'Pretendard',sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 150%;
    color: #00D0C1;
    text-align: center;
    margin-bottom: 8px;
  }
  > div {
    font-family: 'Pretendard',sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: #6F6F6F;
    
    > div {
      cursor: pointer;
      border-bottom: 1px solid #F2F2F2;
      padding-top: 12px;
      padding-bottom: 12px;
      > img {
        margin-left: 8px;
      }
      
      font-family: 'Pretendard',sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      
      > div {
        font-family: 'Pretendard',sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 160%;
        color: #000000;
        padding: 6px 12px;
        margin-top: 6px;
        background-color: #EEEEEE;
        border-radius: 12px;
      }
    }
  }
`

const Card7 = styled.div`
  //padding: 0 20px 80px;

  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 20px;
  > h2 {
    font-family: 'Pretendard',sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 150%;
    color: #00D0C1;
    text-align: center;
    margin-bottom: 12px;
  }
  > div {
    font-family: 'Inter',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    justify-content: center;
    color: #707070;
    
    > :first-child {
      margin-right: 14px;
    }
    a {
      flex-grow: 1;
    }
    div {
      text-align: center;
      padding-top: 15px;
      padding-bottom: 15px;
      flex-grow: 1;
      border: 1px solid #707070;
      border-radius: 5px;
      cursor: pointer;
    }
  }
`

const QNA = ({title, children}) => {
    const [open, setOpen] = useState(false);
    return <div onClick={() => setOpen(!open)}>
        Q. &nbsp;{title}
        {open? <img src="/event/chevron_up.svg" />: <img src="/event/chevron_down.svg" />}
        {open && <>{children}</>}
    </div>
}

const _RequestBtn = ({className, onClick}) => {
    const writeRef = useRef();
    return <div className={className}>
    {/*return <div className={className}>*/}
    {/*    <a target="_blank" href="https://forms.gle/rXq3dZ56DynPA7fP9">*/}
        <Btn onClick={onClick}>
            내차번호로 예상수익 조회하기
        </Btn>
        <Btn onClick={() => writeRef.current.click()}>
            내차 바로등록하기
        </Btn>
        {/*<div className="kakao" onClick={() => openKakaoChat("host_landing_1on1_chat", null)}>*/}
        {/*    <img src="/vehicle/kakao_icon.png"/>*/}
        {/*    톡상담*/}
        {/*</div>*/}
        <Link to="/vehicle/write" ref={writeRef}/>
        {/*</a>*/}
    </div>
}

const Btn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  font-family: 'Pretendard',sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  white-space: pre-wrap;
  word-break: keep-all;
  color: white;
  padding: 12px 16px;
  border-radius: 8px;
  margin-right: 12px;
  &:hover {
    cursor: pointer;
  }
`

const RequestBtn = styled(_RequestBtn)`
  display: flex;
  justify-content: space-between;
  padding: 15px 20px 40px;
  z-index: 0;
  position: sticky;
  bottom: 0px;
  width: 100%;
  max-width: 450px;
  background: #ffffff;
  box-sizing: border-box;

  //filter: drop-shadow(0px -20px 12px rgba(0, 0, 0, 0.07));
  
    div {
      z-index: 10;
      flex-grow: 1;
      &:hover {
        cursor: pointer;
      }
      :first-child {
        background-color: #5ECDC0;
        word-break: keep-all;
      }
      :nth-child(2) {
        background: black;
        word-break: keep-all;
      }
      :last-child {
        margin-right: 0;
      }
    }

.kakao {
  img {
    height: 20px;
    object-fit: none;
  }
  background-color: #FEE500;
  color: black;
}
`
const HostLanding2Page = () => {
    const submitLink = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // const [차량시세, set차량시세] = useState(2500);
    // const [보험세금, set보험세금] = useState(120);
    // const [정비비, set정비비] = useState(300);
    // const [리터당주유비, set리터당주유비] = useState(1700);
    //
    //
    // const [감가분담액, set감가분담액] = useState(차량시세*0.15);
    // const [보험세금분담액, set보험세금분담액] = useState(120/2);
    //
    // const 연비 = 10;  // 리터당 주행거리
    // const 연간게스트주행거리 = 10000;
    // const [거리당비용, set거리당비용] = useState();
    // const [거리당주유비, set거리당주유비] = useState();
    // const [연간주유비, set연간주유비] = useState();
    //
    // const [거리당정비비, set거리당정비비] = useState();

    const [open, setOpen] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const { form, onChange, resetValue } = useForm({name: '', number: '', phone: '010-'});

    const { form: calculatorForm, onChange: calculatorChange } = useForm({
        차량시세: 4000,
        보험세금: 120,
        예상거리당비용: 170,
        // 정비비: 300,
        // 리터당주유비: 1700,
    });

    const getModalTitle = () => {
       if (form.phase === 1) {
           return "내 차 번호로 예상수익 조회하기";
       }
       else if (form.phase === 2) {
           return "알림톡으로 결과를 보내드려요";
       }
    }

    const getModalContent = () => {
        if (form.phase === 1) {
            return <>
                <TextField id="number" label="차량번호" value={form.number} placeholder="11하2022" onChange={(e) => {
                    const targetValue = e.target.value;
                    if (targetValue && !/^\d/.test(targetValue)) {
                        dispatch(toastAction.toast({severity: 'error', 'message': '올바른 차량번호를 입력해주세요.'}));
                    } else {
                        onChange({id: 'number', value: toNumberPlateFormat(targetValue)})}
                    }
                } />
                <div/>
                <br/>
                <TextField id="name" label="차량 소유주명" value={form.name} placeholder="홍길동 또는 롯데렌탈(주)" onChange={(e) => {
                    // let result = e.target.value.replace(/[^a-z|A-Z|가-힣|ㄱ-ㅎ]/g, "");
                    onChange({id: 'name', value: e.target.value})}
                } />
                <p style={{paddingTop: '12px', lineHeight: '130%', maxWidth: '250px', whiteSpace:'pre-wrap', wordBreak: 'keep-all'}}>
                    ⚠️정부에서 운영하는 차량조회시스템에서 조회가 이뤄지며 마케팅 등 다른 용도로 활용되지 않습니다. 렌터카, 리스차인 경우 차량등록증 상의 회사명(소유주명)을 정확하게 입력해야 조회가 가능합니다.<br/><br/>
                    (예시)<br/>
                    - 현대캐피탈 주식회사<br/>
                    - 케이비캐피탈(주)<br/>
                    - 신한캐피탈(주)<br/>
                    - 하나캐피탈(주)<br/>
                    - 롯데렌탈(주)<br/>
                    - 에스케이렌터카(주)
                </p>
            </>;
        }
        else if (form.phase === 2) {
            return <TextField id="phone" type="tel" label="휴대폰 번호" value={form.phone} placeholder="010-1234-5678" onChange={(e) => {
                onChange({id: 'phone', value: toPhoneFormat(e.target.value)})}
            } />;
        }
    }

    const valueCheck = () => {
        if (form?.phase === 1) {
            return isNumberPlateFormat(form?.number) && form?.name.length > 1;
        } else if (form?.phase === 2) {
            return isPhoneFormat(form?.phone);
        }
        return false;
    }

    const getModalActions = () => {
        if (form.phase === 1) {
            return [{title: '다음', onClick: () => {
                if (!valueCheck()) {
                    alert('올바른 차량번호를 입력해주세요')
                    return;
                }
                onChange({id: 'phase', value: 2});
            }}];
        }
        else if (form.phase === 2) {
            return [{title: '완료', onClick: () => {
                if (!valueCheck()) {
                    alert('올바른 휴대폰 번호를 입력해주세요')
                    return;
                }
                dispatch(loadingAction.loading(true));
                window.fbq('track', 'Lead');
                axios.post(SERVER_ADDRESS + '/api/used-vehicles/getEstimate2', {name: form?.name, numberPlate: form?.number, phone: form?.phone}, {}).then((res) => {
                    console.log(res);
                    const payload = res.data;
                    if (payload.errYn === 'N') {
                        const uid = uuidv4().replace(/-/g, '');
                        dispatch(sessionGuardAction.update({key: uid, value: payload}));
                        navigate(`/host_result?key=${uid}`);
                    } else {
                        alert('소유주명과 차량번호를 다시 한번 확인해주세요.\n' + payload.errMsg);
                    }
                    dispatch(loadingAction.loading(false));
                    setOpen((prev) => false);
                    setToastOpen(true);
                }).catch(() => {
                    const _form = cloneDeep(form);
                    delete _form.phase;
                    const payload = JSON.stringify(_form, null, 2);
                    setOpen((prev) => false);
                    dispatch(loadingAction.loading(false));
                });
            }}];
        }
    }

    return <PageWrap>
        <EventBanner style={{padding: '16px'}}>
            <EventSwipe images={[{link: '/vehicle/write', url: '/event/detailing50.png'}]} />
        </EventBanner>
        <Card1>
            <h3>
                자주 안타는 내 차,<br/>
                검증된 게스트 1명과 공유하세요!
            </h3>
        </Card1>
        <ProfitCalculator form={calculatorForm} onChange={calculatorChange} onIdkValue={() => {
            onChange({id: 'phase', value: 1});
            setOpen((prev) => true);
        }}/>
        <div style={{display: 'flex', justifyContent: 'center', paddingBottom: '40px'}}>
            <Btn style={{backgroundColor: '#33CCC1'}} onClick={() => {
                navigate("/vehicle/write");
            }}>위 금액으로 관심 있는 게스트 알림 받아보기 🔔</Btn>
        </div>

        {/*<Card2>*/}
        {/*    <div style={{margin: '20px 8px 10px', padding: '12px', border: '1px solid #DDDDDD'}}>*/}
        {/*        <img style={{width: '100%'}} src="/event/post3.png" />*/}
        {/*    </div>*/}
        {/*</Card2>*/}

            {/*<div>*/}
            {/*    <h1>예상 수익</h1>*/}
            {/*    <MB16 />*/}
            {/*    <div className="result">*/}
            {/*        /!*₩ {commaNum(감가분담액 * 12 + 거리당비용 * 10000 + 1000000)}원 / 년<br/>*!/*/}
            {/*        /!*<span>(₩ {commaNum((Math.floor((감가분담액 * 12 + 거리당비용 * 10000 + 1000000) / 12)))}원 / 월)</span>*!/*/}
            {/*        {commaNum(감가분담액+보험세금분담액+연간주유비+정비비/2)}만원/년*/}
            {/*        /!*<p>(₩ {commaNum((감가분담액+보험세금분담액+연간주유비+정비비)/12*10000)}원 / 월)</p>*!/*/}
            {/*    </div>*/}
            {/*    <MB16/>*/}

            {/*    /!*<div className="input-field">*!/*/}
            {/*    /!*    <div className="title">공유수익</div>*!/*/}
            {/*    /!*    <input type="tel" maxLength={7} disabled={true} value={감가분담액} onChange={(e) => {*!/*/}
            {/*    /!*        set감가분담액(Number(toNumberFormat(e.target.value)))*!/*/}
            {/*    /!*    }} />*!/*/}
            {/*    /!*    <div className="won">원 / 월</div>*!/*/}
            {/*    /!*</div>*!/*/}
            {/*    /!*<div className="input-field">*!/*/}
            {/*    /!*    <div className="title">관리수익</div>*!/*/}
            {/*    /!*    <input maxLength={4} type="tel" disabled={true} value={거리당비용} onChange={(e) => {*!/*/}
            {/*    /!*        set거리당비용(Number(toNumberFormat(e.target.value)))*!/*/}
            {/*    /!*    }} />*!/*/}
            {/*    /!*    <div className="won">원 / km</div>*!/*/}
            {/*    /!*</div>*!/*/}
            {/*    /!*<div className="input-field">*!/*/}
            {/*    /!*    <div className="title">보험/세금 분담액</div>*!/*/}
            {/*    /!*    <input type="tel" value={1000000} disabled={true} />*!/*/}
            {/*    /!*    <div className="won">원 / 년</div>*!/*/}
            {/*    /!*</div>*!/*/}
            {/*    /!*<MB32/>*!/*/}

            {/*    <br/>*/}
            {/*    <p><h3>연간 고정비용 ({감가분담액 + 보험세금분담액}만원/년)</h3></p>*/}
            {/*    <br/>*/}
            {/*    <p><b>(1-1) 감가분담액: {commaNum(감가분담액)}만원/년 </b></p>*/}
            {/*    <p>내 차의 현재 시세는 얼마인가요? {commaNum(차량시세)}만원</p>*/}
            {/*    <StyledSlider*/}
            {/*        thumbClassName="--circle"*/}
            {/*        trackClassName="--track"*/}
            {/*        defaultValue={25}*/}
            {/*        min={3}*/}
            {/*        max={100}*/}
            {/*        onChange={onChange차량시세}*/}
            {/*    />*/}

            {/*    <p><b>(1-2) 보험세금분담액: {commaNum(보험세금분담액)}만원/년 </b></p>*/}
            {/*    <p>1년간 납부하는 보험 및 자동차세는 얼마인가요? {commaNum(보험세금)}만원</p>*/}
            {/*    <StyledSlider*/}
            {/*        thumbClassName="--circle"*/}
            {/*        trackClassName="--track"*/}
            {/*        defaultValue={120}*/}
            {/*        step={2}*/}
            {/*        min={50}*/}
            {/*        max={400}*/}
            {/*        onChange={onChange보험세금}*/}
            {/*    />*/}

            {/*    <MB16/>*/}
            {/*    <p><h1>+</h1></p>*/}
            {/*    <br/>*/}
            {/*    <p><h3>거리당 비용 ({거리당주유비 + 거리당정비비}원/km, {연간주유비+정비비/2}만원/년)</h3></p>*/}
            {/*    <br/>*/}
            {/*    <p><b>(2-1) 거리당 주유비: {commaNum(거리당주유비)}원/km, {commaNum(연간주유비)}만원/년 </b></p>*/}
            {/*    <p>게스트에게 허용하는 리터당 주유비 : {commaNum(리터당주유비)}원</p>*/}
            {/*    <StyledSlider*/}
            {/*        thumbClassName="--circle"*/}
            {/*        trackClassName="--track"*/}
            {/*        defaultValue={1700}*/}
            {/*        step={10}*/}
            {/*        min={1000}*/}
            {/*        max={2500}*/}
            {/*        onChange={onChange주유비}*/}
            {/*    />*/}

            {/*    <MB16/>*/}
            {/*    <p><b>(2-2) 거리당 정비비: {거리당정비비}원/km, {정비비/2}만원/년</b></p>*/}
            {/*    <p>엔진오일, 타이어, 소모품 교환 등 1년간 발생하는 정비비는 총 얼마인가요? {정비비}만원</p>*/}
            {/*    <StyledSlider*/}
            {/*        thumbClassName="--circle"*/}
            {/*        trackClassName="--track"*/}
            {/*        defaultValue={300}*/}
            {/*        step={2}*/}
            {/*        min={10}*/}
            {/*        max={500}*/}
            {/*        onChange={onChange정비비}*/}
            {/*    />*/}
            {/*    <MB32 />*/}
            {/*    <p>*/}
            {/*        <h3>예상수익: 총 {commaNum(감가분담액+보험세금분담액+연간주유비+정비비/2)}만원/년</h3>*/}
            {/*        (거리당 비용 270원/km, 게스트가 1년간 10,000km 주행 가정)*/}
            {/*    </p>*/}

            {/*    /!*<h2>₩ {commaNum(예상수익(slider))}원 / 월</h2>*!/*/}
            {/*    /!*<h3>+ 게스트 1km 주행당 {주유비 / 10 + 정비비 / 2}원</h3>*!/*/}
            {/*    /!*<p>* 단순 참고용이며, 실제 등록 시 개별 액수 설정 가능합니다.</p>*!/*/}
            {/*    /!*<p>* 게스트가 1년간 10,000km 주행하고, 자동차세 및 보험료 합산 200만원으로 가정한 결과입니다. (연비10km/ℓ)</p>*!/*/}
            {/*</div>*/}
        {/*<Card2>*/}
        {/*    <p>*/}
        {/*        차를 적게 타도<br/>*/}
        {/*        매월 할부금, 보험료는 똑같이 빠져나가고<br/>*/}
        {/*        매년 중고차가격도 뚝뚝 떨어지죠  😢*/}
        {/*    </p>*/}
        {/*    <p>*/}
        {/*        내 이웃과 월 유지비를 분담하여<br/>*/}
        {/*        경제적인 자동차공유를 경험해보세요!*/}
        {/*    </p>*/}
        {/*    <div className="--img">*/}
        {/*        <img src="/event/doori_map.png" />*/}
        {/*    </div>*/}
        {/*</Card2>*/}
        {/*<Card3>*/}
        {/*    <div className="--img">*/}
        {/*        <img src="/event/card3.png" />*/}
        {/*    </div>*/}
        {/*    /!*<a target="_blank" href="https://copper-abrosaurus-467.notion.site/18bad599712044069d3e3d31b9147ec9">*!/*/}
        {/*    /!*    <p>두리카가 예상 수익을 산정하는 방법</p>*!/*/}
        {/*    /!*</a>*!/*/}
        {/*</Card3>*/}


        {/*<Card4>*/}
        {/*    <p>나와 이용패턴이 잘 맞고, 매너가 좋은 이웃 한명을 선택하여 안정적인 공유를 할 수 있습니다.</p>*/}
            {/*<div className="--img">*/}
            {/*    <img src="/event/post.png" />*/}
            {/*</div>*/}
        {/*</Card4>*/}
        <Card5>
            <div className="--img">
                <img src="/event/card5.png" />
            </div>
            <h2 style={{marginBottom: '20px'}}>호스트를 위한 두리커버</h2>
            <p>여러분의 소중한 자동차,<br/>
            두리카에서 책임지고 보장해드립니다.</p>
            <div className="--content">
                <div>
                    <h3>1억 한도 100% 선보상</h3>
                    <p>게스트가 차량을 도난, 횡령, 손괴하는 등 자동차보험으로 보장되지 않는 범죄 유형의 손해가 발생할 경우, 두리카에서 최대 1억원까지 선보상해드립니다.</p>
                    <p>아울러, 두리카의 협력사인 ‘법무법인 웨이브’에서 필요한 모든 민형사상 조치를 대리하며 관련 비용을 두리카에서 전부 부담합니다.</p>
                </div>
                <div>
                    <h3>사고 시 위로금 보장</h3>
                    <p>게스트가 사고를 내면 보험료가 할증되고 차량이 감가되어 호스트에게 손해가 발생할 수 있습니다.</p>
                    <p>따라서 게스트는 사고 발생 시 보험료 할증, 감가상각, 수리기간 동안 차를 이용하지 못하는 손해 등에 대한 포괄적인 손해보상으로 충분한 액수의 ‘위로금’을 지급해야합니다.<br/>(세부금액 기준은 공동사용규칙 참조)</p>
                </div>
                <div>
                    <h3>미납 시 결제 보증</h3>
                    <p>게스트가 월이용료, 위로금 등 결제금액 납부를 지연하거나 거부할 경우, 두리카에서 전액을 호스트에게 대신 지급하여 미납금이 발생하지 않도록 100% 보장해드립니다.</p>
                </div>

                <div>
                    <h3>잔기스 무상수리</h3>
                    <p>공유기간 중에 잔기스(클리어 층 손상으로 파우더 처리 가능한 손상)가 발생할 경우, 두리카에서 협력 정비업체를 통해 무상으로 제거해드립니다.<br/>(년 1회 제공)</p>
                </div>
                <div>
                    <h3>안전운행 모니터링</h3>
                    <p>차량마다 ‘두리플러그’가 지급되어 주행거리를 측정하는 것은 물론, 급출발, 급가속, 급정거, 과속 등 무리한 운전 습관을 적발할 수 있습니다.</p>
                    <p>게스트의 불량한 운전 습관이 발견되면 호스트에게 통지되며, 호스트는 1차 경고 후 동일 패턴 반복 시 1개월 치 월이용료를 몰수하고 공유를 즉시 중단할 수 있습니다.</p>
                </div>
                <div>
                    <h3>검증된 게스트</h3>
                    <p>
                        철저한 가입 검증 시스템을 통해<br/>
                        이름, 주소, 신분증 등의 상세 정보를 확보함으로써 게스트의 신원을 확인합니다.
                    </p>
                    <p>
                        게스트의 매너를 여러 요소로 평가하여 차를 공유할 자격이 없는 사람은 플랫폼에서 영구히 퇴출됩니다.
                    </p>
                </div>
                <div>
                    <h3>1:1 밀착 관리</h3>
                    <p>차량마다 담당 책임매니저가 단톡방에 상주하면서 모든 운영을 처음부터 끝까지 책임집니다.</p>
                </div>
                <div>
                    <h3>긴급차량 지원금</h3>
                    <p>일정 충돌로 인하여 차를 사용하지 못할 경우, 쏘카 대여금액의 30%를 지원해드립니다. (분기당 1회, 연간 4일)</p>
                </div>
            </div>
        </Card5>
        <Card6>
            <h2>자주 묻는 질문과 답변</h2>
            <div className="--question">
                <QNA title="제 차가 두리카에 적합할까요?">
                    <div>
                        두리카 게스트가 찾는 차는 매우 다양합니다.<br/>
                        차종, 연식, 연료타입 등 제한없이 호스팅이 가능합니다. 지금 바로 시작해보세요!
                    </div>
                </QNA>
                <QNA title="얼마동안 호스팅해야하나요?">
                    <div>
                        게스트와 매칭이 이뤄지면 최소 1개월 동안 공유를 유지해야합니다.<br/>
                        그 이후부터는 매월 1개월씩 자동으로 연장됩니다.<br/>
                        자동연장을 원치 않는 경우에는, 최소 한달 이전에 해지를 신청해주세요!
                    </div>
                </QNA>
                <QNA title="게스트를 선택할 수 있나요?">
                    <div>
                        게스트가 매칭을 요청하면 프로필이 호스트에게 전달되며, 매칭매니저가 초대하는 카톡방에서 자유롭게 대화를 나눠볼 수 도 있습니다.<br/>
                        충분히 고민해보시고, 괜찮은 게스트라고 판단이 될 경우에만 매칭을 수락해주세요!
                    </div>
                </QNA>
                <QNA title="좋은 호스트가 되기 위한 팁이 있나요?">
                    <div>
                        기본에 충실한 것이 중요합니다.<br/>
                        차를 깨끗하게 관리하고, 게스트에게 신속하게 답변하며, 핸드폰 거치대 등 편의제품을 갖춰보세요.<br/>
                        향기가 좋은 방향제를 꽂아놓는 등 세심한 배려를 보이면 게스트로부터 좋은 평가를 받을 수 있습니다.
                    </div>
                </QNA>
                <QNA title="두리카 수수료는 얼마인가요?">
                    <div>
                        게스트가 납부하는 금액에서 7%가 관리 수수료로 차감됩니다.<br/>
                        두리카는 매칭이 이뤄진 호스트의 차량을 방치하지 않습니다. 매칭 시작부터 종료 시까지 원활한 공유를 위해 1:1 전담 매니저가 단톡방에 상주하면서 사용일정 관리 등 모든 운영을 지원해드립니다.
                    </div>
                </QNA>
            </div>
        </Card6>
        <Card7>
            <h2>더 궁금하신 점이 있나요?</h2>
            <div>
                <a target="_blank" href="https://copper-abrosaurus-467.notion.site/1a2c696f5199403a938b3b6abf3c1b64">
                    <div>상세가이드 확인하기</div>
                </a>
                <div onClick={() => {
                    openKakaoChat("host_landing_1on1_chat", null);
                    // openChannelTalk("host_landing_1on1_chat", null, '차량 호스팅에 대해 문의가 있어요!')
                }}>
                    실시간 1:1 문의하기
                </div>
            </div>
        </Card7>
        <RequestBtn onClick={() => {
            onChange({id: 'phase', value: 1});
            setOpen((prev) => true);
        }} />
        <DooriveModal open={open} onClose={() => setOpen((prev) => false)} title={getModalTitle()} actions={getModalActions()}>
            {getModalContent()}
        </DooriveModal>
        <Snackbar anchorOrigin={{vertical: 'bottom', horizontal: 'center',}} autoHideDuration={3000} open={toastOpen} onClose={() => setToastOpen(() => false)} >
            <Alert severity="success">견적 신청이 완료되었습니다.</Alert>
        </Snackbar>
    </PageWrap>
}

export default HostLanding2Page;

const EventBanner = styled.div`
    margin: 0;
`
