import styled from "styled-components";
import React, {useEffect, useRef, useState} from "react";
import {MB16, MB32} from "../../../toolbox/UtilComponent";
import {commaNum} from "../../../toolbox";
import ReactSlider from "react-slider";
import {Popover, Popper} from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import {calcOil, calcTax, fillUsedCarInfo} from "../../../toolbox/calculate";
import {getDescriptionSimple} from "./VehicleUseInfoSection";

const StyledSlider = styled(ReactSlider)`
  width: 100%;
  height: 32px;
  margin-top: 24px;
  margin-bottom: 24px;
  overflow: hidden;
  
  .--circle {
    display: flex;
    align-items: center;
    height: 32px;
    border-radius: 100px;
    padding-left: 10px;
    padding-right: 10px;
    font-weight: 700;
    font-size: 16px;
    background-color: black;
    color: white;
    z-index: unset !important;
    user-select: none;
    white-space: nowrap;
    //top: 0;
  }
  
  .--track {
    top: 14px;
    width: 100%;
    height: 3px;
    background-color: #000000;
    border-radius: 3px;
  }
  
  .--track-1 {
    top: 14px;
    width: 100%;
    height: 3px;
    background-color: #E8E8E8;
    border-radius: 3px;
  }
`;

const 연비 = 10;
const 월간게스트주행거리 = 1000;


const MyPopper = styled.div`
  pointer-events: none;
  background-color: white;
  box-shadow: 0 1px 3px hsla(0,0%,0%,0.06), 0 2px 6px hsla(0, 0%, 0%, 0.06), 0 3px 8px hsla(0, 0%, 0%, 0.09);
  border-radius: 4px;
  padding: 12px;
  margin-top: 8px;
  line-height: 17px;

  > div {
    margin-bottom: 12px;
  }

  > :last-child {
    margin-bottom: 0;
  }
`

const OilButton = () => {
    const guideIcon = useRef();
    const [guide, setGuide] = useState(false);
    return <>
        <HelpIcon ref={guideIcon} fontSize="16px" onMouseEnter={() => setGuide(true)} onMouseLeave={() => setGuide(false)} />
        <Popover open={guide} anchorEl={guideIcon.current} style={{pointerEvents: "none"}}>
            <div style={{width: "200px", overflow: "hidden", padding: "8px", lineHeight: "22px"}}>
                게스트는 주행한 거리만큼 ‘거리당 주유비’를 납부합니다.<br/>
                대신, 실제로 결제되는 차량의 총 주유비는 호스트가 부담합니다.<br/>
                리터당 기름값을 연비로 나누면 거리당 주유비가 계산됩니다.<br/>
                (예시)<br/>
                - 1리터당 1,800원이고 연비가 10km/l라면 ⇒ 180원/km<br/>
                - 1리터당 1,800원이고 연비가 12km/l라면 ⇒ 150원/km<br/>
                - 1리터당 1,800원이고 연비가 15km/l라면 ⇒ 120원/km<br/>
            </div>
        </Popover>
    </>
}
const RepairButton = () => {
    const guideIcon = useRef();
    const [guide, setGuide] = useState(false);
    return <>
        <HelpIcon ref={guideIcon} fontSize="16px" onMouseEnter={() => setGuide(true)} onMouseLeave={() => setGuide(false)} />
        <Popover open={guide} anchorEl={guideIcon.current} style={{pointerEvents: "none"}}>
            <div style={{width: "200px", overflow: "hidden", padding: "8px", lineHeight: "22px"}}>
                게스트는 주행한 거리만큼 ‘거리당 정비비’를 납부합니다.<br/>
                엔진오일교환, 타이어교환 등 소모품교체 및 각종 정비에 관한 비용을 모두 포함합니다.<br/>
                (예시) 연간 정비비가 300만원이고 총 주행거리가 2만km라면 ⇒ 150원/km
            </div>
        </Popover>
    </>
}

//
const ProfitCalculator = ({form, onChange, onIdkValue}) => {
    const guideIcon1 = useRef();
    const [guide1, setGuide1] = useState(false);

    const guideIcon2 = useRef();
    const [guide2, setGuide2] = useState(false);

    const guideIcon3 = useRef();
    const [guide3, setGuide3] = useState(false);

    const guideIcon4 = useRef();
    const [guide4, setGuide4] = useState(false);

    useEffect(() => {
        onChange차량시세(form.차량시세);
        onChange보험세금(form.보험세금);
        onChange예상거리당비용(form.예상거리당비용);
    }, []);


    const usedCarInfo = fillUsedCarInfo(form.차량시세 * 10000, 2022);
    const tax = calcTax(2022, 1998);

    const 감가분담액 = () => {
        return Math.round(usedCarInfo.월간감가분담액 / 1000) * 1000;
    }

    const 보험분담액 = () => {
        return Math.round(usedCarInfo.월간보험분담액 / 1000) * 1000;
    }

    const 세금분담액 = () => {
        return Math.round(tax / 12 / 2 / 1000) * 1000;
    }

    const 정비분담액 = () => {
        return Math.round(usedCarInfo.월간정비분담액 / 1000) * 1000;
    }

    const 주유비 = () => {
        return 0;
        return form.예상거리당비용 * 월간게스트주행거리;
    }

    const 월이용료 = (useType) => {
        let 월수익 = 감가분담액() + 정비분담액() + 보험분담액() + 세금분담액();
        if (useType === 'PRIORITY_HOST') {
            월수익 = 월수익 * 0.5;
        } else if (useType === 'PRIORITY_GUEST') {
            월수익 = 월수익 * 1.5;
        }
        return 월수익;
    }

    const 월간추정수익 = (useType) => {
        return 월이용료(useType) + 주유비();
    }

    const onChange차량시세 = (v) => {
        onChange({id: "차량시세", value: v});
    }

    const onChange보험세금= (v) => {
        onChange({id: "보험세금", value: v});
    }

    const onChange예상거리당비용 = (v) => {
        onChange({id: "예상거리당비용", value: v});
    }

    return <_ProfitCalculator>
        <div className="wrap">
            <div className="top-box">
                <div className="profit">
                    예상 수익 : <span className="pink">₩ {commaNum(월간추정수익('PRIORITY_GUEST') * 12)}원 / 년</span>
                </div>
                <div className="desc">
                    게스트우선형 월이용료 기준
                </div>
            </div>
            <div className="slider-box">
                <div className="title">
                    내 차의 현재 시세는 얼마인가요?
                </div>
                <StyledSlider
                    className="horizontal-slider"
                    thumbClassName="--circle"
                    trackClassName="--track"
                    defaultValue={4000}
                    step={100}
                    min={300}
                    max={15000}
                    value={form.차량시세}
                    onChange={onChange차량시세}
                    renderThumb={(props, state) => <div {...props}>{commaNum(state.valueNow)}만원</div>}
                />

                <p style={{lineHeight: '120%', marginBottom: '30px'}}>
                    내 차의 시세를 잘 모른다면 <span style={{cursor: 'pointer', textDecoration: 'underline', color: '#44798f'}} onClick={() => onIdkValue()}>‘내차번호로 예상수익 조회하기’</span> 를 이용해주세요.
                </p>
                <div className="desc">
                    <span>
                        호스트우선형 <HelpIcon ref={guideIcon1} fontSize="16px" onMouseEnter={() => setGuide1(true)} onMouseLeave={() => setGuide1(false)} />
                        <Popover open={guide1} anchorEl={guideIcon1.current} style={{pointerEvents: "none"}}>
                        <div style={{whiteSpace: "pre-wrap", width: "200px", overflow: "hidden", padding: "8px", lineHeight: "22px"}}>
                            {getDescriptionSimple('PRIORITY_HOST')}
                        </div>
                        </Popover> : {commaNum(월이용료('PRIORITY_HOST'))}원 / 월
                    </span>
                </div>
                <div className="desc">
                    <span>
                        요일지정형 <HelpIcon ref={guideIcon2} fontSize="16px" onMouseEnter={() => setGuide2(true)} onMouseLeave={() => setGuide2(false)} />
                        <Popover open={guide2} anchorEl={guideIcon2.current} style={{pointerEvents: "none"}}>
                        <div style={{whiteSpace: "pre-wrap", width: "200px", overflow: "hidden", padding: "8px", lineHeight: "22px"}}>
                            {getDescriptionSimple('DAY_SELECT')}
                        </div>
                        </Popover> : {commaNum(월이용료('DAY_SELECT'))}원 / 월
                    </span>
                </div>
                <div className="desc">
                    <span>
                        게스트우선형 <HelpIcon ref={guideIcon3} fontSize="16px" onMouseEnter={() => setGuide3(true)} onMouseLeave={() => setGuide3(false)} />
                        <Popover open={guide3} anchorEl={guideIcon3.current} style={{pointerEvents: "none"}}>
                        <div style={{whiteSpace: "pre-wrap", width: "200px", overflow: "hidden", padding: "8px", lineHeight: "22px"}}>
                            {getDescriptionSimple('PRIORITY_GUEST')}
                        </div>
                        </Popover> : {commaNum(월이용료('PRIORITY_GUEST'))}원 / 월
                    </span>
                </div>
                <p style={{lineHeight: '120%', marginTop: '12px'}}>
                    위 금액은 추정치이며 실제 매칭유형과 월이용료는 호스트가 자유롭게 정할 수 있습니다.
                </p>
            </div>

            {/*<div className="slider-box">*/}
            {/*    <div className="title">*/}
            {/*        연간 보험료 및 자동차세는 얼마인가요?*/}
            {/*    </div>*/}
            {/*    <StyledSlider*/}
            {/*        thumbClassName="--circle"*/}
            {/*        trackClassName="--track"*/}
            {/*        defaultValue={120}*/}
            {/*        step={2}*/}
            {/*        min={0}*/}
            {/*        max={400}*/}
            {/*        onChange={onChange보험세금}*/}
            {/*        renderThumb={(props, state) => <div {...props}>{commaNum(state.valueNow)}만원</div>}*/}
            {/*    />*/}
            {/*    <div className="desc">*/}
            {/*        <span>*/}
            {/*            보험세금분담액 : {commaNum(보험세금분담액())}만원/년*/}
            {/*        </span>*/}
            {/*    </div>*/}
            {/*</div>*/}

            {/*<div className="slider-box">*/}
            {/*    <div className="title">*/}
            {/*        게스트가 1km를 주행할 때마다 받을 거리당주유비는 얼마인가요?*/}
            {/*    </div>*/}
            {/*    <StyledSlider*/}
            {/*        thumbClassName="--circle"*/}
            {/*        trackClassName="--track"*/}
            {/*        defaultValue={170}*/}
            {/*        step={5}*/}
            {/*        min={0}*/}
            {/*        max={600}*/}
            {/*        value={form.예상거리당비용}*/}
            {/*        onChange={onChange예상거리당비용}*/}
            {/*        renderThumb={(props, state) => <div {...props}>{commaNum(state.valueNow)}원</div>}*/}
            {/*    />*/}
            {/*    <div className="desc">*/}
            {/*        <span>*/}
            {/*            주유비<HelpIcon ref={guideIcon4} fontSize="16px" onMouseEnter={() => setGuide4(true)} onMouseLeave={() => setGuide4(false)} />*/}
            {/*            <Popover open={guide4} anchorEl={guideIcon4.current} style={{pointerEvents: "none"}}>*/}
            {/*            <div style={{whiteSpace: "pre-wrap", width: "200px", overflow: "hidden", padding: "8px", lineHeight: "22px"}}>*/}
            {/*                차종, 연식, 주변 주유소 기름값 등을 종합적으로 고려하여 호스트가 결정할 수 있습니다.*/}
            {/*            </div>*/}
            {/*            </Popover> : {commaNum(주유비())}원 / 월*/}
            {/*        </span>*/}
            {/*    </div>*/}
            {/*</div>*/}

            <div className="bottom-box">
                <div className="profit">
                    예상 수익 : <span className="pink">₩ {commaNum(월간추정수익('PRIORITY_GUEST') * 12)}원 / 년</span>
                </div>
                <div className="desc">(게스트우선형 월이용료 기준)</div>
            </div>
            {/*<p className="h3">예상수익: 총 {commaNum(감가분담액() + 보험세금분담액() + (연간거리당비용() / 10000))}만원/년</p>*/}
        </div>
    </_ProfitCalculator>
}


const _ProfitCalculator = styled.div`
  > .wrap {
    padding: 20px;
    //background: #FCFCFC;
    //border-width: 1px;
    //border-style: solid;
    //border-color: #D9D9D9;
    
    > .top-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: #F7F7F7;
      border-radius: 8px;
      padding: 16px 0;
      margin-bottom: 36px;
      > .profit {
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 12px;
        > .pink {
          font-weight: 800;
          color: #FF8070;
        }
      }
      > .desc {
        font-size: 14px;
        font-weight: 400;
        color: #A9A9A9;
      }
    }
    
    > .slider-box {
      margin-bottom: 48px;
      > .title {
        font-size: 21px;
        font-weight: 700;
        line-height: 150%;
      }
      
      > p {
        font-size: 14px;
        font-weight: 500;
        color: #A9A9A9;
        margin-top: 8px;
      }
      
      > .desc {
        margin: 4px 0;
        display: flex;
        
        > span {
          padding: 8px;
          background-color: #F5F5F5;
          border-radius: 4px;
          font-size: 14px;
          font-weight: 700;
          color: #6D6D6D;
        }
      }
    }


    > .bottom-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      padding: 20px 0;
      > .profit {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 8px;
        > .pink {
          font-weight: 800;
          color: #FF8070;
        }
      }
      > .desc {
        font-size: 12px;
        font-weight: 400;
        color: #A9A9A9;
      }
    }
  }
`;

export default ProfitCalculator;
