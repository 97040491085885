import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useMutation} from "@apollo/client";
import {GET_ME, UPDATE_USER} from "../../../query/userQuery";
import {GUEST_TAGS, GUEST_WANTED} from "../../../toolbox/guestTag";
import {filter, find, isEmpty} from "lodash";
import {pathAction} from "../../../redux/pathReducer";
import {openAddress, userHasPath} from "../../../toolbox/logic";
import {loadingAction} from "../../../redux/loadingReducer";
import axios from "axios";
import {SERVER_ADDRESS} from "../../../index";
import {authAction} from "../../../redux/authReducer";
import {Checkbox, Dialog, Switch} from "@mui/material";
import styled from "styled-components";
import {toastAction} from "../../../redux/toastReducer";
import FilterListIcon from "@mui/icons-material/FilterList";
import {getLoginLink} from "../../../toolbox/format";

export const VehicleWanted = ({tags, setTags, open, onClose, noSave}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);
    const userInfo = useSelector(({user}) => user.user);
    const [profileSave, setProfileSave] = useState(noSave? false: true);
    const [selectedTags, setSelectedTags] = useState('');
    const [updateUser] = useMutation(UPDATE_USER);

    useEffect(() => {
        setSelectedTags(tags);
    }, [tags]);

    const onToggle = (tag) => {
        let tags = [];
        if (selectedTags) {
            tags = selectedTags.split(',');
        }
        if (tags.includes(tag)) {
            tags = filter(tags, (o) => o !== tag);
        } else {
            tags.push(tag);
        }
        setSelectedTags(tags.join(','));
        return tags.join(',');
    }

    const onSave = async () => {
        if (profileSave) {
            if (isEmpty(tokenInfo)) {
                alert("로그인이 필요합니다.");
                const to = getLoginLink(location);
                navigate(to);
                return;
            }
            const matching_profile = {...userInfo?.matching_profile};
            matching_profile["원하는 차량"] = selectedTags;
            const payload = {
                refetchQueries: [{query: GET_ME, variables: { id: tokenInfo.id }}],
                variables: {
                    id: tokenInfo.id,
                    data: {
                        matching_profile: matching_profile,
                    }
                }
            };
            dispatch(loadingAction.loading(true));
            await updateUser(payload);
            dispatch(loadingAction.loading(false));
        }
        setTags(selectedTags);
        onClose();
    }

    const onNotSave = () => {
        setSelectedTags(tags);
        onClose();
    }

    const getClassName = (tag) => {
        return getAllTags().includes(tag)? 'selected': 'not-selected';
    }

    const getAllTags = () => {
        if (selectedTags) {
            return selectedTags.split(',');
        }
        return [];
    }

    return <Dialog open={open} onClose={onClose} fullWidth={true}>
        <_VehicleWanted>
            <div className="content">
                <div className="header">
                    <div className="title">
                        <FilterListIcon fontSize={'14px'} />
                        {/*<img src="/vehicle/tag.svg"/>*/}
                        <span>조건 설정</span>
                    </div>
                    {/*<div className="toggle" onClick={() => setProfileSave(!profileSave)}>*/}
                    {/*    <Switch checked={profileSave} />필터 저장하기*/}
                    {/*</div>*/}
                </div>
                {GUEST_WANTED.map((question, idx) =>
                    <div key={`question${idx}`} className="tag-select">
                        <div className="item">
                            <div className="title">
                                {question.title}
                            </div>
                            <div className="tags">
                                {question.tags.map((t) => <div key={t.id} className={getClassName(t.id)} onClick={() => onToggle(t.id)}>{t.tag}</div>)}
                            </div>
                        </div>
                    </div>)}
            </div>
            {!noSave && <div className="toggle" onClick={() => setProfileSave(!profileSave)}>
                <Checkbox checked={profileSave} />이 조건을 다음에도 사용 (원하는 차량으로 저장합니다)<br/>
            </div>}
            <div className="action-box">
                <div className="submit" onClick={onSave}>검색하기</div>
                <div className="cancel" onClick={onNotSave}>취소</div>
            </div>
        </_VehicleWanted>
    </Dialog>
}

export const VehicleWantedSet = ({tags, setTags, title}) => {

    const onToggle = (tag) => {
        let _tags = tags.split(',');
        if (_tags.includes(tag)) {
            _tags = filter(_tags, (o) => o !== tag);
        } else {
            _tags.push(tag);
        }
        setTags(_tags.join(','));
    }

    const getClassName = (tag) => {
        return getAllTags().includes(tag)? 'selected': 'not-selected';
    }

    const getAllTags = () => {
        if (tags) {
            return tags.split(',');
        }
        return [];
    }

    return <_VehicleWanted>
        <div className="content">
            <div className="header">
                <div className="title">
                    <FilterListIcon fontSize={'14px'} />
                    {/*<img src="/vehicle/tag.svg"/>*/}
                    <span>{title}</span>
                </div>
            </div>
            {GUEST_WANTED.map((question, idx) =>
                <div key={`question${idx}`} className="tag-select">
                    <div className="item">
                        <div className="title">
                            {question.title}
                        </div>
                        <div className="tags">
                            {question.tags.map((t) => <div key={t.id} className={getClassName(t.id)} onClick={() => onToggle(t.id)}>{t.tag}</div>)}
                        </div>
                    </div>
                </div>)}
        </div>
    </_VehicleWanted>
}

const _VehicleWanted = styled.div`
  user-select: none;
  > .content {
    display: flex;
    gap: 12px;
    flex-direction: column;
    padding: 16px 16px 0;
    > .tag-select {
      :last-child {
        margin-bottom: 0;
      }
      > .item {
        //display: flex;
        > .title {
          //width: 60px;
          //min-width: 60px;
          //padding-top: 10px;
          font-weight: 500;
          font-size: 17px;
          line-height: 130%;
          color: #444444;
        }

        > .tags {
          margin-top: 8px;
          display: flex;
          //flex-direction: column;
          align-items: flex-start;
          gap: 8px;
          flex-wrap: wrap;
          
          > input {
            padding: 8px;
            border-radius: 8px;
            border: 1px solid #E0E0E0;
            font-size: 14px;
            line-height: 120%;
          }

          > .selected {
            border-radius: 10px;
            padding: 6px 10px;
            line-height: 120%;
            cursor: pointer;
            background: #5ECDC0;
            color: white;
          }
          
          > .not-selected {
            border-radius: 10px;
            padding: 6px 10px;
            line-height: 120%;
            cursor: pointer;
            color: #878787;
            background: #F7F7F7;
            //border: 1px solid #FFFFFF;
          }
        }
      }
    }
    > .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      white-space: nowrap;
      //margin-bottom: 12px;
      > .title {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 16px;
        line-height: 130%;
        gap: 8px;
        > img {
          width: 16px;
          height: 16px;
        }
        > span {

        }
      }
      
      > .right-btn {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 6px 10px;
        color: #878787;
        background: #F7F7F7;
        border: 1px solid #FFFFFF;
        border-radius: 999px;
      }
    }
    
    > .description {
      margin-top: 4px;
      margin-bottom: 12px;
      word-break: keep-all;
      color: #808080;
    }
  }

  .toggle {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-top: 8px;
    padding-right: 16px;
  }
  
  > .action-box {
    //width: 260px;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    > .submit {
      cursor: pointer;
      background: #5ECDC0;
      border: 1px solid #5ECDC0;
      border-radius: 4px;
      font-weight: 600;
      font-size: 16px;
      line-height: 130%;
      text-align: center;
      color: #FFFFFF;
      padding: 12px;
      flex-grow: 1;
      margin-right: 8px;
    }
    
    > .cancel {
      cursor: pointer;
      border-radius: 4px;
      font-weight: 600;
      font-size: 16px;
      line-height: 130%;
      text-align: center;
      padding: 12px;
      flex-grow: 0.7;
      color: #FFFFFF;
      background: #BBBBBB;
      //background: #5ECDC0;
      border: 1px solid #BBBBBB;
      //border: 1px solid #5ECDC0;
      //color: #878787;
      //background: #F7F7F7;
      //border: 1px solid #FFFFFF;
    }
  }
`;
