import styled, {css} from "styled-components";
import {FormComponent, OptionComponent, PhotoComponent, RadioComponent, SelectComponent} from "./FormComponent";
import React, {useState} from "react";
import BottomDrawer from "./BottomDrawer";
import {PositiveBtn} from "./BottomNavigator";
import moment from "moment-timezone";
import {loadingAction} from "../../../redux/loadingReducer";
import axios from "axios";
import {SERVER_ADDRESS} from "../../../index";
import {useDispatch} from "react-redux";
import {toastAction} from "../../../redux/toastReducer";

const VehicleInfo = ({form, onChange, doorive, draft}) => {
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();

    const getYearSelect = () => {
        const now = moment.tz('Asia/Seoul');
        let i = now.year() + 1;
        const result = [];
        while (i >= 2000) {
            result.push(i);
            i -= 1;
        }
        return result.map((i) => ({id: String(i), label: String(i)}));
    }

    const handleHyphen = async () => {
        if (!form.소유자명 || !form.numberPlate) {
            dispatch(toastAction.toast({severity: 'error', 'message': '차량 소유자명과 번호를 입력해주세요'}));
            return;
        }
        dispatch(loadingAction.loading(true));
        try {
            const {data} = await axios.post(SERVER_ADDRESS + '/api/used-vehicles/getHyphen2', {
                name: form.소유자명,
                numberPlate: form.numberPlate,
            });

            if (data.errYn !== 'N') {
                dispatch(toastAction.toast({severity: 'error', 'message': '소유주명과 차량번호를 확인 후 다시 시도해주세요. 실패 반복 시 차량정보를 수동으로 입력할 수 있습니다.'}));
                console.log(data.errMsg);
                dispatch(loadingAction.loading(false));
                return;
            } else {
                dispatch(toastAction.toast({severity: 'success', 'message': '차량 조회에 성공하여 정보가 자동으로 입력되었습니다.'}));
            }

            if (data?.brand) {
                onChange({id: 'brand', value: data.brand})
            }
            if (data?.model) {
                onChange({id: 'model', value: data.model})
            }
            if (data?.year) {
                onChange({id: 'year', value: data.year})
            }
            if (data?.displacement) {
                onChange({id: 'displacement', value: Number(data.displacement)})
            }

            if (data?.mileage) {
                onChange({id: 'mileage', value: Number(data.mileage)});
            }
            if (data?.ownership) {
                onChange({id: '차량소유형태', value: data.ownership});
            }
            if (data?.isJoint) {
                onChange({id: '공동명의자여부', value: data.isJoint});
            }
            if (data?.mortCt) {
                onChange({id: '저당권건수', value: data.mortCt});
            }
            if (data?.seizCt) {
                onChange({id: '압류건수', value: data.seizCt});
            }

            if (data?.fuelType) {
                /*
                {id: '휘발유', label: '휘발유 (가솔린)'},
                {id: '경유', label: '경유 (디젤)'},
                {id: '전기', label: '전기'},
                {id: 'LPG등기타', label: 'LPG 등 기타'},
                 */
                if (data.fuelType.includes('디젤')) {
                    data.fuelType = '경유';
                } else if (data.fuelType.includes('가솔린')) {
                    data.fuelType = '휘발유';
                }

                if (!['휘발유', '경유', '전기'].includes(data.fuelType)) {
                    data.fuelType = 'LPG등기타';
                }
                onChange({id: 'fuelType', value: data.fuelType});
            }
            if (data?.response) {
                onChange({id: 'hyphenResponse', value: JSON.parse(data.response)});
            }

            if (data?.minGrade) {
                onChange([
                    {id: "하이픈차량번호", value: form.numberPlate},
                    {id: "하이픈차종", value: `${data.brand} ${data.model}`},
                    {id: "하이픈중고차가격", value: data.minGrade.중고차가격},
                    {id: "하이픈월고정비용", value: data.minGrade.월고정비용},
                    {id: "하이픈거리당비용", value: data.minGrade.거리당비용},
                ])
                onChange({id: '차량시세', value: Math.round(data.minGrade.중고차가격 / 10000 / 100) * 100});
            }
        } catch (e) {
            dispatch(toastAction.toast({severity: 'error', 'message': '소유주명과 차량번호를 확인 후 다시 시도해주세요. 실패 반복 시 차량정보를 수동으로 입력할 수 있습니다.'}));
            console.log(e);
        }
        dispatch(loadingAction.loading(false));
    }

    return <>
        <Wrap>
            <div className="forms">
                <h1>차량 정보</h1>
                <FormComponent id="numberPlate" title="차량번호" disabled={!draft} placeholder="차량번호를 입력해주세요" form={form} onChange={onChange} />
                <div>
                    <FormComponent id="소유자명" title="차량소유자명" placeholder="차량등록증에서 확인 가능해요" form={form} onChange={onChange} />
                    <p className="big" style={{marginTop: '30px'}}>법인(회사) 임직원만 보험적용이 가능한 차량은 호스트 등록이 불가합니다.</p>
                    <p className="big">
                        렌터카, 리스차인 경우 차량등록증 상의 회사명(소유주명)을 정확하게 입력해야 조회가 가능합니다.<br/>
                        ex. 롯데렌탈(주), 에스케이렌터카(주), 현대캐피탈 주식회사, 케이비캐피탈 주식회사, 신한캐피탈(주), 하나캐피탈(주) 등
                    </p>
                </div>
                {/*<FormComponent id="numberPlate" title="차량번호" disabled={!draft} placeholder="차량번호를 입력해주세요" form={form} onChange={onChange} />*/}
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <SearchBtn onClick={handleHyphen}><img src="/vehicle/search.svg" style={{filter: 'invert(100%)' }} />클릭해서 차량정보 자동 입력하기</SearchBtn>
                </div>

                <SelectComponent id="차량소유형태" title="차량소유형태" form={form} onChange={onChange} values={[
                    {id: '개인', label: '개인소유'},
                    {id: '리스', label: '리스차량'},
                    {id: '렌터카', label: '렌터카'},
                ]}/>

                {form.차량소유형태 === '개인' && <>
                    <SelectComponent id="공동명의자여부" title="차량등기부상 공동명의자 여부" form={form} onChange={onChange} values={[
                        {id: 'Y', label: '있음'},
                        {id: 'N', label: '없음'},
                    ]}/>
                    {form.공동명의자여부 === 'Y' && <FormComponent id="공동명의자주민번호" title="차량등기부상 공동명의자 주민등록번호" form={form} onChange={onChange} />}
                    <FormComponent id="저당권건수" title="차량등기부상 저당권 건수" form={form} onChange={onChange} />
                    <FormComponent id="압류건수" title="차량등기부상 압류 건수" form={form} onChange={onChange} />
                </>}


                {/*<PhotoComponent id="certificate" isSingle={true} title="차량등록증(선택)" placeholder="이름을 입력해주세요" form={form} onChange={onChange} />*/}
                <FormComponent id="brand" title="브랜드" placeholder="예) 제네시스, 현대, 기아" form={form} onChange={onChange} />
                <FormComponent id="model" title="모델" placeholder="예) G70, 그랜저, K5" form={form} onChange={onChange} />
                {/*<FormComponent id="year" type="tel" title="연식" maxLength={4} placeholder="예) 1998, 2023" form={form} onChange={onChange} />*/}
                <SelectComponent id="year" title="연식" form={form} onChange={onChange} values={getYearSelect()}/>
                <FormComponent id="mileage" type="tel" title="주행거리 (선택사항)" unit="km" placeholder="값을 대략적으로 적어주세요" form={form} onChange={onChange} />

                <RadioComponent id="fuelType" form={form} title="연료타입" onChange={onChange} values={[
                    {id: '휘발유', label: '휘발유'},
                    {id: '고급유', label: '휘발유 (고급유)'},
                    {id: '경유', label: '경유'},
                    {id: '전기', label: '전기'},
                    {id: 'LPG등기타', label: 'LPG 등 기타'},
                ]}/>

                <div>
                    <FormComponent id="displacement" type="tel" title="배기량" unit="cc" placeholder="1998" form={form} onChange={onChange} />
                    <p className="big">전기자동차의 경우는 0을 입력해주세요</p>
                </div>

                {/*<div>*/}
                {/*    <FormComponent id="doorivePriceTax" type="tel" disabled placeholder="연식과 배기량 입력시 자동으로 계산됩니다." title="연간 자동차세" unit="원" form={form} onChange={onChange} />*/}
                {/*</div>*/}

                <OptionComponent id="options" title="차량 옵션" form={form} onChange={onChange}/>

                {doorive && <div>
                    <h2>차량사진(선택)</h2>
                    <h3>퀄리티 높고 여러장일수록 매칭 확률이 올라가요!</h3>
                    <PhotoComponent id="pictures" isSingle={false} placeholder="이름을 입력해주세요" form={form} onChange={onChange} />
                    {form.pictures.length === 0 &&
                        <p>당장 사진 촬영이 어렵다면?<br/>나중에 따로 업로드하거나, 아래 [디테일링 세차비 50% 지원]을 선택해주세요.</p>}
                </div>}

                {draft && <RadioComponent id="이벤트선택" form={form} onChange={onChange} title="이벤트 선택" values={[
                    {id: '이벤트선택안함', label: '선택 안함'},
                    {id: '디테일링세차', label: '디테일링 세차비 50% 지원', description: '사진촬영 후 홈페이지 게시', additional: '* 탁송 서비스 이용 시 비용은 별도로 청구됩니다. (샵 위치: 서울 강남구 도곡동)'},
                ]}/>}
            </div>
        </Wrap>
        {/*<Separator/>*/}
        {/*<Wrap>*/}
        {/*    <div className="forms">*/}

        {/*    </div>*/}
        {/*</Wrap>*/}
        <BottomDrawer open={open} onClose={() => setOpen(false)}>
            <Drawer/>
            <PositiveBtn style={{marginTop: '24px', marginBottom: '14px'}} onClick={() => setOpen(false)}>확인</PositiveBtn>
        </BottomDrawer>
    </>
}


const Drawer = () => {
    return <>
        <h1>위로금 산정 기준</h1>
        <p style={{marginTop: '16px'}}>게스트는 보험처리 1건당 30만원의 위로금을 호스트에게 지급해야합니다. 아래의 경우 위로금이 추가로 할증됩니다.</p>
        <div style={{marginTop: '20px', marginBottom: '20px', borderBottom: '1px solid #D9D9D9'}}></div>
        <p style={{marginTop: '8px'}}>・ 게스트 이용기간 6개월 이내 보험처리시 : +30만원</p>
        <p style={{marginTop: '8px'}}>・ 신차 차량가액 4,000만원 이상인 경우 : +20만원</p>
        <p style={{marginTop: '8px'}}>・ 신차 차량가액 7,000만원 이상인 경우 : +30만원</p>
        <div style={{marginTop: '20px', marginBottom: '20px', borderBottom: '1px solid #D9D9D9'}}></div>
        <p>(예시1)<br/>
            <span className="--gray">호스트의 신차 차량가액 7,000만원 이상의 차를 이용하다가 6개월 이내에 사고를 내서 보험 1건 처리를 한 경우, 90만원의 위로금 발생</span>
        </p>
        <p style={{marginTop: '12px'}}>(예시2)<br/>
            <span className="--gray">호스트의 신차 차량가액 3,000만원의 차를 1년이 된 시점에 사고를 내서 보험 1건 처리를 한 경우, 30만원의 위로금 발생</span>
        </p>
        <p style={{marginTop: '12px'}}>(예시3)<br/>
            <span className="--gray">호스트의 신차 차량가액 4,000만원의 차를 1개월이 된 시점에 사고를 내서 보험 3건 처리를 한 경우, 110만원의 위로금 발생</span>
        </p>
        <p className="--gray" style={{marginTop: '12px'}}>
            ※ 대인/대물/자차 각 1건으로 취급됩니다.<br/>
            ※ 이용기간과 차량가액에 따른 할증은 중복적용됩니다.<br/>
            ※ 보험처리가 필요하지 않은 경미한 사고의 경우, 30만원의 위로금을 지급합니다. (수리비는 따로 지급하지 않음)
            ※ 차량 수리와 상관없이 보험접수가 확정되는 때 즉시 호스트에게 지급해야합니다.
        </p>
    </>
}

const Separator = styled.div`
  height: 10px;
  background: var(--gray-scale-gray-300, #EFEFEF);
`

const Wrap = styled.div`
  padding: 32px 20px 32px;
  h1 {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
  }
  
  h2 {
    color: var(--gray-scale-black, #212121);
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
  }

  h3 {
    margin: 4px 0;
    color: var(--gray-700, #939393);
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 157.143% */
  }
  
  p {
    color: var(--gray-scale-gray-700, #939393);
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    margin: 4px 0;
  }
  
  .underline {
    color: var(--mint, #33CCC1);
    text-decoration-line: underline;
    cursor: pointer;
  }
  
  .big {
    font-size: 14px;
  }
  
  .bold {
    color: #212121;
    font-weight: 600;
  }

  > .forms {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
  
  > .forms2 {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
`

const TextBox = styled.div`
  padding: 16px 12px;
  border-radius: 8px;
  background: var(--gray-scale-gray-200, #F5F5F5);

  color: var(--gray-scale-gray-700, #939393);
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */

  ${(props) => props.onClick && css`
    cursor: pointer;
  `}
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--gray-scale-black, #212121);
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 157.143% */
  }
  .thin {
    font-weight: 400;
  }
  .link {
    font-weight: 600;
    text-decoration-line: underline;
    cursor: pointer;
  }
`

export const SearchBtn = styled.div`
  cursor: pointer;
  gap: 4px;
  display: flex;
  align-items: center;
  padding: 14px;
  border-radius: 8px;
  border: 1px solid #E0E0E0;
  color: white;
  background: rgb(52, 152, 219);
`

export default VehicleInfo;
