import styled from "styled-components";
import {ClickAwayListener, Divider, Popper} from "@mui/material";
import {Link, useLocation} from "react-router-dom";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";
import {NotificationList} from "../page/my/NotificationPage";
import {useEffect, useState} from "react";
import {ChatList} from "../page/my/ChatList";

const _Noti = ({className, notifications, onClick}) => {
    return <div className={className} onClick={onClick}>
        {notifications.map((n, idx) => <div key={idx}>
            <p className="title"><span><b>{n.title}</b></span></p>
            <p className="body"><span>{n.body}</span></p>
            {n.link && n.link.startsWith("/")? <Link to={n.link}><p className="link">이동하기 ></p></Link>:
                <a href={n.link}><p className="link">이동하기 ></p></a>}
        </div>).reduce((acc, x, idx2) => acc === null ? [x] : [acc,
            <Divider />,
            x], null)}
    </div>
}

export const Noti = styled(_Noti)`
  box-shadow: rgb(170 180 190 / 30%) 0px 4px;
  background-color: white;
  border-radius: 12px;
  border: 1px solid #EEEEEE;
  padding: 8px;
  div {
    padding: 12px 16px;
    width: 200px;
  }
  p {
    margin-bottom: 4px;
    word-break: keep-all;
  }
  .link {
    text-align: right;
    text-decoration: underline;
    text-underline-offset: 3px;
  }
`

const MyNotificationList = styled(NotificationList)`
  //height: 500px;
  //overflow-y: scroll;
`
export const NotiPopper = ({open, anchorEl, onClose, chatRooms}) => {
    const hasLoggedIn = useSelector(({auth}) => !isEmpty(auth.tokenInfo));
    const location = useLocation();
    const [opened, setOpened] = useState(false);
    const notis = [
        // {
        //     title: "맘에 드는 차가 없다면?",
        //     body: "렌터카 빌려서 호스트 시작하기",
        //     link: "/rent",
        // },
        {
            title: "노는 차 이웃에게 빌려주고 수익을 만들어보세요",
            body: "나와 이용패턴이 잘 맞고, 매너가 좋은 이웃 한명을 선택하여 안정적인 공유를 할 수 있습니다.",
            link: "/host",
        },
    ]

    useEffect(() => {
        setOpened(open);
    }, [open])

    useEffect(() => {
        onClose();
    }, [location])

    return <ClickAwayListener onClickAway={() => {
        if (opened) {
            onClose();
        }
    }}>
            <Popper style={{zIndex: '1300', minWidth: '200px', maxWidth: '300px'}} open={open} anchorEl={anchorEl} placement="bottom-end">
            {hasLoggedIn? <>
                <div style={{background: 'white', boxShadow: '0 2px 36px 0 rgba(0,0,0,.1)', borderRadius: '4px'}}>
                    <ChatList chatRooms={chatRooms} max={5}/>
                </div>
                    {/*<MyNotificationList limit={3} />*/}
                    {/*<Link to={"/my/notification"}><div style={{boxSizing: 'border-box', width: '300px', padding: '6px', color:'white', backgroundColor:'#007aff', cursor: 'pointer', textAlign: 'center'}}>모든 알림 보기</div></Link>*/}
                </>:
                    <Noti notifications={notis} onClick={onClose}>
                    </Noti>
            }
            </Popper>
        </ClickAwayListener>
}
