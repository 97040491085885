import {cloneDeep, countBy, filter} from "lodash";
import { GUEST_WANTED } from "./guestTag";

const ADMINS = [
    // '61970c8f256b92ebed171f74', // 성환
    // '615164d1291fde00182fe4e3', // 재창
    // '61a038400c976b001801b5de', // 윤석
    // '616cc85c95ee080018e44d50', // 예훈
    // "62186a9a3bf73300186e7d96", //현구
    // '62b92a3379c3ad001601f4f2', //현주
    // '63a93be64996880016562f16', // 인혁
    // '63d22459a348e00016bef3ef', // 인혁
    // '62df8bc994a3610016411fd6', // 둘리둘리 (윤석)
    '19', // 성환
    '9', // 재창
    '21', // 윤석
    '862', // 인혁
    '456', // 둘리둘리 (윤석)
    '2325', // 경미
    '2460', // 홍비
]

const WALLET_ADMINS = [
    '3218' // 회계사무소 직원
]
export const isAdmin = (userId) => {
    return ADMINS.includes(userId);
}

export const isWalletAdmin = (userId) => {
    return WALLET_ADMINS.includes(userId);
}

export const userHasPath = (user) => {
    return user?.lng && user?.lat && user?.address;
}

export const filterNotifications = (_notifications) => {
    if (!_notifications) {
        return null;
    }
    let notifications = cloneDeep(_notifications);
    notifications = filter(notifications, (n) => n.notiType !== 'MANNER_ACTION');
    notifications = filter(notifications, (n) => n.state !== 'hide');
    return notifications;
}

export const isNullish = (v) => {
    return v === undefined || v === null;
}

export const getNotificationBadgeCount = (userInfo) => {
    if (!userInfo) {
        return 0;
    }
    const noti = filterNotifications(userInfo?.notifications);
    const res = countBy(noti, (n) => n.createdAt > userInfo.notification_readed_at);
    return res.true;
}

export const pathSelector = (user, path) => {
    if (userHasPath(user)) {
        return {address: user.address.split('\t')[0], longitude: user.lng, latitude: user.lat}
    }
    return path;
}

export const applyFilters = (vehicles, filters) => {
    let newVehicles = [...vehicles];
    if (!filters) {
        return newVehicles;
    }

    for (let category of GUEST_WANTED) {
        const applied = category.tags.filter(t => filters.includes(t.id));
        if (applied.length) {
        newVehicles = newVehicles.filter(v => {
            for (let apply of applied) {
            if (apply.predicate(v)) {
                return true;
            }
            }
            return false;
        });
        }
    }
    return newVehicles;
};
// export const openAddress = (callback) => {
//     const postCode = new window.daum.Postcode({
//         oncomplete: (response) => {
//             callback(response.autoJibunAddress || response.jibunAddress);
//         }
//     });
//     postCode.open();
// }

export const openAddress = (callback) => {
    const postCode = new window.daum.Postcode({
        oncomplete: (response) => {
            let address = response.autoJibunAddress || response.jibunAddress;
            // if (response.userSelectedType === 'R') { // 사용자가 도로명 주소를 선택했을 경우
            //     address = response.autoRoadAddress || response.roadAddress;
            // }

            let geocoder = new window.kakao.maps.services.Geocoder();
            geocoder.addressSearch(address, (result, status) => {
                if (status === window.kakao.maps.services.Status.OK) {
                    // let simpleAddress = result[0].address;
                    // const depth2 = simpleAddress.address_name.indexOf(simpleAddress.region_2depth_name)
                    // simpleAddress = result[0].address_name.slice(depth2);
                    const longitude = Number(result[0].x);
                    const latitude = Number(result[0].y);
                    callback(address, longitude, latitude);
                } else {
                    callback(address, null, null);
                }
            });
        }
    });
    postCode.open();
}
