import React from "react";
import {Swiper, SwiperSlide} from "swiper/react";

import SwiperCore, {Navigation, Pagination} from "swiper";
import styled from "styled-components";
import 'swiper/swiper-bundle.css';
import {VehicleThumbnail} from "./VehicleThumbnail";

SwiperCore.use([Navigation, Pagination]);

const MyThumbnail = styled(VehicleThumbnail)`
  width: 100%;
  min-width: 100%;
  height: 270px;
  margin: 0 0 8px 0;
`

const _ThumbSwipe = ({images, className, onClick}) => {
    return <>
        <Swiper
            className={className}
            spaceBetween={5}
            slidesPerView={1}
            initialSlide={0}
            navigation
            pagination={{
                type: 'bullets',
                clickable: true,
            }}
            loop>
            {images.map((img, idx) => {
                return (
                    <SwiperSlide key={idx} onClick={onClick}>
                        <MyThumbnail image={img}/>
                    </SwiperSlide>
                )
            })}
        </Swiper>
    </>
};

export const ThumbSwipe2 = styled(_ThumbSwipe)`
  width: 100%;
  z-index: 0;
  //margin-bottom: 50px;

  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    width: 9px;
    height: 16px;
    z-index: 10;
    cursor: pointer;
    border: 16px solid transparent;

    &::after {
      display: none;
    }
  }

  .swiper-button-prev {
    background: url(/vehicle/swiper-left.svg) no-repeat 0 0 /  cover rgba(255, 255, 255, 60%);
  }

  .swiper-button-next {
    background: url(/vehicle/swiper-right.svg) no-repeat 0 0 /  cover rgba(255, 255, 255, 60%);
  }

  .swiper-pagination-fraction {
    //background: white;
    text-align: right;
    
    > .swiper-pagination-total {
      margin-right: 8px;
    }
  }
  .swiper-pagination-bullet {
    box-sizing: border-box;
    //border: 1px solid black;
    border: 1px solid #999999;
    background: #444444;
    opacity: 1;
  }

  .swiper-pagination-bullet-active {
    background: #fff;
    border: 1px solid black;
  }
`
