import styled from "styled-components";
import ReactMarkdown from "react-markdown";

const _VehicleDescriptionSection = ({vehicle, className}) => {
    return <div className={className}>
        <div className="--subject">{vehicle?.title}</div>
        <div className="--content">
            <ReactMarkdown linkTarget="_blank" unwrapDisallowed={true} disallowedElements={["ul", "li"]} children={vehicle?.description?.replace(/\n/gi, "&nbsp;\n\n")}/>
        </div>
    </div>
}

const VehicleDescriptionSection = styled(_VehicleDescriptionSection)`
  border-top: 2px solid #EEEEEE;
  border-bottom: 1px solid #EEEEEE;
  padding: 16px;
  //margin-bottom: 24px;
  .--subject {
    font-weight: 600;
    font-size: 18px;
    margin-top: 8px;
    margin-bottom: 20px;
  }
  .--content {
    width: 100%;
    //display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 10px;
    p {
      width: 100%;
      white-space: pre-wrap;
    }
    img {
      display: none;
    }
    strong {
      font-weight: bold;
    }
    em {
      font-style: italic;
    }
  }
`;

export default VehicleDescriptionSection;
