import {gql} from "@apollo/client";

export const GET_DAILY_UPDATES = gql`
query getDailyUpdates {
  dailyUpdates(pagination: {limit: 100}, sort: "createdAt:desc") {
    data {
      id
      attributes {
        createdAt
        address
        longitude
        latitude
        link
        body
      }
    }
  }
}
`
