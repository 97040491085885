import styled from "styled-components";
import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useMutation} from "@apollo/client";
import {UPDATE_USER} from "../../../query/userQuery";
import {pathAction} from "../../../redux/pathReducer";
import {cloneDeep, filter, find, isEmpty} from "lodash";
import {openAddress, userHasPath} from "../../../toolbox/logic";
import {authAction} from "../../../redux/authReducer";
import {hangulSearch, toTagFormat} from "../../../toolbox/format";
import {WithContext as ReactTags} from "react-tag-input";

const TAGS = [
    '벤츠선호',
    'BMW선호',
    '아우디선호',
    '제네시스선호',
    '전기차선호',
    '준중형선호',
    'SUV선호',
    '평일사용',
    '주말사용',
    '청결사용',
    '비흡연',
    '차잘알',
    '무사고',
    '운전고수',
]

const BOTTOM_TAGS = [
    '평일사용',
    '주말사용',
    '벤츠선호',
    'BMW선호',
    '아우디선호',
    '제네시스선호',
    '전기차선호',
    '준중형선호',
    'SUV선호',
]
const _MyTag = ({className, onChange, placeholder}) => {
    // const initialTags = [{id:'G80', text:'G80'}, {id:'G70', text:'G70'}, {id:'G90', text:'G90'}, {id:'배고프다', text:'배고프다'}];

    const initialTags = TAGS.map((t) => ({id: t, text:t}));
    const bottomTags = BOTTOM_TAGS.map((t) => ({id: t, text:t}));
    // const bottomTags = [{id:'G70', text:'G70'}, {id:'G80', text:'G80'}, {id:'G90', text:'G90'}];
    const [tags, setTags] = useState([]);
    const [inputValue, setInputValue] = useState("");
    const [tagVisible, setTagVisible] = useState(false);

    const userInfo = useSelector(({user}) => user.user);
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);
    const dispatch = useDispatch();

    const [updateUser, {loading}] = useMutation(UPDATE_USER);

    const callbackAddress = (address, longitude, latitude, tags) => {
        dispatch(pathAction.update({
            address: address,
            longitude: longitude,
            latitude : latitude,
            isGps : false,
        }));
        if (!isEmpty(tokenInfo)) {
            setTags(() => tags);
            const payload = {
                variables: {
                    id: tokenInfo.id,
                    data: {
                        lng: longitude,
                        lat: latitude,
                        address: `${address}\t`,
                        introduce: tags.map((t) => t.id).join(),
                    }
                }
            };
            save(payload);
        }
    }

    const getSuggest = () => {
        const newSuggest = cloneDeep(initialTags);
        return newSuggest.filter((t) => !tags.find((t2) => t2.id === t.id));
    }

    useEffect(() => {
        if (userInfo) {
            if (userInfo?.introduce) {
                let splitted = userInfo.introduce.split(',');
                setTags(splitted.map((tag) => ({id: tag, text: '#' + tag})));
            } else {
                setTagVisible(true);
            }
        }
    }, [userInfo]);

    const handleChangeTag = (tags) => {
        if (!userHasPath(userInfo)) {
            alert("태그를 설정하려면 태그를 표시할 위치 설정이 필요합니다.")
            openAddress((address, longitude, latitude) => callbackAddress(address, longitude, latitude, tags));
        } else {
            if (onChange) {
                onChange();
            }
            setTags(() => tags);
            const payload = {
                variables: {
                    id: tokenInfo.id,
                    data: {
                        introduce: tags.map((t) => t.id).join(),
                    }
                }
            };
            save(payload)
        }
    }

    const save = (payload) => {
        updateUser(payload).then(res => {
            dispatch(authAction.refresh());
        });
    }

    return <div className={className} onClick={() => setTagVisible(true)}>
        <div>
            <div>
                <div>
                    {/*<Link to="/my/info">*/}
                    {/*    <div className="profile_image">*/}
                    {/*        <img src={userInfo?.profile_image?.url ?? '/layout/char_doori.svg'}></img>*/}
                    {/*    </div>*/}
                    {/*</Link>*/}
                    <div className="input_box">
                        <div>
                            <MyReactTags
                                tags={tags}
                                label={'245245'}
                                placeholder={placeholder ?? "원하는 차량 적어보기"}
                                handleInputChange={(e) => { setInputValue(() => toTagFormat(e))}}
                                inputValue={inputValue}
                                maxLength={8}
                                minQueryLength={1}
                                suggestions={getSuggest()}
                                handleFilterSuggestions={(textInputValue, suggestions) => {
                                    return suggestions.filter((s) => hangulSearch(textInputValue, s.text) === 0);
                                }}
                                allowDeleteFromEmptyInput={false}
                                delimiters={[188, 13]}
                                handleDelete={(i) => {handleChangeTag(tags.filter((tag, index) => index !== i))}}
                                handleAddition={(tag) => {
                                    if (find(tags, (t) => t.id === tag.id)) {
                                        return;
                                    }
                                    setInputValue('');
                                    handleChangeTag([...tags, {...tag, text: '#' + tag.text}])
                                }}
                                handleDrag={(tag, currPos, newPos) => {
                                    const newTags = tags.slice();
                                    newTags.splice(currPos, 1);
                                    newTags.splice(newPos, 0, tag);
                                    handleChangeTag(newTags);
                                }}
                            />
                            {/*{open? <span>태그로 내 상태를 표현해보세요</span>:*/}
                            {/*    <span>태그로 내 상태를 표현해보세요</span>}*/}
                        </div>
                    </div>
                </div>
                {(tagVisible) &&
                    <div className="top-tag">
                        {bottomTags.map((t) => <div onClick={(e) => {
                            if (tags.find((t2) => t2.id === t.id)) {
                                return;
                            }
                            handleChangeTag([...tags, {...t, text: '#' + t.text}])
                        }}>
                            #{t.text}
                        </div>)}
                    </div>}
            </div>
        </div>
    </div>
}

const _MyTag2 = ({className, form, onChange, onAdd, onDelete, getAllTags}) => {
    const [inputValue, setInputValue] = useState("");

    const getSuggest = () => {
        return TAGS.filter((t) => !getAllTags().includes(t)).map((t2) => ({
            id: t2,
            text: "#" + t2,
        }));
    }

    const getTags = () => {
        return form.수동.map((t) => ({
            id: t,
            text: '#'+t,
        }))
    }

    return <div className={className}>
        <div>
            <div>
                <div>
                    <div className="input_box">
                        <div>
                            <MyReactTags
                                tags={getTags()}
                                placeholder="원하는 차량 적어보기"
                                handleInputChange={(e) => { setInputValue(() => toTagFormat(e))}}
                                inputValue={inputValue}
                                maxLength={8}
                                minQueryLength={1}
                                suggestions={getSuggest()}
                                handleFilterSuggestions={(textInputValue, suggestions) => {
                                    return suggestions.filter((s) => hangulSearch(textInputValue, s.id) === 0);
                                }}
                                allowDeleteFromEmptyInput={false}
                                delimiters={[188, 13]}
                                handleDelete={(i) => {
                                    onDelete(form.수동[i]);
                                }}
                                handleAddition={(tag) => {
                                    onAdd(tag.id);
                                    setInputValue('');
                                }}
                                handleDrag={(tag, currPos, newPos) => {
                                    const newTags = form.수동.slice();
                                    newTags.splice(currPos, 1);
                                    newTags.splice(newPos, 0, tag.id);
                                    onChange({id: '수동', value: newTags})
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

const _MyReactTags = (props) => {
    return <div className={props.className}>
        <ReactTags {...props} />
    </div>
}

const MyReactTags = styled(_MyReactTags)`
  hyphens: none;
  word-break: keep-all;
  .ReactTags__selected {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .ReactTags__tag {
    white-space: nowrap;
    display: block;
    padding: 2px;
    margin: 1px;
    border: 1px solid;
    border-radius: 4px;
    height: 18px;
  }
  .ReactTags__activeSuggestion {
    background-color: #33CCC1;
  }

  .ReactTags__suggestions {
    border: 1px solid black;
    background-color: white;
    position: fixed;
    z-index: 2;

    span {
      padding: 4px;
    }
  }

  .ReactTags__remove {
    margin-top: -1px;
    //padding-top: 1px;
    border: none;
    padding: 0 4px;
  }

  .ReactTags__tagInput {
    margin: 1px;
  }

  .ReactTags__tagInputField {
    width: 150px;
    height: 18px;
    font-size: 16px;
  }
`

export const MyTag2 = styled(_MyTag2)`
  max-width: 410px;
  width: calc(100% - 80px);
  left:50%;
  transform:translateX(-50%);

  color: #1c1e21;
  line-height: 1.34;
  font-size: 12px;
  position: absolute;
  display: flex;
  direction: ltr;
  justify-content: center;
  top: 60px;
  z-index: 3;

  > div {
    border-radius: max(0px, min(8px, ((100vw - 4px) - 100%) * 9999)) / 8px;
    color: #1c1e21;
    direction: ltr;
    font-size: 12px;
    background-color: white;
    //background-color: red;
    box-shadow: 0 1px 2px rgba(0,0,0,0);
    box-sizing: border-box;
    width: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
    position: relative;
    > div {
      padding: 6px 8px;
      line-height: 1.34;
      font-size: 12px;
      flex-wrap: wrap;
      align-items: center;
      display: flex;

      > :first-child {
        font-size: 12px;
        align-items: flex-start;
        width: 100%;
        flex-shrink: 1;
        flex-grow: 1;
        display: flex;

        .profile_image {
          margin-right: 8px;
          width: 40px;
          height: 40px;
          > img {
            box-sizing: border-box;
            border: 2px solid rgba(56, 88, 152, 0.1);
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
          }
        }

        .input_box {
          font-size: 12px;
          border-radius: 12px;
          margin: 0;
          outline: none;
          border-right: 0;
          min-height: 40px;
          justify-content: flex-start;
          border-top: 0;
          border-bottom: 0;
          border-left: 0;
          list-style: none;
          touch-action: manipulation;
          background-color: rgb(240,242,245);
          text-decoration: none;
          box-sizing: border-box;
          cursor: pointer;
          height: auto;
          flex-grow: 1;
          position: relative;
          flex-direction: row;
          align-items: center;
          display: flex;
          padding: 6px 8px;

          > div {
            word-break: break-word;
            word-wrap: break-word;
            hyphens: auto;
            width: 100%;
            color: rgb(101, 103, 107);
            font-size: 14px;
          }
        }
      }

      > .top-tag {
        user-select: none;
        color: #1c1e21;
        direction: ltr;
        line-height: 1.34;
        font-size: 12px;
        flex-wrap: wrap;
        justify-content: space-around;
        //height: 40px;
        //border-top: solid 1px rgb(228,230,235);
        width: 100%;
        //overflow-y: hidden;
        //margin-top: 2px;
        //overflow-x: hidden;
        padding-top: 8px;
        display: flex;

        > div {
          transition: background .1s cubic-bezier(0, 0, 1, 1);
          font-size: 12px;
          outline: none;
          min-width: fit-content;
          list-style: none;
          touch-action: manipulation;
          border-radius: 8px;
          border-color: rgb(228,230,235);
          border-top-style: solid;
          text-decoration: none;
          z-index: 0;
          border-bottom-style: solid;
          border-bottom-width: 0;
          border-left-style: solid;
          min-height: 0;
          border-top-width: 0;
          box-sizing: border-box;
          border-right-width: 0;
          border-left-width: 0;
          border-right-style: solid;
          cursor: pointer;
          flex-shrink: 1;
          flex-grow: 1;
          flex-basis: 40px;
          position: relative;
          flex-direction: row;
          align-items: center;
          display: flex;
          justify-content: center;
          padding: 1px 4px;
          &:hover {
            background-color: rgba(0, 0, 0, 0.05)
          }
        }
      }
    }
  }
`;


export const MyTag = styled(_MyTag)`
  max-width: 410px;
  width: calc(100% - 80px);
  left:50%;
  transform:translateX(-50%);

  color: #1c1e21;
  line-height: 1.34;
  font-size: 12px;
  position: absolute;
  display: flex;
  direction: ltr;
  justify-content: center;
  top: 60px;
  z-index: 3;

  > div {
    border-radius: max(0px, min(8px, ((100vw - 4px) - 100%) * 9999)) / 8px;
    color: #1c1e21;
    direction: ltr;
    font-size: 12px;
    background-color: white;
    //background-color: red;
    box-shadow: 0 1px 2px rgba(0,0,0,0);
    box-sizing: border-box;
    width: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
    position: relative;
    > div {
      padding: 6px 8px;
      line-height: 1.34;
      font-size: 12px;
      flex-wrap: wrap;
      align-items: center;
      display: flex;

      > :first-child {
        font-size: 12px;
        align-items: flex-start;
        width: 100%;
        flex-shrink: 1;
        flex-grow: 1;
        display: flex;

        .profile_image {
          margin-right: 8px;
          width: 40px;
          height: 40px;
          > img {
            box-sizing: border-box;
            border: 2px solid rgba(56, 88, 152, 0.1);
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
          }
        }

        .input_box {
          font-size: 12px;
          border-radius: 12px;
          margin: 0;
          outline: none;
          border-right: 0;
          min-height: 40px;
          justify-content: flex-start;
          border-top: 0;
          border-bottom: 0;
          border-left: 0;
          list-style: none;
          touch-action: manipulation;
          background-color: rgb(240,242,245);
          text-decoration: none;
          box-sizing: border-box;
          cursor: pointer;
          height: auto;
          flex-grow: 1;
          position: relative;
          flex-direction: row;
          align-items: center;
          display: flex;
          padding: 6px 8px;

          > div {
            word-break: break-word;
            word-wrap: break-word;
            hyphens: auto;
            width: 100%;
            color: rgb(101, 103, 107);
            font-size: 14px;
          }
        }
      }

      > .top-tag {
        user-select: none;
        color: #1c1e21;
        direction: ltr;
        line-height: 1.34;
        font-size: 12px;
        flex-wrap: wrap;
        justify-content: space-around;
        //height: 40px;
        //border-top: solid 1px rgb(228,230,235);
        width: 100%;
        //overflow-y: hidden;
        //margin-top: 2px;
        //overflow-x: hidden;
        padding-top: 8px;
        display: flex;

        > div {
          transition: background .1s cubic-bezier(0, 0, 1, 1);
          font-size: 12px;
          outline: none;
          min-width: fit-content;
          list-style: none;
          touch-action: manipulation;
          border-radius: 8px;
          border-color: rgb(228,230,235);
          border-top-style: solid;
          text-decoration: none;
          z-index: 0;
          border-bottom-style: solid;
          border-bottom-width: 0;
          border-left-style: solid;
          min-height: 0;
          border-top-width: 0;
          box-sizing: border-box;
          border-right-width: 0;
          border-left-width: 0;
          border-right-style: solid;
          cursor: pointer;
          flex-shrink: 1;
          flex-grow: 1;
          flex-basis: 40px;
          position: relative;
          flex-direction: row;
          align-items: center;
          display: flex;
          justify-content: center;
          padding: 1px 4px;
          &:hover {
            background-color: rgba(0, 0, 0, 0.05)
          }
        }
      }
    }
  }
`;
