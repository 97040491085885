import {useDispatch, useSelector} from "react-redux";
import styled from "styled-components";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import {asyncSendTelegram, calcDistance, commaNum} from "../../toolbox";
import {cloneDeep, filter, find, get, groupBy, isEmpty, orderBy, reverse, sortBy} from "lodash";
import {
    calcTimeDiff,
    distanceFormat,
    getLoginLink,
    isPhoneFormat,
    isUrlFormat,
    toPhoneFormat
} from "../../toolbox/format";
import {useMutation, useQuery} from "@apollo/client";
import {
    UPDATE_NOTIFICATION
} from "../../query/userQuery";
import {Mannerbox} from "../vehicle/component/Mannerbox";
import {loadingAction} from "../../redux/loadingReducer";
import axios from "axios";
import {SERVER_ADDRESS} from "../../index";
import ReactMarkdown from "react-markdown";
import {authAction} from "../../redux/authReducer";
import moment from "moment-timezone";
import {DailyUpdateList} from "./DailyUpdatePage";
import queryString from "query-string";
import {ChatList} from "./ChatList";

export const ChatAdminPage = () => {
    const [chatRooms, setChatRooms] = useState(null);
    useEffect(() => {
        axios.post(SERVER_ADDRESS + '/api/chat-rooms/findAdmin', {}).then((res) => {
            setChatRooms(res.data);
        });
    }, []);
    return <ChatList chatRooms={chatRooms} />
}
