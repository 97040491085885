import styled from "styled-components";
import {useRef} from "react";
import {useDispatch} from "react-redux";
import queryString from "query-string";
import {Link, useLocation} from "react-router-dom";

const PageWrap = styled.div`
`
export const EventDetailPage = () => {
    const submitLink = useRef();
    const location = useLocation();
    const dispatch = useDispatch();
    const parsed = location.search ? queryString.parse(location.search, {ignoreQueryPrefix: true}) : null;
    const eventId = parsed.id;
    const getImg = () => {
        // if (eventId === 'host_regist') {
        //     return <>
        //         <div>
        //             <img style={{width: '100%', verticalAlign: 'bottom'}} src="/event/host_regist.png" />
        //         </div>
        //         <div>
        //             <Link to="/vehicle/write">
        //                 <img style={{width: '100%'}} src="/event/host_regist2.png" />
        //             </Link>
        //         </div>
        //     </>
        // }
        return <div>종료된 이벤트입니다.</div>
    }

    return <PageWrap>
        {getImg()}
    </PageWrap>
}