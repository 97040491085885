export const SIGN_UP_POLICY_ID = 'SIGN_UP';
export const SIGN_UP_POLICY = '제1장 총칙\n' +
    '\n' +
    '제1조(목적)\n' +
    '본 약관은 원투유니콘 주식회사(이하 ‘회사’)가 운영하는 두리카 서비스를 이용함에 있어 회사와 회원의 권리와 의무, 책임사항 및 기타 필요한 사항을 정함을 목적으로 합니다.\n' +
    '\n' +
    '제2조(용어의 정의)\n' +
    '본 약관에서 사용하는 용어의 뜻은 다음과 같습니다.\n' +
    '\n' +
    '‘두리카 서비스’라 함은 ‘회사’가 본 이용약관에 따른 서비스를 회원에게 제공하기 위하여 구축한 플랫폼으로서 Mobile Application,Mobile Web, Internet Site 등의 형태로 자동차 대여서비스를 제공하는 사업 및 이와 관련된 ‘회사’가 영위하는 제반 사업 일체와 운영을 위한 서비스를 말합니다.\n' +
    '‘회원’이라 함은 본 약관에 대한 동의절차를 거쳐 회원가입을 통해 회사가 제공하는 서비스를 이용할 수 있는 권한을 부여받은 대상을 말합니다.\n' +
    '‘이용자’라 함은 본 약관에 대한 동의절차를 거쳐 회원가입을 하지 아니하고 회사가 제공하는 서비스를 이용할 수 있는 대상을 말합니다.\n' +
    '‘홈페이지’라 함은 회원 계정을 통하여 ‘회사’의 서비스를 제공받을 수 있도록 ‘회사’가 운영하는 두리카 서비스와 관련된 WEB과 APP를 말합니다. \n' +
    '‘사업자’라 함은 홈페이지(APP/WEB) ‘회사’의 예약 및 결제대행계약을 통하여 자동차대여서비스를 제공하는 사업자를 말합니다.\n' +
    '\n' +
    '제2장 약관\n' +
    ' \n' +
    '제3조(약관 명시,개정 등)\n' +
    '\n' +
    '① ‘회사’는 본 약관의 내용을 회원이 쉽게 확인할 수 있도록 ‘홈페이지’에 게시하거나 연결화면을 제공하는 등 적절한 방법으로 회원에게 공지합니다.\n' +
    '‘회원’은 본 약관에 동의하는 것은 ‘회사’가 운영하는 ‘홈페이지’에 정기적으로 약관의 변경사항을 확인하는 것에 동의하는 것으로 봅니다.\n' +
    '\n' +
    '② ‘회사’는 필요하다고 인정되는 경우에는 본 약관을 개정가능합니다. ‘회사’는 개정 약관의 내용과 시행일을 정하여, 그 시행일로부터 7일전 홈페이지에 공시합니다. 다만, ‘회원’에게 불리하게 약관 내용을 개정하는 경우에는 시행일로부터 30일전 홈페이지에 온라인으로 공시하거나 회원이 회원 가입 시 등록한 E-mail로 전송하는 방법,서면 통보, 사업장 및 제휴 가맹점 내 게시 등의 방법 중 1가지 이상의 방법으로 ‘회원’에게 고지합니다. 개정 약관은 공시하거나 고지한 시행일로부터 효력이 발생합니다\n' +
    '\n' +
    ' ③ ‘회사’가 ‘회원’에게 가장 최근 제공받은 E-mail 또는 주소 등으로  E-mail, 서면통보 등의 방법을 통하여 약관이 개정된 사실 및 그 내용을 알린 경우에는 ‘회원’이 최근 정보를 제공하지 아니하여 발생하는 손해에 대해서는 ‘회사’는 어떠한 책임도 지지 않습니다. ‘회원’이 제2항에 의하여 정기적으로 확인하지 아니하여 발생하는 손해에 대해서도 ‘회사’는 어떠한 책임도 지지 않습니다.\n' +
    ' \n' +
    '‘회원’은 개정 약관에 대하여 거부할 권리가 있습니다. 본 약관의 개정에 대하여 이의가 있는 ‘회원’은 서비스 이용을 중단하고 회원 탈퇴를 할 수 있습니다. 제3항의 개시일까지 서비스 이용을 중단하지 아니하고 회원 탈퇴를 하지 않은 경우에는 개정약관에 동의한 것으로 봅니다.\n' +
    '\n' +
    '제4조(약관 해석,효력)\n' +
    '\n' +
    '① 약관이 개정된 경우에는 원칙적으로 그 효력발생일로부터 장래에 향하여 유효합니다. ‘회사’의 통지 효력은 각 조항에서 규정하는 개별적, 전체적인 통지의 내용이 ‘회원’의 권리, 의무와 관련된 경우에도 제1항을 준용합니다.\n' +
    '② ‘회사’는 특정 서비스에 대해서는 별도로 개별 약관을 둘 수 있으며, 이를 ‘홈페이지’를 통해 공지 할 수 있습니다. 개별 약관과 본 약관이 충돌하는 경우에는 개별 약관이 우선하여 적용됩니다.\n' +
    ' ③ ‘회사’는 ‘두리카 서비스’를 제공하기 위하여 세부적인 내용(이하 ‘이용정책’)을 정하여 ‘홈페이지’등을 통하여 공지하거나, ‘회원’에게 설명하고 서면 통지등으로 알릴 수 있습니다.\n' +
    '\n' +
    '제5조(약관 외 준칙)\n' +
    '\n' +
    '본 약관에 명시되지 아니한 사항에 대해서는 ‘정보통신망 이용촉진 및 정보보호 등에 관한 법률’, \'전자문서 및 전자거래기본법\', \'전자상거래 등에서의 소비자보호에 관한 법률\', \'약관의 규제에 관한 법률\', \'전자서명법\', 등의 관계 법령 및 \'회사\'가 정한 서비스의 세부이용지침 등의 규정에 의합니다.\n' +
    '\n' +
    '제3장 서비스\n' +
    '\n' +
    '제6조(구매신청 및 개인정보 제공 동의 등)\n' +
    '\n' +
    '①  이용자는 ‘홈페이지’상에서 다음 또는 이와 유사한 방법에 의하여 구매를 신청하며, ‘회사’는 이용자가 구매신청을 함에 있어서 다음의 각 내용을 알기 쉽게 제공하여야 합니다.\n' +
    '재화 등의 검색 및 선택\n' +
    '받는 사람의 성명, 주소, 전화번호, 전자우편주소(또는 이동전화번호) 등의 입력\n' +
    '약관내용, 청약철회권이 제한되는 서비스, 배송료․설치비 등의 비용부담과 관련한 내용에 대한 확인\n' +
    '이 약관에 동의하고 위 3.호의 사항을 확인하거나 거부하는 표시(예, 마우스 클릭 등)\n' +
    '재화등의 구매신청 및 이에 관한 확인 또는 “홈페이지”의 확인에 대한 동의\n' +
    '결제방법의 선택\n' +
    '\n' +
    '② ‘회사’가 제3자에게 구매자 개인정보를 제공할 필요가 있는 경우 1) 개인정보를 제공받는 자, 2)개인정보를 제공받는 자의 개인정보 이용목적, 3) 제공하는 개인정보의 항목, 4) 개인정보를 제공받는 자의 개인정보 보유 및 이용기간을 구매자에게 알리고 동의를 받아야 합니다. (동의를 받은 사항이 변경되는 경우에도 같습니다.)\n' +
    '\n' +
    '③ ‘회사’가 제3자에게 구매자의 개인정보를 취급할 수 있도록 업무를 위탁하는 경우에는 1) 개인정보 취급위탁을 받는 자, 2) 개인정보 취급위탁을 하는 업무의 내용을 구매자에게 알리고 동의를 받아야 합니다. (동의를 받은 사항이 변경되는 경우에도 같습니다.) 다만, 서비스제공에 관한 계약이행을 위해 필요하고 구매자의 편의증진과 관련된 경우에는 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」에서 정하고 있는 방법으로 개인정보 취급방침을 통해 알림으로써 고지절차와 동의절차를 거치지 않아도 됩니다.\n' +
    '\n' +
    '제6조 (서비스이용)\n' +
    '\n' +
    '① ‘회사’가 제공하는 서비스를 이용하고자 하는 자는 본 약관 및 개별 약관에 대하여 동의를 한 다음 회사가 제시하는 양식과 절차에 따라 이용을 신청하고, ‘회사’가 이용신청을 승낙함으로써 회원의 자격을 얻습니다.\n' +
    '\n' +
    '② ‘회사’는 제1항 신청에 대하여 원칙적으로 승낙을 하여야 합니다. 다만 ‘회사’는 다음 각 호에 해당하는 신청에 대하여는 승낙을 거절하거나, 사후에 이용 계약을 해지할 수 있습니다.\n' +
    '가입신청자가 가입 조건에 미달하는 경우\n' +
    '가입신청자의 실명이 아니거나, 타인 명의를 이용한 경우\n' +
    '가입신청자가 제시한 내용이 회사가 요구하는 내용과 다른 경우\n' +
    '가입신청자의 사정으로 인하여 승인이 불가능한 경우\n' +
    '가입신청자가 본 약관 또는 개별 약관에 의하여 회원자격을 상실한 적이 있는 경우\n' +
    '가입신청자가 이용정책에 위반하거나, 위반할 우려가 있는 경우\n' +
    '가입신청자가 업무에 지장을 주거나, 기술적으로 현저한 지장을 초래하는 경우\n' +
    '회사가 금지하고 있는 사항을 위반하여 신청한 경우\n' +
    '회사의 업무상 또는 기술상 문제가 있는 경우\t\n' +
    '법으로 금지하는 위법행위를 할 목적으로 이용신청을 하는 경우\n' +
    '기타 회원으로서 부적절한 행위를 할 우려가 있다고 인정되는 경우\n' +
    '\n' +
    '③ ‘회사’는 다음 각호에 해당하는 신청에 대하여는 승낙을 유보 또는 취소할 수 있습니다.\n' +
    '\n' +
    '가입신청자가 회사가 제시하는 절차를 완료하지 않은 경우\n' +
    '가입신청자가 관련법령에 따른 절차를 완료하지 않은 경우\n' +
    '‘회사’의 일시적인 업무상 또는 기술상 문제가 있는 경우\n' +
    '‘회사’가 서비스 제공에 여유가 없는 경우\n' +
    '‘회사’가 기술상 서비스를 처리하지 못할 장애사항이 발생하는 경우\n' +
    '기타 회원으로서 부적절한 행위를 할 우려가 있다고 인정되는 경우\n' +
    '\n' +
    '제7조 서비스 제공\n' +
    '\n' +
    '‘회사’는 가입 신청자의 회원 가입을 승낙한 때부터 서비스를 개시합니다. 다만, 성질상 추가적인 조건을 충족하여야 하는 개별 서비스나 별도의 절차를 요구하는 개별 서비스에 대해서는 관련 절차가 완료된 후에 서비스를 개시합니다. ‘회사’의 업무 또는 기술적 필요에 따라 지정된 일자부터 서비스를 개시하기로 한 경우에도 마찬가지입니다.\n' +
    '\n' +
    '제8조 서비스 중단, 제한\n' +
    '\n' +
    '① ‘회사’가 제공하는 서비스 및 제휴 서비스의 내용은 변경될 수 있으며 ‘홈페이지’ 또는 제휴 서비스 홈페이지 등을 통해 회원에게 공지합니다.\n' +
    '② ‘회사’는 ‘회원’과의 계약 및 ‘회사’의 영업방침에 따라 회원들에게 서비스를 제공합니다. 단, 관련법령에 따라 서비스 이용을 제한할 수 있습니다.\n' +
    '③제2항에도 불구하고 다음 각호에 해당하는 경우 일정기간 서비스가 제공되지 아니할 수 있으며, 해당 기간 동안 회사는 서비스를 제공할 의무가 없습니다.\n' +
    '홈페이지 내지 물적 설비의 보수, 점검 또는 관련업무를 수행하기 위하여 필요한 경우\n' +
    '해킹, 통신사고 등 외부적인 장애, 회원들의 비정상적인 이용행태, 회사의 서비스에 예상하지 못한 장애에 대응하기 위하여 필요한 경우\n' +
    '제3자와의 제휴를 통하여 서비스가 제공되는 경우에 제3자의 사정에 따라 서비스가 변경 또는 중지 되는 경우\n' +
    '‘회사’의 원활한 운영을 현저히 저해하는 사유가 발생한 경우\n' +
    '관련 법령에서 특정한 방법으로 서비스 제공을 금지하는 경우\n' +
    '천재지변, 비상사태, 정전, 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 정상적인 서비스 제공이 불가능할 경우\n' +
    '회사의 분할, 합병, 영업양도, 영업폐지, 해당 서비스의 수익성 악화, 해당 서비스 권한 상실 등 회사의 경영상 중대한 필요에 의한 경우\n' +
    '\n' +
    '④‘회사’는 회원으로부터 별도의 요금을 받지 아니하는 서비스와 관련하여 회원에게 어떠한 손해가 발생하더라도 책임을 지지 않습니다. 그러나 ‘회사’의 고의 또는 중대한 과실로 인하여 발생한 손해의 경우는 회원에게 발생한 인과관계 있는 손해에 대하여 책임을 집니다.\n' +
    '\n' +
    '⑤‘회사’는 제1항 내지 3항의 사유로 인하여 서비스 내용이 변경 또는 중단되는 경우에는 이로 인하여 회원에게 손해가 발생하더라도 책임을 지지 않습니다. 그러나 ‘회사’의 고의 또는 중대한 과실이 있는 경우에는 회원에게 발생한 손해에 대하여 책임을 집니다.\n' +
    '\n' +
    '제9조 서비스 요금\n' +
    '\n' +
    '①‘회사’는 회원으로부터 별도의 요금을 받고 추가적인 서비스를 제공할 수 있습니다. ‘회원’은 추가적인 서비스는 본 약관 및 개별적으로 제공되는 약관에 동의하여야 추가적인 서비스를 제공받을 수 있습니다.\n' +
    '②‘서비스’ 요금은 서비스 이용 전, 후에 ‘홈페이지’ 또는 그와 유사한 방법으로 전자결제를 통하여 자동으로 결제할 수 있습니다.\n' +
    '③‘회원’은 서비스 이용에 따른 요금 및 서비스와 관련되어 추가적으로 발생하는 비용, 법률 위반으로 인하여 발생하는 과태료, 벌금 등을 납부하여야 합니다.\n' +
    '④‘회원’은 서비스 이용 형태에 따라 예약, 연장, 변경, 주행요금 기타  ‘회사’에서 규정한 서비스 요금정책에 따라 발생하는 서비스 요금을 납부하여야 합니다.\n' +
    '⑤‘회원’의 개인신용등급, 연체 등의 회원의 사정으로 인하여 서비스 요금이 정산될 때까지 서비스 이용 자격이 정지될 수 있으며, ‘회사’는 ‘회원’이 정상적인 서비스 이용이 불가능하다고 판단되는 경우에는 회원 자격을 박탈할 수 있습니다.\n' +
    '⑥‘서비스’요금과 관련하여 분쟁이 발생하는 경우 이를 해결하기 위하여 ‘회원’은 ‘회사’의 요청에 협조하여야 합니다.\n' +
    '\n' +
    '제10조 테스트 목적의 서비스\n' +
    '\n' +
    '‘회사’는 정식 서비스 전 일정 기간동안 ‘회원’ 중 제한된 인원을 선정하여 앞으로 제공할 서비스를 검증하기 위하여 미리 서비스를 개시할 수 있습니다. 정식 서비스 전 일정 기간동안 제공되는 서비스는 변경될 수 있으며, 이로 인하여 ‘회원’에게 발생하는 손해에 대하여 ‘회사’의 고의 또는 중대한 과실이 없는 이상 ‘회사’는 책임을 지지 않습니다.\n' +
    '\n' +
    '제11조 포인트, 쿠폰 서비스\n' +
    '\n' +
    '①‘회사’는 운영상, 기술상 목적을 달성하기 위하여 포인트 내지 쿠폰 제도를 운영할 수 있습니다. 포인트 내지 쿠폰은 현금으로 교환이 불가능하며, 유효기간이 경과하거나 이용계약이 종료되는 경우에는 자동으로 소멸됩니다.\n' +
    '②포인트 내지 쿠폰은 어떠한 경우에도 거래 및 양도, 부정한 목적을 위하여 사용할 수 없습니다. 거래 및 양도, 부정한 목적을 위하여 사용을 하거나 법령 기타 공서양속을 위반하여 사용한 경우에는 ‘회원’은 형사처벌과는 별개로 그로 인하여 발생하는 회사의 손해를 배상하여야 합니다.\n' +
    '\n' +
    '제12조 두리카 카드 서비스\n' +
    '\n' +
    '①‘회사’는 두리카 서비스를 원활하게 제공하기 위하여 별도의 ‘회사’명의의 카드를 지급합니다. 카드는 하이패스 통행료, 유류비 정산만을 위하여 제공되며, ‘회원’이 다른 목적으로 사용하는 경우에는 비용 및 그로 인하여 발생하는 손해를 배상하여야 합니다. \n' +
    '②카드는 ‘회원’ 외에 타인에게 대여, 양도, 거래에 제공하는 행위 등은 모두 금지되어 있으며 이를 위반하여 사용하게 되는 경우 형사처벌과는 별개로 그로 인하여 발생하는 회사의 손해를 배상하여야 합니다.\n' +
    '\n' +
    '제13조 분실물 등 기타 서비스\n' +
    '\n' +
    '①‘회사’와 ‘회원’은 ‘두리카 서비스’를 이용하면서 발생할 수 있는 분실물 등 사고에 대하여 이를 해결하기 위하여 서로 협조하여야 합니다.\n' +
    '②‘회원’이 분실한 물건이나 발생한 사고에 대하여 ‘회사’는 책임을 지기 어려우나, ‘회원’의 원활한 서비스 이용을 위하여 관련 사고 처리 기준을 마련하고 대책을 마련합니다.\n' +
    '③기타 분실물 처리에 관한 제반 사항은 유실물법 등 관련법의 규정에 따릅니다.\n' +
    '보관기간 및 처리 방법은 다음과 같습니다.\n' +
    '일반 분실물은 1개월간 보관한 후 폐기 또는 관할 경찰서에 인계합니다.\n' +
    '성질상 보관이 어려운 분실물은 1일간 보관 한 후 익일 폐기합니다.\n' +
    '‘회원’의 분실물 회수를 위하여 발생한 비용은 ‘회원’이 부담합니다.\n' +
    '\n' +
    '제14조 서비스 이용 제한\n' +
    '\n' +
    '① ‘회사’는 회원이 이 약관 및 개별약관의 의무를 위반하거나 서비스의 정상적인 운영을 방해한 경우, 경고, 일시정지, 영구정지 등으로 서비스 이용에 대한 회원 자격 재심사를 진행하여 단계적으로 서비스를 제한할 수 있습니다.\n' +
    ' \n' +
    '② ‘회사’는 이용제한 범위 내에서 제한의 조건 및 세부내용은 각 조항 및 개별약관에서 정하는 바에 따릅니다. \n' +
    ' \n' +
    '③ ‘회원’의 자격 재심사의 과정은 해당 회원의 서비스 이용행태 및 이용내역을 토대로 진행되며, 심사 결과에 따라 서비스 이용 자격 제한이 이뤄질 수 있습니다. \n' +
    ' \n' +
    '④ ‘회원’의 서비스 이용의 제한 기간동안 소멸된 포인트, 쿠폰 및 기타 혜택 등은 회사에서 책임지지 않습니다.\n' +
    ' \n' +
    '⑤ ‘회사’는 서비스 이용을 제한하거나 계약을 해지하는 경우에는 이 약관에 따라 통지합니다. \n' +
    ' \n' +
    '⑥ ‘회원’은 본 조에 따른 이용제한 조치 등에 대해 회사가 정한 절차에 따라 이의신청을 할 수 있습니다. 이 때 이의가 정당하다고 회사가 인정하는 경우 회사는 즉시 회원의 서비스 이용을 재개합니다.\n' +
    '\n' +
    '제15조 회원의 탈퇴 및 자격상실\n' +
    ' \n' +
    '① ‘회원’은 언제든지 플랫폼을 통해 회원탈퇴를 요청할 수 있으며, ‘회사’는 관련법 등이 정하는 바에 따라 이를 수일 내에 처리하여야 합니다.\n' +
    ' \n' +
    '② ‘회원’의 요청으로 인하여 회원탈퇴 요청이 처리되는 경우, 회사가 보유하고 있는 회원의 모든 정보는 즉시 삭제됩니다. 단, 다음 각 호의 어느 하나에 해당하는 경우 탈퇴 처리가 거절될 수 있습니다.\n' +
    ' \n' +
    '1. ‘회원’이 ‘회사’에 대한 금전적인 채무가 존재하는 경우\n' +
    ' \n' +
    '2. 사고처리 등과 같이 ‘회원’의 책임있는 행위를 기반으로 업무 처리가 완료되지 않은 경우\n' +
    '③ ‘회원’의 요청으로 인한 회원탈퇴 시 3개월간 회원 재가입 신청이 제한됩니다. 단, 회사와 회원 간에 사전 협의한 경우에는 회원 재가입이 가능합니다. \n' +
    '④ ‘회원’이 다음 각 호의 어느 하나에 해당하는 경우, 회사는 회원 자격을 상실시킬 수 있습니다. \n' +
    ' \n' +
    '회원정보 입력 시 허위 내용을 기입한 경우 \n' +
    '비정상적인 방법으로 다른 사람의 서비스 이용을 방해하거나, 허위 및 거짓 제보를 통해 서비스의 정상적인 운영을 지속 저해하는 경우 \n' +
    '다른 사람의 정보를 불법적으로 취득하거나 도용하는 등 전자거래 질서를 위협하는 경우 \n' +
    '관계법령에 위반되거나 공서양속에 반하는 행위를 하는 등 회원 자격을 유지시키는 것이 부적절하다고 판단되는 경우 \n' +
    ' \n' +
    '⑤ ‘회원’은 제4항에 따른 자격상실에 대해 ‘회사’가 정한 절차에 따라 이의신청을 할 수 있으며, 회사는 회원의 이의신청이 정당하다고 판단되는 경우 즉시 회원 자격을 재개합니다. \n' +
    ' \n' +
    '⑥ ‘회원’이 사망한 경우 회원 사망일에 회원 자격이 상실됩니다.\n' +
    '\n' +
    '제16조(저작권 등의 귀속)\n' +
    '① ‘회사’가 제공하는 모든 서비스의 지적 재산권을 포함한 모든 소유권은 ‘회사’에 귀속합니다. ‘회원’은 회사가 제공하는 서비스 범위 내에서 이용권을 가집니다. ‘회원’은 회사가 정한 방법 이외의 방법으로 이용 또는 사용할 수 없습니다.\n' +
    '② ‘회사’가 작성한 저작물에 대한 저작권 및 기타 지적 재산권은 회사에 귀속합니다. ‘회원’이 서비스 내에 게시한 게시물의 저작권은 해당 저작권자에게 귀속합니다. \n' +
    '③ ‘회사’는 원활한 서비스 운영을 위하여 게시물을 사전 통지 없이 이동하거나 삭제할 수 있습니다.\n' +
    '④ ‘회원’이 게재한 게시물로 인해 발생하는 손실이나 문제는 회원 개인의 책임이며 회사는 이에 대한 책임을 지지 않습니다.\n' +
    '⑤ ‘회원’의 게시물이 타인의 권리를 침해하였음을 이유로 타인으로부터 회사가 손해배상청구 등의 이의 제기를 받은 경우, 해당 게시물을 작성한 회원은 회사의 면책을 위하여 적극적으로 협조하여야 하며, 회사가 면책되지 못한 경우 회원은 그로 인해 발생한 문제에 대해 책임을 부담하여야 합니다.\n' +
    '⑥ ‘회원’이 서비스 내에 게시하는 게시물은 서비스 및 관련 프로모션 등에 노출될 수 있으며, 필요한 범위 내에서는 일부 수정, 복제, 편집될 수 있습니다.\n' +
    '⑦ ‘회원’은 언제든지 고객센터 또는 서비스 내 관리기능을 통해 해당 게시물에 대해 삭제, 검색 결과 제외, 비공개 등을 요청할 수 있습니다. \n' +
    '⑧ ‘회사’는 저작권법 및 관계법령에 따라 사전 통지 없이 이를 삭제하거나 등록 자체를 거부할 수 있습니다.\n' +
    '\n' +
    '\n' +
    '제4장 의무\n' +
    '\n' +
    '제17조(개인정보수집,보호)\n' +
    ' \n' +
    '  ① ‘회사’는 이용자의 개인정보 수집시 서비스제공을 위하여 필요한 범위에서 최소한의 개인정보를 수집합니다.\n' +
    ' \n' +
    '  ② ‘회사’는 회원가입시 구매계약이행에 필요한 정보를 미리 수집하지 않습니다. 다만, 관련 법령상 의무이행을 위하여 구매계약 이전에 본인확인이 필요한 경우로서 최소한의 특정 개인정보를 수집하는 경우에는 그러하지 아니합니다.\n' +
    ' \n' +
    '  ③ ‘회사’는 이용자의 개인정보를 수집·이용하는 때에는 당해 이용자에게 그 목적을 고지하고 동의를 받습니다.\n' +
    ' \n' +
    '  ④ ‘회사’는 수집된 개인정보를 목적외의 용도로 이용할 수 없으며, 새로운 이용목적이 발생한 경우 또는 제3자에게 제공하는 경우에는 이용·제공단계에서 당해 이용자에게 그 목적을 고지하고 동의를 받습니다. 다만, 관련 법령에 달리 정함이 있는 경우에는 예외로 합니다.\n' +
    ' \n' +
    '  ⑤ ‘회사’가 제2항과 제3항에 의해 이용자의 동의를 받아야 하는 경우에는 개인정보관리 책임자의 신원(소속, 성명 및 전화번호, 기타 연락처), 정보의 수집목적 및 이용목적, 제3자에 대한 정보제공 관련사항(제공받은자, 제공목적 및 제공할 정보의 내용) 등 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」 제22조제2항이 규정한 사항을 미리 명시하거나 고지해야 하며 이용자는 언제든지 이 동의를 철회할 수 있습니다.\n' +
    ' \n' +
    '  ⑥ ‘이용자’는 언제든지 ‘회사’가 가지고 있는 자신의 개인정보에 대해 열람 및 오류정정을 요구할 수 있으며 ‘회사’은 이에 대해 지체 없이 필요한 조치를 취할 의무를 집니다. 이용자가 오류의 정정을 요구한 경우에는 ‘회사’은 그 오류를 정정할 때까지 당해 개인정보를 이용하지 않습니다.\n' +
    ' \n' +
    '  ⑦ ‘회사’는 개인정보 보호를 위하여 이용자의 개인정보를 취급하는 자를  최소한으로 제한하여야 하며 신용카드, 은행계좌 등을 포함한 이용자의 개인정보의 분실, 도난, 유출, 동의 없는 제3자 제공, 변조 등으로 인한 이용자의 손해에 대하여 모든 책임을 집니다.\n' +
    ' \n' +
    '  ⑧ ‘회사’ 또는 그로부터 개인정보를 제공받은 제3자는 개인정보의 수집목적 또는 제공받은 목적을 달성한 때에는 당해 개인정보를 지체 없이 파기합니다.\n' +
    ' \n' +
    '  ⑨ ‘회사’는 개인정보의 수집·이용·제공에 관한 동의 란을 미리 선택한 것으로 설정해두지 않습니다. 또한 개인정보의 수집·이용·제공에 관한 이용자의 동의거절시 제한되는 서비스를 구체적으로 명시하고, 필수수집항목이 아닌 개인정보의 수집·이용·제공에 관한 이용자의 동의 거절을 이유로 회원가입 등 서비스 제공을 제한하거나 거절하지 않습니다.\n' +
    '\n' +
    '제18조(‘회사’의 의무)\n' +
    ' \n' +
    '  ① ‘회사’는 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며 이 약관이 정하는 바에 따라 지속적이고, 안정적으로 재화․용역을 제공하는데 최선을 다하여야 합니다.\n' +
    ' \n' +
    '  ② ‘회사’는 이용자가 안전하게 인터넷 서비스를 이용할 수 있도록 이용자의 개인정보(신용정보 포함)보호를 위한 보안 시스템을 갖추어야 합니다.\n' +
    '  \n' +
    '  ③ ‘회사’가 상품이나 용역에 대하여 「표시․광고의 공정화에 관한 법률」 제3조 소정의 부당한 표시․광고행위를 함으로써 이용자가 손해를 입은 때에는 이를 배상할 책임을 집니다.\n' +
    ' \n' +
    '  ④ ‘회사’는 이용자가 원하지 않는 영리목적의 광고성 전자우편을 발송하지 않습니다.\n' +
    ' \n' +
    '제19조(회원의 ID 및 비밀번호에 대한 의무)\n' +
    ' \n' +
    '  ① 제17조의 경우를 제외한 ID와 비밀번호에 관한 관리책임은 회원에게 있습니다.\n' +
    ' \n' +
    '  ② 회원은 자신의 ID 및 비밀번호를 제3자에게 이용하게 해서는 안됩니다.\n' +
    ' \n' +
    '  ③ 회원이 자신의 ID 및 비밀번호를 도난당하거나 제3자가 사용하고 있음을 인지한 경우에는 바로 “회사”에 통보하고 “회사”의 안내가 있는 경우에는 그에 따라야 합니다.\n' +
    ' \n' +
    '제20조(이용자의 의무) \n' +
    '\n' +
    '이용자는 다음 행위를 하여서는 안 됩니다.\n' +
    '신청 또는 변경시 허위 내용의 등록하는 행위\n' +
    '.타인의 정보 도용하는 행위\n' +
    '홈페이지’에 게시된 정보의 변경하는 행위\n' +
    '홈페이지’이 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시하는 행위\n' +
    '‘회사’ 기타 제3자의 저작권 등 지적재산권에 대한 침해하는 행위\n' +
    '‘회사’ 또는 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위\n' +
    '외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 몰에 공개 또는 게시하는 행위\n' +
    '‘회사’에서 개별적으로 금지하고 있는 행위\n' +
    '\n' +
    '제21조(손해배상)\n' +
    '① 회원이 본 약관의 규정을 위반함으로 인하여 회사에 손해가 발생하게 되는 경우, 위반한 회원은 회사에 발생하는 손해를 배상하여야 합니다. 다만 회사에게 귀책사유가 있는 부분은 회사가 책임을 부담합니다.\n' +
    '② 회원이 서비스를 이용함에 있어 행한 불법행위나 본 약관 위반 행위로 인하여 회사가 당해 회원 이외의 제3자로부터 손해배상 청구 또는 소송을 비롯한- 각종 이의 제기를 받는 경우 당해 회원은 자신의 책임과 비용으로 회사를 면책시켜야 하며, 회사가 면책되지 못한 경우 당해 회원은 그로 인하여 회사에 발생한 손해를 배상하여야 합니다. 단 회사에게 귀책사유가 있는 부분은 회사가 책임을 부담합니다.\n' +
    '③ 회사는 무료로 제공하는 서비스와 관련하여 발생하는 사항에 대하여는 어떠한 손해도 책임을 지지 않습니다. 단, 회사의 고의나 중과실로 인한 손해인 경우는 예외로 합니다.\n' +
    '제22조(면책사항)\n' +
    '① 회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다.\n' +
    '② 회사는 이용자의 귀책사유로 인한 서비스의 중지 또는 이용장애, 계약해지에 대하여 책임을 지지 않습니다.\n' +
    '③ 회사는 기간통신 사업자가 전기통신서비스를 중지하거나 정상적으로 제공하지 아니하여 손해가 발생한 경우 회사의 고의 또는 중과실이 없는 한 책임이 면제됩니다.\n' +
    '④ 회사가 제공하는 게임 서비스는 이용자의 개별적 행위에 따라 다양한 결과가 발생할 수 있으므로 회사는 이용자의 취사선택 등으로 발생하는 문제에 대하여는 책임을 부담하지 않습니다.\n' +
    '⑥ 회사는 이용자가 서비스 내 또는 웹사이트 상에 게시 또는 전송한 정보, 자료, 사실의 신뢰도, 정확성 등 내용에 대해서는 회사의 고의 또는 중대한 과실이 없는 한 책임을 지지 않습니다.\n' +
    '⑦ 회사는 이용자 상호간 또는 이용자와 제3자간에 서비스를 매개로 발생한 분쟁에 대해 개입할 의무가 없으며 이로 인한 손해를 배상할 책임을 지지 않습니다.\n' +
    '⑧ 회사는 회원의 컴퓨터 오류에 의한 손해가 발생한 경우 또는 신상정보 및 전자우편주소를 부정확하게 기재하거나 미기재하여 손해가 발생한 경우에 대하여 책임을 부담하지 않습니다.\n' +
    '⑩ 회사가 다른 사업자가 제공하는 서비스를 중개하는 경우 해당 서비스와 관련된 의무와 책임은 개별 서비스 제공자에게 있으며, 이로 인하여 발생한 손해 등에 대해서는 회사는 원칙적으로 책임을 부담하지 않습니다. 다만, 회사는 관련 법령에 따라 책임을 부담할 수 있습니다.\n' +
    '제23조(이의신청 절차)\n' +
    '‘회원’은 서비스를 이용함에 있어 발생한 사안에 대한 이의 또는 본 약관과 관련한 이의를 회사가 마련하는 고객센터에 할 수 있으며, 회사는 고객의 이의가 정당하다고 인정되는 때에는 그에 상응하는 조치를 취합니다.\n' +
    '제24조(관할법원)\n' +
    '① ‘회사’와 ‘회원’간에 서비스와 관련하여 이견 또는 분쟁이 있는 경우, 양 당사자는 협의에 적극적으로 협조하고, 분쟁을 해결할 수 있도록 노력합니다.\n' +
    '② 제1항의 분쟁으로 인하여 소송이 제기된 경우, 소송은 관련 법령에 정한절차에 따른 법원을 관할 법원으로 합니다.\n' +
    '③ ‘회사’와 ‘이용자’간에 제기된 소송에는 대한민국 법을 적용합니다.\n' +
    '② ‘회사’와 ‘회원’ 간 분쟁이 발생한 경우 회원의 주소지를 관할하는 법원을 관할법원으로 하며, 회원의 주소 또는 거소가 명확하지 않을 경우 관할법원은 민사소송법에 따라 정합니다. 단, ‘회원’의 책임있는 사유로 채권채무가 발생한 경우 그 관리업무를 담당하는 회사 사무소 소재지의 지방법원을 관할법원으로 합니다.\n' +
    '\n' +
    '\n' +
    '부칙\n' +
    '(제정 2021. 08. 31.)\n'
