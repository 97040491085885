import styled, {css} from "styled-components";
import {FormComponent, PhotoComponent, RadioComponent, RadioComponentSimple, UseTypeComponent} from "./FormComponent";
import {PositiveBtn} from "./BottomNavigator";
import React, {useEffect, useState} from "react";
import BottomDrawer from "./BottomDrawer";
import {filter, sortBy} from "lodash";
import {toastAction} from "../../../redux/toastReducer";
import {useDispatch} from "react-redux";

const MatchingInfo = ({form, onChange, doorive, draft}) => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [useTypeOpen, setUseTypeOpen] = useState(false);

    useEffect(() => {
        if (!form.address && form.userAddress) {
            let address = form.userAddress;
            let geocoder = new window.kakao.maps.services.Geocoder();
            geocoder.addressSearch(address, (result, status) => {
                if (status === window.kakao.maps.services.Status.OK) {
                    const longitude = Number(result[0].x);
                    const latitude = Number(result[0].y);
                    onChange([
                        {id: 'address', value: address},
                        {id: 'longitude', value: longitude},
                        {id: 'latitude', value: latitude},
                    ]);
                    dispatch(toastAction.toast({severity: 'success', 'message': '차량 위치가 거주지 주소로 자동 입력되었습니다.'}));
                }
            });
        }
    }, []);

    useEffect(() => {
        if (!form.dooriveTitle && form.model) {
            onChange({id: 'dooriveTitle', value: `${form.model} 같이 타실분 구해요~`})
        }

        if (!form.dooriveDescription) {
            onChange({id: 'dooriveDescription', value: `깨끗하고 매너있게 타실 분 구합니다.`})
        }
    }, []);

    return <>
        <Wrap>
            <div className="forms">
                <div>
                    <h1>기타 매칭조건</h1>
                    <h3>게스트에게 보여질 매칭 정보를 입력해주세요!</h3>
                </div>
                <FormComponent id="address" title="주차장 주소" placeholder="이름을 입력해주세요" form={form} onChange={onChange} />
                {/*{doorive && <div>*/}
                {/*        <h2>차량사진(선택)</h2>*/}
                {/*        <h3>퀄리티 높고 여러장일수록 매칭 확률이 올라가요!</h3>*/}
                {/*        <PhotoComponent id="pictures" isSingle={false} placeholder="이름을 입력해주세요" form={form} onChange={onChange} />*/}
                {/*        {form.pictures.length === 0 &&*/}
                {/*            <p><span className="underline">당장 사진 촬영이 어렵다면?</span><br/>나중에 따로 업로드하거나, 아래 [디테일링 세차비 50% 지원]을 선택해주세요.</p>}*/}
                {/*    </div>}*/}

                {doorive && <div>
                    <h2>공유시작가능일</h2>
                    {/*<h3>[2023년 9월 1일] 부터 공유를 시작할 수 있어요.</h3>*/}
                    <FormComponent id="availableDate" type="date" placeholder="공유시작가능일 선택하기" form={form} onChange={onChange} />
                    {/* <p>
                        최소 공유기간은 1개월이며, 기간 만료 시 매월 1개월씩 자동으로 연장됩니다.
                    </p> */}
                </div>}

                <FormComponent id="dooriveTitle" title="제목" placeholder="예) G80 같이 타실 분 구해요!" form={form} onChange={onChange} />
                <div>
                    <h2>설명</h2>
                    <h3>게스트가 꼭 알아야할 주의사항 등 추가적으로 하고 싶은 말을 자유롭게 적어주세요.</h3>
                    <FormComponent id="dooriveDescription" type="textarea" rows={6} placeholder="예) 손세차 필수, 스팀세차 불가, 평소 차를 깨끗하게 관리해서 상태가 아주 좋습니다. 주차장 24시간 출입가능해서 편리해요." form={form} onChange={onChange} />
                </div>

                {/*<FormComponent type="text" id="useType" title="매칭유형" onChange={onChange} form={form} />*/}
                {/*<RadioComponent id="useType" title="매칭유형 선택" form={form} onChange={onUseTypeChange} values={[*/}
                {/*    {id: 'FREE', label: '나중에 정하기', description: '게스트와 협의 후 결정'},*/}
                {/*    {id: 'HALF', label: '반반예약형', description: '호스트/게스트가 동일한 조건으로 예약하며 사용'},*/}
                {/*    {id: 'PRIORITY', label: '우선예약형', description: '우선예약권자는 매월 말일까지 다음달 예약 가능',*/}
                {/*        additional: form.useType === 'PRIORITY'? `선택된 우선예약권자: ${form.우선예약권자}`: undefined},*/}
                {/*    {id: 'DAY_SELECT', label: '요일선택형', description: '호스트가 요일 선택 후, 남은 요일 게스트 사용',*/}
                {/*        additional: form.useType === 'DAY_SELECT' && form.요일선택? `선택된 요일: ${form.요일선택}`: undefined},*/}
                {/*    {id: 'FULL_SHARE', label: '전체공유형', description: '공유기간 전부 게스트가 사용'},*/}
                {/*]}/>*/}

                {/*<UseTypeComponent id="useTypes" title="매칭유형 선택 (다중선택 가능)" form={form} onChange={onUseTypeChange} values={[*/}
                {/*    {id: 'DAY_SELECT', label: '선착순형', description: '호스트와 게스트가 선착순으로 예약하여 이용하는 방식입니다.\n매주 고정적으로 이용할 요일을 미리 지정하는 경우, 해당 날짜는 자동으로 예약되어 선착순 대상에서 제외됩니다.\n(호스트의 주차장 사용이 원칙이에요)',*/}
                {/*        additional: form.useType === 'DAY_SELECT' && form.daySelect? `선택된 요일: ${form.daySelect}`: undefined},*/}
                {/*    {id: 'PRIORITY_HOST', label: '호스트 우선형', description: '호스트가 주로 이용하고 게스트는 매월 5~10일 정도 이용하는 방식입니다.\n게스트는 호스트의 승인을 받아 예약을 확정할 수 있습니다.\n(호스트의 주차장 사용이 원칙이에요)'},*/}
                {/*    {id: 'PRIORITY_GUEST', label: '게스트 우선형', description: '게스트가 주로 이용하고 호스트는 매월 2~5일 정도 이용하는 방식입니다.\n호스트는 게스트의 승인을 받아 예약을 확정할 수 있습니다.\n(게스트의 주차장 사용이 원칙이에요)'},*/}
                {/*]}/>*/}
                <div>
                    <h2>게스트 연령</h2>
                    <p>게스트가 30세 미만이면 운전자추가에 따른 보험료인상액을 별도로 청구할 수있습니다.</p>
                    <div style={{height: "16px"}}></div>
                    <RadioComponentSimple id="게스트연령" isColumn={true} form={form} onChange={onChange} values={[
                        {id: '무관', label: '연령 무관'},
                        {id: '입력', label: `[${(form.최소연령제한 && form.게스트연령 === '입력') ? form.최소연령제한: ' '}]세 이상만 매칭`},
                    ]}/>
                    {form.게스트연령 === '입력' &&  <FormComponent style={{marginTop: '12px'}} id="최소연령제한" title="연령 입력 (만)" placeholder="예) 26" form={form} onChange={onChange} />}
                </div>
                <div style={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
                    <h2>세부 매칭조건</h2>
                    <RadioComponentSimple id="성별" title="성별" form={form} onChange={onChange} values={[
                        {id: '무관', label: '무관'},
                        {id: '남자만', label: '남자만'},
                        {id: '여자만', label: '여자만'},
                    ]}/>
                    <RadioComponentSimple id="흡연" title="차량 내 흡연여부" form={form} onChange={onChange} values={[
                        {id: '가능', label: '가능'},
                        {id: '전자담배만', label: '전자담배만'},
                        {id: '불가', label: '불가'},
                    ]}/>
                    <RadioComponentSimple id="반려동물탑승" title="반려동물 탑승여부" form={form} onChange={onChange} values={[
                        {id: '가능', label: '가능'},
                        {id: '케이지만', label: '케이지만'},
                        {id: '불가', label: '불가'},
                    ]}/>
                    <RadioComponentSimple id="차량 내 짐 비치" title="차량 내 짐 비치 여부" form={form} onChange={onChange} values={[
                        {id: '가능', label: '가능'},
                        {id: '트렁크만', label: '트렁크만'},
                        {id: '불가', label: '불가'},
                    ]}/>
                </div>
                {/*<div>*/}
                {/*    <h2>운전자 범위</h2>*/}
                {/*    <p className="big">*/}
                {/*        게스트 1인 외에는 호스트가 사전에 승인한 자만 운전이 가능합니다.*/}
                {/*    </p>*/}
                {/*</div>*/}
            </div>
        </Wrap>

        <BottomDrawer open={open} onClose={() => setOpen(false)}>
            <Drawer1/>
            <PositiveBtn style={{marginTop: '24px', marginBottom: '14px'}} onClick={() => setOpen(false)}>확인</PositiveBtn>
        </BottomDrawer>

        <BottomDrawer open={useTypeOpen} onClose={() => setUseTypeOpen(false)}>
            <UseTypeDrawer id="daySelect" form={form} onChange={onChange} onClose={() => setUseTypeOpen(false)}/>
            <PositiveBtn style={{marginTop: '24px', marginBottom: '14px'}} onClick={() => setUseTypeOpen(false)}>확인</PositiveBtn>
        </BottomDrawer>
    </>
}

const Drawer1 = () => {
    return <>
        <h1>위로금 산정 기준</h1>
        <p style={{marginTop: '16px'}}>게스트는 보험처리 1건당 30만원의 위로금을 호스트에게 지급해야합니다. 아래의 경우 위로금이 추가로 할증됩니다.</p>
        <div style={{marginTop: '20px', marginBottom: '20px', borderBottom: '1px solid #D9D9D9'}}></div>
        <p style={{marginTop: '8px'}}>・ 게스트 이용기간 6개월 이내 보험처리시 : +30만원</p>
        <p style={{marginTop: '8px'}}>・ 신차 차량가액 4,000만원 이상인 경우 : +20만원</p>
        <p style={{marginTop: '8px'}}>・ 신차 차량가액 7,000만원 이상인 경우 : +30만원</p>
        <div style={{marginTop: '20px', marginBottom: '20px', borderBottom: '1px solid #D9D9D9'}}></div>
        <p>(예시1)<br/>
            <span className="--gray">호스트의 신차 차량가액 7,000만원 이상의 차를 이용하다가 6개월 이내에 사고를 내서 보험 1건 처리를 한 경우, 90만원의 위로금 발생</span>
        </p>
        <p style={{marginTop: '12px'}}>(예시2)<br/>
            <span className="--gray">호스트의 신차 차량가액 3,000만원의 차를 1년이 된 시점에 사고를 내서 보험 1건 처리를 한 경우, 30만원의 위로금 발생</span>
        </p>
        <p style={{marginTop: '12px'}}>(예시3)<br/>
            <span className="--gray">호스트의 신차 차량가액 4,000만원의 차를 1개월이 된 시점에 사고를 내서 보험 3건 처리를 한 경우, 110만원의 위로금 발생</span>
        </p>
    </>
}

export const UseTypeDrawer = ({id, form, onChange, onClose}) => {
    const checked = (v) => {
        if (form[id]) {
            const vs = form[id].split(',');
            return vs.includes(v);
        }
        return false;
    }
    const handleChange = (label) => {
        let options = [];
        if (form[id]) {
            options = form[id].split(',');
        }
        if (options.includes(label)) {
            options = filter(options, (o) => o !== label);
        } else {
            options.push(label);
        }
        options = sortBy(options, (v) => {
            if (v === '월') {
                return 0;
            } else if (v === '화') {
                return 1;
            } else if (v === '수') {
                return 2;
            } else if (v === '목') {
                return 3;
            } else if (v === '금') {
                return 4;
            } else if (v === '토') {
                return 5;
            } else if (v === '일') {
                return 6;
            } else {
                return 7;
            }
        });
        onChange({id, value: options.join(',')});
    }

    const idToLabel = (id) => {
        if (id === "평일형") {
            return "평일전부";
        } else if (id === "휴일형") {
            return "휴일전부";
        }
    }

    return <>
        <h1>요일 지정</h1>
        <p style={{marginTop: '16px', marginBottom: '4px'}}>내가 매주 타야 할 요일이 있는 경우 지정해주세요. (생략가능)</p>
        <div style={{display: 'flex', gap: '8px', }}>
            {["평일형", "휴일형"].map(day =>
                <DaySelect onClick={() => handleChange(day)} checked={checked(day)}>{idToLabel(day)}</DaySelect>
            )}
        </div>
        <div style={{display: 'flex', gap: '8px', marginTop: '8px'}}>
            {["월", "화", "수", "목", "금"].map(day =>
                <DaySelect onClick={() => handleChange(day)} checked={checked(day)}>{day}</DaySelect>
            )}
        </div>
        <div style={{display: 'flex', gap: '8px', marginTop: '8px'}}>
            {["토", "일"].map(day =>
                <DaySelect onClick={() => handleChange(day)} checked={checked(day)}>{day === '공휴일'? '공휴일\n(토, 일 제외)': day}</DaySelect>
            )}
        </div>
        <p style={{marginTop: '16px', marginBottom: '4px'}}>불필요하게 지정 시 매칭확률이 내려가요. 이용하는 날이 많은 경우는 매일 우선권이 있는 ‘호스트 우선형’을 고려해주세요!</p>
        <div>

        </div>
    </>

    // if (form.useType === 'HALF') {
    //     return <>
    //         <h1>반반예약형</h1>
    //         <p style={{marginTop: '16px'}}>・ 호스트와 게스트가 각자 매월 15개의 토큰으로 캘린더에 예약하는 방식입니다. (평일 1개, 주말 및 공휴일 2개 차감)</p>
    //         <p style={{marginTop: '8px'}}>・ 매월 말일까지 다음달에 탈 날을 미리 예약할 수 있어요. (예약충돌시 홀짝일로 결정)</p>
    //         <p style={{marginTop: '8px'}}>・ 다음달이 도래하면? 남은 토큰으로 자유롭게 예약하면서 사용하세요. (이때는 선착순으로 예약)</p>
    //         <p style={{marginTop: '8px'}}>・ 토큰을 다 소진하면? 당일예약 후 차를 사용할 수 있어요.</p>
    //         <div style={{marginTop: '20px', marginBottom: '20px', borderBottom: '1px solid #D9D9D9'}}></div>
    //         <p className="--gray">⚠ 월고정수익은 토큰 사용개수에 따라 변동되지 않습니다.</p>
    //     </>
    // } else if (form.useType === 'PRIORITY') {
    //     return <>
    //         <h1>우선예약형</h1>
    //         <p style={{marginTop: '16px', marginBottom: '4px'}}>우선예약권자를 선택해주세요.</p>
    //         <div style={{display: 'flex', padding: '4px', backgroundColor: "#F5F5F5"}}>
    //             <PrioritySelect onClick={() => onChange({id: '우선예약권자', value: '호스트'})} checked={form.우선예약권자 === '호스트'}>호스트 우선</PrioritySelect>
    //             <PrioritySelect onClick={() => onChange({id: '우선예약권자', value: '게스트'})} checked={form.우선예약권자 === '게스트'}>게스트 우선</PrioritySelect>
    //         </div>
    //         <p style={{marginTop: '20px'}}>・ 우선예약권자가 다음달 사용일을 먼저 예약할 수 있는 방식입니다.(매월 마지막날까지 선예약 가능)</p>
    //         <p style={{marginTop: '8px'}}>・ 우선예약권자는 8개, 상대방은 15개의 토큰을 보유합니다.</p>
    //     </>
    // } else if (form.useType === 'DAY_SELECT' || form.useType === 'PRIORITY_HOST') {
    //     const id = 'daySelect'
    //     const checked = (v) => {
    //         if (form[id]) {
    //             const vs = form[id].split(',');
    //             return vs.includes(v);
    //         }
    //         return false;
    //     }
    //     const handleChange = (label) => {
    //         let options = [];
    //         if (form[id]) {
    //             options = form[id].split(',');
    //         }
    //         if (options.includes(label)) {
    //             options = filter(options, (o) => o !== label);
    //         } else {
    //             options.push(label);
    //         }
    //         options = sortBy(options, (v) => {
    //             if (v === '월') {
    //                 return 0;
    //             } else if (v === '화') {
    //                 return 1;
    //             } else if (v === '수') {
    //                 return 2;
    //             } else if (v === '목') {
    //                 return 3;
    //             } else if (v === '금') {
    //                 return 4;
    //             } else if (v === '토') {
    //                 return 5;
    //             } else if (v === '일') {
    //                 return 6;
    //             } else {
    //                 return 7;
    //             }
    //         });
    //         onChange({id, value: options.join(',')});
    //     }
    //     return <>
    //         <h1>요일 지정</h1>
    //         <p style={{marginTop: '16px', marginBottom: '4px'}}>내가 매주 타야 할 요일이 있는 경우 지정해주세요. (생략가능)</p>
    //         <div style={{display: 'flex', gap: '8px', }}>
    //             {["평일형", "휴일형"].map(day =>
    //                 <DaySelect onClick={() => handleChange(day)} checked={checked(day)}>{day}</DaySelect>
    //             )}
    //         </div>
    //         <div style={{display: 'flex', gap: '8px', marginTop: '8px'}}>
    //             {["월", "화", "수", "목", "금"].map(day =>
    //                 <DaySelect onClick={() => handleChange(day)} checked={checked(day)}>{day}</DaySelect>
    //             )}
    //         </div>
    //         <div style={{display: 'flex', gap: '8px', marginTop: '8px'}}>
    //             {["토", "일"].map(day =>
    //                 <DaySelect onClick={() => handleChange(day)} checked={checked(day)}>{day === '공휴일'? '공휴일\n(토, 일 제외)': day}</DaySelect>
    //             )}
    //         </div>
    //         <p style={{marginTop: '16px', marginBottom: '4px'}}>불필요하게 지정 시 매칭확률이 내려가요. 이용하는 날이 많은 경우는 매일 우선권이 있는 ‘호스트 우선형’을 고려해주세요!</p>
    //         <div>
    //
    //         </div>
    //     </>
    // }
    // onClose();
    // return <div></div>;
}

const DaySelect = styled.div`
  cursor: pointer;
  padding: 10px 8px;
  flex: 1 0 0;
  color: var(--gray-scale-gray-700, #939393);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 157.143% */
  white-space: pre-wrap;

  border-radius: 6px;
  border: 1px solid var(--gray-scale-gray-400, #D9D9D9);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.05);
  
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  user-select: none;

  ${(props) => props.checked && css`
    background-color: var(--mint, #33CCC1);
    border: 1px solid var(--mint, #33CCC1);
    color: white;
  `}
`

const PrioritySelect = styled.div`
  cursor: pointer;
  padding: 10px 8px;
  flex: 1 0 0;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  border-radius: 6px;
  color: var(--gray-scale-gray-700, #939393);
  user-select: none;

  ${(props) => props.checked && css`
    background: var(--gray-scale-white, #FFF);
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.05);
    color: var(--gray-scale-black, #212121);
    font-weight: 600;
  `}
`

const TextBox = styled.div`
  margin-top: 16px;
  padding: 16px 12px;
  border-radius: 8px;
  background: var(--gray-scale-gray-200, #F5F5F5);

  color: var(--gray-scale-gray-700, #939393);
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
  
  .thin {
    font-weight: 400;
  }
  .link {
    font-weight: 600;
    text-decoration-line: underline;
    cursor: pointer;
  }
`
const Wrap = styled.div`
  padding: 32px 20px 100px;
  h1 {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
  }

  h2 {
    color: var(--gray-scale-black, #212121);
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
  }
  
  h3 {
    margin: 4px 0;
    color: var(--gray-700, #939393);
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 157.143% */
  }
  
  p {
    color: var(--gray-scale-gray-700, #939393);
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    margin: 4px 0;
  }

  .underline {
    color: var(--mint, #33CCC1);
    text-decoration-line: underline;
    cursor: pointer;
  }

  .big {
    font-size: 14px;
  }
  
  > .forms {
    display: flex;
    flex-direction: column;
    gap: 32px;
    
    > .etc {
      
    }
  }
`

export default MatchingInfo;
