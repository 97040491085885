import {useDispatch, useSelector} from "react-redux";
import styled, {css} from "styled-components";
import {useLocation, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {commaNum} from "../../toolbox";
import {filter, find, get, groupBy, isEmpty, reverse, sortBy} from "lodash";
import {filterAvailableWallets, getLoginLink} from "../../toolbox/format";
import {useMutation, useQuery} from "@apollo/client";
import {CREATE_WALLET, GET_USER, GET_WALLETS_BY_ADMIN, GET_WALLETS_BY_USER} from "../../query/userQuery";
import queryString from "query-string";
import {flatEntity} from "../../toolbox/query";
import moment from "moment-timezone";
import {isAdmin, isWalletAdmin} from "../../toolbox/logic";
import {GET_ALL_CONTRACTS_CALENDAR} from "../../query/calendarQuery";
import {isShowContractCalendar, isShowContractWallet} from "../../toolbox/calendar";

export const ContractSelect = styled.div`
  padding-left: 20px;
  display: flex;
  overflow: auto;
  > .selected {
    padding: 4px;
    background-color: black;
    color: white;
    border-radius: 4px;
    margin: 4px;
  }
  > .unselected {
    padding: 4px;
    background-color: #999999;
    color: white;
    border-radius: 4px;
    margin: 4px;
  }
  
  ${(props) => !props.visible && css`
    display: none;
  `}
`

const WalletHistory = ({w}) => {
    return <History>
        <div className="left-box">
            <div className="date">
                {moment.tz(w.recordedAt ? w.recordedAt: w.createdAt, 'Asia/Seoul').format('MM.DD')}
            </div>
            <div className="text">
                <div className="title">{w.title}</div>
                {w.description && <div className="description">{w.description}</div>}
            </div>
        </div>
        <div className="right-box">
            <div className="amount">
                {w.amount >= 0? '+': '-'}{commaNum(Math.abs(w.amount))}P
            </div>
            <div className="incr">
                {w.incr >= 0? '': '-'}{commaNum(Math.abs(w.incr))}P
            </div>
        </div>
    </History>
}

export const WalletPage = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);
    const parsed = location.search ? queryString.parse(location.search, {ignoreQueryPrefix: true}) : null;
    const {data} = useQuery(GET_USER, {variables: {id: parsed?.id ?? tokenInfo?.id}, fetchPolicy: 'cache-first'});

    let user;
    if (data) {
        user = get(data, "usersPermissionsUser.data", null);
        user = flatEntity(user);
    }

    const _isAdmin = () => {
        return isWalletAdmin(tokenInfo?.id) || isAdmin(tokenInfo?.id);
    }

    useEffect(() => {
        if (!parsed?.id) {
            if (isEmpty(tokenInfo)) {
                const to = getLoginLink(location);
                navigate(to);
            } else {
                window.history.replaceState(null, null, '?id=' + tokenInfo.id);
            }
        }
    }, [tokenInfo, parsed]);

    const [createWallet, {}] = useMutation(CREATE_WALLET);

    const {data: userWallets} = useQuery(GET_WALLETS_BY_USER, {
        variables: {
            id: parsed?.id ?? tokenInfo?.id,
        },
    });

    const {data: adminContracts, refetch: refetchAdmin} = useQuery(GET_ALL_CONTRACTS_CALENDAR, {
        variables: {
            endDate: moment.tz('Asia/Seoul').subtract(2, 'month').format('YYYY-MM-DD'),
        },
        fetchPolicy: "cache-first",
        skip: !_isAdmin(tokenInfo?.id),
    });

    const {data: adminWallets, refetch} = useQuery(GET_WALLETS_BY_ADMIN, {
        fetchPolicy: "cache-first",
        skip: !_isAdmin(tokenInfo?.id),
    });

    const [contracts, setContracts] = useState(null);
    const [selectedContractId, setSelectedContractId] = useState(null);

    const [wallets, setWallets] = useState(null);
    const [remains, setRemains] = useState(null);

    useEffect(() => {
        if (_isAdmin(tokenInfo?.id)) {
            if (adminWallets && adminContracts) {
                let wallets = get(adminWallets, "wallets.data", []);
                let _contracts = get(adminContracts, 'contracts.data', []);
                _contracts = _contracts.map(c => flatEntity(c));
                _contracts = filter(_contracts, c => c.vehicle);
                _contracts = filter(_contracts, c => isShowContractWallet(c));
                processWallets(wallets, _contracts);
            }
        } else {
            if (userWallets) {
                let wallets = get(userWallets, "wallets.data", []);
                processWallets(wallets);
            }
        }
    }, [userWallets, adminWallets, adminContracts]);

    useEffect(() => {
        let wallets = filterWallets();
        if (wallets) {
            let incr = 0;
            for (let w of wallets) {
                incr += w.amount;
                w.incr = incr;
            }
            setRemains(incr);
        }
    }, [selectedContractId, wallets]);

    const processWallets = (wallets, contracts) => {
        wallets = sortBy(wallets.map(e => flatEntity(e)), (w) => {
            return w.recordedAt ? w.recordedAt: w.createdAt;
        });
        setWallets(wallets);

        const walletGroup = groupBy(wallets, (w) => w.contract.id);
        const cs = [];
        for (let contractId in walletGroup) {
            let sum = 0;
            for (let w of walletGroup[contractId]) {
                sum = sum + w.amount;
            }
            cs.push({...walletGroup[contractId][0].contract, sum});
        }
        if (contracts) {
            for (let _c of contracts) {
                if (!find(cs, c => String(c.id) === String(_c.id))) {
                    cs.push({..._c, sum: 0});
                }
            }
        }
        setContracts(reverse(sortBy(cs, (c) => Math.abs(c.sum))));
    }

    const filterWallets = () => {
        if (wallets) {
            let _wallets = filterAvailableWallets(wallets);
            _wallets = sortBy(_wallets, (w) => {
                return w.recordedAt ? w.recordedAt: w.createdAt;
            });

            if (selectedContractId) {
                _wallets = filter(_wallets, (w) => w.contract.id === selectedContractId);
            }

            let incr = 0;
            for (let w of _wallets) {
                incr += w.amount;
                w.incr = incr;
            }
            return reverse(_wallets);
        }
        return null;
    }

    return <_WalletPage>
        <h2>{_isAdmin(tokenInfo?.id) ? '관리자': user?.realname ? `${user.realname}님`: ''} 월렛 내역</h2>
        <ContractSelect visible={contracts && contracts.length > 1}>
            {contracts && contracts.map(c => <div key={c.id} className={selectedContractId === c.id? 'selected': 'unselected'}
                     onClick={() => {
                         if (selectedContractId === c.id) {
                             setSelectedContractId(null);
                         } else {
                             setSelectedContractId(c.id);
                         }
                     }}>
                {c.vehicle?.model ?? '오류'} ({c.vehicle?.numberPlate ?? '오류'}) {_isAdmin(tokenInfo?.id) ? `(${c?.users_permissions_user?.realname ?? '이름없음'})`: ''}
            </div>)}
        </ContractSelect>
        <div style={{fontSize: '18px', textAlign: 'center', marginTop: '12px', marginBottom: '48px', fontWeight: '700'}}>
            {remains !== null && `현재 잔액 : ${commaNum(remains)}P`}
        </div>
        {isAdmin(tokenInfo?.id) && selectedContractId && <div
            style={{marginBottom: '26px', textAlign: 'center', padding: '8px', borderRadius: '12px', border: '1px solid black'}}
            onClick={async () => {
                let amount = window.prompt('생성할 금액을 숫자로 입력하세요. (차감은 마이너스 기호를 붙여주세요)');
                if (amount) {
                    amount = Number(amount);
                    const contract = find(contracts, c => c.id === selectedContractId);
                    const wallet = await createWallet({
                        variables: {
                            data: {
                                title: "수익금 출금",
                                amount: amount,
                                user: contract.users_permissions_user.id,
                                memo: '월렛페이지에서 생성됨',
                                contract: selectedContractId,
                            },
                        },
                        onCompleted: data => {
                            refetch();
                        }
                    });
                    const createdId = wallet?.data?.createWallet?.data?.id;
                    window.prompt(`"수익금 출금" 으로 생성되었습니다. 제목 또는 내용 수정은 아래 strapi 링크에서 해주세요.`,
                        `https://dry-river-37620.herokuapp.com/admin/content-manager/collectionType/api::wallet.wallet/${createdId}`);
                } else {
                }
            }}>
            월렛 히스토리 생성하기
        </div>}
        <div>
            {filterWallets() && filterWallets().map((w) =>
                <>
                    <WalletHistory w={w} />
                    <MyDivider />
                </>
            )}
            {filterWallets() && wallets.length === 0 && <div>
                표시할 항목이 없습니다.
            </div>}
        </div>
    </_WalletPage>
}

const _WalletPage = styled.div`
  box-sizing: border-box;
  font-family: 'Pretendard',sans-serif;
  padding-left: 10px;
  padding-right: 10px;

  > h2 {
    padding: 20px 16px;
    margin-bottom: 12px;
  }
`

const History = styled.div`
  display: flex;
  justify-content: space-between;
  
  .left-box {
    display: flex;
    
    .date {
      font-size: 14px;
      min-width: 60px;
    }
    .text {
      font-size: 16px;
      .title {
        
      }
      
      .description {
        margin-top: 6px;
        font-size: 14px;
        white-space: pre-wrap;
        line-height: 120%;
      }
    }
  }
  
  .right-box {
    text-align: right;
    .amount {
      font-weight: 700;
      font-size: 16px;
    }
    
    .incr {
      margin-top: 6px;
      color: #808080;
      font-size: 14px;
    }
  }
`
const MyDivider = styled.div`
  box-sizing: border-box;
  width: 100%;
  border-width: 0;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.12);
  border-bottom-width: thin;
  padding: 0 22px;
  margin-top: 14px;
  margin-bottom: 14px;
`

