import React from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import {Link} from "react-router-dom";

import SwiperCore, {Autoplay, Navigation, Pagination} from "swiper";

SwiperCore.use([Navigation, Autoplay, Pagination]);

export const EventSwipe = ({images}) => {
    return <Swiper
        style={{width: '100%'}}
        className="swiper-container"
        // autoplay={{delay: 5000}}
        // pagination={true}
        spaceBetween={5}
        slidesPerView={1}
        initialSlide={0}
        loop>
        {images.map((img, idx) => {
            return (
                <SwiperSlide key={idx}>
                    <div>
                        {img.link ?
                            <Link to={img.link} onClick={() => {
                                if (img.onClick) {
                                    img.onClick();
                                }}}>
                                <img style={{width: '100%'}} src={img.url} />
                            </Link>: img.onClick?
                                <Link>
                                    <img onClick={() => {
                                        if (img.onClick) {
                                            img.onClick();
                                        }}} style={{width: '100%'}} src={img.url} />
                                </Link>:
                                <img style={{width: '100%'}} src={img.url} />}
                    </div>
                </SwiperSlide>
            )
        })}
    </Swiper>
};
